import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Input, Label, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { dashboardSaveRequest, dashboardUpdateRequest } from "store/report/action";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { ApplicationState } from "store";

interface EditModelProps {
    dashboardName: any;
    reports: any;
    saveModel: boolean;
    setSaveModel: (saveModel: boolean) => void;
    t: (key: string) => string;
}

const EditModel: React.FC<EditModelProps> = ({
    dashboardName,
    reports,
    saveModel,
    setSaveModel,
    t,
}) => {
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const loading = useSelector((state: ApplicationState) => state.report.dashboard_save_loading)
    const navigate = useNavigate();
    
    const [name, setName] = useState('')
    const [newName, setNewName] = useState('')
    const [edit, setEdit] = useState(true)

    useEffect(() => {
        if(dashboardName) {
            setName(dashboardName)
        }
    }, [dashboardName])

    const onSaveReport = () => {
        const reportAssociations = reports.map((report: any) => {
            return {
                report_id: report?.report?.id,
                layout: {
                    key: report.key,
                    layout: report.layout,
                    title: report.title,
                }
            }
        })
        const data = {
            name: edit ? name : newName,
            config: {},
            report_associations: reportAssociations
        }
        const handleSuccess = () => {
            setSaveModel(false);
            toast(`Dashboard saved successfully.`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            navigate('/dashboard');
        }
        const handleError = () => {
            toast(`Error saving dashboard.`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
        }
        if(edit) {
            dispatch(dashboardUpdateRequest(id, data, handleSuccess, handleError))
        } else {
            dispatch(dashboardSaveRequest(data, handleSuccess, handleError))
        }
    }
    return (
        <>
            <ToastContainer />
            <Modal isOpen={saveModel} toggle={() => loading ? null : setSaveModel(false)} backdrop={'static'} centered>
                <ModalHeader className="bg-light p-3" toggle={() => loading ? null : setSaveModel(false)}>
                    {t("dashboard.save_dashboard")}
                </ModalHeader>
                    <ModalBody>
                        <div className="d-flex flex-column gap-3 p-3">
                            <div className="d-flex flex-column gap-1">
                                <div className="form-check form-radio-primary">
                                    <Input className="form-check-input" type="radio" name="editModelRadio" onChange={() => setEdit(true)} checked={edit} />
                                    <Label className="form-check-label" for="formradioRight5">
                                    {t("common.modals.save_changes")}
                                    </Label>
                                </div>
                                <div className="form-group mb-3">
                                    <Label className="form-label fw-bold fs-6">
                                        Name
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder='Enter report name'
                                        onChange={(event) => setName(event?.target.value)}
                                        value={name}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-1">
                                <div className="form-check form-radio-primary">
                                    <Input className="form-check-input" type="radio" name="editModelRadio" onChange={() => setEdit(false)} checked={!edit} />
                                    <Label className="form-check-label" for="formradioRight5">
                                        {t("dashboard.save_as_new_dashboard")}
                                    </Label>
                                </div>
                                <div className="form-group mb-3">
                                    <Label className="form-label fw-bold fs-6">
                                        Name
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder='Enter report name'
                                        onChange={(event) => setNewName(event?.target.value)}
                                        value={newName}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <div className="modal-footer">
                        <div className="hstack gap-2 justify-content-end">
                            <Button color="danger"
                                disabled={loading}
                                onClick={() => {
                                    setSaveModel(false)
                                }}
                            >{t("common.offcanvas.import_cancel")}</Button>
                            {loading ? 
                            <Button color="primary" className="btn-label btn-load" disabled>
                                <i className="ri-tools-fill label-icon align-middle fs-16 me-2"></i>
                                <span className="d-flex align-items-center">
                                    <span className="flex-grow-1 me-2">
                                    {t("common.offcanvas.saving")}
                                    </span>
                                    <Spinner size="sm" className="flex-shrink-0" role="status"> {t("common.offcanvas.saving")} </Spinner>
                                </span>
                            </Button> : <Button
                                onClick={() => onSaveReport()}
                                disabled={!name}
                                color="primary"
                                className="btn-label">
                                    <i className="ri-tools-fill label-icon align-middle fs-16 me-2"></i>
                                    {t("common.offcanvas.save")}
                            </Button>}
                        </div>
                    </div>
            </Modal>
        </>
    );
};

export default withTranslation()(EditModel);
