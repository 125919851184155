import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Listing from 'Components/Common/Views/Listing';
import { paymentCreateRequest, paymentDelete, paymentGetRequest, paymentListGetRequest, paymentsMetadataGetRequest, paymentUpdateRequest } from 'store/payments/action';

const Payment = (props: any) => {
    const { viewId, isCreateModal, setCreateOpen } = props;    
    return (
        <Listing 
            viewId={viewId}
            isCreateModal={isCreateModal}
            setCreateOpen={setCreateOpen}
            getListAction={paymentListGetRequest}
            deleteAction={paymentDelete}
            detailsGetAction={paymentGetRequest}
            metaDatagetRequest={paymentsMetadataGetRequest}
            createAction={paymentCreateRequest}
            updateAction={paymentUpdateRequest}
            model={"payments"}
            reducerState={"payments"}
            translater={"payment"}
            isEditDrawer={true}
            isViewDrawer={true}
        />
    );
};
export default withTranslation()(Payment);


