
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, ButtonGroup, Card, Col, Input, Label, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
import { ApplicationState } from "store";
import { studentsMetadataGetRequest } from "store/student/action";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Select from "react-select";
import { formCreateRequest } from "store/customform/action";
import Settings from "./Setting";
import { withTranslation } from "react-i18next";
import FormPreview from "./FormPreview";
import { toast, ToastContainer } from "react-toastify";
import { isJson } from "utils";
import { tenantGetRequest } from "store/user/action";
import DtsCKEditor from "Components/Common/FormBuilder/DtsCKEditor";


const CustomForm: React.FC<any> = (props: any) => {
    const navigate = useNavigate()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [formValues, setFormValues] = useState<any>({});
    const loading = useSelector((state: ApplicationState) => state.form.loading);
    const studentMetadata = useSelector((state: ApplicationState) => state.student.metaDataFields);
    const isLoading = useSelector((state: ApplicationState) => state.customform.loading);
    const formloading = useSelector((state: ApplicationState) => state.customform.formloading);
    const tenatDetails = useSelector((state: ApplicationState) => state.user.tenatDetails);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const [formtitle, setFormtitle] = useState("")
    const [formname, setFormname] = useState("")
    const [formDescription, setFormDescription] = useState<any>({})
    const [index, setIndex] = useState<any>(-1);
    const [searchvalue, setSearchvalue] = useState<any>("");
    const [dataFields, setDataFields] = useState<any>([])
    const [rawFields, setRawFields] = useState<any>([])
    const [formField, setformField] = useState<any>([])
    const [cols, setCols] = useState<any>(6)
    const [record, setRecord] = useState<any>({})
    const [isSettingopen, setSettingopen] = useState(false)
    const [isPreview, setIsPreviewOpen] = useState(false)
    const [components, setComponents] = useState([])
    const [btndisabled, setbtnDisabled] = useState(false)
    const [tenant, setTenant] = useState<any>({})
    const [error, setError] = useState<string>('')

    useEffect(() => {
        setDataFields(studentMetadata)
    }, [studentMetadata])
    
    useEffect(() => {
        let filters = { pageSize: 500 }
        dispatch(studentsMetadataGetRequest("students", filters));
        dispatch(tenantGetRequest(userProfile?.tenant?.id))
    }, [])

    useEffect(() => {
        let tenant = tenatDetails?.settings && isJson(tenatDetails?.settings) ? JSON.parse(tenatDetails?.settings) : tenatDetails?.settings;
        setTenant(tenant) 
    }, [tenatDetails])

    useEffect(() => {
        let fields: any = [];
        dataFields && dataFields.length && dataFields.map((field: any) => {
            let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
            let newField = { ...item, id: field.id }
            if (field.valuesJson) return fields.push(newField)
        })
        setRawFields(fields)
    }, [dataFields])
    
    useEffect(() => {
        let formcomponents: any = [];
        formField && formField.map((item: any) => {
            let field = { id: item.id, extras: { key: item.key, hidden: item.hidden } }
            formcomponents.push(field);
        })
        setComponents(formcomponents)
    }, [formField])

    
    useEffect(() => {
        let error = 'Please Check for mandatory Fields-'
        if(!formname) {
            error=error+""+"name,"
        }
        if(!formtitle) {
            error=error+""+"header,"
        }
        if(!components?.length) {
            error=error+""+"select fields for form"
        }
        setError(error)
    }, [formname, formtitle, components])

    const onDragEnd = (result: any) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if (destination.droppableId === 'rawFields') {
            return;
        }
        if (source.droppableId === destination.droppableId) {
            const newFormFields = [...formField];
            const [removed] = newFormFields.splice(source.index, 1);
            newFormFields.splice(destination.index, 0, removed);
            setformField(newFormFields);
        } else {
            const newFormFields = [...formField];
            const newRawFields = [...rawFields];
            const [removed] = newRawFields.splice(source.index, 1);
            newFormFields.splice(destination.index, 0, removed);
            setformField(newFormFields);
            setRawFields(newRawFields);
        }
    };


    const search = (value: any) => {
        let updatedList: any = [];
        dataFields && dataFields.length && dataFields.map((field: any) => {
            let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
            let newField = { ...item, id: field.id }
            if (newField?.label.toLowerCase().search(value.toLowerCase()) !== -1) updatedList.push(newField)
        })
        updatedList = updatedList.filter((field: any) => {
            return !formField.find((formFieldItem: any) => (formFieldItem.id === field.id))
        })
        setRawFields(updatedList)
        setSearchvalue(value);
    }

    const ColsOptions = [
        {
            label: 1,
            value: 12
        },
        {
            label: 2,
            value: 6
        },
        {
            label: 3,
            value: 4
        }
    ]

    const onCreate = () => {
        setbtnDisabled(true)
        if (formname && formtitle) {
            let newFormData = { components: components, column: cols, values: formValues, title: formtitle, description: formDescription }
            let data = {
                "name": formname,
                "valuesJson": JSON.stringify(newFormData)
            }
            const handlSuccess = (body: any) => {
                setIsPreviewOpen(false)
                navigate('/forms')
            }
            const handleFailure = (body: any) => {
            }
            dispatch(formCreateRequest(data, handlSuccess, handleFailure))
        }
    };

    const customStyles = {
        // Customize the style of the dropdown menu
        control: (provided: any, state: any) => ({
            ...provided,
            // color: 'black',
            fontWeight: 900,
            border: state.isFocused ? '0.5px solid lightgrey' : '0.5px solid lightgrey',
            borderRadius: 8,
            boxShadow: 'none',
            '&:hover': {
                border: '0.5px solid lightgrey',
            },
        }),
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: 'white',
            borderRadius: 8,
            //   boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            //   border: '1px solid #ccc',
        }),
        // Customize the style of the option in the dropdown menu
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'white' : 'white', // Custom background color for selected option
            color: state.isSelected ? 'black' : 'black', // Custom text color for selected option
            '&:hover': {
                backgroundColor: 'lightgrey', // Custom background color on hover
            },
        }),
    }

    const onChange = async (option: any, key: any, field: any) => {  
        if(field.type === "selectboxes" || field.isMulti) {   
            setFormValues({ ...formValues, [key]: option })
        }        
        else if(key === 'parent') {
            setFormValues({ ...formValues, [key]: option?.value || option })
        }
        else if(key === 'source') {
            let newFormvalues = { ...formValues, [key]:  option, subagent: null }
            setFormValues(newFormvalues)
        }
        else if(key === 'subagent' && option !=null) {
            setFormValues({ ...formValues, [key]: option?.value || option, source: "c017ce92-15ae-47b1-9ab1-197895ce94d6" })
        }        
        else if(isNaN(Date.parse(option)) === false) {
            setFormValues({...formValues, [key]: option})
        }
        else if (typeof option === 'object') {            
            setFormValues({ ...formValues, [key]: option?.value || option })
        }
        else {
            setFormValues({ ...formValues, [key]: option })
        }
    };

    const onBack = () => {
        navigate("/forms", { replace: true });
    }

    const onRemove = (obj: any) => {
        let newList = formField.filter((item: any) => item.id !== obj.id);
        let newrowfields = rawFields;
        setformField(newList)
        setComponentFields(newList)
        dataFields && dataFields.length && dataFields.map((field: any, index: any) => {
            let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
            let newField = { ...item, id: field.id }
            if (field.valuesJson && field.id === obj.id) {
                newrowfields.splice(index, 1, newField);
            }
        })
        setRawFields(newrowfields)
    }

    const setComponentFields = (newFields: any) => {
        let formcomponents: any = []
        newFields && newFields.map((item: any) => {
            let field = { id: item.id, extras: { key: item.key, hidden: item.hidden } }
            formcomponents.push(field);
        })
        setComponents(formcomponents)
    }

    const onUpdateSetting = () => {
        let newList = formField;
        let index = newList.findIndex((element: any) => element.id === record?.id);
        newList.splice(index, 1, record);
        setformField(newList)
        setComponentFields(newList)
        setSettingopen(false)
    }

    const onSettingsClick = async (data: any) => {
        setRecord(data);
        setSettingopen(true)
    };

    const onpreview = (e: any) => {
        e.preventDefault();
        if (formname && formtitle && components.length) {
            setIsPreviewOpen(true)
        }
        if (!formname) {
            const message = () => toast("Form name is mandatory field", { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            message();
        }
        if (formname && !formtitle) {
            const message = () => toast("Form header is mandatory field", { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            message();
        }
        if (formname && formtitle && !components.length) {
            const message = () => toast("No fileds selected for form", { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            message();
        }
    }


    let rows = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "1", "2", "3", "4", "5"]

    return (
        <div>
            <ToastContainer />
            <Settings
                show={isSettingopen}
                onCloseClick={() => setSettingopen(false)}
                props={props}
                record={record}
                onUpdateSetting={onUpdateSetting}
                setRecord={setRecord}
            />
            <FormPreview
                show={isPreview}
                onCloseClick={() => setIsPreviewOpen(false)}
                props={props}
                formField={formField}
                cols={cols}
                title={formtitle}
                description={formDescription}
                onUpdateSetting={onUpdateSetting}
                setRecord={setRecord}
                formValues={formValues}
                onCreate={onCreate}
                loading={loading}
                btndisabled={btndisabled}
                tenant={tenant}
            />
            <Card className="p-3">
                <Row>
                    <div className='hstack gap-2 justify-content-start mb-2'>
                        <div className='hstack gap-2'>
                            <Button
                                onClick={() => onBack()}
                                color="primary"
                                className="btn-label btn-sm">
                                <i className="ri-article-line label-icon align-middle fs-16 me-2"></i>
                                Go to Forms
                            </Button>
                        </div>
                    </div>
                </Row>
                <Row className="mt-1 mb-2">
                    <Col>
                        <div>
                            <Label>
                                Form Name
                            </Label><span className="fw-bold text-danger">{" "}*</span>
                            <Input type={"text"} className="form-control w-50" placeholder={"Enter Form name"} value={formname} onChange={(e: any) => setFormname(e.target.value)} />
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <Label>
                                Form Header
                            </Label><span className="fw-bold text-danger">{" "}*</span>
                            <Input type={"text"} className="form-control w-50" placeholder={"Enter Form Tile"} value={formtitle} onChange={(e: any) => setFormtitle(e.target.value)} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-3">                
                        <div>
                            <Label>
                                Description
                            </Label>
                            <div>                           
                                 <DtsCKEditor
                                    value={formDescription?.body ? formDescription?.body : ""}
                                    onChange={(data: any) => {
                                        setFormDescription({ ...formDescription, body: data })
                                    }}
                                    name={'body'}
                                    type={'texteditor'}
                                    actionButtons={false}
                                    defaultValue={formDescription?.body}
                                />
                            </div>                 
                        </div>
                    </Col>
                </Row>
                <hr />
                <DragDropContext onDragEnd={onDragEnd}>
                    <Row>
                        <Col lg={4} md={4}
                            onDragOver={(e) => e.preventDefault()}>
                            <h6 className="fw-bold mt-2 fs-16 mb-3 ps-1">Select Field to add</h6>
                            <div className="search-box">
                                <Input
                                    type="text"
                                    size={14}
                                    className="search"
                                    placeholder={"Search Field..."}
                                    onChange={(e) => search(e.target.value)}
                                    value={searchvalue}
                                />
                                {searchvalue == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => search("")}></i>}

                            </div>
                            <SimpleBar autoHide={false} className="simplebar-track-dark p-3" style={{ maxHeight: "600px" }}>
                                <Droppable droppableId="rawFields">
                                    {(provided: any) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className="vstack gap-2"
                                        >
                                            {!isLoading ? rawFields &&
                                                rawFields.length ?
                                                rawFields.map((field: any, index: number) => {
                                                    return <Draggable key={field.key} draggableId={field.key} index={index}>
                                                        {(provided: any) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <div>
                                                                    <Label className="fw-bold fs-16">{field.label}</Label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                }) :
                                                <div>
                                                    <span className="fs-16">No fields to show</span>
                                                </div>
                                                : <div>{
                                                    rows.map((item: any) => (
                                                        <div key={index} className="placeholder-glow p-1">
                                                            <span className="placeholder col-8"></span>
                                                        </div>
                                                    ))}</div>
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </SimpleBar>
                        </Col>
                        <Col lg={8} md={8}>
                            <div>
                                <h6 className="fw-bold mt-2 fs-16 mb-3 ps-1">Selected Fields ({formField.length}) </h6>
                                <div>
                                    <div className="vstack">
                                        <Label>Select Number of Columns </Label>
                                        <span className="hstack fs-12 fw-mediumr">{" "}{" ("}This will divide panel vertically in number of columns{")"}</span>
                                    </div>
                                    <Select
                                        defaultOptions
                                        styles={customStyles}
                                        placeholder={'Select Columns'}
                                        className="form-select-sm p-0 border-0"
                                        defaultValue={{ label: 2, value: 6 }}
                                        onChange={(option: any) => setCols(option.value)}
                                        options={ColsOptions}
                                        name="choices-single-default"
                                    ></Select>
                                </div>
                            </div>
                            <Droppable droppableId="formField">
                                {(provided: any) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className="vstack h-100"
                                    >
                                        <div
                                            onDragOver={(e: any) => e.preventDefault()}>
                                            <Row className="hstack h-100 p-2">
                                                {!formloading ? formField &&
                                                    formField.length ?
                                                    formField.map((field: any, index: number) => {
                                                        return <Col sm={12} md={cols} xl={cols} style={{ cursor: 'grab' }}>
                                                            <Draggable key={field.key} draggableId={field.key} index={index}>
                                                                {(provided: any) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}

                                                                    >
                                                                        <div className="search-box">
                                                                            <FormBuilder
                                                                                placeholder={field.placeholder}
                                                                                name={field.key}
                                                                                onChange={(e: any) => onChange(e, field.key, field)}
                                                                                options={(field.values && field.values.reverse()) || []}
                                                                                label={field.label}
                                                                                type={field.type}
                                                                                value={formValues[field.key]}
                                                                                defaultValue={formValues[field.key]}
                                                                                isEditState={true}
                                                                                actionButtons={false}
                                                                                Editable={true}
                                                                                rest={field}
                                                                                invalid={true}
                                                                                formValues={formValues}
                                                                                dataFields={formField}
                                                                            />

                                                                            <ButtonGroup className="mt-4 mt-sm-0" style={{ right: 1, left: 'auto', top: -3, position: "absolute" }}>
                                                                                <Button color="success" className="btn-icon btn-soft-success btn-sm" onClick={() => onSettingsClick(field)}> <i className="ri-settings-5-line" /> </Button>
                                                                                <Button color="danger" className="btn-icon btn-soft-danger btn-sm" onClick={() => onRemove(field)}> <i className="ri-close-circle-fill" /> </Button>
                                                                            </ButtonGroup>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        </Col>
                                                    }) : <div className="p-3 fw-bold fs-16">No fields Selected. Drag Fields from left and drop here.</div>
                                                    : <div className="w-100">{
                                                        rows.map((item: any, index: any) => {
                                                            return <div className="hstack w-100 p-2">
                                                                {[1, 2].map((column: any, index: any) => {
                                                                    return <div key={index} className="placeholder-glow col-6">
                                                                        <span className="placeholder col-12">{column}</span>
                                                                    </div>
                                                                })}</div>
                                                        }
                                                        )}</div>
                                                }
                                                {provided.placeholder}
                                            </Row>
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        </Col>
                    </Row>
                    <Row>
                        <div className='hstack justify-content-end gap-2' title={!formname || !formtitle || !components.length ? error : ''}>
                            <div>
                                <Button disabled={!formname || !formtitle || !components.length} onClick={(e) => onpreview(e)} className="btn btn-success w-md mb-3">Preview</Button>
                            </div>
                        </div>
                    </Row>
                </DragDropContext>
            </Card>
        </div>
    )
};

export default withTranslation()(CustomForm);