import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
    Button,
    Label,
    Input,
} from "reactstrap";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { teamsListGetRequest, teamGetRequest, teamUpdateRequest } from "store/teams/action";
import { ApplicationState } from "store";
import { useEffect, useState } from "react";

const EditEntry = ({ t, show, onClose, id}: any) => {
	const teamList = useSelector((state: ApplicationState) => state.teams.teams);
	const teamDetails = useSelector((state: ApplicationState) => state.teams.team);
	const loading = useSelector((state: ApplicationState) => state.teams.loading);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [teams, setTeams] = useState<any>([]);
    const [team, setTeam] = useState<any>(null);
    const [name, setName] = useState<any>("");

    useEffect(() => {
        if(id) {
            dispatch(teamGetRequest(id))
        }
    }, [id])

    useEffect(() => {
        if(teamDetails?.id) {
            const { name, parentTeam } = teamDetails;
            setName(name)
            if(parentTeam) {
                setTeam({label: parentTeam.name, value: parentTeam.id})
            }
        }
    }, [teamDetails])

    useEffect(() => {
        const teams = teamList?.filter(team => team.id !== id)
            .map(team => ({
                label: team?.name,
                value: team?.id
            }));
        setTeams(teams);

    }, [teamList])
    const OnAddEntry = () => {
        let data = {
            name,
            parentTeamId: team?.value
        }
        const handleSuccess = (body: any) => {
            dispatch(teamsListGetRequest())
            setTeam(null);
            setName(null);
            onClose()
        }
        const handleFailure = (body: any) => {
        }
        dispatch(teamUpdateRequest(id, data, handleSuccess, handleFailure))
    }
    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onClose}
            backdrop="static"
        >
            <OffcanvasHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={() => { onClose() }}>
                <span className="m-0 me-2 text-white">
                {t("team.edit.edit_team")}
                </span>
            </OffcanvasHeader>
            <OffcanvasBody>
                <Card className="border-0" style={{ boxShadow: 'none' }}>
                    <CardBody className="border-0 d-flex flex-column gap-4">
                        <div className="d-flex flex-column gap-1">
                            <Label htmlFor="exampleInputrounded" className="form-label">
                                {t("team.edit.parent_team")}
                            </Label>
                            <Input
                                name="team"
                                id="id-field"
                                className="form-control"
                                placeholder={t("team.edit.no_team_selected")}
                                type="text"
                                value={team?.label || t("team.edit.no_team_selected")}
                                disabled={true}
                            />
                        </div>
                        <div className="d-flex flex-column gap-1">
                            <Label
                                htmlFor="id-field"
                                className="form-label"
                            >
                                {t("team.edit.name")}<span className="text-danger"> *</span>
                            </Label>
                            <Input
                                name="name"
                                id="id-field"
                                className="form-control"
                                placeholder={t("team.edit.enter_team_name")}
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </CardBody>
                </Card>
            </OffcanvasBody>
            <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
                <Button
                    color="primary"
                    className="btn w-100"
                    onClick={() => OnAddEntry()}
                    disabled={loading || !name}
                >
                    {loading ? t("team.edit.btn_update_team_loading") : t("team.edit.btn_update_team")}
                </Button>
            </div>
        </Offcanvas>
    );
};

export default withTranslation()(EditEntry);

