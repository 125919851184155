import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, ListGroup, ListGroupItem, UncontrolledDropdown } from "reactstrap";
import { Action } from "redux";
import { ApplicationState } from "store";
import { reportsGetRequest } from "store/report/action";
import { Report } from "store/report/types";

const ICONS: any = {
    "textfield": "ri-input-method-line",
    "textarea": "ri-file-text-line",
    "varchar": "ri-file-text-line",
    "phone": "ri-input-method-line",
    "email": "ri-input-method-line",
    "checkbox": "ri-checkbox-line",
    "selectboxes": "ri-checkbox-multiple-line",
    "datetime": "ri-calendar-line",
    "select": "ri-list-check",
    "datasetselect": "ri-database-2-line",
    "treeselect": "ri-database-2-line",
    "radio": "ri-radio-button-line",
    "number": "ri-hashtag",
    "file": "ri-file-upload-line"
}

interface ReportPanelProps {
    selectedReports: any;
    onAddReport: (report: any) => void;
    t?: any;
}

const ReportPanel: React.FC<ReportPanelProps> = ({selectedReports, onAddReport, t}) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const reports_loading = useSelector((state: ApplicationState) => state.report.reports_loading);
    const reports = useSelector((state: ApplicationState) => state.report.reports);
    const navigate = useNavigate();
    
    const [toggleData, setToggleData] = useState(true);
    const [filteredReports, setFilteredReports] = useState<Report[] | null>(null)
    const [search, setSearch]: any = useState('')

    useEffect(() => {
        dispatch(reportsGetRequest())
    }, [])

    useEffect(() => {
        setFilteredReports(reports);
    }, [reports])

    const onCreate =  () => {
        navigate('/reports');
    }

    const onSearch = (search: string) => {
        setSearch(search)
        // Create a case-insensitive regex for searching
        if(search) {
            const searchRegex = new RegExp(search, 'i');
            const filtered = reports?.filter((report) => {
                    const reportName = report?.name.toLowerCase();
                    return (
                        searchRegex.test(reportName)
                    );
                })
                .sort((a, b) => {
                    const reportA = (a['name'] as string || '').toLowerCase();
                    const reportB = (b['name'] as string || '').toLowerCase();
                    return reportA.localeCompare(reportB);
                });
            setFilteredReports(filtered || []);
        } else {
            setFilteredReports(reports);
        }
    };

    if(toggleData) {
        return (
            <div className="d-flex flex-column h-100 bg-white border-end">
                <div className="d-flex h-100 flex-column gap-2" style={{width: "350px"}}>
                    <div className="d-flex justify-content-between px-3 py-2 align-items-center bg-primary-subtle">
                        <h5 className="m-0 text-primary">{t("dashboard.reports")}</h5>
                        <Button color="primary" className="btn-icon btn-ghost-primary" onClick={() => setToggleData(false)}>
                            <i className="cursor-pointer ri-indent-decrease fs-4"></i>
                        </Button>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center gap-2 border-bottom border-primary-subtle pb-2">
                        <div className="form-icon">
                            <Input
                                type="text"
                                className="form-control form-control-sm form-control-icon fs-14 bg-light-subtle"
                                placeholder={t("common.filter.search.search_reports")}
                                value={search}
                                onChange={(e: any) => onSearch(e.target.value)}
                            />
                            <i className="fs-14 ri-search-line text-muted"></i>
                        </div>
                    </div>
                    <div>
                        {reports_loading ? <div className="d-flex gap-2 flex-column h-100 overflow-x-hidden overflow-y-scroll pb-5 placeholder-glow">
                            <ListGroup flush>
                            {
                                Array.from({ length: 20 }, (_, index) => index + 1).map((count) => {
                                    return (
                                        <ListGroupItem key={count} className="cursor-pointer d-flex list-group-item-action justify-content-between border-0">
                                            <div className="cursor-pointer d-flex list-group-item-action gap-2">
                                                <i className="placeholder" style={{height: "20px", width: "20px"}}></i>
                                                <Label className="cursor-pointer m-0 text-dark placeholder w-100"></Label>
                                            </div>
                                        </ListGroupItem>
                                    )
                                })
                            }
                            </ListGroup>
                        </div> : filteredReports?.length ? <div className="d-flex gap-2 flex-column h-100 overflow-x-hidden overflow-y-scroll pb-5">
                            <ListGroup flush>
                            {
                                filteredReports?.map((report: any, reportIndex: number) => {
                                    return (
                                        <ListGroupItem key={reportIndex} className="cursor-pointer d-flex list-group-item-action justify-content-between border-0">
                                            <div className="cursor-pointer d-flex list-group-item-action gap-2">
                                                <i className={`${ICONS[report.column_type]}`}></i>
                                                <Label className="cursor-pointer m-0 text-dark">{report.name}</Label>
                                            </div>
                                            <ButtonGroup>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle tag="button" className="px-1 py-0 btn btn-ghost-primary">
                                                        <i className="ri-more-2-fill"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => onAddReport(report)}>{t("dashboard.add_to_dashboard")}</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </ButtonGroup>
                                        </ListGroupItem>
                                    )
                                })
                            }
                            </ListGroup>
                        </div> : 
                        <div className="d-flex align-items-center justify-content-center px-2 py-3">
                            <div className="d-flex flex-column align-items-center justify-content-center w-100 gap-3">
                                <Label>{t("dashboard.no_dashboard_message")}</Label>
                                <div>
                                    <Button
                                        color="primary"
                                        className="btn-label"
                                        onClick={() => onCreate()}
                                    >
                                            <i className="ri-server-line label-icon align-middle fs-16 me-2"></i>
                                            {t("dashboard.create_report")}
                                    </Button>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="d-flex flex-column bg-white border-end">
                <div className="d-flex justify-content-between p-2 py-4 align-items-center">
                    <Button color="primary" className="btn-icon btn-ghost-primary" onClick={() => setToggleData(true)}>
                        <i className="cursor-pointer ri-indent-increase fs-4"></i>
                    </Button>
                </div>
            </div>
        )
    }
};

export default withTranslation()(ReportPanel);
