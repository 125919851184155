import React, { useState } from "react";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { Button, Spinner } from 'reactstrap';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { callsDocumentDownload } from "store/calls/action";

const Recording = ({ id }: any) => {
   const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
   const [recording, setRecording] = useState<any>(null)
   const [recordingDownload, setRecordingDownload] = useState<any>(null)
   const onClickRecording = (callsId: any) => {
      let queryParams: any = new URLSearchParams({});
      queryParams.set('id', callsId)
      queryParams.set('isRecording', true)
      setRecordingDownload(true)
      const handleSuccess = async (blob: any) => {
         try {

            const url = window.URL.createObjectURL(blob);
            setRecording(url)
            setRecordingDownload(false)
         } catch (error) {
            console.error("Error downloading file:", error);
         }
      }
      const handleFailure = () => {
      }
      dispatch(callsDocumentDownload(queryParams.toString(), handleSuccess))
   }
   return (
      <React.Fragment>
         {recording ? <AudioPlayer
            style={{
               width: '300px'
            }}
            autoPlay
            src={recording}
            onPlay={e => console.log("onPlay", e)}
            showJumpControls={false}
            customAdditionalControls={[]}
            layout="horizontal-reverse"
         // other props here
         /> : <Button
            onClick={() => {
               onClickRecording(id)
            }}
            color="primary"
            className="btn-icon btn-soft-primary btn-load"
            outline>
            {recordingDownload ? <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> : <i className="ri-volume-up-fill" />}
         </Button>}
      </React.Fragment>
   )
};

export default Recording;