import React, { useEffect, useState } from "react";
import {
  Col,
  Input,
  Label,
  Row
} from "reactstrap";
import Select from "react-select";
import TextPreviewComponent from "./TextPreviewComponent";
import PhoneNumberPreview from "./PhoneNumberPreview";
import SingleCheckbox from "./SingleCheckbox";
import MultipleCheckbox from './MultipleCheckBoxPreview';
import DropdownSelectPreview from "./DropdownSelectPreview";
import RadioPreview from "./RadioPreview";
import DatePickerPreview from "./DatePickerPreview";
import NumberPreview from "./NumberPreview";
import FieldArray from '../Inputs.json';
import DataSetDropdown from "./DataSetDropdown";
import { components } from "react-select";
import TextEditorPreview from "./TextEditorPreview";
import AutoIncrementPreview from "./AutoIncrementPreview";
import { withTranslation } from "react-i18next";

const BasicInfo = ({setCreateInputs, createInputs, setFieldJson, fieldJson, t}: any) => {  
    const [selectedFieldtype, setSelectedFieldtype] = useState<any>(null);

    const handleselected = (option: any ) => {
        if(option.value === 'auto-increment'){
            setSelectedFieldtype(option);
            let fieldvalue = option.value;
            let field = FieldArray.filter((field: any) => field.type === option.value)[0];
            let newfield = {...field, label: createInputs.label, key: createInputs.key, description: createInputs.description, placeholder: "", uuidFormat: {prefix: 'ID', resetType: 'yearly', autoincrement: 1}}
            setCreateInputs({...field, ...createInputs,fieldtype: fieldvalue, label: createInputs.label, key: createInputs.key, description: createInputs.description, placeholder: "", uuidFormat: { prefix: 'ID', resetType: 'yearly', autoincrement: 1}})
            setFieldJson(newfield)
        }
        else {
            setSelectedFieldtype(option);
            let fieldvalue = option.value;
            let field = FieldArray.filter((field: any) => field.type === option.value)[0];
            let newfield = {...field, label: createInputs.label, key: createInputs.key, description: createInputs.description, placeholder: ""}
            setCreateInputs({...field, ...createInputs,fieldtype: fieldvalue, label: createInputs.label, key: createInputs.key, description: createInputs.description, placeholder: ""})
            setFieldJson(newfield)
        }
    }

    const handlestatusselected = (option: any,key: any ) => {
        let fieldvalue = option.value;
        let newfield = {...fieldJson,[key] : fieldvalue }
        setCreateInputs({...createInputs, [key] : fieldvalue })
        setFieldJson(newfield)
    }

    const fieldtype = [
        {
            label: "Text input",
            options: [
                { label: "Single-line text", value: "textfield" },
                { label: "Multi-line text", value: "textarea" },
                { label: "Phone number", value: "phone" },
                { label: "Email", value: "email" },
                { label: "Text Editor", value: "texteditor" },
            ]
        },
        {
            label: "Choosing options",
            options: [
                { label: "Single checkbox", value: "checkbox" },
                { label: "Multiple checkboxes", value: "selectboxes" },
                { label: "Date picker", value: "datetime" },
                { label: "Dropdown select", value: "select" }, 
                { label: "Suggest dropdown", value: "datasetselect" },                 
                { label: "Radio select", value: "radio" }
            ]
        },
        {
            label: "values",
            options: [
                { label: "Number", value: "number" }
            ]
        },
        {
            label: "other",
            options: [
                { label: "File", value: "file" },
                { label: "Auto Increment", value: "auto-increment" },
            ]
        }

    ];

    const handlePreviewComponent = () => {
        let renderfieldtype = selectedFieldtype && selectedFieldtype.value ? selectedFieldtype.value : '';
        switch(renderfieldtype) { 
            case 'email':  
            case 'textarea':   
            case 'textfield': return <TextPreviewComponent createInputs={createInputs} setCreateInputs={setCreateInputs} fieldType={renderfieldtype} fieldJson={fieldJson} />
            case 'texteditor': return <TextEditorPreview createInputs={createInputs} setCreateInputs={setCreateInputs} fieldType={renderfieldtype} fieldJson={fieldJson} />
            case 'phone': return <PhoneNumberPreview createInputs={createInputs} setCreateInputs={setCreateInputs}  fieldType={renderfieldtype} fieldJson={fieldJson}/>
            case 'checkbox': return <SingleCheckbox createInputs={createInputs} setCreateInputs={setCreateInputs}  fieldType={renderfieldtype} fieldJson={fieldJson}/>
            case 'selectboxes': return <MultipleCheckbox setFieldJson={setFieldJson} fieldJson={fieldJson} createInputs={createInputs} setCreateInputs={setCreateInputs} fieldType={renderfieldtype}  />
            case 'select': return <DropdownSelectPreview setFieldJson={setFieldJson} fieldJson={fieldJson} createInputs={createInputs} setCreateInputs={setCreateInputs} fieldType={renderfieldtype}  />
            case 'datasetselect': return <DataSetDropdown setFieldJson={setFieldJson} fieldJson={fieldJson} createInputs={createInputs} setCreateInputs={setCreateInputs} fieldType={renderfieldtype}  />
            case 'radio': return <RadioPreview setFieldJson={setFieldJson} fieldJson={fieldJson}  createInputs={createInputs} setCreateInputs={setCreateInputs}  fieldType={renderfieldtype}   />
            case 'datetime': return <DatePickerPreview setFieldJson={setFieldJson} fieldJson={fieldJson} createInputs={createInputs} setCreateInputs={setCreateInputs}  fieldType={renderfieldtype} />
            case 'file': return null
            case 'number': return <NumberPreview setFieldJson={setFieldJson} fieldJson={fieldJson} createInputs={createInputs} setCreateInputs={setCreateInputs}  fieldType={renderfieldtype} />
            case 'auto-increment': return <AutoIncrementPreview setFieldJson={setFieldJson} fieldJson={fieldJson} createInputs={createInputs} setCreateInputs={setCreateInputs} fieldType={renderfieldtype} />
            default: return null
        }
    }

    const handlePlaceholderComponent = () => {
        let renderfieldtype = selectedFieldtype && selectedFieldtype.value ? selectedFieldtype.value : ''
        switch(renderfieldtype) {  
            case 'textarea':   
            case 'textfield':
            case 'phone': 
            case 'datetime': 
            case 'email': 
            case 'number': return Placeholder()
            default: return null
        }
    }

    const Placeholder = () => {
        return <div>
                <Label className="fs-16 fw-medium my-1">{t("common.offcanvas.enter_placeholder")}</Label>                            
                <Input
                    type={"text"}
                    name={"label"}
                    className="fs-16 fw-medium"
                    value={createInputs.placeholder}
                    onChange={(e) => setCreateInputs({...createInputs, placeholder: e.target.value})}                                                         
                />
            </div>
    }

    useEffect(() => {
        let newfield = {...fieldJson, placeholder: createInputs.placeholder}
        setFieldJson(newfield)
    }, [createInputs])
    
    return (
        <div>  
            <Row className="hstack gap-3 flex-column justify-content-center">
                <Col lg={12} className="d-flex gap-2 fs-24 fw-bold flex-column justify-content-center text-center">    
                        {createInputs.label}
                </Col>
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">    
                    <div>
                        <Label className="fs-16 fw-semibold">{t("common.offcanvas.field_type")}</Label>                            
                        <Select
                            defaultOptions
                            placeholder={t("common.filter.search.select_field_type")}
                            className="fs-16 fw-medium"
                            value={selectedFieldtype}
                            onChange={(option : any) => handleselected(option)}
                            options={fieldtype}
                            name="choices-single-default"
                        ></Select>
                    </div>
                    <div>
                        {handlePlaceholderComponent()}
                    </div>
                    {/* { createInputs.objectType == "applications" ?
                        <div className="live-preview vstack gap-2 mb-2">     
                        <Label className="form-label">{"Status"}<span className="d-none fw-bold text-danger">{" "}*</span></Label>
                        <p>Select status if field is linked to any specific application status else leave it blank. </p>
                        <Select
                            styles={customStyles}
                            components={{ Option: CustomOption }}
                            className='form-select-sm p-0'
                            placeholder={`Select status`}
                            onChange={(selectedOption: any) => handlestatusselected(selectedOption, "applicationstatusLinked")}
                            options={statusoptions}              
                        >                        
                        </Select>
                    </div> : null 
                    } */}
                    <div>
                        {handlePreviewComponent()}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default withTranslation()(BasicInfo);
