import { Card, CardBody, Container, Row } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import UsersTable from "./UsersTable";

const index = () => {
  document.title = "DTS | Zilter - Users";

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={"Users"} pageTitle={"Users"} icon={"ri-list-check"} />
          <Card>
            <CardBody className="">
              <UsersTable />
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default index;
