import React from 'react';
import { withTranslation } from 'react-i18next';
import Listing from 'Components/Common/Views/Listing';
import { companiesMetadataGetRequest, companyCreateRequest, companyDelete, companyGetRequest, companyListGetRequest } from 'store/companies/action';

const Companies = (props: any) => {
    const { viewId, isCreateModal, setCreateOpen } = props;   
    return (
        <React.Fragment>
            <Listing 
                viewId={viewId}
                isCreateModal={isCreateModal}
                setCreateOpen={setCreateOpen}
                getListAction={companyListGetRequest}
                deleteAction={companyDelete}
                detailsGetAction={companyGetRequest}
                metaDatagetRequest={companiesMetadataGetRequest}
                createAction={companyCreateRequest}
                model={"companies"}
                reducerState={"companies"}
                translater={"company"}
            />
        </React.Fragment>
    );
};
export default withTranslation()(Companies);


