import React, { useEffect, useState } from 'react'
import avatar7 from "../../../../assets/images/users/avatar-7.jpg"
import { Link } from "react-router-dom";
import moment from 'moment';
import { withTranslation } from "react-i18next";
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Select from "react-select";
import { Button, Col, Row } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { isJson } from 'utils';
import { noteCreateRequest } from 'store/notes/action';
import DtsCKEditor from 'Components/Common/FormBuilder/DtsCKEditor';


const Comments = ({ t, noteType, transcribing, setTranscribing, record, applicationId, studentId, metaData, notesFilter }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const notes = useSelector((state: ApplicationState) => state.notes.list);
    const [selectedNoteType, setSelectedNoteType] = useState(null);
    const [shortNoteValue, setShortNoteValue] = useState("");
    const [addShortNoteValue, setAddShortNoteValue] = useState("");
    const [replyNoteId, setReplyNoteId] = useState<string | null>(null);
    const [replyNote, setReplyNote] = useState("")
    const [newNoteId, setNewNoteId] = useState<string | null>(null)
    const [isAddNoteButtonDisabled, setIsAddNoteButtonDisabled] = useState<boolean>(false);
    const [note, setNote] = useState(""); // State to store final transcript
    const [previousWords, setPreviousWords] = useState<string[]>([]);

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition({
        transcribing: transcribing == "child",
        clearTranscriptOnListen: true,  // Clear transcript when microphone is turned on
        commands: [  // Define custom speech commands if needed
            {
                command: 'stop',
                callback: () => stopListening(),
            },
        ]
    });

    useEffect(() => {
        resetTranscript();
    }, [record])


    useEffect(() => {
        if (listening && transcript) {
          const words = transcript.trim().split(" "); // Convert transcript to words
    
          // Find new words by slicing from previous transcript length
          const newWords = words.slice(previousWords.length).join(" ").trim();
    
          if (newWords) {
            setNote((prevNote) => `${prevNote?prevNote:''} ${newWords?newWords:''}`.trim());
          }
    
          setPreviousWords(words); // Store current words for next comparison
        }
      }, [transcript, listening]);

      useEffect(() => {
        if(note) {
            console.log("newNote", replyNote, "note", note)
            setReplyNote(note)
        }
      }, [note])


    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    const startListening = () => {
        setTranscribing("child");
        SpeechRecognition.startListening({ continuous: true });
    };

    const stopListening = () => {
        setTranscribing(false);  // Stop transcribing when listening stops
        SpeechRecognition.stopListening();
        resetTranscript();
    };

    const handleReplyNote = () => {
        stopListening();
        const data =
        {
            shortNote: shortNoteValue,
            parent: replyNoteId,
            note: replyNote,
            student: studentId,
            application: applicationId ? applicationId : null
        }
        const handleSuccess = (body: any): void => {
            handleReset()
            notesFilter()
        };

        const handleError = (body: any): void => {
        };
        dispatch(noteCreateRequest(data, handleSuccess, handleError));
    };

    const handleReset = () => {
        setReplyNote("");
        setSelectedNoteType(null)
        resetTranscript();
        setAddShortNoteValue('')
    };
    const handleReplyNoteChange = (input: React.ChangeEvent<HTMLTextAreaElement> | string,
        appendSpeech: boolean = false) => {
        if(!listening){
            setNote(`${input?input:''}`.trim())
        }
        setNewNoteId("");
        setReplyNoteId(replyNoteId);
    };

    const handleNoteTypeChange = (selectedOption: any) => {
        setSelectedNoteType(selectedOption);
        setShortNoteValue(selectedOption.label)
        // You can also update the formData here if needed
    };

    const toggleReply = (noteId: string) => {
        if (replyNoteId === noteId) {
            setReplyNoteId(null);
        } else {
            setReplyNoteId(noteId);
        }
    };

    const textEle = <div>
        <DtsCKEditor
            value={replyNote || ""}
            onChange={(data: any) => {
                handleReplyNoteChange(data)
            }}
            stopListening={stopListening}
            name={'body2'}
            type={'texteditor'}
            actionButtons={false}
            defaultValue={replyNote}
        />
    </div>

    const renderComments = (notes: any) => {
        return (
            <React.Fragment>
                {notes && notes.map((note: any, key: number) => {
                    let valuesJson = note?.valuesJson && isJson(note?.valuesJson) ? JSON.parse(note?.valuesJson) : note?.valuesJson
                    let comment = { ...valuesJson, ...note }
                    return <div className="d-flex flex-column mb-4 notes-comments" key={key}>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <img src={avatar7} alt="" className="avatar-xs rounded-circle" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h5 className="d-flex gap-2 fs-15 mb-1">
                                    <Link to="/pages-profile">{comment?.created_by?.name}</Link>{" "}
                                    <small className="text-muted">{moment(note?.createdAt).format('MMMM DD, YYYY hh:mm A')}</small>
                                    <span className="badge rounded-pill bg-info-subtle text-info">{comment?.shortNote}</span>
                                </h5>
                                <p className="text-muted mb-0">
                                    <div dangerouslySetInnerHTML={{ __html: comment?.note }}></div>
                                </p>
                            </div>
                        </div>
                        <div className="ms-5 mb-2">
                            {replyNoteId === comment.id ? (
                                <Row className="vstack gap-2 mt-1 ms-1">
                                    <Col xs={12}>
                                        {metaData?.length > 0 &&
                                            metaData.map((item: any, index: number) => {
                                                if (item.type === "textarea") return (
                                                    <>
                                                        {textEle}
                                                    </>
                                                )
                                            })}
                                    </Col>
                                    <Col xs={12} className="mb-1">
                                        <div style={{ width: 500 }} className="d-flex justify-content-start gap-2">
                                            <div>
                                                {metaData?.length > 0 &&
                                                    metaData.map((item: any, index: number) => {
                                                        if (item.type === "select") return (
                                                            <Select
                                                                key={index} // Unique key for the Select element
                                                                placeholder={t("common.filter.search.select_note_type")}
                                                                classNamePrefix="js-example-data-array"
                                                                isLoading={false}
                                                                options={item?.values} // Pass all options directly
                                                                value={selectedNoteType}
                                                                onChange={handleNoteTypeChange}
                                                                menuPlacement="auto"
                                                                menuPosition="fixed"
                                                            />
                                                        )
                                                    })}
                                            </div>
                                            {listening ? (
                                                <Button
                                                    color="danger"
                                                    className="ms-2 btn-icon"
                                                    outline
                                                    type="button"
                                                    onClick={stopListening}
                                                >
                                                    <i className="ri-mic-off-line"></i>
                                                </Button>
                                            ) : (
                                                <Button
                                                    color="primary"
                                                    className="ms-2 btn-icon"
                                                    outline
                                                    type="button"
                                                    onClick={startListening}
                                                >
                                                    <i className="ri-mic-line"></i>
                                                </Button>
                                            )}
                                            <button onClick={(e) => {
                                                handleReplyNote();
                                                e.preventDefault();
                                                toggleReply(comment.id);
                                            }}
                                                disabled={isAddNoteButtonDisabled || (!replyNote || !selectedNoteType) && (!transcript || !selectedNoteType)}
                                                className="btn btn-sm btn-success mt-1">{t("applications.details.activities.reply")}</button>
                                            <button onClick={() => {
                                                setReplyNote("")
                                                toggleReply(comment.id)
                                            }}
                                                className="btn btn-sm btn-danger mt-1">{t("applications.details.activities.cancel")}</button>

                                        </div>
                                    </Col>
                                </Row>
                            ) :
                                <>
                                    <Link to="#" className="badge text-muted bg-light" onClick={() => {
                                        // e.preventDefault(); 
                                        toggleReply(comment.id)
                                        setReplyNoteId(comment.id)
                                    }}>
                                        <i className="mdi mdi-reply"></i> {t("applications.details.activities.reply")}
                                    </Link>
                                </>
                            }
                        </div>
                        {/* Render nested comments */}
                        {comment.children && comment.children.length > 0 && (
                            <div className="ms-5">
                                {renderComments(comment.children)}
                            </div>
                        )}
                    </div>
                })}
            </React.Fragment>
        );
    };
    return (
        <React.Fragment>
            {/* <SimpleBar className="mt-3 mx-n2 px-4 file-menu-sidebar-scroll"> */}
                {notes && notes.map((note: any, key: number) => {
                    let valuesJson = note?.valuesJson && isJson(note?.valuesJson) ? JSON.parse(note?.valuesJson) : note?.valuesJson
                    let comment = { ...valuesJson, ...note }
                    return <div className="d-flex mx-3 flex-column mb-4 notes-comments" key={key}>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <img src={avatar7} alt="" className="avatar-xs rounded-circle" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h5 className="d-flex gap-2 fs-15 mb-1">
                                    <Link to="/pages-profile">{comment?.created_by?.name}</Link>{" "}
                                    <small className="text-muted">{moment(note?.createdAt).format('MMMM DD, YYYY hh:mm A')}</small>
                                    <span className="badge rounded-pill bg-info-subtle text-info">{comment?.shortNote}</span>
                                </h5>
                                <p className="text-muted mb-0">
                                    <div dangerouslySetInnerHTML={{ __html: comment?.note }}></div>
                                </p>
                            </div>
                        </div>
                        <div className="ms-5 mb-2">
                            {replyNoteId === comment.id ? (
                                <div>
                                    <div>
                                    <DtsCKEditor
                                        value={replyNote || ""}
                                        onChange={(data: any) => {
                                            handleReplyNoteChange(data)
                                        }}
                                        stopListening={stopListening}
                                        name={'body'}
                                        type={'texteditor'}
                                        actionButtons={false}
                                        defaultValue={replyNote}
                                    />
                                    </div>
                                    <div className="d-flex justify-content-end gap-1 mt-1">
                                        <div style={{ minWidth: '250px' }}>
                                            <Select
                                                placeholder={t("common.filter.search.select_note_type")}
                                                classNamePrefix="js-example-data-array"
                                                isLoading={false}
                                                options={noteType}
                                                value={selectedNoteType}
                                                onChange={handleNoteTypeChange}
                                                menuPlacement="auto" // You can also try "bottom" or "top"
                                                menuPosition="fixed"
                                            />
                                        </div>
                                        {listening ? (
                                            <>
                                                <Button color="danger" className="ms-2 btn-icon" outline type="button" onClick={stopListening}> <i className="ri-mic-off-line"></i> </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button color="primary" className="ms-2 btn-icon" outline type="button" onClick={startListening}> <i className="ri-mic-line"></i> </Button>
                                            </>
                                        )}
                                        <button onClick={(e) => {
                                            handleReplyNote();
                                            e.preventDefault();
                                            toggleReply(comment.id);
                                        }}
                                            disabled={isAddNoteButtonDisabled || (!replyNote || !selectedNoteType) && (!transcript || !selectedNoteType)}
                                            className="btn btn-sm btn-success mt-1">{t("applications.details.activities.reply")}</button>
                                        <button onClick={() => {
                                            setReplyNote("")
                                            toggleReply(comment.id)
                                        }} className="btn btn-sm btn-danger mt-1">{t("applications.details.activities.cancel")}</button>
                                    </div>
                                </div>
                            ) :
                                <>
                                    <Link to="#" className="badge text-muted bg-light" onClick={() => {
                                        // e.preventDefault(); 
                                        toggleReply(comment.id)
                                        setReplyNoteId(comment.id)
                                    }}>
                                        <i className="mdi mdi-reply"></i> {t("applications.details.activities.reply")}
                                    </Link>
                                </>
                            }
                        </div>
                        {/* Render nested comments */}
                        {comment.children && comment.children.length > 0 && (
                            <div className="ms-5 mt-1">
                                {renderComments(comment.children)}
                            </div>
                        )}
                    </div>
                })}
            {/* </SimpleBar> */}
        </React.Fragment>
    );
};

export default withTranslation()(Comments);