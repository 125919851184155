import React from 'react';
import { withTranslation } from 'react-i18next';
import StudentsList from './StudentsList';
import { Container, Row } from 'reactstrap';

const Import = (props: any) => {   
    return (
        <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <Row>
                    <StudentsList />      
                </Row>
            </Container>
        </div>
    </React.Fragment>
    );
};

export default withTranslation()(Import);
