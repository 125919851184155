import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ActionItem from './ActionItem';

interface WithId { id: string | number }

interface Permission {
  access: boolean;
  type: 'lock' | string;
  help?: string;
}

interface ExtraAction<T> {
  btn_icon: string;
  btn_type: string;
  btn_name: string;
  clickHandler: (row: T) => void;
}

interface DropdownActionsProps<T extends WithId> {
  row: { original: T };
  url?: string;
  permissions: {
    view?: Permission;
    edit?: Permission;
    delete?: Permission;
  };
  extraActions?: ExtraAction<T>[];
  handleLeadClick: (row: T) => void;
  setIsViewAction?: (value: boolean) => void;
  setIsEditAction?: (value: boolean) => void;
  setIsDeleteOpen?: (value: boolean) => void;
  isViewDrawer?: boolean;
  isEditDrawer?: boolean;
  translater: (key: string) => string;
}

export const DropdownActions = <T extends WithId>({
  row,
  url,
  permissions,
  extraActions = [],
  handleLeadClick,
  setIsViewAction,
  setIsEditAction,
  setIsDeleteOpen,
  isViewDrawer = false,
  isEditDrawer = false,
  translater,
}: DropdownActionsProps<T>) => {
  const navigate = useNavigate();
  const itemId = String(row.original.id);
  const [open, setOpen] = useState<string | null>(null);
  const toggleDropdown = () => {
    console.log("itemId>>", itemId)
    setOpen(prev => (prev === itemId ? null : itemId));
  }

  const renderAction = (
    perm: Permission | undefined,
    drawer: boolean,
    icon: string,
    labelKey: string,
    btnClass: string,
    onDrawerClick: () => void,
    redirectPath?: string
  ) => {
    if (!perm) return null;
    const tooltipId = `${labelKey}-${itemId}`;
    const handler = drawer && perm.access
      ? onDrawerClick
      : perm.access && redirectPath
        ? () => navigate(redirectPath)
        : () => { };

    return (
      <ActionItem
        permission={perm}
        icon={icon}
        label={translater(`common.table.${labelKey}`)}
        btnClassName={btnClass}
        tooltipId={tooltipId}
        tooltipMessage={perm.help}
        onClick={handler}
      />
    );
  };
  return (
    <ul className="list-inline hstack gap-2 mb-0">
      <li className="list-inline-item">
        <Dropdown isOpen={open === itemId} toggle={() => toggleDropdown()}>
          <DropdownToggle className="btn btn-soft-primary btn-sm" tag="button">
            <i className="ri-more-fill" />
          </DropdownToggle>
          <DropdownMenu container="body" className="dropdown-menu-center">
            <div className="d-flex flex-column">
              {renderAction(
                permissions.view,
                isViewDrawer,
                'ri-eye-fill',
                'view',
                'btn-soft-info',
                () => {
                  handleLeadClick(row.original);
                  setIsViewAction?.(true);
                },
                url ? `${url}/${itemId}` : undefined
              )}
              {renderAction(
                permissions.edit,
                isEditDrawer,
                'ri-pencil-fill',
                'edit',
                'btn-soft-secondary',
                () => {
                  handleLeadClick(row.original);
                  setIsEditAction?.(true);
                },
                url ? `${url}/${itemId}` : undefined
              )}
              {renderAction(
                permissions.delete,
                true,
                'ri-delete-bin-fill',
                'delete',
                'btn-soft-danger',
                () => {
                  handleLeadClick(row.original);
                  setIsDeleteOpen?.(true);
                }
              )}
              {extraActions.map((action, idx) => (
                <DropdownItem
                  key={idx}
                  className="dropdown-item fs-12 hstack justify-content-between"
                  onClick={() => action.clickHandler(row.original)}
                >
                  <button className={`btn btn-sm ${action.btn_type}`}>
                    <i className={action.btn_icon} />
                  </button>
                  <span className="text-primary">{action.btn_name}</span>
                </DropdownItem>
              ))}
            </div>
          </DropdownMenu>
        </Dropdown>
      </li>
    </ul>
  );
};
