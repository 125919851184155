import React from "react";
import { Form } from "reactstrap";
import DtsTextInput from "./DtsTextInput";
import DtsPhoneInput from "./DtsPhoneInput";
import DtsDateInput from "./DtsDateInput";
import DtsSelectInput from "./DtsSelectInput";
import DtsCheckbox from "./DtsCheckBox";
import DtsRadioInput from "./DtsRadioInput";
import DtsCheckBoxes from "./DtsCheckBoxes";
import DtsDataSelectInput from "./DtsDataSelectInput";
import DtsFilterDataSelectInput from "./DtsFilterDataSelectInput";
import DtsGroupInput from "./DtsGroupInput";
import DtsStarRating from "./DtsStartRatings";
import DtsTreeSelect from "./TreeSelect";
import DtsFileInput from "./DtsFileInput";
import DtsFilterSelectInput from "./DtsFilterSelectInput";
import { evaluateConditionalLogic } from "utils";
import DtsCKEditor from "./DtsCKEditor";
import DtsAutoIncrement from "./DtsAutoIncrement";

interface FormBuilderProps {
    label?: string;
    type: string;
    value?: string;
    placeholder?: string;
    onChange?: (value: any, str?: string) => void;
    options?: any; 
    Editable?: boolean;
    className?: string;
    name?: string;
    isEditState?: boolean;
    description?: any;    
    errorText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    actionButtons?: boolean;
    defaultValue?: string;
    rest?: any;
    invalid?:any;
    formValues?: any;
    dataFields?: any;     
    onSaveClick?: (value?: any) => void;
    setdisabled?: any;
    setError?: any;
    formerror?: any;
    isFilter?: boolean;
    isTextract?: any;
    classes?: any;
    resetValue?:any;
    isTarget?:any;
    rows?: any;
    loading?: boolean
}

const FormBuilder: React.FC<FormBuilderProps> = ({loading, rows, isTarget, resetValue, classes, isTextract,isFilter, formerror, setError, onSaveClick, formValues, dataFields, invalid, rest, defaultValue, description, label, type, value, placeholder, onChange, options, Editable, className, name, isEditState, errorText, actionButtons, readOnly, disabled, setdisabled}: any) => {
    const checkboxOption = [{label: 'YES', value: true},{label: 'NO', value: false}]
    const handleComponent = () => {  
        const showComponent = rest?.conditional?.isConditional ? evaluateConditionalLogic(rest?.conditional, formValues, rest): true;     
        // const showComponent = rest?.conditional?.isConditional ? evaluateConditionalLogic(rest?.conditional, formValues): true;
        const resetvalue = resetValue;
        if(showComponent) {
            switch (type) {
                case 'textfield':
                case 'textarea':
                case 'password':
                case 'email':
                case 'number' :
                    return (<>
                        <DtsTextInput
                            label={label}
                            type={type}
                            value={value}
                            name={name}
                            placeholder={placeholder}
                            onChange={onChange}
                            isEditable={Editable}
                            errorText={errorText}
                            readOnly= {readOnly}
                            disabled= {disabled}
                            actionButtons={actionButtons}
                            defaultValue={defaultValue}
                            rest={rest}
                            invalid={invalid}
                            formValues={formValues}
                            dataFields={dataFields}
                            onSaveClick={onSaveClick}
                            setdisabled={setdisabled}
                            setError={setError}
                            formerror={formerror}
                            resetvalue={resetvalue}
                            rows={rows}
                            loading={loading}
                        />
                    </>
                    )
                case 'auto-increment' :
                    return (<>
                        <DtsAutoIncrement
                            label={label}
                            type={type}
                            value={value}
                            name={name}
                            placeholder={placeholder}
                            onChange={onChange}
                            isEditable={Editable}
                            errorText={errorText}
                            readOnly= {readOnly}
                            disabled= {disabled}
                            actionButtons={actionButtons}
                            defaultValue={defaultValue}
                            rest={rest}
                            invalid={invalid}
                            formValues={formValues}
                            dataFields={dataFields}
                            onSaveClick={onSaveClick}
                            setdisabled={setdisabled}
                            setError={setError}
                            formerror={formerror}
                            resetvalue={resetvalue}
                            rows={rows}
                            loading={loading}
                        />
                    </>
                    )
                case 'file':
                        return (<>
                            <DtsFileInput
                                label={label}
                                type={type}
                                value={value}
                                name={name}
                                placeholder={placeholder}
                                onChange={onChange}
                                isEditable={Editable}
                                errorText={errorText}
                                readOnly= {readOnly}
                                disabled= {disabled}
                                actionButtons={actionButtons}
                                defaultValue={defaultValue}
                                rest={rest}
                                invalid={invalid}
                                formValues={formValues}
                                dataFields={dataFields}
                                onSaveClick={onSaveClick}
                                setdisabled={setdisabled}
                                setError={setError}
                                formerror={formerror}
                                isTextract={isTextract}
                                loading={loading}
                            />
                        </>
                        )
                case 'phone': return (<DtsPhoneInput               
                    label={label}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    onChange={onChange}
                    isEditable={Editable}
                    errorText={errorText}
                    readOnly= {readOnly}
                    disabled= {disabled}
                    actionButtons={actionButtons}
                    defaultValue={defaultValue}
                    rest={rest}
                    invalid={invalid}
                    formValues={formValues}
                    dataFields={dataFields}
                    onSaveClick={onSaveClick}
                    setdisabled={setdisabled}
                    setError={setError}
                    formerror={formerror}
                    resetvalue={resetvalue}
                    loading={loading}
                />
                )
                case 'datetime':
                    return (<DtsDateInput
                        label={label}
                        value={value}
                        name={name}
                        rest={rest}
                        placeholder={placeholder}
                        onChange={onChange}
                        isEditable={Editable}
                        errorText={errorText}
                        readOnly= {readOnly}
                        disabled= {disabled}
                        actionButtons={actionButtons}
                        defaultValue={defaultValue}
                        options={options}
                        onSaveClick={onSaveClick}
                        setdisabled={setdisabled}
                        setError={setError}
                        formerror={formerror}
                        resetvalue={resetvalue}
                        loading={loading}
                    />
                    );
                case 'select':
                    if(isFilter)  return (<DtsFilterSelectInput
                        placeholder={placeholder}
                        onChange={onChange}
                        options={rest && rest.type === 'checkbox' ? checkboxOption : options}
                        label={label}                   
                        isEditable={Editable}
                        errorText={errorText}
                        readOnly={readOnly}
                        disabled={disabled}
                        actionButtons={actionButtons}
                        defaultValue={defaultValue}
                        rest={rest}
                        value={value}
                        invalid={invalid}
                        formValues={formValues}
                        dataFields={dataFields}
                        onSaveClick={onSaveClick}
                        setdisabled={setdisabled}
                        name = {name}
                        setError={setError}
                        formerror={formerror}
                        loading={loading}
                    />
                    );
                    else  return (<DtsSelectInput
                        placeholder={placeholder}
                        onChange={onChange}
                        options={rest && rest.type === 'checkbox' ? checkboxOption : options}
                        label={label}                   
                        isEditable={Editable}
                        errorText={errorText}
                        readOnly={readOnly}
                        disabled={disabled}
                        actionButtons={actionButtons}
                        defaultValue={defaultValue}
                        rest={rest}
                        value={value}
                        invalid={invalid}
                        formValues={formValues}
                        dataFields={dataFields}
                        onSaveClick={onSaveClick}
                        setdisabled={setdisabled}
                        name = {name}
                        setError={setError}
                        formerror={formerror}
                        classes={classes}
                        resetvalue={resetvalue}
                        isTarget={isTarget}
                        loading={loading}
                    />
                    );
                case 'datasetselect':
                    if(isFilter)  return (<DtsFilterDataSelectInput
                            isMulti={true}
                            placeholder={placeholder}
                            onChange={onChange}
                            label={label}                   
                            isEditable={Editable}
                            errorText={errorText}
                            readOnly={readOnly}
                            disabled={disabled}
                            actionButtons={actionButtons}
                            defaultValue={defaultValue}
                            rest={rest}
                            value={value}
                            invalid={invalid}
                            formValues={formValues}
                            dataFields={dataFields}
                            onSaveClick={onSaveClick}
                            setdisabled={setdisabled}
                            name={name}
                            setError={setError}
                            formerror={formerror}
                            loading={loading}
                        />
                        );
                    else return (<DtsDataSelectInput
                        placeholder={placeholder}
                        onChange={onChange}
                        label={label}                   
                        isEditable={Editable}
                        errorText={errorText}
                        readOnly={readOnly}
                        disabled={disabled}
                        actionButtons={actionButtons}
                        defaultValue={defaultValue}
                        rest={rest}
                        value={value}
                        invalid={invalid}
                        formValues={formValues}
                        dataFields={dataFields}
                        onSaveClick={onSaveClick}
                        setdisabled={setdisabled}
                        name={name}
                        setError={setError}
                        formerror={formerror}
                        resetvalue={resetvalue}
                        loading={loading}
                    />
                    );
                case 'checkbox':
                return (
                    <DtsCheckbox
                        label={label}
                        value={value}
                        onChange={onChange}
                        name={name}
                        rest={rest}
                        actionButtons={actionButtons}
                        setdisabled={setdisabled}
                        setError={setError}
                        formerror={formerror}
                        resetvalue={resetvalue}
                    />
                );
                case 'selectboxes':
                    return (
                        <DtsCheckBoxes
                            label={label}
                            value={value}
                            onChange={onChange}
                            name={name}
                            rest={rest}
                            actionButtons={actionButtons}
                            setdisabled={setdisabled}
                            setError={setError}
                            formerror={formerror}
                            resetvalue={resetvalue}
                        />
                    );
                case 'groupinput':
                    return (
                        <DtsGroupInput
                            label={label}
                            value={value}
                            name={name}
                            placeholder={placeholder}
                            onChange={onChange}
                            isEditable={Editable}
                            errorText={errorText}
                            readOnly= {readOnly}
                            disabled= {disabled}
                            actionButtons={actionButtons}
                            defaultValue={defaultValue}
                            rest={rest}
                            invalid={invalid}
                            formValues={formValues}
                            dataFields={dataFields}
                            onSaveClick={onSaveClick}
                            setdisabled={setdisabled}
                            setError={setError}
                            formerror={formerror}
                            resetvalue={resetvalue}
                            loading={loading}
                        />
                    );
                case 'treeselect':
                    return (
                        <DtsTreeSelect
                            label={label}
                            value={value}
                            name={name}
                            placeholder={placeholder}
                            onChange={onChange}
                            isEditable={Editable}
                            errorText={errorText}
                            readOnly= {readOnly}
                            disabled= {disabled}
                            actionButtons={actionButtons}
                            defaultValue={defaultValue}
                            rest={rest}
                            invalid={invalid}
                            formValues={formValues}
                            dataFields={dataFields}
                            onSaveClick={onSaveClick}
                            setdisabled={setdisabled}
                            setError={setError}
                            formerror={formerror}
                            resetvalue={resetvalue}
                            loading={loading}
                        />
                    );
                
                case 'radio': return (
                    <DtsRadioInput
                        value={value}
                        label={label}
                        onChange={onChange}
                        name={name}
                        rest={rest}
                        actionButtons={actionButtons}
                        onSaveClick={onSaveClick}
                        setdisabled={setdisabled}
                        setError={setError}
                        formerror={formerror}
                        readOnly={readOnly}
                        resetvalue={resetvalue}
                        loading={loading}
                    />
                )
                case 'starratings': 
                return (
                    <DtsStarRating 
                        value={value}
                        label={label}
                        onChange={onChange}
                        name={name}
                        rest={rest}
                        actionButtons={actionButtons}
                        onSaveClick={onSaveClick}
                        setdisabled={setdisabled}
                        setError={setError}
                        formerror={formerror}
                        readOnly={readOnly}
                        resetvalue={resetvalue}
                        loading={loading}
                    />
                )
                case 'texteditor': 
                return (
                    <DtsCKEditor 
                        value={value}
                        label={label}
                        onChange={onChange}
                        name={name}
                        rest={rest}
                        actionButtons={actionButtons}
                        onSaveClick={onSaveClick}
                        setdisabled={setdisabled}
                        setError={setError}
                        formerror={formerror}
                        readOnly={readOnly}
                        resetvalue={resetvalue}
                        loading={loading}
                    />
                )
    
                default:
                    return null;
            }
        }
    }

    return (
        <div>
            <Form>
                {
                    handleComponent()
                }
            </Form>
        </div>
    );
};

export default FormBuilder;
