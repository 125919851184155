import React, { useEffect, useState } from 'react';
import { Col, Input, Row, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { useParams } from 'react-router-dom';
import { dealCreateRequest, dealDelete, dealGetRequest, dealListGetRequest, dealsMetadataGetRequest } from 'store/deals/action';

import DealCreate from './DealCreate';
import InvoiceProductPreview from './InvoiceProductPreview';
import { isJson } from 'utils';
import Listing from 'Components/Common/Views/Listing';
import AccessDenied from 'pages/AccessDenied/Denied';
import ListingLoader from 'Components/Common/FormBuilder/ListingLoader';

const Deals = (props: any) => {
    const { isSubAgent } = props;
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const metaData = useSelector((state: ApplicationState) => state.deals.metaDataFields);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const metaLoading = useSelector((state: ApplicationState) => state.deals.metaLoading);
    const authLoading = useSelector((state: ApplicationState) => state.auth.meloading);

    const { viewId, isCreateModal, setCreateOpen } = props;
    const [ create, setCreate] = useState(false);
    const [link, setLink] = useState<any>(null);
    const [previewModal, setpreview] = useState<boolean>(false);
    const [linktrue, setlinktrue] = useState<boolean>(false)
    const [disabled, setdisabled] = useState<boolean>(false)
    const [shortListed, setShortListed] = useState<any>([])
    const [selectedlist, setSelectedList] = useState<any>([]);
    const [checkedRows, setCheckedRows] = useState<any>({});
    const [createDeal, setCreateDeal] = useState<boolean>(false);
    const [options, setOptions] = useState<any>([])
    const [disabledBtn, setDisabledBtn] = useState<any>(false)
    const [productid, setproductId] = useState<any>(null)
    const products: any = useSelector((state: ApplicationState) => state.products.list);
    const productmetadata: any = useSelector((state: ApplicationState) => state.properties.list);
    const product: any = useSelector((state: ApplicationState) => state.products.details);
    const [ parentField, setParentField ] = useState({})
    const [viewAccess, setViewAccess] = useState<boolean>(false);

    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(dealsMetadataGetRequest("deal", filters));
    }, [])
    

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setCreate(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.applications;
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false) : setCreate(true)
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const handleselect = (obj: any, value: boolean) => {
        if (value) {
            let newList = selectedlist;
            newList.push(obj)
            setlinktrue(true)
            setSelectedList(newList)
            let newshortListed = shortListed;
            newshortListed.push(obj.id)
            setShortListed(newshortListed)
            handleCheckboxChange(obj.id, true)
            setdisabled(!newshortListed.length)
        }
        else {
            let newList = selectedlist.filter((item: any) => item.id !== obj.id);
            if (!newList.length) {
                setlinktrue(false)
            }
            setSelectedList(newList)
            let newshortListed = shortListed.filter((item: any) => item !== obj.id);
            setShortListed(newshortListed)
            handleCheckboxChange(obj.id, false)
            setdisabled(!newList.length)
        }
    }

    const handleCheckboxChange = (id: any, value: boolean) => {
        setCheckedRows((prevCheckedRows: any) => ({ ...prevCheckedRows, [id]: value }));
    };


    useEffect(() => {
        if (selectedlist && selectedlist.length > 0) {
            setlinktrue(true)
        }
        else {
            setlinktrue(false)
        }
    }, [selectedlist])


    const shortListBtn = () => {
        if (linktrue) return  <Row>
                <Col md={4} lg={4} xl={4}>
                    <Button className="btn-sm" onClick={() => setpreview(true)}>
                        {props.t("courses.review_products")}
                    </Button>
                </Col>
            </Row>
    }

    const applyBtn = () => {
        if (isSubAgent && linktrue) return <Row>
            <Col md={4} lg={4} xl={4}>
                <Button className="btn-sm" onClick={() => setpreview(true)}>
                    {props.t("student.apply_btn")}
                </Button>
            </Col>
        </Row>
    }


    const checkbox = (row: any) => {
        if(create) return <span style={{ width: 20 }} className="align-content-center" >
               <Input
                        className="form-check-input form-check-outline border border-primary form-check-primary ms-3 cursor-pointer"
                        type="checkbox"
                        id={"todo" + row?.id} 
                        checked={checkedRows[row.original.id] === undefined ? false : checkedRows[row.original.id]}
                        onChange={(e) => { handleselect(row.original, e.target.checked); }}
                    />
            </span>
        else return false
    }

    const createBtn = () => {
        if (create) return <Button
            onClick={() => { setCreateDeal(true)}}
            color="primary"
            size='sm'
            className="btn-label py-2">
            <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
            {props.t("deals.create_entry")}
        </Button>
        else return <Button
            color="primary"
            size='sm'
            disabled
            className="btn-label py-2">
            <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
            {props.t("deals.create_entry")}
        </Button>
    }

    useEffect(() => {
        metaData && metaData.length && metaData.map((item: any) =>{
            let field = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) : {}
            if(field.key === "students") setParentField({...field, ...item})
        })
    }, [metaData])

    
    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setViewAccess(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.["deals"];
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'view') {
                            item.value == "" || item.value == "none" ? setViewAccess(false) : setViewAccess(true)
                        }
                        if (item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false) : setCreate(true)
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const initializeData = () => {
        setLink(false); 
        setSelectedList([])
         setCheckedRows([])
    }

    return (
        <React.Fragment>
            <InvoiceProductPreview
                show={previewModal}
                onCloseClick={() => { setpreview(false); }}
                selectedlist={selectedlist}
                setSelectedList={setSelectedList}
                handleselect={handleselect}
                props={props}
                link={link}
                onRemove={handleselect}
                disabled={disabled}
            />
            {createDeal && <DealCreate
                show={createDeal}
                onCloseClick={() => { setCreateDeal(false); }}
                props={props}
                options={options}
                productid={productid}
                setproductId={setproductId}
                product={product}
                products={products}
                metadata={productmetadata}
                metaLoading={metaLoading}
                setOptions={setOptions}
                parentField={parentField}
                setDisabledBtn={setDisabledBtn}
                disabledBtn={disabledBtn}
            />}

{
                !authLoading ?
                    viewAccess ?
             
            <Listing
                viewId={viewId}
                isCreateModal={isCreateModal}
                setCreateOpen={setCreateOpen}
                getListAction={dealListGetRequest}
                deleteAction={dealDelete}
                detailsGetAction={dealGetRequest}
                metaDatagetRequest={dealsMetadataGetRequest}
                createAction={dealCreateRequest}
                shortListBtn={shortListBtn}
                createBtn={createBtn}
                model={"deals"}
                reducerState={"deals"}
                translater={"deals"}
                checkbox={checkbox}
                parentField={parentField}
                parentId={id}
                applyBtn={applyBtn}
                initializeData={initializeData}
            /> : <AccessDenied />
            : <ListingLoader
                title={"deals"}
                tableClass="align-middle table-nowrap"
                theadClass="table-light text-muted"
                thClass="border-bottom-1 table-soft-primary"
            />
    }
        </React.Fragment>
    );
};
export default withTranslation()(Deals);


