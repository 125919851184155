import React, { useEffect, useState } from "react"
import loaderlogo from "../../assets/images/dtsimg/svg/zilter_logo_white.svg"
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import ArrangeFields from "./ArrangeFields";
import ViewAll from "./ViewAllView";
import UpdateArrangeFields from "./UpdateArrangeFields";
import { getViewList, isJson } from "utils";
import DeleteViewModal from "./DeleteViewModal";
import { viewDelete } from "store/views/action";
import { useDispatch } from "react-redux";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { withTranslation } from "react-i18next";

const Arranger = ({metadata, onChange, fields, views, view, props, setViewId, setView, setFields, model} : any) => {
    const [isopen, setOpen] = useState<boolean>(false)
    const [viewAllOpwn, setViewAllOpen] = useState<boolean>(false);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [record, setRecord]  = useState<any>(null);

     const onDelete = (id: any) => {
            const handleSuccess = () => {
                getViewList(dispatch, model, "about")
                setisDeleteOpen(false);
            }
            const handleFailure = () => {
    
            }
            dispatch(viewDelete(id, handleSuccess, handleFailure));
        }

    return (
        <React.Fragment>            
            <ArrangeFields
                show={isopen}
                onCloseClick={() => setOpen(false)}
                dataFields={metadata}
                onChange={onChange}
                fields={fields}
                views={views}
                props={props}
            />   
            <UpdateArrangeFields
                show={isEditOpen}
                onCloseClick={() => setIsEditOpen(false)}
                dataFields={metadata}
                onChange={onChange}
                fields={record?.fields && isJson(record.fields) ? JSON.parse(record.fields) : []}
                views={views}
                view={record}
            />                  
            <ViewAll
                show={viewAllOpwn}
                onCloseClick={() => setViewAllOpen(false)}
                viewlist={views}
                setisDeleteOpen={setisDeleteOpen}
                setIsEditOpen={setIsEditOpen}
                view={view}
                setRecord={setRecord}
                setViewId={setViewId}
                setView={setView}
                setFields={setFields}
            />
            <DeleteViewModal
                show={isDeleteOpen}
                onCloseClick={() => setisDeleteOpen(false)}
                record={record}
                onDelete={onDelete}
                props={props}
            />
            <div>
                <span className="cursor-pointer">
                    <UncontrolledDropdown>
                        <DropdownToggle
                            href="#"
                            className="btn btn-ghost-primary btn-sm dropdown"
                            tag="button"
                            title="View Settings"
                        >
                            <i className="ri-settings-2-fill label-icon align-middle fs-16 me-2 text-primary"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" onClick={() => setViewAllOpen(true)} >
                                <span className="fs-12 fw-normal" >
                                {props.t(`student.details.about.all_view`)}
                                </span>
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" onClick={() => {setOpen(true)}} >
                                <span className="fs-12">
                                {props.t(`student.details.about.create_view`)}
                                </span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </span>
            </div>
        </React.Fragment>
    )
}

export default withTranslation()(Arranger);