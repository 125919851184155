import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
} from "reactstrap";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import QuickForm from "Components/Common/FormBuilder/QuickForm";
import { useState } from "react";
import { withTranslation } from "react-i18next";

const AddEntry = ({ props, show, onCloseClick, dataFields, setTriggerApi, createAction, parent, parentId, size = "" }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [disabled, setDisabled] = useState<any>(false)
    const OnAddEntry = (formValues: any) => {
        setDisabled(true)
        let data: any = parent ? { ...formValues, [parent]: parentId } : formValues;
        const handleSuccess = (body: any) => {
            setTriggerApi((triggerApi: boolean) => !triggerApi)
            onCloseClick()
            setDisabled(false)
        }
        const handleFailure = (body: any) => {
            setDisabled(false)
        }
        dispatch(createAction(data, handleSuccess, handleFailure))
    }

    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onCloseClick}
            backdrop="static"
            className={size}
        >
            <OffcanvasHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={() => { onCloseClick(!show) }}>
                <span className="m-0 me-2 text-white">
                    {props.t("student.modals.add_new_entry")}
                </span>
            </OffcanvasHeader>
            <OffcanvasBody>
                <Card className="border-0" style={{ boxShadow: 'none' }}>
                    <CardBody className="border-0">
                        <div className="live-preview vstack gap-2">
                            <QuickForm dataFields={dataFields} btnAction={OnAddEntry} btn_text={props.t("common.action_button.add_entry")} formfor="students" btndisabled={disabled} />
                        </div>
                    </CardBody>
                </Card>
            </OffcanvasBody>
        </Offcanvas>
    );
};

export default withTranslation()(AddEntry);

