import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { withTranslation } from "react-i18next";
import { Button, Input, Label, ListGroup, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import Select, { components } from "react-select";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DNDField } from './DNDField';
import { ApplicationState } from "store";
import { ToastContainer } from "react-toastify";
import { roleListGetRequest } from "store/role/action";
import { propertyListGetRequest } from "store/properties/action";
import { templateListGetRequest } from 'store/templates/action';
import { getViewList, isJson } from "utils";


const ValueContainer = ({ children, getValue, ...props }: any) => {
    var length = getValue().length;
    if(length) {
        return (
          <components.ValueContainer {...props}>
            {!props.selectProps.inputValue &&
              `${length} Field${length !== 1 ? "s" : ""} selected`
            }
            {React.Children.map(children, child => {
              return child.type === components.Input ? child : null;
            })}
          </components.ValueContainer>
        );
    }
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>
};

// Type definition for options used in Select components
interface OptionsState {
    value: string;
    label: string;
    mandatory?: boolean;
}

interface CreateStatusDrawerProps {
    model: any;
    isOpen: boolean;
    toggle: (value: boolean) => void;
    statuses: any[];
    onSubmit: (value: any) => void;
    t: (key: string) => string;
}

const CreateStatusDrawer: React.FC<CreateStatusDrawerProps> = ({
    model,
    isOpen,
    toggle,
    statuses,
    onSubmit,
    t
}) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();

    // Fetching state from Redux store
    const rolesList = useSelector((state: ApplicationState) => state.role.list);
    const viewslist: any = useSelector((state: ApplicationState) => state.views.list);
    const propertiesList = useSelector((state: ApplicationState) => state.properties.list);
    const templateList = useSelector((state: ApplicationState) => state.templates.list);

    // State management for the form
    const [statusName, setStatusName] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [roleOptions, setRoleOptions] = useState<OptionsState[]>([]);
    const [aboutViewOptions, setAboutViewOptions] = useState<OptionsState[]>([]);
    const [fieldsOptions, setFieldsOptions] = useState<OptionsState[]>([]);
    const [templatesOptions, setTemplatesOptions] = useState<OptionsState[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<OptionsState[]>([]);
    const [selectedAboutView, setSelectedAboutView] = useState<OptionsState | null>(null);
    const [selectedFields, setSelectedFields] = useState<OptionsState[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<OptionsState | null>(null);
    const [final, setFinal] = useState<boolean>(false);
    const [notificationEmail, setNotificationEmail] = useState<boolean>(false);
    const [viewlist, setViewList] = useState<any>([]);

      
            // Update list data
    useEffect(() => {
        const parsedData: any = viewslist?.[model] && viewslist?.[model]?.length ? viewslist?.[model].map((item: any) => {
            let valuesJson = item?.valuesJson || item;
            let values = { ...item, ...valuesJson }
            return values
        }) : []
        let data = parsedData && parsedData.length ? parsedData : []
        setViewList(data)
    }, [dispatch, model,viewslist?.[model] ])

    // Fetch roles and views on component mount
    useEffect(() => {
        dispatch(roleListGetRequest());
        getViewList(dispatch, model, "about");
        dispatch(propertyListGetRequest(model, {pageSize: 500}, 0));
        dispatch(templateListGetRequest({}, 0, 500));
    }, [dispatch, model]);

    // Update roles state when roles list is fetched
    useEffect(() => {
        if (rolesList?.length) {
            const roles = rolesList.map((role: any) => ({
                value: role.id,
                label: role.title
            }));
            setRoleOptions(roles);
        }
    }, [rolesList]);

    // Update snapshot views state when view list is fetched
    useEffect(() => {
        if (viewlist?.length) {
            const aboutViews = viewlist
                .filter((view: any) => view.location === 'about' && view.model === model)
                .map((view: any) => ({
                    value: view.id,
                    label: view.name
                }));
            setAboutViewOptions(aboutViews);
        }
    }, [viewlist]);

    // Update fields state when fields list is fetched
    useEffect(() => {
        if (propertiesList?.length) {
            const fields = propertiesList
                .map((viewJson: any) => {
                    const view = viewJson.valuesJson  && isJson(viewJson.valuesJson) ? JSON.parse(viewJson.valuesJson) : {}
                    return {
                        value: view.key,
                        label: view.label
                    }
                });
                setFieldsOptions(fields);
        }
    }, [propertiesList]);

    // Update templates state when template list is fetched
    useEffect(() => {
        if (templateList?.length) {
            const templates = templateList
                .map((tempalteJson: any) => {
                    const template = tempalteJson.valuesJson  && isJson(tempalteJson.valuesJson) ? JSON.parse(tempalteJson.valuesJson) : tempalteJson.valuesJson
                    return {
                        value: tempalteJson.id,
                        label: template.name
                    }
                });
                setTemplatesOptions(templates);
        }
    }, [templateList]);

    // Handle changes to the status name input field
    const handleStatusNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value;
        setStatusName(name);

        // Check for existing statuses with the same value
        const statusExists = statuses.some((status) => status.label?.toLowerCase() === name?.toLowerCase());
        setErrorMessage(statusExists ? t('properties.process_flow.common.status.create.name_error') : "");
    };

    // Handle adding a new status
    const handleAddStatus = () => {
        const newStatus = {
            label: statusName,
            value: uuidv4(),
            in_forms: true,
            roles: selectedRoles,
            snapshot_view: selectedAboutView,
            fields: selectedFields,
            notifications: [{
                "type": "email",
                "enabled": notificationEmail,
                "template": selectedTemplate
            }],
            final
        };
        onSubmit(newStatus)
        toggle(false);
        resetFormValues();
    };

    // Reset form values after adding or closing the drawer
    const resetFormValues = () => {
        setStatusName('');
        setErrorMessage('');
        setSelectedRoles([]);
        setSelectedAboutView(null);
        setSelectedFields([]);
        setSelectedTemplate(null);
        setFinal(false);
        setNotificationEmail(false);
    };

    // Mark field mandatory
    const markMandatory = (selectedField: any) => {
        const updatedFields = selectedFields.map(field => {
            if(field.value === selectedField.value) {
                return {
                    ...field,
                    mandatory: !field?.mandatory
                }
            } else {
                return field
            }
        })
        setSelectedFields(updatedFields)
    }

    // Remove of the field from selected list
    const removeField = (selectedField: any) => {
        const filteredFields = selectedFields.filter(field => field.value !== selectedField.value)
        setSelectedFields(filteredFields)
    }

    // Function to handle moving statuses in the list
    const moveStatus = useCallback((dragIndex: number, hoverIndex: number) => {
        setSelectedFields((prevFields: any) => {
            const updatedFields = [...prevFields];
            const [draggedStatus] = updatedFields.splice(dragIndex, 1);
            updatedFields.splice(hoverIndex, 0, draggedStatus);
            return updatedFields;
        });
    }, []);
    return (
        <Offcanvas
            direction="end"
            isOpen={isOpen}
            id="offcanvasWithBothOptions"
            backdrop={false}
            toggle={() => toggle(false)}
        >
            <ToastContainer />
            <OffcanvasHeader
                className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary"
                id="offcanvasWithBothOptions"
                toggle={() => toggle(false)}
            >
                <span className="m-0 me-2 text-white">
                    {t('properties.process_flow.common.status.create.header')}
                </span>
            </OffcanvasHeader>
            <OffcanvasBody className="bg-light" id="offcanvasWithBothOptions">
                <div className="d-flex flex-column gap-2">
                    <div className="form-group mb-3">
                        <Label className="form-label fw-bold fs-6">
                            {t('properties.process_flow.common.status.create.status_label')}<span className="fw-bold text-danger">{" "}*{" "}</span>:
                        </Label>
                        <Input
                            type="text"
                            placeholder={t('properties.process_flow.common.status.create.status_placeholder')}
                            onChange={handleStatusNameChange}
                            value={statusName}
                        />
                        {errorMessage && <p className="form-text text-danger">{errorMessage}</p>}
                    </div>
                    <div className="form-check form-switch">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="markFinal"
                            checked={final}
                            onChange={() => setFinal(!final)}
                        />
                        <Label className="form-check-label" for="markFinal">{t('properties.process_flow.common.status.create.mark_final')}</Label>
                    </div>
                    <span className="text-muted small mark">
                        <abbr title={t('properties.process_flow.common.status.create.mark_final_helper')}>
                            {t('properties.process_flow.common.status.create.mark_final_helper')}
                        </abbr>
                    </span>
                    <div className="form-group mb-3">
                        <Label className="form-label fw-bold fs-6">
                            {t('properties.process_flow.common.status.create.roles_label')}<span className="fw-bold text-danger">{" "}*{" "}</span>:
                        </Label>
                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable
                            isSearchable
                            name="roles"
                            options={roleOptions}
                            onChange={setSelectedRoles}
                            value={selectedRoles}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <Label className="form-label fw-bold fs-6">
                            {t('properties.process_flow.common.status.create.snapshot_views_label')}<span className="fw-bold text-danger">{" "}*{" "}</span>:
                        </Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable
                            isSearchable
                            name="aboutViews"
                            options={aboutViewOptions}
                            onChange={setSelectedAboutView}
                            value={selectedAboutView}
                        />
                    </div>
                    <div className="d-flex flex-column gap-2 p-2 border pb-3">
                        <div className="form-group mb-1">
                            <Label className="form-label fw-bold fs-6">
                                {t('properties.process_flow.common.status.create.fields_label')}:
                            </Label>
                            <Select
                                placeholder={t("common.filter.search")}
                                closeMenuOnSelect={false}
                                isMulti
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable
                                isSearchable
                                name="fields"
                                options={fieldsOptions}
                                onChange={setSelectedFields}
                                value={selectedFields}
                                components={{ ValueContainer }}
                            />
                        </div>
                        <span className="text-muted small mark">
                            <abbr title={t('properties.process_flow.common.status.create.fields_label_helper')}>
                                {t('properties.process_flow.common.status.create.fields_label_helper')}
                            </abbr>
                        </span>
                        <ListGroup className="overflow-x-hidden overflow-y-scroll" style={{maxHeight: "300px"}}>
                            <DndProvider backend={HTML5Backend}>
                                {
                                    selectedFields.map((field: any, fieldIndex: number) => {
                                        return (
                                            <DNDField
                                                key={field.value}
                                                index={fieldIndex}
                                                id={field.value}
                                                field={field}
                                                selectedFields={selectedFields}
                                                moveStatus={moveStatus}
                                                markMandatory={() => markMandatory(field)}
                                                removeField={() => removeField(field)}
                                            />
                                        )
                                    })
                                }
                            </DndProvider>
                        </ListGroup>
                    </div>
                    <div className="d-flex flex-column gap-2 p-2 border pb-3">
                        <div className="d-flex flex-column gap-2 form-group mb-1">
                            <Label className="form-label fw-bold fs-6">
                                {t('properties.process_flow.common.status.create.notifications')}:
                            </Label>
                            <div className="form-check form-switch">
                                <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="notificationEmail"
                                    checked={notificationEmail}
                                    onChange={() => setNotificationEmail(!notificationEmail)}
                                />
                                <Label className="form-check-label" for="notificationEmail">{t('properties.process_flow.common.status.create.notification_email')}</Label>
                            </div>
                            <div className="form-group mb-1">
                                <Label className="form-label fw-bold fs-6">
                                    {t('properties.process_flow.common.status.create.notification_email_template')}:
                                </Label>
                                <Select
                                    placeholder={t("common.filter.search")}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable
                                    isSearchable
                                    name="fields"
                                    options={templatesOptions}
                                    onChange={setSelectedTemplate}
                                    value={selectedTemplate}
                                    components={{ ValueContainer }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </OffcanvasBody>
            <div className="offcanvas-footer bg-info bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                <Link
                    to="#"
                    className="text-danger fw-bold w-auto"
                    onClick={() => toggle(false)}
                >
                    {t('properties.process_flow.common.status.create.btn_cancel')}
                </Link>
                <Button
                    color="primary"
                    className="btn btn-primary w-auto"
                    disabled={!statusName || !selectedRoles?.length || !selectedAboutView || !!errorMessage}
                    onClick={handleAddStatus}
                >
                    {t('properties.process_flow.common.status.create.btn_add')}
                </Button>
            </div>
        </Offcanvas>
    );
};

export default withTranslation()(CreateStatusDrawer);
