import React, { useCallback, useEffect, useState } from "react";
import { Button, Input } from 'reactstrap';
import conditionsJson from "./conditionsJson.json";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Action, createSelector, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from 'react-select/creatable';
import { optionsListGetRequest } from "store/auth/action";
import { ApplicationState } from "store";
import Flatpickr from "react-flatpickr";
import SelectTree from "Components/Common/SelectTree/SelectTree";
import { isJson } from "utils";
import { getStatusOptions } from "helpers/workflowStatusHelper";

const dynamicDateOptions = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "This Week", value: "this_week" },
    { label: "Last Week", value: "last_week" },
    { label: "This Month", value: "this_month" },
    { label: "Last Month", value: "last_month" },
    { label: "Specific Date", value: "specific_date" }
];

const animatedComponents = makeAnimated();

// Interface for DateType
interface DateTypeProps {
    label: string;
    value: string;
}

// Interface for Property
interface PropertyProps {
    id: number;
    key: string;
    label: string | Date;
    type: string;
    value: string;
    model: string;
    dataSource: any;
    optionLabel: string;
    values: any;
    options: any;
    process_flows: any;
}

// Interface for Condition
interface ConditionProps {
    label: string;
    value: string;
}

// Interface for TransformedNode
interface TransformedNode {
    label: any;
    value: any;
    createdBy: any;
    createdAt: any;
    updatedAt: any;
    deletedAt: any;
    parentId: any;
    parent: any;
    children?: TransformedNode[]; // Mark children as optional
}

const AddFilter = ({ filter, onFilterUpdated, activeFilters, toggleDefault }: any) => {
    const selectDashboardData = createSelector(
        (state: any) => state.Layout,
        (state) => ({
            layoutModeType: state.layoutModeType
        })
    );
    // Inside your component
    const { layoutModeType } = useSelector(selectDashboardData);

    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const optionsObj = useSelector((state: ApplicationState) => state.auth.optionData);
    const optionsLoading = useSelector((state: ApplicationState) => state.auth.optionsLoading);

    // State Variables
    const [selectedProperty, setSelectedProperty] = useState<PropertyProps | null>(null);
    const [conditions, setConditions] = useState<ConditionProps[]>([]);
    const [selectedCondition, setSelectedCondition] = useState<ConditionProps | null>(null);
    const [options, setOptions] = useState<any>([]);
    const [values, setValues] = useState<PropertyProps[]>([]);
    const [value, setValue] = useState<Date | number | null>(null);
    const [highValue, setHighValue] = useState<Date | number | null>(null);
    const [dateType, setDateType] = useState<DateTypeProps | null>(null);

    // Clear filter selections
    const clearFilter = useCallback(() => {
        setSelectedProperty(null);
        setConditions([]);
        setSelectedCondition(null);
        resetValueStates();
    }, []);

    useEffect(() => {
        if(filter) {
            setSelectedProperty(filter)
            const conditionOptions = conditionsJson[filter.type as keyof typeof conditionsJson];
            setConditions(conditionOptions);
            if(filter?.condition) {
                setSelectedCondition({value: filter?.condition, label: filter?.conditionLabel})
            } else {
                if(conditionOptions) {
                    setConditions(conditionOptions);
                    setSelectedCondition(conditionOptions[0]); // Set the first condition by default
                }
            }
            if(filter?.valuesLabel) {
                setValues(filter?.valuesLabel);
            }
            if(filter?.value) {
                setValue(filter?.value);
            }
            if(filter?.high_value) {
                setHighValue(filter?.high_value);
            }
            if(filter?.dateType) {
                setDateType(filter?.dateType);
            }
        } else {
            clearFilter()
        }
    }, [filter, clearFilter])

    // Update options based on selected property and options data from Redux
    useEffect(() => {
        if (selectedProperty && optionsObj && optionsObj?.[selectedProperty?.model]) {
            if (selectedProperty.type === 'treeselect') {
                const createdNodes: TransformedNode[] = optionsObj?.[selectedProperty?.model].map(transformTree);

                setOptions(createdNodes);
            } else {
                const newOptions = optionsObj?.[selectedProperty?.model].map((option: any) => {
                    const optionsJson = option.valuesJson && isJson(option.valuesJson) ? JSON.parse(option.valuesJson) : option.valuesJson;
                    return { label: optionsJson?.[selectedProperty?.optionLabel], value: option.id, valuesJson: optionsJson };
                });
                setOptions(newOptions);
            }
        }
    }, [optionsObj, selectedProperty]);

    // Function to find multiple nodes by a list of ids
    const transformTree = (node: any): TransformedNode => {
        // Parse the valuesJson field to extract the name
        const values = node?.valuesJson && isJson(node?.valuesJson) ? JSON.parse(node?.valuesJson) : node?.valuesJson;
        // Create the base transformed node
        const transformedNode: TransformedNode = {
            label: values?.name,
            value: node?.id,
            createdBy: node?.createdBy,
            createdAt: node?.createdAt,
            updatedAt: node?.updatedAt,
            deletedAt: node?.deletedAt,
            parentId: node?.parentId,
            parent: node?.parent,
        };

        // Recursively transform children if they exist and are not empty
        if (node?.children && node?.children.length > 0) {
            transformedNode.children = node?.children.map(transformTree);
        }

        return transformedNode;
    }

    // Reset value-related states
    const resetValueStates = () => {
        setValues([]);
        setValue(null);
        setHighValue(null);
    };

    // Dispatch option fetch action if a model is associated with the selected property
    const onFocus = () => {
        setOptions([])
        if (selectedProperty?.model) {
            let filter ={
                "filters": [
                  {
                    "quick": [],
                    "advance": [],
                    "search": null
                  }
                ],
                "sorts": [
                  {
                    "field": "name",
                    "order": "desc"
                  }
                ]
            }
            let filterObject=null
            if(selectedProperty?.model === 'sources') filterObject= {responseType:'treeselect'}
            dispatch(optionsListGetRequest(selectedProperty?.model, filter, 0, 500, filterObject)); 	
        }
    };

    // Handle filter selection for submission
    const onFilterSelectClicked = () => {
        const { key, label: keyLabel } = selectedProperty || {};
        const { value: condition, label: conditionLabel } = selectedCondition || {};
    
        const isNewFilter = !filter?.id;
    
        const newFilter = {
            id: isNewFilter ? activeFilters.length + 1 : filter.id, // assign new ID if it's a new filter
            key,
            keyLabel,
            condition,
            conditionLabel,
            dateType,
            values: values?.map(({ value }) => value),
            valuesLabel: values,
            property: selectedProperty,
            value,
            high_value: condition === "BETWEEN" ? highValue : null
        };
    
        onFilterUpdated(newFilter);
        toggleDefault('');
        clearFilter();
    };    
    const conditionRequiresInput = () => {
        return !(
          selectedCondition?.value === "IS_EMPTY" ||
          selectedCondition?.value === "IS_NOT_EMPTY"
        );
    };

    // Render input field based on property type
    const renderValueInput = () => {
        // If condition doesn't require input, don't show any input box.
        if (selectedCondition && !conditionRequiresInput()) {
            return null;
        }
        switch (selectedProperty?.type) {
            case 'datasetselect':
                return (
                    <Select
                        onFocus={onFocus}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        isClearable
                        isSearchable
                        styles={customStyles}
                        name="values"
                        onChange={setValues}
                        value={values}
                        isLoading={optionsLoading}
                        options={optionsLoading ? [{ label: 'Loading...', value: null }] : options || []}
                    />
                );
            case 'select':
            case 'checkbox':
            case 'selectboxes':
                let valueOptions = selectedProperty?.options || []
                if (selectedProperty?.process_flows?.length) {
                    const { options: groupOptions } = getStatusOptions(selectedProperty?.process_flows)
                    valueOptions = groupOptions;
                }
                return (
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        isClearable
                        isSearchable
                        styles={customStyles}
                        name="values"
                        options={valueOptions}
                        onChange={setValues}
                        value={values}
                    />
                );
            case 'textfield':
            case 'textarea':
            case 'email':
            case 'phone':
                return (
                    <CreatableSelect
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        isClearable
                        isSearchable
                        name="values"
                        styles={customStyles}
                        options={[]}
                        onChange={(newValue: any) => setValues(newValue)}
                        value={values}
                    />
                );
            case 'datetime':
                if (selectedCondition?.value === 'BETWEEN') {
                    return (
                        <div className="d-flex flex-column gap-1">
                            <Flatpickr
                                className="form-control w-100 text-start"
                                value={value ? value : undefined}
                                options={{ maxDate: highValue ? highValue : undefined }}
                                onChange={([date]) => setValue(date)}
                            />
                            <span>and</span>
                            <Flatpickr
                                className="form-control w-100 text-start"
                                value={highValue ? highValue : undefined}
                                options={{ minDate: value ? value : undefined }}
                                onChange={([date]) => setHighValue(date)}
                            />
                        </div>
                    );
                } else if (selectedCondition?.value === 'EQ') {
                    return (
                        <div className="d-flex flex-column gap-2">
                            <Select
                                options={dynamicDateOptions}
                                isClearable
                                onChange={setDateType}
                                placeholder="Select Date Option"
                                value={dateType}
                            />
                            {
                                dateType?.value === 'specific_date' && (
                                    <Flatpickr
                                        className="form-control w-100 h-100 text-start"
                                        value={value ? value : undefined}
                                        onChange={([date]) => setValue(date)}
                                    />
                                )
                            }
                        </div>
                    )
                } else {
                    return (
                        <Flatpickr
                            className="form-control w-100 h-100 text-start"
                            value={value ? value : undefined}
                            onChange={([date]) => setValue(date)}
                        />
                    );
                }
            case 'number':
                if (selectedCondition?.value === 'BETWEEN') {
                    return (
                        <div className="d-flex flex-column gap-1">
                            <Input
                                type="number"
                                name="label"
                                value={value ? value.toString() : undefined}
                                onChange={(e: any) => setValue(e.target.value)}
                                placeholder='Enter minimum value'
                            />
                            <span>and</span>
                            <Input
                                type="number"
                                name="label"
                                styles={customStyles}
                                value={highValue ? highValue.toString() : undefined}
                                onChange={(e: any) => setHighValue(e.target.value)}
                                placeholder='Enter maximum value'
                            />
                        </div>
                    );
                } else {
                    return (
                        <Input
                            type="number"
                            name="label"
                            styles={customStyles}
                            value={value ? value.toString() : undefined}
                            onChange={(e: any) => setValue(e.target.value)}
                            placeholder='Enter value'
                        />
                    );
                }
            case 'treeselect':
                return (
                    <SelectTree
                        setValues={(values: any) => setValues(values)}
                        onFocus={onFocus}
                        options={options}
                        values={values}
                        showValues={true}
                        optionsLoading={optionsLoading}
                    />
                );
            default:
                return null;
        }
    };
    const customStyles = {

        // Customize the style of the dropdown menu
        input: (provided: any) => ({
            ...provided,
            color: layoutModeType === 'light'? 'black' : 'white',
          }),
        control: (provided: any, state: any) => ({
                    ...provided,
                    backgroundColor: 'transparent'
        }),
        menu: (provided: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
        }),
        // Customize the style of the option in the dropdown menu
        option: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
          '&:hover': {
            backgroundColor: layoutModeType === 'light' ? 'white' : '#495057',
          },
        }),
    }

    return (
        <div className="d-flex flex-column gap-2 w-100">
            {selectedProperty && (
                <div className="d-flex flex-column gap-2">
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable
                        name="conditions"
                        options={conditions}
                        onChange={setSelectedCondition}
                        value={selectedCondition}
                    />
                    {renderValueInput()}
                </div>
            )}
            <div className='d-flex gap-2 justify-content-start w-100'>
                <Button
                    onClick={onFilterSelectClicked}
                    color="primary"
                    disabled={
                        conditionRequiresInput()
                          ? (selectedCondition?.value === 'BETWEEN'
                              ? !(highValue && value && highValue >= value)
                              : !(values?.length || value || (dateType?.value && dateType?.value !== "specific_date")))
                          : false
                      }
                >
                    Apply
                </Button>
                <Button color="light"
                    onClick={() => toggleDefault('')}
                >
                    Cancel
                </Button>
            </div>
        </div>
    );
};

export default AddFilter;
