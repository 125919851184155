
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
    Col,
    Input,
    Label,
    Row
} from "reactstrap";

const BasicInfo = ({ setEditInputs, EditInputs, setFieldJson, fieldJson, t }: any) => {
    const [rules, setRules] = useState<any>({
        show_informs: false,
        required: false,
        unique: false,
        minLimit: { restrictminLimit: false, value: 1 },
        maxLimit: { restrictmaxLimit: false, value: 30 },
        restrictspecialcharacter: false,
        restrictalpha: false
    })

    const onChanged = (target: any, name: any) => {
        if (name == "hidden") {
            setRules({ ...rules, [name]: !target.checked })
            let newfield = { ...fieldJson, hidden: !target.checked }
            setEditInputs({ ...EditInputs, hidden: !target.checked })
            setFieldJson(newfield)
        }
        else if (name == "quickCreate") {
            setRules({ ...rules, [name]: target.checked })
            let newfield = { ...fieldJson, quickCreate: target.checked }
            setEditInputs({ ...EditInputs, quickCreate: target.checked })
            setFieldJson(newfield)
        }
        else if (name == "mainFields") {
            setRules({ ...rules, [name]: target.checked })
            let newfield = { ...fieldJson, mainFields: target.checked }
            setEditInputs({ ...EditInputs, mainFields: target.checked })
            setFieldJson(newfield)
        }
        else if (name == "unique") {
            setRules({ ...rules, [name]: target.checked })
            let newvalidate = fieldJson.validate
            let newuniquevalidation = { ...newvalidate, unique: target.checked }
            let newfield = { ...fieldJson, validate: newuniquevalidation }
            setEditInputs({ ...EditInputs, validate: newuniquevalidation })
            setFieldJson(newfield)
        }
        else if (name == "required") {
            setRules({ ...rules, [name]: target.checked })
            let newvalidate = fieldJson.validate
            let newuniquevalidation = { ...newvalidate, required: target.checked }
            let newfield = { ...fieldJson, validate: newuniquevalidation }
            setEditInputs({ ...EditInputs, validate: newuniquevalidation })
            setFieldJson(newfield)
        }
        else if (name == "filter") {
            setRules({ ...rules, [name]: target.checked })
            let newfield = { ...fieldJson, filter: target.checked }
            setEditInputs({ ...EditInputs, filter: target.checked })
            setFieldJson(newfield)
        }
        else if (name == "tablecolumn") {
            setRules({ ...rules, [name]: target.checked })
            let newfield = { ...fieldJson, tablecolumn: target.checked }
            setEditInputs({ ...EditInputs, tablecolumn: target.checked })
            setFieldJson(newfield)
        }        
        else if (name == 'enableTime') {
            // setRules({ ...rules, [name]: target.checked })
            let newvalidate = fieldJson.widget
            let newuniquevalidation = { ...newvalidate, enableTime: target.checked }
            let newfield = { ...fieldJson, widget: newuniquevalidation }
            let newrules = { ...rules, enableTime: target.checked }
            setEditInputs({ ...EditInputs, widget: newuniquevalidation })
            setFieldJson(newfield)
        }
        else {
            setRules({ ...rules, [name]: target.checked })
            let newvalidate = fieldJson.validate
            let newuniquevalidation = { ...newvalidate, [name]: target.checked }
            let newfield = { ...fieldJson, validate: newuniquevalidation }
            setEditInputs({ ...EditInputs, validate: newuniquevalidation })
            setFieldJson(newfield)
        }
    }

    useEffect(() => {
        setEditInputs({ ...EditInputs, rules })
    }, [rules]);
    return (
        <div>
            <Row className="hstack gap-3 flex-column justify-content-center">
                <Col lg={12} className="d-flex gap-2 fs-24 fw-bold flex-column justify-content-center text-center">
                    {EditInputs.label}
                </Col>
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">
                    <div className="fs-18 mb-1 fw-bold">
                    {t("properties.rules.set_field_rules")}
                    </div>
                    <div className="fs-16 mb-1 fw-SemiBold">
                    {t("properties.rules.field_visibility")}
                    </div>
                    <div className="hstack align-content-center gap-1 mb-1">
                        <Input className="mb-1 form-check-input" type="checkbox" name="show-informs" onClick={(e) => onChanged(e.target, 'hidden')} checked={!EditInputs.hidden} />
                        <Label className="fs-16 mb-0 fw-medium">{t("properties.rules.show_in_forms_popups_bots")}</Label>
                    </div>
                    {
                        EditInputs.type === 'auto-increment' ? null
                        :
                        <div className="hstack align-content-center gap-1 mb-1">
                            <Input className="mb-1 form-check-input" type="checkbox" name="show-informs" onClick={(e) => onChanged(e.target, 'quickCreate')} checked={EditInputs.quickCreate} />
                            <Label className="fs-16 mb-0 fw-medium">{t("properties.rules.show_in_quick_create_forms")}</Label>
                        </div>
                    }
                    {/* {
                        EditInputs.type === 'auto-increment' ? null
                        :
                        <div className="hstack align-content-center gap-1 mb-1">
                            <Input className="mb-1 form-check-input" type="checkbox" name="show-informs" onClick={(e) => onChanged(e.target, 'mainFields')} checked={EditInputs.mainFields} />
                            <Label className="fs-16 mb-0 fw-medium">Show in Main Fields</Label>
                        </div>
                    } */}
                    <div className="hstack align-content-center gap-1 mb-1">
                        <Input className="mb-1 form-check-input" type="checkbox" name="show-informs" onClick={(e) => onChanged(e.target, 'tablecolumn')} checked={EditInputs.tablecolumn} />
                        <Label className="fs-16 mb-0 fw-medium">{t("properties.rules.show_in_table")}</Label>
                    </div>
                    <div className="hstack align-content-center gap-1 mb-1">
                        <Input className="mb-1 form-check-input" type="checkbox" name="show-informs" onClick={(e) => onChanged(e.target, 'filter')} checked={EditInputs.filter} />
                        <Label className="fs-16 mb-0 fw-medium">{t("properties.rules.add_in_filters")}</Label>
                    </div>
                    <div className="fs-16 mb-1 fw-SemiBold">
                    {t("properties.rules.validation_rules")}
                        <p className="fs-14 mb-0 fw-medium">{t("properties.rules.specify_what_values_are_allowed")}</p>
                    </div>
                    {
                        EditInputs.type === 'auto-increment' ? null
                        :
                        <div className="hstack align-content-center gap-1 mt-1" onClick={(e) => onChanged(e.target, 'required')}>
                            <Input className="mb-1 form-check-input" type="checkbox" checked={EditInputs.validate.required} />
                            <Label className="fs-16 mb-0 fw-normal">{t("properties.rules.set_as_mandatory")} </Label>
                        </div>  
                    }                  
                    { 
                        EditInputs.type === 'datetime' ?
                            <div className="hstack align-content-center gap-1 mb-1" onClick={(e) => onChanged(e.target, 'enableTime')}>
                                <Input className="mb-1 form-check-input" type="checkbox" checked={EditInputs?.widget?.enableTime} />
                                <Label className="fs-16 mb-0 fw-normal">{t("properties.rules.enable_time_in_date")} </Label>
                            </div>
                            : null
                    }
                </Col>
            </Row>
        </div>
    );
};

export default withTranslation()(BasicInfo);
