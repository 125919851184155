import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { Table, Row, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { DefaultColumnFilter } from "../../../../../Components/Common/filters";
import Paginator from "Components/Common/Paginator";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";


interface TableContainerProps {
    columns?: any;
    data?: any;
    tableClass?: any;
    theadClass?: any;
    customPageSize?: any;
    isPageSizeChange?: any;
    trClass?: any;
    thClass?: any;
    handleLeadClick: (e: any) => void,
    setisDeleteOpen: (e: any) => void,
    pageChanged: (e: any) => void,
    pagination: any;
    pageSize: any;
    setPagination: (e: any) => void;
    onChangePageSize: (e: any) => void;
    props: any;
    loading: boolean;
}

const TableContainer = ({
    columns,
    data,
    isPageSizeChange,
    customPageSize,
    tableClass,
    theadClass,
    trClass,
    thClass,
    handleLeadClick,
    setisDeleteOpen,
    pageChanged,
    pagination,
    props,
    onChangePageSize,
    pageSize,
    loading
}: TableContainerProps) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: 0, pageSize: customPageSize, selectedRowIds: 0
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

    const generateSortingIndicator = (column: any) => {
        return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
    };

    const onChangeInSelect = (event: any) => {
        setPageSize(Number(event.target.value));
        onChangePageSize(event.target.value);
    };

    return (
        <div>
            {loading ?
                <DtsTablePlaceholder
                    columns={columns}
                    theadClass={theadClass}
                    tableClass="align-middle table-nowrap"
                    thClass="border-bottom-1 table-soft-primary"
                />
                :
                <div>{page && page.length ?
                    <Table responsive={true} hover {...getTableProps()} className={tableClass + 'm-0'}>
                        <thead className={theadClass}>
                            {headerGroups.map((headerGroup: any) => (
                                <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column: any) => (
                                        <th key={column.id} className={thClass + "fw-bold"}
                                            {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}
                                        >
                                            {column.render("Header")}
                                            {generateSortingIndicator(column)}
                                        </th>
                                    ))}                                   
                                </tr>
                            ))}
                        </thead>

                        <tbody {...getTableBodyProps()}>
                            {page.map((row: any) => {
                                prepareRow(row);
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        <tr style={{ verticalAlign: 'middle' }}>
                                            {row.cells.map((cell: any) => {
                                                let classList = cell.column.className;
                                                return (
                                                    <td key={cell.id} style={{ verticalAlign: 'middle' }} className='align-items-center justify-content-between' {...cell.getCellProps()}>
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    </Fragment>
                                );
                            })}
                        </tbody>
                    </Table> : <div className="hstack p-5 fw-semibold fs-16 justify-content-center w-100"><span className="hstack p-5  justify-content-center  fw-semibold fs-16 text-center w-100">{props.t("common.message.no_data_found")}</span></div>}
                    <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                        <div className="col-sm">
                            <div className="text-muted">
                                {props.t("common.pagination.showing")}
                                <span className="fw-semibold ms-1">
                                    {data.length > 0 ? `${pagination.offset + 1}-${Math.min(pagination.offset + pagination.size, pagination.elements)} of ${pagination.elements} results` : `0-0 of 0 results`}
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-auto">
                            <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                                currentPage={pagination?.page}
                                pageChanged={(e: any) => pageChanged(e)}
                                pageSize={pageSize}
                                isPageSizeChange={isPageSizeChange}
                                onChangeInSelect={(e: any) => onChangeInSelect(e)}
                                pagination={pagination}
                                props={props}
                            />
                        </div>
                    </Row>
                </div>}
        </div>
    );
};

TableContainer.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;