import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
    Button,
    Col,
    Row,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    Card,
} from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { tenantGetRequest, tenantUpdateRequest, userUpdateRequest } from "store/user/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { getGoogleCalendarData } from "store/calendarSync/action";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { toast } from "react-toastify";
import ConfirmSyncModal from "../Accounts/GmailSync";
import SyncOptionModal from "../Accounts/SyncOptionModal";
import { userGetRequest } from "store/auth/action";
import { permissionsGetRequest } from "store/role/action";
import Upload from "./Upload/Upload";
import { useParams } from "react-router";
import UserAvatar from "Components/Common/UserAvtar/UserAvatar";
import { isJson } from "utils";
import SimpleBar from "simplebar-react";

const Profile = ({ t }: any) => {
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const user: any = useSelector((state: ApplicationState) => state.auth.userProfile);
    const tenatDetails: any = useSelector((state: ApplicationState) => state.user?.tenatDetails);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState<any>(null);
    const [modal, setModal] = useState(false);
    const [modalPicture, setModalPicture] = useState(false);
    const [userData, setUserData] = useState<any>(user);
    const [selectedFiles, setselectedFiles] = useState<any>([]);
    const [fileInfo, setFileInfo] = useState<any>({});
    const [uploadModel, setUploadModal] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getGoogleCalendarData())
    }, [])

    useEffect(() => {
        setUserData(user);
        dispatch(tenantGetRequest(user?.tenant?.id))
    }, [user]);

    useEffect(() => {
        if (user) {
            dispatch(userGetRequest());
            dispatch(permissionsGetRequest());
        }
    }, [user.profilePic]);

    const handleUpdateInfo = () => {
        const attributes = [];

        if (userData.company_name !== user.company_name) {
            attributes.push({
                key: "company_name",
                value: userData.company_name
            });
        }
        if (userData.no_of_employees !== user.no_of_employees) {
            attributes.push({
                key: "no_of_employees",
                value: userData.no_of_employees
            });
        }
        if (userData.website !== user.website) {
            attributes.push({
                key: "website",
                value: userData.website
            });
        }
        attributes.push({
            key: "logo",
            value: 'file_upload_2b90fe01-8ecb-4a33-b62f-6ff81c8bc54b_CV.jpg'
        });

        // Only dispatch if there are attributes to update
        if (attributes.length > 0) {
            const data = {
                attributes: attributes
            };
            const handleSuccess = (body: any): void => {
                // const message = () => toast(`User information updated successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
                // message();
                dispatch(tenantGetRequest())
            };
            const handleError = (body: any): void => {
                const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
                message();
            };
            dispatch(userUpdateRequest(id, data, handleSuccess, handleError));
        }
    };

    const handleDeleteProfilePic = () => {
        let formValues: any = { logo: "" }
        const data = {
            "attributes": [
                {
                    "key": "settings",
                    "value": "{logo:''}"
                }
            ]
        }
        const handleSuccess = (body: any): void => {
            setDeleteModal(false)
            // toast(`Profile image removed successfully.`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            dispatch(tenantGetRequest(id))
        };
        const handleError = (body: any): void => {
            const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            message();
        };
        dispatch(tenantUpdateRequest(data, handleSuccess, handleError));
    };



    const fileToggle = useCallback(() => {
        if (uploadModel) {
            setUploadModal(false);
        } else {
            setUploadModal(true);
        }
    }, [uploadModel]);

    const handleFileClicks = () => {
        setUploadModal(!uploadModel);
        fileToggle();
    };


    const handleClick = (event: any) => {
        setTarget(event.target);
        setShow(!show);
        setModalPicture(!modalPicture);
    };

    useEffect(() => {
        let settings = tenatDetails?.settings && isJson(tenatDetails?.settings) ? JSON.parse(tenatDetails?.settings) : {}
        setUserData({ company_name: tenatDetails?.companyName, no_of_employees: tenatDetails?.noOfEmployees, website: tenatDetails?.website, email: tenatDetails?.email, logo: settings?.logo })
    }, [tenatDetails])

    console.log("user data", userData)
    return (
        <React.Fragment>
            <Modal backdrop="static" isOpen={deleteModal} toggle={() => setDeleteModal(false)} centered={true}>
                <ModalBody className="py-3 px-5">
                    <div className="mt-2 text-center">
                        <i className="ri-delete-bin-line display-5 text-danger"></i>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>{t("common.modals.are_you_sure")}</h4>
                            <p className="text-muted mx-4 mb-0">
                                {t("users.are_you_sure_remove_company_logo")}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            data-bs-dismiss="modal"
                            onClick={() => setDeleteModal(false)}
                        >
                            {t("common.action_button.close")}
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-danger "
                            id="delete-record"
                            onClick={() => handleDeleteProfilePic()}
                        >
                            {t("common.modals.yes_remove")}
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <ConfirmSyncModal
                confirmModal={confirmModal}
                setConfirmModal={setConfirmModal}
            // props={props}
            />
            <SyncOptionModal
                modal={modal}
                setModal={setModal}
                confirmModal={confirmModal}
                setConfirmModal={setConfirmModal}
            // props={props}
            />
            <Modal backdrop="static" id="createFileModal" isOpen={uploadModel} toggle={() => { fileToggle(); setselectedFiles([]); }} modalClassName="zoomIn" centered tabIndex={1}>
                <ModalHeader toggle={() => { fileToggle(); setselectedFiles([]); }} className="p-3 bg-primary-subtle">{t("userpreferences.upload_file")}</ModalHeader>
                <ModalBody>
                    <Upload onCloseModal={() => setUploadModal(false)} selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} fileInfo={fileInfo} setFileInfo={setFileInfo} />
                </ModalBody>
            </Modal>
            <div>
                <h4 className="">{t("userpreferences.company_profile")}</h4>
                <div className="hstack mb-4">
                    <Row className="p-2 w-100 hstack gap-3 justify-items-center">
                        {/* <Card className="p-3 shadow-lg"> */}
                            <div className="vstack gap-1 justify-content-center">

                                <p>{t("userpreferences.this_applies_across_zilter")}</p>
                            </div>
                            <div className="vstack gap-3 align-items-left">
                                <Card className="p-3 mb-1">
                                    <Label className="fw-bold fs-14">{t("userpreferences.company_logo")}</Label>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex position-relative gap-3">
                                            <div className="vstack">
                                                <div className="profile-user position-relative d-inline-block mx-auto">
                                                    {userData.logo ? (
                                                        <img
                                                            src={userData?.logo}
                                                            className="rounded-circle avatar-lg img-thumbnail user-profile-image"
                                                            alt="company-logo"
                                                            onClick={handleClick}
                                                            ref={setTarget}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    ) :
                                                        <UserAvatar className="rounded-circle avatar-lg img-thumbnail user-profile-image" img={userData?.logo} firstName={userData?.company_name} />
                                                    }
                                                    <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                        <Label
                                                            htmlFor="profile-img-file-input"
                                                            className="profile-photo-edit avatar-xxs"
                                                        >
                                                            <span className="avatar-title rounded-circle bg-light text-body">
                                                                <i onClick={handleFileClicks} className="ri-camera-fill"></i>
                                                            </span>
                                                        </Label>
                                                    </div>
                                                </div>
                                                <p className="fs-12 text-center mt-1">166X132(WxH)</p>
                                            </div>
                                            <div className="mt-3">
                                                <Label className="fs-16">{userData?.company_name}</Label>
                                                <div className="vstack gap-1 align-self-center flex-grow-1">
                                                    <h6>{user?.tenant?.email}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {userData && userData.logo !== undefined && userData.logo !== "" && userData.logo !== null ? (
                                            <div>
                                                <Button
                                                    size="sm"
                                                    color="danger"
                                                    className="btn-danger"
                                                    onClick={() => setDeleteModal(true)}
                                                >
                                                    <i className="ri-delete-bin-2-line label-icon align-middle fs-16 me-2"></i>
                                                    {t("userpreferences.remove_logo")}
                                                </Button>
                                            </div>
                                        ) : null}

                                    </div>
                                </Card>
                                <Card className="p-3 mb-1">
                                    <Row>
                                        <Col md={6} lg={6} xl={6} xxl={6}>
                                            <FormBuilder
                                                label={"Company Name"}
                                                type={"textfield"}
                                                value={userData?.company_name}
                                                defaultValue={userData?.company_name}
                                                name={"company_name"}
                                                placeholder={`Enter Company Name`}
                                                className="flex-grow-1 me-3 mb-3"
                                                onChange={(value: any) => {
                                                    setUserData({ ...userData, company_name: value })
                                                }}
                                                Editable={true}
                                            />
                                        </Col>
                                        <Col md={6} lg={6} xl={6} xxl={6}>
                                            <FormBuilder
                                                label={"Number of employees"}
                                                type={"textfield"}
                                                value={userData?.no_of_employees}
                                                defaultValue={userData?.no_of_employees}
                                                name={"last_name"}
                                                placeholder={`Enter number of employees`}
                                                className="flex-grow-1 me-3 mb-3"
                                                onChange={(value: any) => {
                                                    setUserData({ ...userData, no_of_employees: value })
                                                }}
                                                Editable={true}
                                            />
                                        </Col>
                                        <Col md={6} lg={6} xl={6} xxl={6}>
                                            <FormBuilder
                                                label={"Website"}
                                                type={"textfield"}
                                                defaultValue={userData?.website}
                                                value={userData?.website}
                                                name={"phone_number"}
                                                placeholder={`Enter website`}
                                                className="flex-grow-1 me-3 mb-3"
                                                onChange={(value: any) => {
                                                    setUserData({ ...userData, website: value })
                                                }}
                                                Editable={true}
                                            />
                                            <Button className='align-self-end' color="primary" onClick={handleUpdateInfo}>{t("userpreferences.update_info")}</Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        {/* </Card> */}
                    </Row>
                </div>
                {/* </SimpleBar> */}
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Profile);
