/**
 * buildTree.ts
 *
 * This helper file contains functions to build and sort a tree structure from flat API data.
 * The tree is constructed based on parent-child relationships, and each node's children are
 * sorted by the createdAt timestamp for consistent ordering.
 */

import { Team } from "store/teams/types";

// Extend the Team interface to include a children array.
export interface TreeNode extends Team {
	children: TreeNode[];
}

/**
 * buildTree
 * Constructs a tree from an array of teams by linking parent-child relationships.
 * It also sorts the children of each node based on the createdAt timestamp.
 *
 * @param data Array of teams from the API.
 * @returns Array of root TreeNodes.
 */
export function buildTree(data: Team[]): TreeNode[] {
	const nodeMap: Map<string, TreeNode> = new Map();
	const roots: Set<string> = new Set();

	// Helper function to add a node and its parent chain into the map.
	function flatten(node: Team | null): void {
		if (!node) return;
		if (!nodeMap.has(node.id)) {
			const treeNode: TreeNode = { ...node, children: [] };
			nodeMap.set(node.id, treeNode);
			// If there's no parent, mark as root.
			if (!node.parentTeam) {
				roots.add(node.id);
			}
			// Recursively flatten the parent node.
			if (node.parentTeam) {
				flatten(node.parentTeam);
			}
		}
	}

	// Flatten each node from the API data.
	data.forEach(item => flatten(item));

	// Link each node to its parent's children array.
	nodeMap.forEach(node => {
		if (node.parentTeam) {
			const parent = nodeMap.get(node.parentTeam.id);
			if (parent) {
				parent.children.push(node);
				// Remove node from roots since it has a parent.
				roots.delete(node.id);
			}
		}
	});

	/**
	 * sortChildren
	 * Recursively sorts each node's children by the createdAt timestamp.
	 *
	 * @param node A tree node.
	 */
	function sortChildren(node: TreeNode): void {
		if (node.children && node.children.length > 0) {
			node.children.sort(
				(a, b) =>
					new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
			);
			node.children.forEach(child => sortChildren(child));
		}
	}

	// Sort children for each root node.
	const treeRoots = Array.from(roots).map(id => {
		const rootNode = nodeMap.get(id)!;
		sortChildren(rootNode);
		return rootNode;
	});

	return treeRoots;
}
