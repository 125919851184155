import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { withTranslation } from "react-i18next";

const DeleteModal = ({ t, show, onCloseClick, record , onDelete}: any) => {
    return ( record && record.id ?
        <Modal backdrop="static" isOpen={show} toggle={onCloseClick} centered={true}>
                <ModalHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={onCloseClick}>
                    <span className='text-light'>{t("common.modals.remove_item")}</span>
                </ModalHeader>
                <ModalBody className='text-center p-5'>
                    <div className="mt-1">     
                        <i className="ri-delete-bin-5-line display-5 text-danger"></i>                                  
                        <h4 className="mb-2 mt-2">{record?.student?.name}</h4>
                        <p className="text-muted fs-15 mb-4">{t("common.modals.are_you_sure_remove_item")}</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button className="btn btn-danger" onClick={() => onDelete(record?.id)}>
                                {t("common.modals.remove")}
                            </Button>
                            <Button color="danger" onClick={() => onCloseClick()} outline>
                                {t("common.offcanvas.import_cancel")}
                            </Button>
                        </div>
                    </div>
                </ModalBody>
        </Modal> : null
    ) as unknown as JSX.Element;
};

export default withTranslation()(DeleteModal);