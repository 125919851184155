export interface Recommend {
    
}

export enum RecommendActionTypes {
    POST_CONFIRMED_COURSES_REQUEST = "@@recommend/POST_CONFIRMED_COURSES_REQUEST",
    POST_CONFIRMED_COURSES_SUCCESS = "@@recommend/POST_CONFIRMED_COURSES_SUCCESS",
    POST_CONFIRMED_COURSES_ERROR = "@@recommend/POST_CONFIRMED_COURSES_ERROR",
    POST_RECOMMEND_COURSES_REQUEST = "@@recommend/POST_RECOMMEND_COURSES_REQUEST",
    POST_RECOMMEND_COURSES_SUCCESS = "@@recommend/POST_RECOMMEND_COURSES_SUCCESS",
    POST_RECOMMEND_COURSES_ERROR = "@@recommend/POST_RECOMMEND_COURSES_ERROR",    
    GET_RECOMMENDATIONS_DETAILS_REQUEST = "@@recommend/GET_RECOMMENDATIONS_DETAILS_REQUEST",
    GET_RECOMMENDATIONS_DETAILS_SUCCESS = "@@recommend/GET_RECOMMENDATIONS_DETAILS_SUCCESS",
    GET_RECOMMENDATIONS_DETAILS_ERROR = "@@recommend/GET_RECOMMENDATIONS_DETAILS_ERROR",
    GET_RECOMMENDATIONS_List_REQUEST = "@@recommend/GET_RECOMMENDATIONS_List_REQUEST",
    GET_RECOMMENDATIONS_List_SUCCESS = "@@recommend/GET_RECOMMENDATIONS_List_SUCCESS",
    GET_RECOMMENDATIONS_List_ERROR = "@@recommend/GET_RECOMMENDATIONS_List_ERROR",
    GET_AUTHORIZATIONFORM_DETAILS_REQUEST = "@@recommend/GET_AUTHORIZATIONFORM_DETAILS_REQUEST",
    GET_AUTHORIZATIONFORM_DETAILS_SUCCESS = "@@recommend/GET_AUTHORIZATIONFORM_DETAILS_SUCCESS",
    GET_AUTHORIZATIONFORM_DETAILS_ERROR = "@@recommend/GET_AUTHORIZATIONFORM_DETAILS_ERROR",
    GET_RECOMMENDATIONS_REQUEST = "@@recommend/GET_RECOMMENDATIONS_REQUEST",
    GET_RECOMMENDATIONS_SUCCESS = "@@recommend/GET_RECOMMENDATIONS_SUCCESS",
    GET_RECOMMENDATIONS_ERROR = "@@recommend/GET_RECOMMENDATIONS_ERROR",    
    PUT_RECOMMENDATIONS_REQUEST = "@@recommend/PUT_RECOMMENDATIONS_REQUEST",
    PUT_RECOMMENDATIONS_SUCCESS = "@@recommend/PUT_RECOMMENDATIONS_SUCCESS",
    PUT_RECOMMENDATIONS_ERROR = "@@recommend/PUT_RECOMMENDATIONS_ERROR",
}

export interface RecommendState {
    readonly loading: boolean;
    readonly errors?: string;
    readonly details?: any;
    readonly list?: any;
    readonly pagination: any;
    readonly formDetails?: any;
}
