import {CurrencyActionTypes, Currency} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";

const { api } = config;

const DTS_API_DOMAIN = api.DTS_API_DOMAIN
const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const currencyGetRequest: AppThunk = () => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: CurrencyActionTypes.GET_CURRENCY_REQUEST
        });
        try {
            const response = await fetch(`https://api.dts.auth.zilter.io/exchange-rates/today`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: CurrencyActionTypes.GET_CURRENCY_ERROR,
                    payload: body
                });
                // handleError(body);
            } else {
                dispatch({
                    type: CurrencyActionTypes.GET_CURRENCY_SUCCESS,
                    payload: body
                });
                // handleSuccess(body);
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle parsing error here
        }
    };
};

const createQueryLink = (filterObject: any, page: any) => {
    const keys = Object.keys(filterObject);
    const values = Object.values(filterObject);
    let query = `${DTS_API_STUDENT}/currencies`;
    for (let i = 0; i < keys.length; i++) {
        if (values[i]) query += `${keys[i]}=${values[i]}&`;
    }
    return query;
};
export const currencysListGetRequest: AppThunk = (queryParams) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CurrencyActionTypes.GET_CURRENCIES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const response = await fetch(`${DTS_API_STUDENT}/currencies/${queryParams}`, {
            method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CurrencyActionTypes.GET_CURRENCIES_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: CurrencyActionTypes.GET_CURRENCIES_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const currencyAddRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CurrencyActionTypes.POST_CURRENCY_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(`${DTS_API_STUDENT}/currencies`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        // const body = await response.json();
        const {response: response_1} = {response};
        if (!response_1.ok) {
            dispatch({
                type: CurrencyActionTypes.POST_CURRENCY_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: CurrencyActionTypes.POST_CURRENCY_SUCCESS,
                payload: []
            });
            handleSuccess();
        }
    };
};

export const currencyUpdateRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data: any, handleSuccess: () => void, handleError: () => void) => {
    return async (dispatch: Dispatch): Promise<void> => {
        try {
            // Dispatch success action with the currency data
            dispatch({
                type: CurrencyActionTypes.UPDATE_CURRENCY_SUCCESS,
                payload: data, // Assuming 'data' holds the currency info you want to store
            });
            
            handleSuccess && handleSuccess(); // Call success callback
        } catch (error) {
            // In case there's an error (although it should be rare in this case)
            dispatch({
                type: CurrencyActionTypes.UPDATE_CURRENCY_ERROR,
                error: 'Error while updating currency in the store',
            });
            handleError && handleError(); // Call error callback
        }
    };
};

export const countriesListGetRequest: AppThunk = (filters,pageno, pageSize, signal) => {
    let requestbody = filters ? filters : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CurrencyActionTypes.GET_COUNTRIES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }              
        const response = await fetch(`${DTS_API_STUDENT}/models/get/countries?page=${pageno?pageno:0}&pageSize=${pageSize?pageSize:300}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestbody),
            signal
        }).catch((error) => {
            return false;
        });
        if(typeof response === 'object' && !Array.isArray(response) && response !== null) {
            const body = await response.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: CurrencyActionTypes.GET_COUNTRIES_ERROR,
                    payload: body_1
                });
            } else {
                dispatch({
                    type: CurrencyActionTypes.GET_COUNTRIES_SUCCESS,
                    payload: body_1
                });
            }
        }
    };
};