import { Reducer } from "redux";
import { PaymentActionTypes, PaymentState } from "./types";


export const initialState: PaymentState = {
    metaDataFields: [],
    list: null,
    details: null,
    pagination: null,
    hierarchysources: [],
    loading: false,
    dataLoading: false,
    metaLoading: false,
}
const reducer: Reducer<PaymentState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case PaymentActionTypes.GET_METADATA_REQUEST: {
            return { ...state, metaLoading: true };
        }
        case PaymentActionTypes.GET_METADATA_SUCCESS: {
            return {
                ...state,
                metaLoading: false,
                metaDataFields: action.payload.content
            };
        }
        case PaymentActionTypes.GET_METADATA_ERROR: {
            return { ...state, metaLoading: false, errors: action.payload };
        }
        case PaymentActionTypes.GET_REQUEST: {
            return { ...state, loading: true };
        }
        case PaymentActionTypes.GET_SUCCESS: {
            return {
                ...state,
                loading: false,
                details: action.payload
            };
        }
        case PaymentActionTypes.GET_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case PaymentActionTypes.POST_REQUEST: {
            return { ...state, loading: true };
        }
        case PaymentActionTypes.POST_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case PaymentActionTypes.POST_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case PaymentActionTypes.GET_ALL_REQUEST: {
            return { ...state, dataLoading: true, list: [] };
        }
        case PaymentActionTypes.GET_ALL_SUCCESS: {
            return {
                ...state,
                dataLoading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case PaymentActionTypes.GET_ALL_ERROR: {
            return { ...state, dataLoading: false, errors: action.payload, list: [] };
        }

        case PaymentActionTypes.PUT_REQUEST: {
            return { ...state, loading: true };
        }
        case PaymentActionTypes.PUT_SUCCESS: {
            return {
                ...state,
                loading: false,
                sourcelist: action.payload.content
            };
        }
        case PaymentActionTypes.PUT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case PaymentActionTypes.DELETE_REQUEST: {
            return { ...state, loading: true };
        }
        case PaymentActionTypes.DELETE_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case PaymentActionTypes.DELETE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        default: {
            return state;
        }
    }
};

export { reducer as PaymentReducer };
