import React, { useEffect, useRef, useState } from "react";
import { createRoot, Root } from "react-dom/client";
import {
    Label,
    Button,
    FormFeedback,
    ButtonGroup,
    Input,
    Spinner,
} from "reactstrap";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
// import './styles.css'
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { propertiesListGetRequest } from "store/auth/action";
import { ApplicationState } from "store";
import { startCase } from "lodash";
import { isJson } from "utils";
import config from "../../../config";
const { DTS_API_STUDENT } = config.api;

interface DtsCKEditorProps {
    label?: string;
    type?: string;
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
    isEditable?: boolean;
    name?: string;
    errorText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    actionButtons?: boolean;
    defaultValue?: string;
    rest?: any;
    invalid?: any;
    formValues?: any;
    dataFields?: any;
    onSaveClick?: any;
    setdisabled?: any;
    setError?: any;
    formerror?: any;
    resetvalue?: any;
    loading?: boolean;
    stopListening?:() => void;
}

const DtsCKEditor: React.FC<DtsCKEditorProps> = ({
    loading,
    resetvalue,
    formerror,
    setError,
    setdisabled,
    onSaveClick,
    rest,
    label,
    type,
    value,
    placeholder,
    onChange,
    name,
    actionButtons,
    readOnly,
    disabled,
    stopListening
}: any) => {
    const [hover, setHover] = useState(false);
    const optionList = useSelector(
        (state: ApplicationState) => state.auth.propertiesOptionData
    );
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [checkdisabled, setCheckdisabled] = useState(false);
    const [errormsg, setErrormsg] = useState<any>();
    // Controls visibility of the suggestion box.
    const [suggestionsVisible, setSuggestionsVisible] = useState(true);
    // Holds the current search term.
    const [searchTerm, setSearchTerm] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);
    // Full and filtered suggestion lists.
    const [allSuggestions, setAllSuggestions] = useState<any>([]);
    const [filteredSuggestions, setFilteredSuggestions] = useState<any>([]);
    const [editor, setEditor] = useState<any>(null);
    const [resetValue, setResetValue] = useState<any>(null);
    // Holds the suggestion list JSX.
    const [suggestionList, setSuggestionList] = useState<JSX.Element | null>(null);
    // This component wraps the search input and suggestion list.
    const [suggestionListComponent, setSuggestionListComponent] = useState<JSX.Element | null>(null);
    const [models] = useState([
        "students",
        "applications",
        "sources",
        "channels",
        "institutes",
        "courses",
    ]);
    // Create the suggestion container only once.
    const [suggestionContainer, setSuggestionContainer] = useState<any>(null);
    const [root, setRoot] = useState<Root | null>(null);
    const [y, setY] = useState<number>(0);
    const editorRef = useRef<any>(null);

    const handleScroll = (): void => {
        const editorContainer = document.getElementById(`${name}editorcontainer`) as HTMLElement | null;
        const toolbar = document.querySelector(".ck-toolbar") as HTMLElement | null;
        if (!editorContainer || !toolbar) return;
        const editorTop = editorContainer?.getBoundingClientRect()?.top;
        const editorBottom = editorContainer?.getBoundingClientRect()?.bottom;
        if (editorTop <= 50 && editorBottom >= 150) {
            toolbar.classList.add("fixed-ck-toolbar");
            const parent = toolbar?.parentElement;
            console.log("parent",parent)
            if (toolbar && parent) {
                const setWidth = () => {
                    const parentWidth = parent.getBoundingClientRect().width;
                    toolbar.style.width = `${parentWidth}px`;                    
                };        
                setWidth();
                window.addEventListener('resize', setWidth);
            }
        } else {
            toolbar?.classList.remove("fixed-ck-toolbar");
        }
        setY(window?.scrollY);
    };
  
    useEffect(() => {
        window?.addEventListener("scroll", handleScroll);
        return () => {
            window?.removeEventListener("scroll", handleScroll);
        };
    }, []);  // Runs only once to prevent multiple event bindings

    const onhandleChange = (data: any) => {
        onChange(data);
        validateInput(data);
    };

    const editorConfiguration: any = {
        allowedContent: 'p[style]; span[style]; div[style];',
        extraAllowedContent: '*[style]; p[style]; span[style]; div[style];',
        ckfinder: {
            uploadUrl: `${DTS_API_STUDENT}/documents/forms/ck`,
            method: "POST",
            options: {
                headers: {
                    Authorization: "Bearer YOUR_TOKEN"
                }
            }
        },
        removePlugins: ['Title'],
        toolbar: {
            shouldNotGroupWhenFull: false,
            items: [
                'undo', 'redo', '|',
                'heading', '|',
                'bold', 'italic', 'underline', 'strikethrough', '|',
                'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
                'alignment', 'indent', 'outdent', '|',
                'link', 'bulletedList', 'numberedList', '|',
                'insertTable', 'blockQuote', 'mediaEmbed', 'imageUpload', 'imageInsert', '|',
                'horizontalLine', 'htmlEmbed', 'code', 'codeBlock', '|',
                'subscript', 'superscript', 'todoList', '|',
                'findAndReplace', 'selectAll', 'showBlocks', 'sourceEditing', '|',
                'removeFormat', 'specialCharacters', 'pageBreak', 'restrictedEditingException'
            ]
        },
        restrictedEditing: {
            isEnabled: true,  // Enable restricted editing mode
            allowedCommands: ['bold', 'italic', 'link']
        },
        language: 'en',
        image: {
            resizeUnit: "px",
            resizeOptions: [
                { name: "resizeImage:original", value: null, label: "Original" },
                { name: "resizeImage:50", value: "50", label: "50%" },
                { name: "resizeImage:75", value: "75", label: "75%" },
                { name: "resizeImage:100", value: "100", label: "100%" },
                { name: "resizeImage:200", value: "200", label: "200%" },
                { name: "resizeImage:custom", value: "200", label: "Custom (200px)" }
            ],
            styles: ["full", "alignLeft", "alignRight"],
            toolbar: [
                "resizeImage", "imageTextAlternative", "toggleImageCaption",
                "imageStyle:inline", "imageStyle:block", "imageStyle:side", "linkImage"
            ]
        },
        table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties', 'tableProperties']
        },
        heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
            ]
        },
        htmlEmbed: { showPreviews: true },
        htmlSupport: {
            allow: [
                { name: /.*/, attributes: true, classes: true, tag: true },
                { name: 'p', attributes: { contenteditable: 'false' } }
            ]
        },
        fontFamily: {
            options: [
                'default', 'Arial, Helvetica, sans-serif', 'Calibri, sans-serif', 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif',
                'Candara, sans-serif', 'Century Gothic, sans-serif', 'Comic Sans MS, cursive, sans-serif',
                'Courier New, Courier, monospace', 'Garamond, serif', 'Georgia, serif', 'Helvetica, sans-serif',
                'Impact, Charcoal, sans-serif', 'Lucida Console, Monaco, monospace', 'Lucida Sans Unicode, Lucida Grande, sans-serif',
                'Palatino Linotype, Book Antiqua, Palatino, serif', 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
                'Tahoma, Geneva, sans-serif', 'Times New Roman, Times, serif', 'Trebuchet MS, sans-serif',
                'Verdana, Geneva, sans-serif'
            ],
            supportAllValues: true
        },
        fontSize: {
            supportAllValues: true,
            options: [10, 12, 'default', 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36]
        }
    };

    // Create suggestion container on mount.
    useEffect(() => {
        const container = document.createElement("div");
        const rootInstance: any = createRoot(container);
        setRoot(rootInstance);
        container.className = "suggestion-container";
        container.style.cssText = `
        position: absolute;
        background-color: #f9f9f9;
        border: 1px solid #ccc;
        padding: 10px;
        z-index: 2000;
        width: 200px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        min-height: 200px;
        max-height: 300px;
        overflow-y: auto;
        display: none;
        `;
        setSuggestionContainer(container);
        document.body.appendChild(container);
    }, []);

    useEffect(() => {
        if (onChange) onChange(resetvalue);
        setResetValue(resetvalue);
    }, [resetvalue]);

    const validateInput = (data: any) => {
        if (rest && rest.type === "email") {
            if (
                data === "" ||
                !/^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data)
            ) {
                const error: any = `Please enter valid ${label}`;
                setErrormsg(error);
                if (typeof setError === "function") {
                    setError({ ...formerror, [name]: error });
                }
                setCheckdisabled(true);
                return false;
            }
        }
        if (rest && rest.validate && rest.validate.required) {
            if (data === "") {
                const error: any = `Please enter ${label}`;
                setErrormsg(error);
                if (typeof setError === "function") {
                    setError({ ...formerror, [name]: error });
                }
                setCheckdisabled(true);
                return false;
            }
        }
        if (rest && rest.validate && rest.validate.minLength) {
            if (data.length < rest.validate.minLength) {
                const error: any = `Length of ${label} must be at least ${rest.validate.minLength}`;
                setErrormsg(error);
                if (typeof setError === "function") {
                    setError({ ...formerror, [name]: error });
                }
                setCheckdisabled(true);
                return false;
            }
        }
        if (rest && rest.validate && rest.validate.maxLength) {
            if (data.length > rest.validate.maxLength) {
                const error: any = `Length of ${label} must be not more than ${rest.validate.maxLength}`;
                setErrormsg(error);
                if (typeof setError === "function") {
                    setError({ ...formerror, [name]: error });
                }
                setCheckdisabled(true);
                return false;
            }
        }
        setErrormsg(undefined);
        if (typeof setError === "function") {
            const { [name]: _, ...newObject } = formerror;
            setError(newObject);
        }
        setCheckdisabled(false);
        return true;
    };

    useEffect(() => {
        if (rest && rest.validate) {
            const inputdiv: any = document.getElementById(`${name}feedback`);
            if (errormsg) {
                if (typeof setdisabled === "function") {
                    setdisabled(true);
                }
                if (inputdiv) inputdiv.classList.add("d-block");
            } else {
                if (typeof setdisabled === "function") {
                    setdisabled(false);
                }
                if (inputdiv) inputdiv.classList.remove("d-block");
            }
        }
    }, [value, errormsg, name, rest, formerror, setError, setdisabled]);

    const onCheckClick = (e: any) => {
        e.preventDefault();
        setHover(false);
        onSaveClick();
    };

    useEffect(() => {
        const filters = { pageSize: 100 };
        models.forEach((model) => {
            dispatch(propertiesListGetRequest(model, filters));
        });
    }, [dispatch, models]);

    useEffect(() => {
        if (rest && rest.validate && rest.validate.required) {
            const feedback: any = document.getElementById(`starred${name}`);
            if (feedback) feedback.classList.remove("d-none");
        }
    }, [rest, name]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (inputRef && inputRef.current && !inputRef.current.contains(event.target)) {
                setHover(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Build the full suggestions list from optionList.
    useEffect(() => {
        let suggestion: any = [];
        const usersoptions = [{ label: "Name", value: "name" }];
        const users = { label: "users", options: usersoptions };
        const tenantsoptions = [
            { label: "Company name", value: "tenant_companyName" },
            { label: "logo", value: "tenant_logo" },
        ];
        const tenants = { label: "tenant", options: tenantsoptions };

        models.forEach((model) => {
            const options: any =
                optionList?.[model]?.length &&
                optionList[model].map((item: any) => {
                    const valueJson =
                        item.valuesJson && isJson(item.valuesJson)
                            ? JSON.parse(item.valuesJson)
                            : {};
                    return { label: valueJson.label, value: valueJson.key, valueJson };
                });
            const newOption = { label: model, options };
            suggestion.push(newOption);
        });
        suggestion.push(users);
        suggestion.push(tenants);
        setAllSuggestions(suggestion);
        setFilteredSuggestions(suggestion);
    }, [optionList, models]);

    // Updated optionClick:
    // Instead of iterating over multiple child nodes, we simply work with the parent's current offset.
    // If the parent's text (from its current offset) ends with "{{", we remove that trigger and insert the suggestion.
    const optionClick = async (option: any, modelname: any) => {
        const model = editor?.model;
        if (model) {
            model.change((writer: any) => {
                const selection = model.document.selection;
                const currentPosition = selection.getFirstPosition();
                const parent = currentPosition.parent;
                const offset = currentPosition.offset;
                if (offset >= 2) {
                    // Create a range covering the last two characters of the parent's text.
                    const triggerRange = writer.createRange(
                        writer.createPositionAt(parent, offset - 2),
                        currentPosition
                    );
                    // Optionally, verify that the removed text is exactly "{{".
                    // Remove the trigger from the model.
                    writer.remove(triggerRange);
                    // Insert the suggestion text at the new position.
                    const insertPos = writer.createPositionAt(parent, offset - 2);
                    const insertedText = `{{${modelname}.${option.value}}}`;
                    writer.insertText(insertedText, insertPos);
                    // Set the selection after the inserted text.
                    writer.setSelection(writer.createPositionAt(parent, offset - 2 + insertedText.length), "after");
                } else {
                    writer.insertText(`{{${modelname}.${option.value}}}`, currentPosition);
                }
            });
            // After inserting, close the suggestion box and clear the search.
            close();
        } else {
            console.error("Editor model is not initialized");
        }
    };

    // Build the suggestion list JSX based on filtered suggestions.
    useEffect(() => {
        const suggestionListJSX = filteredSuggestions.map((suggestionGroup: any) => (
            <optgroup key={suggestionGroup.label} label={startCase(suggestionGroup.label)}>
                {suggestionGroup?.options?.length &&
                    suggestionGroup.options.map((suggestion: any) => (
                        <option
                            className="p-1 cursor-pointer ms-3"
                            key={suggestion.value}
                            value={suggestion.value}
                            onClick={(e: any) => optionClick(suggestion, suggestionGroup.label)}
                        >
                            {suggestion.label}
                        </option>
                    ))}
            </optgroup>
        ));
        setSuggestionList(suggestionListJSX);
    }, [filteredSuggestions]);

    // Filter suggestions based on the search input.
    const onChangeValue = (term: string) => {
        setSearchTerm(term);
        if (term !== "") {
            const filtered = allSuggestions.map((suggestionGroup: any) => ({
                ...suggestionGroup,
                options: suggestionGroup.options.filter((option: any) =>
                    option.label.toLowerCase().includes(term.toLowerCase())
                ),
            }));
            setFilteredSuggestions(filtered);
        } else {
            setFilteredSuggestions(allSuggestions);
        }
    };

    // Build the suggestion container component (including the search input and suggestion list).
    useEffect(() => {
        const comp = (
            <div className="position-relative">
                <Button
                    size="sm"
                    className="bg-danger position-absolute top-0 end-0"
                    style={{ zIndex: 99 }}
                    onClick={() => close()}
                >
                    <i className="ri-close-line search-icon"></i>
                </Button>
                <div className="search-box" style={{ paddingTop: 35 }}>
                    <Input
                        type="text"
                        size={14}
                        className="search py-1"
                        placeholder="Search for..."
                        value={searchTerm}
                        onChange={(e) => onChangeValue(e.target.value)}
                    />
                    <i className="ri-search-line search-icon" style={{ top: 18 }}></i>
                </div>
                {suggestionList}
            </div>
        );
        setSuggestionListComponent(comp);
    }, [suggestionList, searchTerm]);

    // Re-render the suggestion container component when it updates and is visible.
    useEffect(() => {
        if (suggestionsVisible && suggestionContainer && root && suggestionListComponent) {
            root.render(suggestionListComponent);
        }
    }, [suggestionListComponent, suggestionsVisible, suggestionContainer, root]);

    // When closing the suggestion box, hide it and clear the search.
    const close = () => {
        setSuggestionsVisible(false);
        if (suggestionContainer) {
            suggestionContainer.style.display = "none";
        }
        setSearchTerm("");
        setFilteredSuggestions(allSuggestions);
    };

    // Editor change listener:
    // Open the suggestion box only if the node immediately before the caret is a text node ending with "{{".
    useEffect(() => {
        if (editor && editor.model) {
            const handleChange = () => {
                const selection = editor.model.document.selection;
                const firstPosition = selection.getFirstPosition();
                if (firstPosition) {
                    const nodeBefore = firstPosition.nodeBefore;
                    if (nodeBefore && nodeBefore.is("text")) {
                        const data = nodeBefore.data;
                        if (data.endsWith("{{")) {
                            setSuggestionsVisible(true);
                            if (suggestionContainer) {
                                suggestionContainer.style.display = "block";
                                const sel = window.getSelection();
                                if (sel && sel.rangeCount > 0) {
                                    const range = sel.getRangeAt(0);
                                    const rect = range.getBoundingClientRect();
                                    suggestionContainer.style.top = `${rect.top + rect.height + window.scrollY}px`;
                                    suggestionContainer.style.left = `${rect.left + window.scrollX}px`;
                                }
                                root?.render(suggestionListComponent);
                            }
                        } else {
                            if (suggestionContainer) {
                                suggestionContainer.style.display = "none";
                            }
                        }
                    } else {
                        if (suggestionContainer) {
                            suggestionContainer.style.display = "none";
                        }
                    }
                }
            };

            editor.model.document.on("change:data", handleChange);
            return () => {
                editor.model.document.off("change:data", handleChange);
            };
        }
    }, [editor, allSuggestions, suggestionListComponent, suggestionContainer, root]);

    return (
        <div className="pb-3">
            {actionButtons ? (
                <div>
                    {hover ? (
                        <div>
                            {label && (
                                <Label htmlFor={name} className="form-label fw-bold fs-14">
                                    {label}
                                    <span id={`starred${name}`} className="d-none fw-bold text-danger">
                                        {" "}
                                        *
                                    </span>
                                </Label>
                            )}
                            <div className="hstack border rounded-3 align-items-center w-100">
                                <div className="w-100 search-box editor-container" id={`${name}editorcontainer`}>
                                    <CKEditor
                                        editor={Editor}
                                        config={editorConfiguration}
                                        data={value || ""}
                                        id={"editorui"}
                                        onFocus={() => stopListening && stopListening()}
                                        onChange={(event: any, editorInstance: any) => {
                                            setEditor(editorInstance);
                                            const data = editorInstance.getData();
                                            const parser = new DOMParser();
                                            const doc = parser.parseFromString(data, "text/html");

                                            doc.querySelectorAll("img").forEach((img) => {
                                                // Remove unwanted styles
                                                img.removeAttribute("width");  // Remove width attribute
                                                img.removeAttribute("height"); // Remove height attribute
                                                img.style.removeProperty("aspect-ratio");
                                            });

                                            onhandleChange(doc.body.innerHTML);
                                        }}
                                        disabled={disabled}
                                        onReady={(editorInstance: any) => {
                                            editorRef.current = editorInstance;
                                            setEditor(editorInstance);
                                        }}
                                    />
                                    <ButtonGroup
                                        className="mt-4 mt-sm-0"
                                        style={{ right: 1, left: "auto", top: 37, position: "absolute" }}
                                    >
                                        <Button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setHover(false);
                                                onChange(resetValue);
                                            }}
                                            color="primary"
                                            className="btn-icon btn-soft-primary"
                                        >
                                            <i className="ri-close-fill" />
                                        </Button>
                                        <Button
                                            onClick={(e) => onCheckClick(e)}
                                            disabled={checkdisabled}
                                            color="primary"
                                            className="btn-icon"
                                        >
                                            <i className="ri-check-fill" />
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                            <FormFeedback id={`${name}feedback`}>{errormsg}</FormFeedback>
                        </div>
                    ) : (
                        <div>
                            {label && (
                                <Label htmlFor={name} className="form-label fw-bold fs-14 mb-0">
                                    {label}
                                    <span id={`starred${name}`} className="d-none fw-bold text-danger">
                                        {" "}
                                        *
                                    </span>
                                </Label>
                            )}
                            <div className="hstack justify-content-between topbar-user">
                                <div
                                    className="fs-14 fw-medium form-control border-0 topbar-user ps-2"
                                    style={{ height: "auto" }}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: value }} />
                                </div>
                                {!readOnly && (
                                    <Button disabled={loading} onClick={() => setHover(true)} color="primary" className="btn-icon btn-soft-primary" id={`btn${name}`}>
                                        {loading ? <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> : <i className="ri-edit-2-fill" />}
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    {label && (
                        <Label
                            htmlFor={name}
                            className="form-label fw-bold fs-14 text-nowrap text-truncate text-nowrap-ellipsis"
                        >
                            {label}
                            <span id={`starred${name}`} className="d-none fw-bold text-danger">
                                {" "}
                                *
                            </span>
                        </Label>
                    )}
                    <div className="editor-container" id={`${name}editorcontainer`}>
                        <CKEditor
                            editor={Editor}
                            config={editorConfiguration}
                            data={value || ""}
                            id={"editorui"}
                            onFocus={() => stopListening && stopListening()}
                            onChange={(event: any, editorInstance: any) => {
                                setEditor(editorInstance);
                                const data = editorInstance.getData();
                                const parser = new DOMParser();
                                const doc = parser.parseFromString(data, "text/html");

                                doc.querySelectorAll("img").forEach((img) => {
                                    // Remove unwanted styles
                                    img.removeAttribute("width");  // Remove width attribute
                                    img.removeAttribute("height"); // Remove height attribute
                                    img.style.removeProperty("aspect-ratio");
                                });

                                onhandleChange(doc.body.innerHTML);
                            }}
                            disabled={disabled}
                            onReady={(editorInstance: any) => {
                                editorRef.current = editorInstance;
                                setEditor(editorInstance);
                            }}
                        />
                    </div>
                    <FormFeedback id={`${name}feedback`}>{errormsg}</FormFeedback>
                </div>
            )}
        </div>
    );
};

export default DtsCKEditor;
