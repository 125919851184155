import React, { useEffect, useState } from 'react';
import { Container, Row, Button, ModalFooter, Modal, ModalBody, ModalHeader, Label } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from 'store';
import { Action, createSelector, ThunkDispatch } from "@reduxjs/toolkit";
import Form from './Form';
import Basic from './View/Basic';
import Evaluation from './View/Evaluation';
import Feedback from './View/Feedback';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { isJson } from "utils";
import { optionsListGetRequest } from "store/auth/action";
import { cgrSendRequest } from "store/cgr/action";

const animatedComponents = makeAnimated();

const WIZARDSTEPS = [{
    "id": '166f5e6a-b19a-4f3a-a692-dd0fd9557b9e',
    "previous": "Cancel",
    "next": "Next",
}, {
    "id": [
      '8f98658d-e15b-4665-9efa-3b82b825b408', 
      '5915b364-b781-4b00-ae02-28876d43cbe2', 
      'a6505d0e-a479-4763-9030-75c5abfd7cdb'
    ],
    "previous": "Back",
    "next": "Next",
}, {
    "id": 'f3766056-29ae-40c1-9eb5-acc89fec3964',
    "previous": "Back",
    "next": "Submit",
}];

interface PropertyProps {
    key: string;
    label: string | Date;
    type?: string;
    value: string;
    valueJson?: any;
}

const EvaluationContainer = (props: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const details = useSelector((state: ApplicationState) => state.student.details);
    const optionsObj = useSelector((state: ApplicationState) => state.auth.optionData);
    const optionsLoading = useSelector((state: ApplicationState) => state.auth.optionsLoading);
    const cgrLoading = useSelector((state: ApplicationState) => state.cgr.loading);
    const [evalute, setEvaluate] = useState(false);
    const [isSend, setIsSend] = useState(false);
    const [options, setOptions] = useState<any>([]);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const [emailTemplate, setEmailTemplate] = useState<PropertyProps | null>(null);
    const [reportTemplate, setReportTemplate] = useState<PropertyProps | null>(null);
    const [formIndex, setFormIndex] = useState<number | null>(null);
    // A key to force the Form component to remount (so it always starts at step 0)
    const [formResetKey, setFormResetKey] = useState<number>(Date.now());

    const selectDashboardData = createSelector(
        (state: any) => state.Layout,
        (state) => ({
            layoutModeType: state.layoutModeType
        })
    );
    const { layoutModeType } = useSelector(selectDashboardData);

    useEffect(() => {
        if (optionsObj && optionsObj?.['templates']) {
            const newOptions = optionsObj?.['templates'].map((option: any) => {
                const optionsJson = option.valuesJson && isJson(option.valuesJson)
                  ? JSON.parse(option.valuesJson)
                  : option.valuesJson;
                return { label: optionsJson?.['name'], value: option.id, valuesJson: optionsJson, in_forms: true };
            });
            setOptions(newOptions);
        }
    }, [optionsObj?.['templates']]);

    useEffect(() => {
        if (details?.valuesJson) {
            const studentClass = (details?.valuesJson as any)?.student_class;
            if(studentClass) {
                if (studentClass.startsWith("yr_11_")) {
                    setFormIndex(0);
                } else if (studentClass.startsWith("yr_12_") || studentClass.startsWith("btech_1")) {
                    setFormIndex(1);
                } else if (studentClass.startsWith("yr_13_") || studentClass.startsWith("btech_2")) {
                    setFormIndex(2);
                }
            }
        }
    }, [details]);

    const onFocus = () => {
        setOptions([]);
        const filter = {
            "filters": [
                { "quick": [], "advance": [], "search": null }
            ],
            "sorts": [
                { "field": "name", "order": "asc" }
            ]
        };
        let filterObject = null;
        dispatch(optionsListGetRequest('templates', filter, 0, 20, filterObject)); 
    };

    const customStyles = {
        input: (provided: any) => ({
            ...provided,
            color: layoutModeType === 'light' ? 'black' : 'white',
        }),
        control: (provided: any) => ({
            ...provided,
            backgroundColor: 'transparent'
        }),
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: layoutModeType === 'light' ? 'white' : '#495057',
            color: layoutModeType === 'light' ? 'black' : 'white',
        }),
        option: (provided: any) => ({
            ...provided,
            backgroundColor: layoutModeType === 'light' ? 'white' : '#495057',
            color: layoutModeType === 'light' ? 'black' : 'white',
            '&:hover': {
                backgroundColor: layoutModeType === 'light' ? 'white' : '#495057',
            },
        }),
    };

    const onInputChange = (value: any) => {        
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            const filter = {
                "filters": [
                    { "search": { "term": value, "field": "all" } }
                ],
                "sorts": [
                    { "field": "name", "order": "asc" }
                ]
            };
            let filterObject = null;
            dispatch(optionsListGetRequest('templates', filter, 0, 20, filterObject)); 	
        }, 2000);
        setTypingTimeout(newTimeout);
    };

    const onSend = () => {
        const data = {
            "studentId": details?.id,
            "emailTemplateId": emailTemplate?.value,
            "reportTemplateId": reportTemplate?.value
        };
        const handleSuccess = (body: any) => {
            setIsSend(false);
        };
        dispatch(cgrSendRequest(data, handleSuccess, () => { }));
    };

    return (
        <React.Fragment>
            {(details?.valuesJson as any)?.student_class ?
                <Container fluid>
                    <div className="d-flex flex-column gap-3 justify-content-center pt-3 pb-3">
                        <div className='d-flex justify-content-end gap-2'>
                            <Button
                                color="primary"
                                className="btn-label"
                                onClick={() => {
                                    setFormResetKey(Date.now());
                                    setEvaluate(true);
                                }}
                            >
                                <i className="ri-server-line label-icon align-middle fs-16 me-2"></i>
                                {props.t("student.details.activities.update_evaluation")}
                            </Button>
                            <Button
                                color="success"
                                className="btn-label"
                                onClick={() => setIsSend(true)}
                            >
                                <i className="ri-mail-send-fill label-icon align-middle fs-16 me-2"></i>
                                {props.t("student.details.activities.send_report")}
                            </Button>
                        </div>
                        <Basic id={WIZARDSTEPS[0].id} />
                        {formIndex !== null && formIndex >= 0 ? (
                            <Evaluation key={formIndex} id={WIZARDSTEPS[1].id[formIndex]} />
                        ) : null}
                        <Feedback id={WIZARDSTEPS[2].id} />
                    </div>
                </Container>
                : 
                <Container fluid>
                    <Row>
                        <div className='d-flex justify-content-center'>
                            <Button
                                color="primary"
                                className="btn-label"
                                onClick={() => {
                                    setFormResetKey(Date.now());
                                    setEvaluate(true);
                                }}
                            >
                                <i className="ri-server-line label-icon align-middle fs-16 me-2"></i>
                                {props.t("student.details.activities.begin_evaluation")}
                            </Button>
                        </div>
                    </Row>
                </Container>}
            {evalute ? (
                <div className="position-fixed top-0 bottom-0 start-0 w-100 h-100 bg-light overflow-auto" style={{ zIndex: 1003 }}>
                    <div className="d-flex flex-column justify-content-between align-items-center gap-3">
                        <div className="d-flex justify-content-between bg-primary p-2 align-items-center w-100">
                            <div>
                                <Button
                                    color="primary"
                                    className="btn-label"
                                    onClick={() => setEvaluate(false)}
                                >
                                    <i className="ri-arrow-left-line label-icon align-middle fs-16"></i>
                                    {props.t("student.details.activities.evaluation_close")}
                                </Button>
                            </div>
                        </div>
                        {/* By passing a unique key (formResetKey) we force the Form to remount fresh */}
                        <Form key={formResetKey} setEvaluate={setEvaluate} wizardSteps={WIZARDSTEPS} />
                    </div>
                </div>
            ) : null}
            <Modal id="myModal"
                isOpen={isSend}
                toggle={() => setIsSend(!isSend)}
                centered
            >
                <ModalHeader>
                    <h5 className="modal-title" id="myModalLabel">
                        Send Report to Student
                    </h5>
                </ModalHeader>
                <ModalBody>
                    <div className='d-flex flex-column gap-3'>
                        <div className='d-flex flex-column justify-content-between gap-1'>
                            <Label className="form-label">Select Email Template</Label>
                            <Select
                                onFocus={onFocus}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                isClearable
                                isSearchable
                                styles={customStyles}
                                onInputChange={(inputValue: any) => onInputChange(inputValue)}
                                name="values"
                                onChange={setEmailTemplate}
                                value={emailTemplate}
                                isLoading={optionsLoading}                     
                                options={optionsLoading ? [{ label: 'Loading...', value: null }] : options || []}
                            />
                        </div>
                        <div className='d-flex flex-column justify-content-between gap-1'>
                            <Label className="form-label">Select Report Template</Label>
                            <Select
                                onFocus={onFocus}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                isClearable
                                isSearchable
                                styles={customStyles}
                                onInputChange={(inputValue: any) => onInputChange(inputValue)}
                                name="values"
                                onChange={setReportTemplate}
                                value={reportTemplate}
                                isLoading={optionsLoading}                     
                                options={optionsLoading ? [{ label: 'Loading...', value: null }] : options || []}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="light"
                        onClick={() => setIsSend(false)}
                        disabled={cgrLoading}
                    >
                        Close
                    </Button>
                    <Button
                        color="primary"
                        onClick={onSend}
                        disabled={cgrLoading}
                    >
                        Send
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default withTranslation()(EvaluationContainer);
