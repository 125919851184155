import React from 'react';
import { Container } from 'reactstrap';
import AuthorizationForm from './AuthorizationForm';

const Recommendation = () => {
    document.title = "Authorization Letter | Zilter";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <AuthorizationForm />
                </Container>
            </div>
        </React.Fragment>
    );
};
export default Recommendation;


