import React, { useState, ChangeEvent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Spinner } from 'reactstrap';
import ParticlesAuth from './ParticlesAuth';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from "react-redux";
import { setResponse, setTokenRequest, signinRequest, userGetRequest } from "store/auth/action";
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import config from "../../config";
import logo from "../../assets/images/dtsimg/svg/zilter_logo_white.svg";
import { ApplicationState } from 'store';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { useGoogleLogin } from '@react-oauth/google';
import MicrosoftLogin from 'react-microsoft-login';
import microsoft from '../../assets/images/microsoftSignin.svg';
import googleicon from '../../assets/images/google-icon-logo-svgrepo-com.svg';
import * as msal from '@azure/msal-browser';
import { isJson } from 'utils';
const { api } = config;
const url = api.url;

declare global {
    interface Window {
        google: any; // You can use 'any' or a more specific type if you prefer
    }
}

interface SignInState {
    email: string;
    password: string;
}

const Signin: React.FC<any> = (props) => {
    const navigate = useNavigate();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    document.title = "SignIn | Zilter";
    const auth = useSelector((state: ApplicationState) => state.auth);
    const loading = false;
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [remember, setRememebr] = useState<boolean>(false);
    const [inputs, setInputs] = useState<SignInState>({
        email: '',
        password: ''
    });

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value });
    };

    const handleGoogleSignup = (code: any) => {
        const data =
        {
            "attributes": [
                {
                    "key": "credential",
                    "value": code
                }
            ]
        }
        const handleSuccess = async (body: any): Promise<void> => {
            await dispatch(setResponse(body));
            await dispatch(userGetRequest())
            const userData: any = jwtDecode(body.token);
            if (parseInt(userData.current_stage) < 7) navigate('/signup');
            else navigate('/students');
        };
        const handleError = (body: any): void => {
            const message = () => toast(`${body?.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            message();
        };
        dispatch(signinRequest(data, handleSuccess, handleError));
    };

    const onSubmit = () => {
        // e.preventDefault();
        if (inputs.email && inputs.password) {
            const data = {
                "attributes": [
                    {
                        "key": "email",
                        "value": inputs.email
                    },
                    {
                        "key": "password",
                        "value": inputs.password
                    }
                ]
            }
            const handleSuccess = async (body: any): Promise<void> => {
                // localStorage.setItem('multi_association_token', body.token);
                if (remember) {
                    // If remember me is checked, set cookies for username and password
                    Cookies.set('username', inputs.email, { expires: 7 }); // Expires in 7 days
                    Cookies.set('password', inputs.password, { expires: 7 });
                } else {
                    // If remember me is not checked, remove any stored cookies
                    Cookies.remove('username');
                    Cookies.remove('password');
                }
                if (Array.isArray(body)) {
                    localStorage.setItem("accountData", JSON.stringify(body));
                    navigate('/my-accounts');
                } else {
                    await dispatch(setTokenRequest(body.token))
                    await dispatch(setResponse(body));
                    await dispatch(userGetRequest())
                    const userData: any = jwtDecode(body.token);
                    if ((parseInt(userData.current_stage) < 8) && (userData.subscription === null || userData.subscription === false)) {
                        navigate('/signup');
                    }
                    else if (userData.subscription && isJson(userData.subscription) && JSON.parse(userData.subscription).status === 'paid') {
                        navigate('/students');
                    }
                    else if (userData.subscription === null || userData.subscription === false || userData.subscription === "") {
                        navigate('/pricing');
                    }
                    else {
                        navigate('/students');
                    }
                }

            }
            const handleError = (): void => {
                const message = () => toast(`Invalid Email or Password...!`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
                message();
            }
            dispatch(signinRequest(data, handleSuccess, handleError));
        }
    };

    const getRedirectUri = () => {
        const hostname = window.location.hostname;

        switch (hostname) {
            case 'app.zilter.io':
                return 'https://app.zilter.io';
            case 'qa.zilter.io':
                return 'https://qa.zilter.io';
            default:
                return 'http://localhost:3000'; // Fallback to current URL if none match
        }
    };

    const msalConfig: any = {
        auth: {
            clientId: "9dce3457-ea35-4f69-9c96-aecbda169c12",
            authority: "https://login.microsoftonline.com/common",
            redirectUri: `${url}`
        }
    };

    const msalInstance: any = new msal.PublicClientApplication(msalConfig);

    async function initializeMsal() {
        await msalInstance.initialize(); // Ensure MSAL is initialized
    }
    
    async function signUpMicrosoft() {
        // try {
        await initializeMsal();
        const loginRequest = {
            scopes: [
                "User.Read",            // Basic profile information
                "Calendars.ReadWrite",  // Read and write access to the user's calendar
                "Mail.ReadWrite"        // Read and write access to the user's email
            ],
            prompt: 'select_account',
        };
        const response = await msalInstance.loginPopup(loginRequest);
        if (response.idToken) {
            const accessToken = response.idToken;
            handleGoogleSignup(accessToken)
        }
    }

    useEffect(() => {
        let cookieemail = Cookies.get('username'); // Expires in 7 days
        let cookiepassword = Cookies.get('password');
        const token = localStorage.getItem('dts_token');
        if (token) {
            const checkToken = async () => {
                if (token) {
                    await dispatch(userGetRequest());
                    if (auth && auth.userProfile && auth.userProfile.currentStage && auth.userProfile.currentStage > 6) {
                        navigate("/students");
                    }
                    else if (auth && auth.userProfile && auth.userProfile.currentStage && auth.userProfile.currentStage < 6) {
                        navigate("/signup");
                    }
                    else {
                        navigate("/signin");
                    }
                };

            }
            checkToken();
        }
        else if (cookieemail && cookiepassword) {
            setRememebr(true)
            setInputs({ ...inputs, email: cookieemail, password: cookiepassword });
        }
    }, [])

    const MicrosoftSignIn: React.FC = () => {
        const MicrosoftSignInStyle: React.CSSProperties = {
            fontFamily: 'Nunito, sans-serif',
            fontSize: '14px',
            fontWeight: 500,
            color: '#2a5f8d',
            backgroundColor: '#fff',
            border: '1px solid #8c8c8c',
            padding: '5px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
        };

        return (
            <div className='p-1 mt-1' style={MicrosoftSignInStyle}>
                <img src={microsoft} alt="Microsoft Sign In" style={{ width: '20px', height: '20px', marginRight: "5px" }} />
                Sign in with Microsoft
            </div>
        );
    };

    const CustomGoogleSignIn = () => {
        // Google login logic
        const login = useGoogleLogin({
            onSuccess: codeResponse => {
                handleGoogleSignup(codeResponse.code);
            },
            onError: (error) => console.log('Login Failed:', error),
            flow: 'auth-code',
            scope: 'https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.readonly'
        });

        return (
            <div className='p-1 mt-1'>
                <button
                    style={{
                        fontFamily: 'Nunito, sans-serif',
                        fontSize: '14px',
                        fontWeight: 500,
                        color: '#2a5f8d',
                        backgroundColor: '#fff',
                        border: '1px solid #8c8c8c',
                        padding: '5px',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={() => login()}
                >
                    <img src={googleicon} alt="Microsoft Sign In" style={{ width: '20px', height: '20px', marginRight: "5px" }} />
                    Sign in with Google
                </button>
            </div>
        );
    };

    return (
        <React.Fragment>
            <ParticlesAuth>
                <ToastContainer />
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/#" className="d-inline-block auth-logo">
                                            <img src={logo} alt="" height="50" />
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">{props.t("signin.welcome_back")}</h5>
                                            <p className="text-muted">{props.t("signin.top_statement")}</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <div>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="email">{props.t("signin.email")}</Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control w-100"
                                                        id="email"
                                                        placeholder={props.t("signup.enter_email")}
                                                        onChange={handleInputChange}
                                                        value={inputs.email}
                                                        name="email"
                                                    />
                                                    {inputs.email !== "" && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputs.email) ?
                                                        <div className="text-danger fs-14 fw-medium">{props.t("signup.please_enter_valid_email_address")}</div> : null}
                                                </div>
                                                <div className="mb-3">
                                                    <div className="float-end">
                                                        <Link to="/password/forgot" className="text-muted">{props.t("signin.forgot_password")}</Link>
                                                    </div>
                                                    <Label className="form-label" htmlFor="password-input">{props.t("signin.password")}</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control w-100"
                                                            id="password-input"
                                                            placeholder={props.t("signup.enter_password")}
                                                            onChange={handleInputChange}
                                                            value={inputs.password}
                                                            name="password"
                                                        />
                                                        {passwordShow ? <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><FeatherIcon icon="eye" className="m-1 icon-xs icon-dual-info" /></button> :
                                                            <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><FeatherIcon icon="eye-off" className="m-1 icon-xs icon-dual-info" /></button>}
                                                    </div>
                                                </div>
                                                <div className="form-check">
                                                    <Input className="form-check-input" type="checkbox" value="" checked={remember} id="auth-remember-check" onChange={(e: any) => setRememebr(e.target.checked)} />
                                                    <Label className="form-check-label" htmlFor="auth-remember-check">{props.t("signin.remember_me")}</Label>
                                                </div>
                                                <div className="mt-4">
                                                    <Button color="primary" className="btn btn-primary w-100" onClick={() => onSubmit()} disabled={!inputs.email || !inputs.password}>{loading ? <span className="d-flex align-items-center">
                                                        <span className="flex-grow-1 me-2">
                                                            Loading...
                                                        </span>
                                                        <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                                                    </span> : props.t("signin.sign_in")}</Button>
                                                </div>
                                                <div className="mt-4 text-center">
                                                    <div className="signin-other-title">
                                                        <h5 className="fs-13 mb-4 title">{props.t("signin.sign_in_with")}</h5>
                                                    </div>
                                                    <div className='align-items-center d-flex gap-2 justify-content-center'>
                                                        <div>
                                                            <CustomGoogleSignIn
                                                            />
                                                        </div>
                                                        <div>
                                                            <MicrosoftLogin
                                                                className="border border-0 cursor-pointer"
                                                                clientId={'9dce3457-ea35-4f69-9c96-aecbda169c12'}
                                                                authCallback={signUpMicrosoft}
                                                                redirectUri={getRedirectUri()}
                                                                debug={true}
                                                                graphScopes={['user.read', 'openid', 'profile', 'Calendars.ReadWrite', 'Mail.ReadWrite']}
                                                            >
                                                                <MicrosoftSignIn />
                                                            </MicrosoftLogin>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">{props.t("signin.dont_have_an_account")}<Link to="/signup" className="fw-bold text-primary text-decoration-underline">&nbsp;{props.t("signin.signup")}</Link> </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withTranslation()(Signin);