import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import { ApplicationState } from 'store';

interface FProps {
    setselectedFile: (event: any) => void;
    selectedFile: any;
    show: boolean;
    onUpload: () => void;
    onCloseClick: (event: any) => void;
    props: any
}
const Upload: React.FC<FProps> = ({ setselectedFile, selectedFile, onCloseClick, onUpload, show, props }: any) => {
    const { t } = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const downloadLoading = useSelector((state: ApplicationState) => state.document.loading)
    const [fileError, setFileError] = useState<any>('');
    const formatBytes = (bytes: any, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const validateExcelFile = (file: any) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            const workbook = XLSX.read(e.target.result, { type: 'array' });
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            const data = XLSX.utils.sheet_to_json(sheet);
            if (data.length === 0) {
                setFileError('Error: The uploaded Excel file does not contain any data rows.');
            } else {
                setFileError(null);
                // File is valid, proceed with the upload logic
                // ...
            }
        };
        reader.readAsArrayBuffer(file);
    };


    const handleAcceptedFiles = (files: any) => {
        files.map((file: any) => {
            validateExcelFile(file)
            let extension = file.name.split(".")
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
                uuid: `${uuidv4()}.${extension[extension.length - 1]}`
            })
        });
        setselectedFile(files);
    }

    const onClickFileDownload = () => {
        // const handleSuccess = async (body: any) =>{

        //     try {        
        //         // Create a Blob from the response data
        //         const fileBlob = await body.blob();

        //         // Create a temporary URL for the Blob
        //         const url = window.URL.createObjectURL(fileBlob);

        //         // Create a temporary <a> element to trigger the download
        //         const tempLink = document.createElement("a");
        //         tempLink.href = url;
        //         tempLink.setAttribute("download", importJson.model); // Set the desired filename for the downloaded file

        //         // Append the <a> element to the body and click it to trigger the download
        //         document.body.appendChild(tempLink);
        //         tempLink.click();

        //         // Clean up the temporary elements and URL
        //         document.body.removeChild(tempLink);
        //         window.URL.revokeObjectURL(url);
        //     } catch (error) {
        //         console.error("Error downloading file:", error);
        //     }
        // }
        // const handleFailure = () =>{

        // }
        // dispatch(downloadBulkUploadTemplate(importJson.model, handleSuccess, handleFailure))
    }


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleAcceptedFiles,
        maxSize: 10 * 1024 * 1024, // 10 MB
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel': ['.xls'],
        },
    });

    return (
        <Modal backdrop="static" isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={onCloseClick}>
                <span className='text-light'>Upload</span>
            </ModalHeader>
            <ModalBody className='text-center p-5'>
                <div>
                    <div className='mt-3'>
                        <div className="mt-3">
                            <div {...getRootProps()} className="dropzone dz-clickable cursor-pointer">
                                <div className="dz-message needsclick pt-4">
                                    <div className="mb-3">
                                        <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                    </div>
                                    <h4>
                                        {isDragActive ? 'Drop the file here ...' : 'Drop files here or click to upload.'}
                                    </h4>
                                </div>
                                <input {...getInputProps()} />
                            </div>
                        </div>

                    </div>
                    <div>
                        <div className='hstack justify-content-start px-2'>
                            {fileError ? <span className='text-danger'>Uploaded file contains no records</span> : null}
                        </div>
                        <div className='hstack justify-content-end px-2'>
                            {!downloadLoading ? <Link to={''} className='fs-16 fw-semibold' onClick={() => onClickFileDownload()}>Download sample File</Link> : <Link to={''} className='fs-16 fw-semibold' style={{ textDecoration: 'none' }}>Downloading...</Link>}
                        </div>
                    </div>
                    <div className="list-unstyled mb-0" id="file-previews">
                        {selectedFile.map((f: any, i: any) => {
                            return (
                                <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                >
                                    <div className="p-2">
                                        <Row className="me-1 text-nowrap text-truncate text-nowrap-ellipsis text-muted align-items-center">

                                            <Col>
                                                <Link
                                                    to="#"
                                                    className="text-decoration-none text-ellipsis text-muted font-weight-bold"
                                                >
                                                    File uploaded, and renamed {f.uuid}
                                                </Link>
                                                <p className="mb-0">
                                                    <strong>{f.formattedSize}</strong>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            );
                        })}
                    </div>
                </div>
                <div className="hstack gap-2 justify-content-center">
                    <Button className="btn btn-danger" onClick={() => onUpload()}>
                        {t("common.offcanvas.import_upload")}
                    </Button>
                    <Button color="danger" onClick={() => onCloseClick()} outline>
                        {t("common.offcanvas.import_cancel")}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default Upload
