import React, { useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ViewsTab from 'Components/Common/Views/ViewsTab';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { Action } from 'redux';
import SubAgent from './Subagents';
import { subagentMetadataGetRequest } from 'store/subagents/action';

const SubAgents = (props: any) => {    
    const metaData = useSelector((state: ApplicationState) => state.subagents.metaDataFields);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();

    useEffect(() => {
        const filters = { pageSize: 500 }
        dispatch(subagentMetadataGetRequest("subagents", filters));
    }, [])
    
    document.title="DTS | Zilter";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                           
                    <Row>
                        <ViewsTab 
                            component={SubAgent}
                            metadata={metaData}
                            model={"subagents"}
                            title={props.t("subagent.listing.breadcrumb.subagents")} 
                        />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(SubAgents);

