import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {importModel} from './Commission';
import { Card, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { isJson } from 'utils';
import FormBuilder from 'Components/Common/FormBuilder/FormBuilder';
import { commissionsMetadataGetRequest } from 'store/commissions/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

const Model = (props: any) => {   
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const { setImportJson, importJson } = props;
    const [ selectedCard, setselectedCrad] = useState<any>(importJson?.commissionModel); 
    const [ field, setField] = useState<any>(""); 
    const [ commissionModel, setCommissionModel] = useState<any>(importJson?.commissionModel); 
    const [ selectedValue, setSelectedValue]= useState<any>(importJson?.[importJson?.submodelkey]);
    const [ key, setkey ] = useState<any>(importJson?.submodelkey);
    
    const dataFields = useSelector((state: ApplicationState) => state.commissions.metaDataFields);

    useEffect(() => {
        dispatch(commissionsMetadataGetRequest({ pageSize: 500 }, 0));
    }, [])

    useEffect(() => {
        setImportJson({...importJson, commissionModel: commissionModel, [key]: selectedValue?.value || selectedValue ,commission : {commissionModel: commissionModel, [key] : selectedValue?.value  || selectedValue}})
    }, [selectedValue, commissionModel])

    useEffect(() => {
        dataFields && dataFields?.length && dataFields?.map((item: any) => {
            let valuesJson = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) : item?.valuesJson
            if(valuesJson?.key === key) {
               return setField({...item, ...valuesJson})
            }
        })
    }, [key, dataFields])

     return (
        <React.Fragment>
            <div className='vstack justify-content-center gap-3'>
                <h3 className='text-center'>What kind of data is in your file?</h3>
                <div className='hstack justify-content-center gap-3'>
                {
                    importModel && importModel.length ? importModel.map((item: any) => {
                        return <Card className={item.model === selectedCard ? 'p-4 border  border-3 border-primary bg-primary-subtle bg-opacity-10 gap-2' : 'p-4 border border-1 border-primary border-opacity-25 gap-2'} style={{ width: 180, height: 250 }} onClick={() =>{setselectedCrad(item.model); setCommissionModel(item.model); setImportJson({...importJson, commissionModel: item.model}); setkey({key: item.key}); setSelectedValue({})}} >
                            <div className='hstack justify-content-end'>
                                <Input type='checkbox' checked={item.model === selectedCard} />
                            </div>
                            <div className='align-self-center'>
                                <i className={`${item.svg} fs-24 text-primary`}></i>
                            </div>
                            <div className='vstack gap-2'>
                                <h5 className='text-center'>{item.title}</h5>
                            </div>
                            <p className='fs-14 text-center'>
                                {item.description}
                            </p>
                        </Card>
                    }) : null
                }
                </div>
                {/* <div className='w-100'>
                {
                    commissionModel ? <FormBuilder
                    placeholder={field.placeholder}
                    name={field.key}
                    onChange={(e: any) => setSelectedValue(e)}
                    label={field.label}
                    type={field.type}
                    value={selectedValue?.value || selectedValue || null}
                    isEditState={true}
                    actionButtons={false}
                    defaultValue={selectedValue?.value || selectedValue || null}
                    Editable={true}
                    rest={field}
                    invalid={true}
                    resetValue={selectedValue?.value || selectedValue || null}
                />
                    :                     
                    null
                }
                </div> */}
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Model);
