import {
    Card,
    CardBody,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import classnames from "classnames";
import TeamsTable from "./Tabs/TeamsTable";
import { withTranslation } from "react-i18next";
import BreadCrumb from "Components/Common/BreadCrumb";
import { useState } from "react";
import TreeFlow from "./Tabs/TreeFlow";

const Teams = ({ t }: any) => {
    const [activeTab, setActiveTab] = useState('table')
    return (
        <>
            <BreadCrumb title={"Teams"} pageTitle={"Teams"} icon={"ri-list-check"} />
            <Card>
                <CardHeader>
                    <Nav
                        className="nav-custom-light rounded card-header-tabs nav-border-top"
                        role="tablist"
                    >
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "table",
                                })}
                                onClick={() => {
                                    setActiveTab("table");
                                }}
                                href="#"				>
                                <strong>Table</strong>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "visualize",
                                })}
                                onClick={() => {
                                    setActiveTab("visualize");
                                }}
                                href="#"				>
                                <strong>Visualize</strong>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </CardHeader>
                <CardBody className="">
                    <TabContent activeTab={activeTab}>
                        {
                            activeTab === "table" && <TabPane tabId="table">
                                <TeamsTable />
                            </TabPane>
                        }
                        {
                            activeTab === "visualize" && <TabPane tabId="visualize">
                                <TreeFlow />
                            </TabPane>
                        }
                    </TabContent>
                </CardBody>
            </Card>
        </>
    );
};
export default withTranslation()(Teams);
