import { useEffect, useState } from "react";

import {
	CardBody,
	Row,
	Col,
	Card,
	Container,
	Input,
	Label,
	Table,
	Button,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import Select from "react-select";
//redux
import { useDispatch, useSelector } from "react-redux";
import PreviewModal from "./PreviewModal";
import { companyListGetRequest } from "store/companies/action";
import { ApplicationState } from "store";
import { ToastContainer } from "react-toastify";
import { convertCurrency, isJson } from "utils";
import { bankListGetRequest } from "store/banks/action";
import currrencyField from './currencyFieldJson.json';
import DeleteModal from "./DeleteModal";


const InvoiceCreate = () => {
	const navigate = useNavigate();
	const dispatch: any = useDispatch();
	const companyList = useSelector((state: ApplicationState) => state.companies.list);
	const banks = useSelector((state: ApplicationState) => state.banks.list);
	const conversionRates = useSelector((state: ApplicationState) => state.currency.conversion);

	const [companyOptions, setCompanyOptions] = useState<any>([]);
	const [companies, setCompanies] = useState<any>({});
	const [currencyoptions, setCurrencyoptions] = useState<any>([]);
	const [formValues, setFormValues] = useState<any>({
		terms: "All accounts are to be paid within 7 days from receipt of invoice. To be paid by cheque or credit card or direct payment online. If account is not paid within 7 days the credits details supplied as confirmation of work undertaken will be charged the agreed quoted fee noted above.",
		notes: "Notes all about payment Details",
		currency: {
			"id": 235,
			"label": "United Kingdom GBP (£)",
			"value": "GBP",
			"in_forms": true
		}
	});
	const [campanyobject, setcampanyobject] = useState<any>(null);
	const [bankOptions, setBankOptions] = useState<any>(null);
	const [bankobject, setbankobject] = useState<any>(null);
	const [banksList, seBanksList] = useState<any>(null)
	const [previewModal, setpreview] = useState<any>(false)
	const [StudentsList, setStudentsList] = useState<any>([]);
	const [currency, setCurrency] = useState<any>(null)
	const [assignRates, setAssignRates] = useState<any>(null)
	const [parsedRates, setParsedRates] = useState<any>(null)
	const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false)
	const [record, setRecord] = useState<any>(null)

	useEffect(() => {
		if (companyList && companyList.length) {
			let options: any = []
			companyList.map((item: any, index: any) => {
				const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : item.valuesJson;
				let values = { ...valuesJson, id: item.id, createdAt: item.createdAt }
				let option = { label: values.name, value: values.id, ...values, company_email: values.email }
				options.push(option)
			})
			setCompanyOptions(options)
			setCompanies(options)
		}
	}, [companyList])

	useEffect(() => {
		if (conversionRates) {
			setAssignRates(conversionRates)
		}
	}, [conversionRates])


	useEffect(() => {
		let valuesJson = currrencyField?.valuesJson && isJson(currrencyField?.valuesJson) ? JSON.parse(currrencyField?.valuesJson) : currrencyField?.valuesJson;
		let currencyoptions = valuesJson.values
		setCurrencyoptions(currencyoptions);
		let currency = valuesJson.values?.length ? valuesJson.values.find((item: any) => item?.label === 'United Kingdom GBP (£)') : {}
		setCurrency(currency)
	}, [currrencyField])

	const dateFormat = () => {
		let d = new Date(),
			months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear()).toString());
	};

	const [date, setDate] = useState(dateFormat());

	const dateformate = (e: any) => {
		const date = e.toString().split(" ");
		const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
		setDate(joinDate);
		setFormValues({ ...formValues, invoiceDate: joinDate })
	};

	const handleselected = async (option: any, str: any) => {
		switch (str) {
			case 'campany': setcampanyobject(option);
				let newcompany: any = {}
				companies.map((item: any) => {
					if (item.id == option.value) {
						newcompany = item;
					}
				})
				let filtersData = {
					parent:
					{
						model: "companies", id: newcompany.id
					}
				}
				await dispatch(bankListGetRequest(filtersData, 0, 100));
				let companyDetails = { company_registrationNo: newcompany.registration_number, company_name: newcompany.name, company_id: newcompany.id, company_website: newcompany.website, company_city: newcompany.city, company_country: newcompany.country, company_email: newcompany.email, company_phone: newcompany.phone_number, company_postal_code: newcompany.postal_code, company_state: newcompany.state, company_address: newcompany.address }
				setFormValues({ ...formValues, ...companyDetails })

				break
			case 'currency': setCurrency(option)
				setFormValues({ ...formValues, currency: option })
				setCurrency(option)
				break
			case 'bank': setbankobject(option)
				let newBank = { bank_id: option.value, account_number: option.account_number, account_name: option.account_name || option.name, ifsc: option.ifsc, bank_name: option.bank_name }
				setFormValues({ ...formValues, ...newBank })
				break
			default: return
		}
	}

	const toggle = () => {
		setpreview(!previewModal)
	}

	useEffect(() => {
		seBanksList(banks)
	}, [banks])

	useEffect(() => {
		if (assignRates && assignRates?.length > 0) {
			const targetCurrencyJson = assignRates[0]?.targetCurrencyJson;

			// Check if `targetCurrencyJson` exists and is a valid JSON string
			const convertJson = targetCurrencyJson && isJson(targetCurrencyJson)
				? JSON.parse(targetCurrencyJson)
				: {};

			// Set the parsed rates only if convertJson is valid
			setParsedRates(convertJson?.conversion_rates);
		}
	}, [assignRates]);

	useEffect(() => {
		dispatch(companyListGetRequest({}, 0, 500))
		let StudentsList: any = localStorage.getItem('invoiceItems');
		let channel: any = localStorage.getItem('invoiceCustomer');
		let customer = JSON.parse(channel);
		let students = JSON.parse(StudentsList);
		let newList = students.map((student: any, index: any) => {
			let taxObject = isJson(student?.channels?.taxPercentage) ? JSON.parse(student?.channels?.taxPercentage) : student?.channels?.taxPercentage ? JSON.parse(JSON.stringify(student?.channels?.taxPercentage)) : student?.channels?.taxPercentage;
			let taxPercentage = taxObject?.replace("%", "") && isJson(taxObject?.replace("%", "")) ? JSON.parse(taxObject?.replace("%", "")) : taxObject?.replace("%", "")
			let originalcommission = parseFloat(student?.actual_commission)
			let commission = parseFloat(student?.actual_commission)
			const originalCurrency = typeof student?.courses?.currency === "string" ? student?.courses?.currency.toUpperCase().match(/[A-Z]+/g)?.[0] : null;
			let selectedCurrency = formValues?.currency?.value
			if (commission && selectedCurrency) {
				if (
					originalCurrency &&
					!isNaN(commission) &&
					originalCurrency !== selectedCurrency &&
					originalCurrency?.toLowerCase() !== "na" &&
					parsedRates
				) {					
					const convertedFee: any = convertCurrency(commission, originalCurrency, selectedCurrency, parsedRates);
					commission = convertedFee?.toFixed(2);
				}
			}
			let item = { ...student, ...student?.valuesJson, commission: commission, originalcommission: originalcommission, taxPercentage: taxPercentage?.value, taxtype: taxPercentage?.type, taxIncluded: student?.commission?.basic?.is_tax_included, taxType: student?.channels?.taxType ? student?.channels?.taxType : 'percent', courses: student?.courses, institutes: student?.institution_name }
			if (taxObject?.includes("%") || item?.taxType == 'percent') {
				let taxpercent = item?.taxPercentage?.replace("%", "")
				let itemAmount = handleTaxInclusion(item, taxpercent, item.taxType)   //taxpercent ? (parseFloat(item.commission) + ((parseFloat(item.commission)) * (parseFloat(item.taxPercentage) / 100))) : parseFloat(item.commission);
				let newitem = { ...item, itemAmount: itemAmount ? itemAmount.toFixed(2) : 0, taxPercentage: taxpercent, taxType: 'percent', taxIncluded: item.taxIncluded }
				return newitem;
			}
			else {
				let tax = item?.taxPercentage?.replace("%", "")
				let itemAmount = handleTaxInclusion(item, tax, item.taxType)  //tax ? (parseFloat(item.commission) + (parseFloat(tax))) : parseFloat(item.commission);
				let newitem = { ...item, itemAmount: itemAmount ? itemAmount.toFixed(2) : 0, taxPercentage: tax, taxType: 'fixed', taxIncluded: item.taxIncluded }
				return newitem;
			}
		});
		let initChannel: any = { ...customer?.ChannelDetail?.valuesJson, ...customer }
		let newstudentDetails = { channel_id: customer?.id, channel_name: initChannel.name, channel_phone: initChannel.phone_number, channel_short_name: initChannel.channel_short_name, channel_city: initChannel.city, channel_email: initChannel.email, channel_state: initChannel.state, channel_address: initChannel.address }
		let initialValues = { ...formValues, ...newstudentDetails }
		setStudentsList(newList)
		valuesChanged(newList, initialValues)
	}, [formValues?.currency?.value, parsedRates])

	const handleTax = (item: any, totaltax: any) => {
		let taxAmount = 0;
		let taxpercent = item?.taxPercentage?.replace("%", "")
		if (item.taxType === 'percent') {
			taxAmount = taxpercent ? totaltax + (parseFloat(item.commission)) * (parseFloat(taxpercent) / 100) : 0;
			return taxAmount;
		}
		else return totaltax + parseFloat(taxpercent)
	}

	const valuesChanged = (newList: any, initialValues?: any) => {
		let invoice_amount = 0;
		let totaltax = 0;
		let subtotal = 0;
		let newValues = {};
		newList && newList.length && newList.map((item: any, index: any) => {
			invoice_amount = invoice_amount + parseFloat(item.itemAmount);
			subtotal = subtotal + parseFloat(item.commission);
			totaltax = item?.taxPercentage ? handleTax(item, totaltax) : totaltax;
		});
		if (initialValues) {
			let balanceDue = initialValues.paidAmount ? invoice_amount - initialValues.paidAmount : invoice_amount;
			newValues = { ...initialValues, invoice_amount: invoice_amount ? invoice_amount.toFixed(2) : 0, balanceDue: balanceDue ? balanceDue.toFixed(2) : 0, subtotal: subtotal ? subtotal.toFixed(2) : 0, totaltax: totaltax ? totaltax.toFixed(2) : 0 }
		}
		else {
			let balanceDue = formValues.paidAmount ? invoice_amount - formValues.paidAmount : invoice_amount;
			newValues = { ...formValues, invoice_amount: invoice_amount ? invoice_amount.toFixed(2) : 0, balanceDue: balanceDue ? balanceDue.toFixed(2) : 0, subtotal: subtotal ? subtotal.toFixed(2) : 0, totaltax: totaltax ? totaltax.toFixed(2) : 0 }
		}
		setFormValues(newValues)
	}

	useEffect(() => {
		if (formValues.paidAmount) {
			valuesChanged(StudentsList)
		}
	}, [formValues.paidAmount])

	const onDelete = (id: any) => {
		let newList = StudentsList.filter((item: any) => item.id !== id);
		setIsDeleteOpen(false)
		setStudentsList(newList)
		valuesChanged(newList)
		
	}

	const onChangeCommission = (value: any, id: any) => {
		let newList = StudentsList.map((item: any, index: any) => {
			if (id === item.id) {
				if (item?.taxType == 'percent') {
					let taxpercent = item?.taxPercentage?.replace("%", "")
					let newdetails = { ...item, commission: value }
					let itemAmount = taxpercent ? handleTaxInclusion(newdetails, taxpercent, item.taxType) : parseFloat(value);
					let newitem = { ...item, itemAmount: itemAmount ? itemAmount.toFixed(2) : 0, taxPercentage: taxpercent, taxType: 'percent', commission: value }
					return newitem;
				}
				else {
					let taxpercent = item?.taxPercentage?.replace("%", "")
					let newdetails = { ...item, commission: value }
					let itemAmount = taxpercent ? handleTaxInclusion(newdetails, taxpercent, item.taxType) : parseFloat(value);
					let newitem = { ...item, itemAmount: itemAmount ? itemAmount.toFixed(2) : 0, taxPercentage: taxpercent, taxType: 'fixed', commission: value }
					return newitem;
				}
			}
			return item; // Return the current item if it doesn't match the index
		});
		setStudentsList(newList);
		valuesChanged(newList)
	}

	const onChangeitemtax = (value: any, taxType: string, id: any,) => {
		let newList = StudentsList.map((item: any, index: any) => {
			if (id === item.id) {
				if (taxType == 'percent') {
					let taxpercent = value
					let itemAmount = taxpercent ? handleTaxInclusion(item, taxpercent, taxType) : parseFloat(item.commission);
					let newitem = { ...item, itemAmount: itemAmount ? itemAmount.toFixed(2) : 0, taxPercentage: taxpercent, taxType: taxType }
					return newitem;
				}
				else {
					let taxpercent = value
					let itemAmount = taxpercent ? handleTaxInclusion(item, taxpercent, taxType) : parseFloat(item.commission);
					let newitem = { ...item, itemAmount: itemAmount ? itemAmount.toFixed(2) : 0, taxPercentage: taxpercent, taxType: taxType }
					return newitem;
				}
			}
			return item; // Return the current item if it doesn't match the index
		});
		setStudentsList(newList);
		valuesChanged(newList)
	}

	const handleTaxInclusion = (item: any, taxpercent: any, taxType: any) => {
		let amount = 0;
		if (taxType == 'percent') {
			if (item.taxIncluded) amount = (parseFloat(item.commission) - ((parseFloat(item.commission)) * (parseFloat(taxpercent) / 100)))
			else amount = (parseFloat(item.commission) + ((parseFloat(item.commission)) * (parseFloat(taxpercent) / 100)))
		}
		else {
			if (item.taxIncluded) amount = (parseFloat(item.commission) - (parseFloat(taxpercent)));
			else amount = (parseFloat(item.commission) + (parseFloat(taxpercent)));
		}
		return amount;
	}

	const onChangeitemtaxtype = (taxType: string, id: any,) => {
		let newList = StudentsList.map((item: any, index: any) => {
			if (id === item.id) {
				if (taxType == 'percent') {
					let taxpercent = item?.taxPercentage?.replace("%", "")
					let itemAmount = taxpercent ? handleTaxInclusion(item, taxpercent, taxType) : parseFloat(item.commission);
					let newitem = { ...item, itemAmount: itemAmount ? itemAmount.toFixed(2) : 0, taxPercentage: taxpercent, taxType: taxType }
					return newitem;
				}
				else {
					let taxpercent = item?.taxPercentage?.replace("%", "")
					let itemAmount = taxpercent ? handleTaxInclusion(item, taxpercent, taxType) : parseFloat(item.commission);
					let newitem = { ...item, itemAmount: itemAmount ? itemAmount.toFixed(2) : 0, taxPercentage: taxpercent, taxType: taxType }
					return newitem;
				}
			}
			return item; // Return the current item if it doesn't match the index
		});
		setStudentsList(newList);
		valuesChanged(newList)
	}

	useEffect(() => {
		let newbankoptions: any = [];
		if (banksList && banksList.length) {
			banksList.map((item: any) => {
				const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : item.valuesJson;
				let option = { value: item.id, label: valuesJson.bank_name, ...valuesJson }
				newbankoptions.push(option)
			})
			setBankOptions(newbankoptions)
		}
	}, [banksList])

	const goBack = () => {
		navigate(-1);
	};

	const onChangedPaidAmount = (value: any) => {
		if (!value) {
			setFormValues({ ...formValues, paidAmount: value });
			valuesChanged(StudentsList, { ...formValues, paidAmount: 0 })
		}
		else {
			setFormValues({ ...formValues, paidAmount: value });
		}
	}
	return (
		<div className="page-content">
			<ToastContainer />
			<Container fluid>
				<BreadCrumb title="Create Invoice" pageTitle="Invoices" icon={"ri-contacts-book-line"} />
				<PreviewModal
					show={previewModal}
					onCloseClick={() => setpreview(false)}
					formValues={formValues}
					ontoggle={toggle}
					setFormValues={setFormValues}
					lineItems={StudentsList}
				/>
				 {isDeleteOpen && <DeleteModal
					show={isDeleteOpen}
					onCloseClick={() => setIsDeleteOpen(false)}
					record={record}
					onDelete={onDelete}
				/>}
				<Row>
					<Col>
						<div className="hstack gap-2 justify-content-start d-print-none mt-4">
							<Button
								onClick={() => goBack()}
								color="primary"
								className="btn-sm"	>
								Go back
							</Button>
						</div>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col xxl={9}>
						<Card>
							<CardBody className="border-bottom border-bottom-dashed p-4">
								<Row>
									<Col lg={4}>
										<div className="vstack">

											<Label for="invoicenoInput">Company<span className="fw-bold text-danger">{" "}*</span></Label>

											<Select
												defaultOptions
												className='border border-dark border-opacity-25 rounded-2'
												placeholder={"Select Company"}
												value={campanyobject}
												onChange={(option: any) => handleselected(option, 'campany')}
												options={companyOptions}
												name="choices-single-default"
												id="idStatus"
											></Select>
										</div>
										<div>
											<div>
												<Label for="companyAddress">Address</Label>
											</div>
											<div className="mb-2">
												<Input
													type="textarea"
													className="form-control bg-light border-0"
													id="companyAddress"
													rows={3}
													value={formValues.company_address}
													onChange={(e) => setFormValues({ ...formValues, company_address: e.target.value })}
													placeholder="Company Address"
												/>
												<div className="invalid-feedback">
													Please enter a address
												</div>
											</div>
											<div>
												<Label for="companyAddress">Postal Code</Label>
												<Input
													type="text"
													className="form-control bg-light border-0"
													id="companyaddpostalcode"
													minLength={5}
													maxLength={6}
													name="postalcode"
													value={formValues?.company_postal_code || ""}
													onChange={(e) => setFormValues({ ...formValues, company_postal_code: e.target.value })}
													placeholder="Enter Postal Code"
												/>

											</div>
										</div>
									</Col>
									<Col lg={4} className="ms-auto">
										<div className="vstack justify-content-end">
											<Label for="invoicenoInput">Currency<span className="fw-bold text-danger">{" "}*</span></Label>

											<Select
												// isMulti
												defaultOptions
												className='border border-dark border-opacity-25 rounded-2 w-75 mb-2'
												placeholder={"Select Currency"}
												value={currency}
												onChange={(option: any) => handleselected(option, 'currency')}
												options={currencyoptions}
												name="choices-single-default"
												id="idStatus"
											></Select>
										</div>
										<div className="mb-2">
											<Label for="companyAddress">Registration Number</Label>
											<Input
												type="text"
												className="form-control bg-light border-0"
												id="registrationNumber"
												name="registration"
												value={formValues.company_registrationNo || ""}
												onChange={(e) => setFormValues({ ...formValues, company_registrationNo: e.target.value })}
												maxLength={12}
												placeholder="Legal Registration No"
											/>
										</div>
										<div className="mb-2">
											<Label for="companyAddress">Email</Label>
											<Input
												type="email"
												className="form-control bg-light border-0"
												id="companyEmail"
												name="email"
												value={formValues.company_email || ""}
												onChange={(e) => setFormValues({ ...formValues, company_email: e.target.value })}
												placeholder="Email Address"
											/>
										</div>
										<div className="mb-2">
											<Label for="companyAddress">Website</Label>
											<Input
												type="text"
												className="form-control bg-light border-0"
												id="companyWebsite"
												name="website"
												value={formValues.company_website || ""}
												onChange={(e) => setFormValues({ ...formValues, company_website: e.target.value })}
												placeholder="Website"
											/>
										</div>
										<div>
											<Label for="companyAddress">Contact Number</Label>
											<Input
												type="text"
												className="form-control bg-light border-0"
												data-plugin="cleave-phone"
												id="compnayContactno"
												name="contact"
												value={formValues.company_phone || ""}
												onChange={(e) => setFormValues({ ...formValues, company_phone: e.target.value })}
												placeholder="Contact No"
											/>
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardBody className="p-4 border-top border-top-dashed hstack justify-content-between">
								<Row className="w-100 hstack justify-content-between">
									<Col md={5} lg={5} sm={5} xl={5}>
										<div>
											<Label
												for="billingName"
												className="text-muted text-uppercase fw-semibold"
											>
												Billing Address
											</Label>
										</div>
										<div className="mb-2">
											<Input
												type="text"
												className="form-control bg-light border-0"
												id="billingName"
												name="name"
												value={formValues.channel_name || ""}
												onChange={(e) => setFormValues({ ...formValues, channel_name: e.target.value })}
												placeholder="Name"
											/>

										</div>
										<div className="mb-2">
											<Input
												type="textarea"
												className="form-control bg-light border-0"
												id="billingAddress"
												name="billing_address"
												value={formValues.channel_address || ""}
												onChange={(e) => setFormValues({ ...formValues, channel_address: e.target.value })}
												rows={3}
												placeholder="Address"
											/>

										</div>
										<div className="mb-2">
											<Input
												type="text"
												className="form-control bg-light border-0"
												data-plugin="cleave-phone"
												id="billingPhoneno"
												name="billing_phone"
												value={formValues.channel_phone || ""}
												onChange={(e) => setFormValues({ ...formValues, channel_phone: e.target.value })}
												placeholder="(123)456-7890"
											/>

										</div>
										<div className="mb-3">
											<Input
												type="text"
												className="form-control bg-light border-0"
												name="email"
												value={formValues.channel_email || ""}
												onChange={(e) => setFormValues({ ...formValues, channel_email: e.target.value })}
												placeholder="Email"
											/>

										</div>
										<div className="mb-3">
											<Input
												type="text"
												className="form-control bg-light border-0"
												name="billing_taxno"
												value={formValues.channel_taxNumber || ""}
												onChange={(e) => setFormValues({ ...formValues, channel_taxNumber: e.target.value })}
												placeholder="Tax Number"
											/>

										</div>
									</Col>
									<Col md={5} lg={5} sm={5} xl={5} className="align-self-start">
										<div>
											<Label for="invoicenoInput">Invoice No<span className="fw-bold text-danger">{" "}*</span></Label>
											<Input
												type="text"
												className="form-control bg-light border-0"
												id="invoicenoInput"
												name="invoiceId"
												value={formValues.invoice_number || ""}

												// 
												onChange={(e) => setFormValues({ ...formValues, invoice_number: e.target.value })}
												placeholder="Invoice No"
											/>
										</div>
										<div>
											<Label for="date-field">Invoice Date<span className="fw-bold text-danger">{" "}*</span></Label>
											<Flatpickr
												name="date"
												id="date-field"
												className="form-control bg-light"
												placeholder="Select a date"
												options={{
													altInput: true,
													altFormat: "d M, Y",
													dateFormat: "d M, Y",
												}}
												onChange={(e) =>
													dateformate(e)
												}
												value={formValues.invoiceDate || ""}
											/>
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardBody className="p-4">
								<div className="table-responsive">
									<Table className="invoice-table table-borderless table-nowrap mb-0">
										<thead className="align-middle">
											<tr className="table-active">
												<th scope="col" style={{ width: "50px" }}>
													#
												</th>
												<th scope="col">Student Details</th>
												<th scope="col" style={{ width: "120px" }}>
													<div className="d-flex currency-select input-light align-items-center text-center ">
														Commission
													</div>
												</th>
												<th
													scope="col"
													className="text-center"
													style={{ width: "200px" }}
												>
													Tax
												</th>
												<th
													scope="col"
													className="text-center"
													style={{ width: "150px" }}
												>
													Amount
												</th>
												<th
													scope="col"
													className="text-center"
													style={{ width: "105px" }}
												>
													Action
												</th>
											</tr>
										</thead>
										<tbody id="newlink">
											{StudentsList && StudentsList.length ? StudentsList.map((item: any, index: any) => {
												return <tr id="1" className="product">
													<th scope="row" className="product-id">
														{index + 1}
													</th>
													<td className="text-start" style={{ maxWidth: 300 }}>
														<div className="mb-2">
															{item?.student?.name}
														</div>
														<div className='text-wrap' style={{ maxWidth: 300 }} title={item?.institutes?.name}>{item?.institutes?.name}<br /></div>
														<div className='text-wrap' style={{ maxWidth: 300 }} title={item?.courses?.name}>{item?.courses?.name}<br /></div>
														(Fee: {item?.courses.currency}{item?.courses?.course_fee})<br />
														(Commission: {item?.courses.currency}{item?.originalcommission})<br />
														{item?.taxIncluded ? "Tax is included" : "Tax is excluded"}
														({item?.taxPercentage}{item?.taxType == "percent" ? "%" : ""})
													</td>
													<td className="justify-content-center">
														<Input
															type="number"
															className="form-control product-price bg-light border-0"
															placeholder="0.00"
															id="productRate-1"
															value={item.commission}
															onWheel={(e: any) => e.target.blur()} // Disable mouse wheel change
															onChange={(e) => onChangeCommission(e.target.value, item.id)}
														/>
													</td>
													<td className="text-center">
														<div className="mb-2 hstack align-items-center gap-2">
															<div className="hstack  w-100">
																{
																	item.taxType == 'percent' ?
																		<div className="position-relative w-100">
																			<Input
																				type="text"
																				className="form-control bg-light border-0"
																				id="companyEmail"
																				name="email"
																				value={item.taxPercentage ? `${item.taxPercentage}` : ""}
																				onChange={(e: any) => onChangeitemtax(e.target.value, 'percent', item.id)}
																				placeholder="0"
																			/>
																			<div className="position-absolute" style={{ right: 5, top: 7 }}>
																				<div className="hstack gap-1">
																					<span>
																						%
																					</span>
																				</div>
																			</div>
																		</div>
																		:
																		<div className="position-relative w-100">
																			<Input
																				type="text"
																				className="form-control bg-light border-0 ps-5"
																				id="companyEmail"
																				name="email"
																				value={item.taxPercentage ? `${item.taxPercentage}` : ""}

																				onChange={(e: any) => onChangeitemtax(e.target.value, 'fixed', item.id)}
																				placeholder="0"
																			/>
																			<div className="position-absolute" style={{ left: 10, top: 7 }}>
																				<div className="hstack gap-1">
																					<span>
																						{currency?.value}
																					</span>
																				</div>
																			</div>
																		</div>
																}
																<button className="btn btn-sm btn-soft-dark remove-list hstack align-items-center" style={{ height: 35 }} onClick={() => onChangeitemtaxtype(item.taxType === "percent" ? "fixed" : "percent", item.id)}>
																	<i className="ri-repeat-fill fs-14"></i>
																</button>
															</div>
														</div>
													</td>
													<td className="text-end">
														<div className="position-relative w-100">
															<Input
																type="text"
																className="form-control bg-light border-0 product-line-price ps-5"
																id="productPrice-1"
																placeholder={`0.00`}
																value={item.itemAmount || ""}
																readOnly
															/>
															<div className="position-absolute" style={{ left: 10, top: 7 }}>
																<div className="hstack gap-1">
																	<span>
																		{currency?.value}
																	</span>
																</div>
															</div>
														</div>
													</td>
													<td className="product-removal text-center">
														<button className="btn btn-sm btn-soft-danger remove-list" onClick={() => {setRecord(item); setIsDeleteOpen(true)}}>
															<i className="ri-delete-bin-fill"></i>
														</button>  {" "}
													</td>
												</tr>
											}) : null}

										</tbody>
									</Table>
								</div>
								<Row className="mt-3 justify-content-between">
									<Col className="mt-5" lg={5} md={5} xl={5}>
										<Label>NOTES<span className="fw-bold text-danger">{" "}*</span></Label>
										<Select
											defaultOptions
											className='border border-dark border-opacity-25 rounded-2'
											placeholder={"Select Bank"}
											value={bankobject}
											onChange={(option: any) => handleselected(option, 'bank')}
											options={bankOptions || []}
											name="choices-single-default"
											id="idStatus"
										></Select>
										<div className="mb-2">
											<Input
												className="form-control bg-light border-0"
												type="text"
												id="cardholderName"
												placeholder="Bank Name"
												value={formValues.bank_name || ""}
												onChange={(e) => setFormValues({ ...formValues, bank_name: e.target.value })}
											/>
										</div>
										<div className="mb-2 mt-2">
											<Input
												className="form-control bg-light border-0"
												type="text"
												id="cardNumber"
												placeholder="Account name"
												value={formValues.account_name || formValues.name || ""}
												onChange={(e) => setFormValues({ ...formValues, account_name: e.target.value })}
											/>
										</div>
										<div className="mb-2">
											<Input
												className="form-control bg-light border-0"
												type="text"
												id="cardNumber"
												placeholder="Account number"
												value={formValues.account_number || ""}
												onChange={(e) => setFormValues({ ...formValues, account_number: e.target.value })}
											/>
										</div>
										<div>
											<Input
												className="form-control  bg-light border-0"
												type="text"
												id="amountTotalPay"
												placeholder="IFSC code"
												value={formValues.ifsc || ""}
												onChange={(e) => setFormValues({ ...formValues, ifsc: e.target.value })}
											/>
										</div>
									</Col>
									<Col lg={5} md={5} xl={5} className="mt-5">
										<div className="mb-2 hstack align-items-center gap-2">
											<Label for="companyAddress" className="mb-0 w-25 text-end">Subtotal</Label>
											<div className="position-relative w-75">
												<Input
													type="text"
													className="form-control bg-light border-0 ps-5"
													id="subtotal"
													name="subtotal"
													value={formValues.subtotal || ""}
													maxLength={12}
													placeholder="0"
												/>
												<div className="position-absolute" style={{ left: 8, top: 7 }}>
													<div className="hstack gap-1">
														<span>
															{currency?.value}
														</span>
													</div>
												</div>
											</div>
										</div>
										<div className="mb-2 hstack align-items-center gap-2">
											<Label for="companyAddress" className="mb-0 w-25 text-end">Tax</Label>
											<div className="position-relative  hstack  w-75">
												<Input
													type="text"
													className="form-control bg-light border-0 ps-5"
													id="companyEmail"
													name="email"
													value={formValues.totaltax ? `${formValues.totaltax}` : ""}

													// onChange={(e: any) => onChangetax(e.target.value, 'fixed' )}
													placeholder="0"
												/>
												<div className="position-absolute" style={{ left: 10, top: 7 }}>
													<div className="hstack gap-1">
														<span>
															{currency?.value}
														</span>
													</div>
												</div>
											</div>

										</div>
										<div className="mb-2 hstack align-items-center gap-2">
											<Label for="companyAddress" className="mb-0 w-25 text-end">Total</Label>
											<div className="position-relative w-75">
												<Input
													type="text"
													className="form-control bg-light border-0 ps-5"
													id="total"
													name="total"
													value={formValues.invoice_amount || ""}
													placeholder="0"
												/>
												<div className="position-absolute" style={{ left: 10, top: 7 }}>
													<div className="hstack gap-1">
														<span>
															{currency?.value}
														</span>
													</div>
												</div>
											</div>

										</div>
										<div className="mb-2 hstack align-items-center gap-2">
											<Label for="companyAddress" className="mb-0 w-25 text-end">Amount Paid</Label>
											<div className="position-relative w-75">
												<Input
													type="text"
													className="form-control bg-light border-0 ps-5"
													id="paidAmount"
													name="paidAmount"
													value={formValues.paidAmount || ""}
													placeholder="0"
													onChange={(e: any) => { onChangedPaidAmount(e.target.value) }}
												/>
												<div className="position-absolute" style={{ left: 10, top: 7 }}>
													<div className="hstack gap-1">
														<span>
															{currency?.value}
														</span>
													</div>
												</div>
											</div>
										</div>
										<div className="mb-2 hstack align-items-center gap-2">
											<Label for="companyAddress" className="mb-0 w-25 text-end">Balance Due</Label>
											<div className="position-relative w-75">
												<Input
													type="text"
													className="form-control bg-light border-0 ps-5"
													id="companyEmail"
													name="email"
													value={formValues.balanceDue || ""}
													placeholder="0"
												/>
												<div className="position-absolute" style={{ left: 10, top: 7 }}>
													<div className="hstack gap-1">
														<span>
															{currency?.value}
														</span>
													</div>
												</div>
											</div>
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg={12}>
										<div className="mt-4">
											<Label
												for="exampleFormControlTextarea1"
												className="form-label text-muted text-uppercase fw-semibold"
											>
												TERMS
											</Label>
											<Input
												type="textarea"
												className="form-control alert alert-info"
												id="exampleFormControlTextarea1"
												placeholder="Terms"
												rows={3}
												onChange={(e: any) => setFormValues({ ...formValues, terms: e.target.value })}
												value={formValues.terms}
												defaultValue="All accounts are to be paid within 7 days from receipt of
													invoice. To be paid by cheque or credit card or direct
													payment online. If account is not paid within 7 days the
													credits details supplied as confirmation of work
													undertaken will be charged the agreed quoted fee noted
													above."
											/>
										</div>
									</Col>
								</Row>
								<div className="hstack gap-2 justify-content-end d-print-none mt-4">
									<button
										onClick={() => setpreview(true)}
										className="btn btn-primary"
										disabled={!formValues.invoiceDate || !formValues.invoice_number || !StudentsList.length || !formValues.bank_name || !formValues.company_name || !formValues.currency}
									>
										<i className="ri-article-line align-bottom me-1"></i> Preview
										Invoice
									</button>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default InvoiceCreate;
