import React from 'react';
import { Container } from 'reactstrap';
import AgentChangeForm from './AgentChangeForm';

const Recommendation = () => {
    document.title = "Agent Change Letter | Zilter";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <AgentChangeForm />
                </Container>
            </div>
        </React.Fragment>
    );
};
export default Recommendation;


