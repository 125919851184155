import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import TableComponent from './TableComponent';
import { importListGetRequest } from 'store/import/action';
import { useDispatch, useSelector } from 'react-redux';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { ApplicationState } from 'store';
import { isEqual, startCase} from 'lodash';
import moment from 'moment';
import { documentDownload } from 'store/documents/action';
import FeatherIcon from "feather-icons-react";


const RecentImports = (props: any) => {   
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const importlist = useSelector((state: ApplicationState) => state.import.importList);
    const paginationDetails = useSelector((state: ApplicationState) => state.import.pagination);
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [importData, setImportData] = useState([])
    const [paginations, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )

    useEffect(() => {
        dispatch(importListGetRequest(paginations?.page, paginations?.size));
    }, [paginations?.size, paginations?.page])

    // Table Data methods 
        // Decide updating list
    const execute = () => {
        let a = isEqual(importlist, importData)
        return !a
    }

   
    useEffect(() => {
        const parsedData: any = importlist && importlist.length ? importlist.map((item: any) => {
            let model = item?.documentKey?.split('_')[0]
            let values = { id: item.id ,documentName: item?.documentName, model: model, createdAt: item.createdAt, ...item   }
            return values
        }) : []
        let list = parsedData && parsedData.length && parsedData;
        setData(list)
        setImportData(importlist)
    }, [execute()])   

    useEffect(() => {
        if(data && data.length) {
            let fields = data[0];  
            const keys = Object.keys(fields)
            const columns: any = []
            if(keys && keys.length) {
                keys.map((field,index) => { 
                    if(field === 'documentName' || field ===  'createdAt' || field ===  'model' ) {
                        let column = {
                                Header: field === 'documentName'? 'File Name'  : startCase(field),
                                accessor: field,
                                filterable: true,
                                disableFilters: true,
                                isSortable: false,
                                show: true,
                                Cell: (cellProps: any) => { 
                                   if(field ===  'createdAt') {
                                        return  <div className="d-flex align-items-center justify-content-between">                                
                                                <div className="hstack flex-grow-1  gap-2">
                                                    <div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2" >                                  
                                                    {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll') :
                                            ''}
                                                    </div>
                                                </div>
                                        </div>  }
                                    if(field === 'documentName' ) {
                                        return <div className='text-ellipsis'>
                                                       <span  style={{ maxWidth: 300, overflow: 'hidden'}} className='text-primary cursor-pointer text-truncate' onClick={() => onClickFileDownload(cellProps.row.original?.['documentKey'], `${field}_${cellProps.row.original?.id}`)}> <FeatherIcon icon="download" className="icon-xs text-primary cursor-pointer" />
                                                       {" "} {cellProps.row.original?.['documentKey']}</span>
                                                    </div>
                                    }
                                    if(field === 'model') return <div className="d-flex align-items-center justify-content-between">                                
                                                <div className="hstack flex-grow-1  gap-2">
                                                    <div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2" >                                  
                                                        {startCase(cellProps.row.original[field])}
                                                    </div>
                                                </div>
                                        </div> 
                                    else return <div className="d-flex align-items-center justify-content-between">                                
                                                <div className="hstack flex-grow-1  gap-2">
                                                    <div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2" >                                  
                                                        {cellProps.row.original[field]}
                                                    </div>
                                                </div>
                                        </div>                
                                }
                            }
                        columns.push(column)
                    }
                })
                setColumns(columns);
            }             
        }
    }, [data])
    
    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
        }
    }, [paginationDetails])

    const onClickFileDownload = (documentKey: any, key: any) => {
        const handleSuccess = async (body: any) => {
            try {
                // Create a Blob from the response data
                const fileBlob = await body.blob();
    
                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(fileBlob);
    
                // Create a temporary <a> element to trigger the download
                const tempLink = document.createElement("a");
                tempLink.href = url;
                tempLink.setAttribute("download", key); // Set the desired filename for the downloaded file
    
                // Append the <a> element to the body and click it to trigger the download
                document.body.appendChild(tempLink);
                tempLink.click();
    
                // Clean up the temporary elements and URL
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("Error downloading file:", error);
            }
        }
        const handleFailure = () => {
    
        }
        // const encodedString = encodeURIComponent(documentKey).split('.')[0]+"."+encodeURIComponent(documentKey).split('.')[1];
        dispatch(documentDownload(documentKey, handleSuccess, handleFailure))
    }
    
    // Handle page change
    const pageChanged = (pageVal: any) => {
        const { pages, page } = paginations;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...paginations, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...paginations, page: newPageNo });
                }
                break;
            case 'first':
                newPageNo = 0;
                setPagination({ ...paginations, page: 0 });
                break;
            case 'last':
                newPageNo = lastPage - 1;
                setPagination({ ...paginations, page: newPageNo });
                break;
            default:
                newPageNo = pageVal - 1;
                setPagination({ ...paginations, page: newPageNo });
                break;
        }
    };

    const onChangePageSize = (value: any) => {
        setPagination({ ...paginations, size: value, page: 0 })
    }

    return (
        <React.Fragment>
            <div className='vstack gap-3'>
                <div>
                    <div>
                        <TableComponent 
                            tableClass="align-middle table-nowrap"
                            theadClass="table-light text-muted"
                            thClass="border-bottom-1 table-soft-primary" 
                            columns={columns} 
                            data={data}
                            paginations={paginations}
                            props={props}
                            pageChanged={pageChanged}
                            onChangePageSize={onChangePageSize}
                        />                   
                    
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
};

export default withTranslation()(RecentImports);
