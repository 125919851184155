import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Row } from "reactstrap";
import { ApplicationState } from "store";
import { tenantGetOpenRequest } from "store/user/action";
import { isJson } from "utils";
interface TenantProps {
    logo: string
    companyName: string
    email: string
    id: string
    noOfEmployees: string
    website: string
}


const CompanyletterHeadHeader: React.FC = () => {  
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const tenatDetails = useSelector((state: ApplicationState) => state.user.tenatDetails);
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const [tenant, setTenant] = useState<TenantProps | null>(null)
    const [base64Image, setBase64Image] = useState<string | null>(null);

    useEffect(() => {
        dispatch(tenantGetOpenRequest("d15fa65bc2594f80a86556530bd5aadc"))
    }, [])
    
    useEffect(() => {
        let tenant = tenatDetails?.settings && isJson(tenatDetails?.settings) ? JSON.parse(tenatDetails?.settings) : tenatDetails?.settings;
        setTenant({...tenant, ...tenatDetails})
    }, [tenatDetails])
    
    const { logo, companyName, email, website  } = tenant ?? {} as TenantProps;
    
    return (
        <Container fluid className="letterhead-container">
            {/* Header with Logo */}
            <Row className="letterhead-header hstack justify-content-center text-center p-3">
                <Col md={2} className="text-left border-primary align-content-center border-end border-3 pe-2">
                   <img
                        src={logo || ''}
                        alt={`${companyName} Logo`}
                        style={{
                            width: "100%",
                            objectFit: "cover",
                            borderRadius: "5px"
                        }}
                    />
                </Col>
                <Col md={8} className="align-content-center">
                    <h1 className="company-name">{companyName}</h1>
                    <p className="company-info">
                        Email: {email} | Website: {website}
                    </p>
                </Col>
            </Row>

            {/* Horizontal Line */}
            <hr className="letterhead-line" />
        </Container>
    );
};

export default CompanyletterHeadHeader;
