import React  from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
interface SuccessPops { 
    successModal: boolean;
    setSuccessModal: (e: boolean) => void; 
    pdfPreview: () => void;
    setIsConfirm: (e: boolean) => void;
}
const InPersonVerify: React.FC<SuccessPops> = ({ successModal, setSuccessModal, pdfPreview, setIsConfirm}) => {

    return (
        <Modal
            isOpen={successModal}
            backdrop={'static'}
            id="staticBackdrop"
            centered
        >
            <ModalHeader className='hstack justify-content-between' toggle={() => {setSuccessModal(!successModal); setIsConfirm(true)}}>
                <h5 className="modal-title" id="staticBackdropLabel"></h5>
            </ModalHeader>
            <ModalBody className="text-center p-5">
                <div className="mt-4">
                    <h4 className="mb-3">Thank you..!</h4>
                    <p className="text-muted mb-4">Your Form submited successfully.</p>
                    <p className="text-muted mb-4">If you want to download a copy of form please click on download button.</p>
                    <div className="hstack gap-2 justify-content-center">
                        <Button color='danger' onClick={() => {setSuccessModal(false); setIsConfirm(true)}}>Close</Button>
                        <Button color='primary' onClick={() => pdfPreview()}>Download</Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default InPersonVerify;
