import React, { useState } from "react";
import { Accordion, AccordionItem, AccordionHeader, AccordionBody } from "reactstrap";
import SimpleBar from "simplebar-react";


const Transcript = ({ transcript }: any) => {
    const [openDefault, setOpenDefault] = useState("0");
    const toggleDefault = (id: any) => {
        if (openDefault !== id) {
            setOpenDefault(id);
        }
    };
    return (
        <React.Fragment>
            <Accordion id="default-accordion-example" open="1" toggle={toggleDefault}>
                <AccordionItem>
                    <AccordionHeader targetId="1">Transcript</AccordionHeader>
                    <AccordionBody accordionId="1">
                        <SimpleBar style={{ maxHeight: "500px" }}>
                            <div className="position-relative" id="users-chat">
                                <div className="chat-conversation p-3 p-lg-4 simplebar-scrollable-y" id="chat-conversation">
                                    <ul className="list-unstyled chat-conversation-list" id="users-conversation">
                                        {
                                            transcript.map((sentiment: any, key: number) => {
                                                let emotion = <i className="ri-emotion-normal-fill text-primary fs-4"></i>
                                                switch (sentiment.Sentiment) {
                                                    case "POSITIVE":
                                                        emotion = <i className="ri-emotion-happy-fill text-success fs-4"></i>
                                                        break;
                                                    case "NEGATIVE":
                                                        emotion = <i className="ri-emotion-unhappy-fill text-danger fs-4"></i>
                                                        break;
                                                    default:
                                                        emotion = <i className="ri-emotion-normal-fill text-primary fs-4"></i>
                                                        break;
                                                }
                                                return (
                                                    <li className={sentiment.ParticipantId === 'CUSTOMER' ? "chat-list left" : "chat-list right"} key={key}>
                                                        <div className="conversation-list">
                                                            <div className="user-chat-content">
                                                                <div className="ctext-wrap">
                                                                    <div className="ctext-wrap-content">
                                                                        <p className="mb-0 ctext-content">
                                                                            {sentiment.Content}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="conversation-name">
                                                                    {emotion}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </SimpleBar>
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </React.Fragment>
    )
};

export default Transcript;