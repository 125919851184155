import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Input, Label } from 'reactstrap';

// Define the shape for the role input.
interface CreateInputs {
	role: string;
}

interface RoleNameProps extends WithTranslation {
	createInputs: CreateInputs;
	nextClicked: () => void;
	setCreateInputs: React.Dispatch<React.SetStateAction<CreateInputs>>;
}

/**
 * RoleName Component (Step 1)
 * Renders a centered form for entering a role name.
 */
const RoleName: React.FC<RoleNameProps> = ({ createInputs, setCreateInputs, t, nextClicked }) => {
	return (
		<div className="w-100 p-4">
			<Label
				className="form-label fw-bold fs-4 text-center d-block mb-3"
				htmlFor="roleNameInput"
			>
				{t('roles.role_name')}
			</Label>
			<p className="text-center text-muted mb-4">
				{t('roles.role_name_description')}
			</p>
			<div className="d-flex justify-content-center align-items-center gap-3">
				<Input
					type="text"
					className="form-control w-50"
					id="roleNameInput"
					placeholder={t('roles.enter_role_name')}
					value={createInputs.role}
					onChange={(e) =>
						setCreateInputs({ ...createInputs, role: e.target.value })
					}
				/>
				<Button color="primary" onClick={nextClicked} disabled={!createInputs.role}>
					<i className="ri-arrow-right-line" />
				</Button>
			</div>
		</div>
	);
};

export default withTranslation()(RoleName);
