import { useState, useEffect, } from "react";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import './styles.css'
import config from "../../../config";

const { DTS_API_STUDENT } = config.api;
const editorConfiguration = {
	ckfinder: {
		uploadUrl: `${DTS_API_STUDENT}/documents/forms/ck`,
		method: "POST",
	  },
	removePlugins: ['Title'],
	toolbar: {
		shouldNotGroupWhenFull: false,
		items: [
			'heading', '|', // Heading Options
			'bold', 'italic', 'underline', 'strikethrough', '|', // Text Formatting
			'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', 'highlight', '|', // Font Customization
			'link', 'bulletedList', 'numberedList', '|', // Lists & Links
			'outdent', 'indent', '|', // Text Alignment
			'insertTable', 'blockQuote', 'mediaEmbed', 'imageInsert', 'imageUpload', '|', // Media & Tables
			'horizontalLine', 'htmlEmbed', 'code', 'codeBlock', '|', // Special Elements
			'findAndReplace', 'selectAll', 'showBlocks', 'sourceEditing', '|', // Editing Tools
			'style', 'subscript', 'superscript', 'textPartLanguage', 'todoList', '|', // Extra Features
			'restrictedEditingException', '|', // Restrictions
			'undo', 'redo' // Always at the end for quick access
		]
		// items: [
		// 	'heading','|',
		// 	'bold',
		// 	'underline',
		// 	'italic','|',
		// 	'link','|',
		// 	'bulletedList',
		// 	'numberedList',
		// 	'|',
		// 	'outdent',
		// 	'indent',
		// 	'|',
		// 	'imageUpload',
		// 	'blockQuote',
		// 	'insertTable',
		// 	'mediaEmbed',
		// 	'undo',
		// 	'redo',
		// 	'code',
		// 	'codeBlock',
		// 	'findAndReplace',
		// 	'fontBackgroundColor',
		// 	'fontColor',
		// 	'fontFamily',
		// 	'fontSize',
		// 	'highlight',
		// 	'horizontalLine',
		// 	'htmlEmbed',
		// 	'imageInsert',
		// 	'selectAll',
		// 	'showBlocks',
		// 	'sourceEditing',
		// 	'restrictedEditingException',
		// 	'strikethrough',
		// 	'style',
		// 	'subscript',
		// 	'superscript',
		// 	'textPartLanguage',
		// 	'todoList',
		// ]
	},
	language: 'en',
	image: {
		toolbar: [
			'imageTextAlternative',
			'toggleImageCaption',
			'imageStyle:inline',
			'imageStyle:block',
			'imageStyle:side',
			'linkImage'
		]
	},
	elementAddAttributes: {
		enabled: true, // false will disable the button on the UI
	},

	table: {
		contentToolbar: [
			'tableColumn',
			'tableRow',
			'mergeTableCells',
			'tableCellProperties',
			'tableProperties'
		]
	},
	heading: {
		options: [
			{ model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
			{ model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
			{ model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
			{ model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
			{ model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
			{ model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
			{ model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
		]
	},
	htmlEmbed: {
		showPreviews: true,
	},	
	htmlSupport: {
		allow: [
			{
				name: /.*/,
				attributes: true,
				classes: true,
				tag: true,
			},
		]
	},
	
	fontFamily: {
		options: [
			'default',
			'NunitoSans-Light',
			'NunitoSans-Regular',
			'NunitoSans-SemiBold',
			'NunitoSans-Bold',
			'Arial, Helvetica, sans-serif',
			'Calibri, sans-serif',
			'Cambria, Cochin, Georgia, Times, "Times New Roman", serif',
			'Candara, sans-serif',
			'Century Gothic, sans-serif',
			'Comic Sans MS, cursive, sans-serif',
			'Courier New, Courier, monospace',
			'Garamond, serif',
			'Georgia, serif',
			'Helvetica, sans-serif',
			'Impact, Charcoal, sans-serif',
			'Lucida Console, Monaco, monospace',
			'Lucida Sans Unicode, Lucida Grande, sans-serif',
			'Palatino Linotype, Book Antiqua, Palatino, serif',
			'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
			'Tahoma, Geneva, sans-serif',
			'Times New Roman, Times, serif',
			'Trebuchet MS, sans-serif',
			'Verdana, Geneva, sans-serif'
		],
		supportAllValues: true
	},
	fontSize: {
		supportAllValues: true,
		options: [
			10,
			12,
			'default',
			14,
			16,
			18,
			20,
			22,
			24,
			26,
			28,
			30,
			32,
			34,
			36
		]
	},
};


const CKeditor = (props) => {
	const { data, onChange } = props;	
	console.log("props", props)
    const [y, setY] = useState(0);
    const handleNavigation = (e) => {
        const window = e.currentTarget;
        var scrollheightvar = document.getElementById("toolbar") && document.getElementById("toolbar").scrollHeight ? document.getElementById("myarticleRef").scrollHeight : 0
        var bottom = scrollheightvar - window.scrollY;          
        setY(window.scrollY); 
		console.log("window.scrollY",window.scrollY)     
         if (y > 680 && bottom > 550) {
            document.getElementById("toolbar").className = 'fixedtop';
        }
        else {
            if(document.getElementById("toolbar") && document.getElementById("toolbar").classList) 
				document.getElementById("toolbar").classList.remove('fixedtop')
        }
        setY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", (e) => handleNavigation(e));

        return () => { // return a cleanup function to unregister our function since its gonna run multiple times
            window.removeEventListener("scroll", (e) => handleNavigation(e));
        };
    }, [y]);

		return (<div className="page-content">
			<CKEditor
				editor={Editor}
				data={data}
				config={editorConfiguration}
				onChange={onChange}
			/>
		</div>
		);
	};

export default CKeditor;