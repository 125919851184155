import React from 'react';
import { withTranslation } from 'react-i18next';
import Listing from 'Components/Common/Views/Listing';
import { channelCreateRequest, channelDelete, channelGetRequest, channelListGetRequest, channelMetadataGetRequest } from 'store/channels/action';


const Channels = (props: any) => {
    const { viewId, isCreateModal, setCreateOpen } = props;
    return (
        <React.Fragment>
            <Listing
                viewId={viewId}
                isCreateModal={isCreateModal}
                setCreateOpen={setCreateOpen}
                getListAction={channelListGetRequest}
                deleteAction={channelDelete}
                detailsGetAction={channelGetRequest}
                metaDatagetRequest={channelMetadataGetRequest}
                createAction={channelCreateRequest}
                model={"channels"}
                reducerState={"channels"}
                translater={"channel"}
            />
        </React.Fragment>
    );
};
export default withTranslation()(Channels);


