import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Label,
    Input,
    Col,
    Card,
    CardBody,
    Button,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
    UncontrolledAccordion,
} from "reactstrap";
import { useSelector } from "react-redux";
import PreviewInfo from './PreviewInfo';
import UserAvatar from "../UserAvatarpreview";
import { withTranslation } from "react-i18next";
import { createSelector } from "@reduxjs/toolkit";
interface PreviewModalProps {
    show?: boolean;
    onCloseClick: () => void;
    record: {};
    props: any;
    reducerState: any;
    fieldsMetaData: any;
    translater: any;
    previewAccordianItems: any,
    model: any,    
    studentId: any,
    applicationId: any,
    userProfile: any,
    t: any
}
interface ReducerState {
    [key: string]: any;
}

const PreviewModal: React.FC<PreviewModalProps> = ({ show, onCloseClick, record, props, reducerState, fieldsMetaData, translater, previewAccordianItems, model, studentId, applicationId, userProfile, t }: any) => {
    const createReducerSelector = (
        reducerName: string,
        properties: any
    ) =>
        createSelector(
            (state: ReducerState) => state[reducerName],
            (reducerState) => {
                const result: any = {};
                properties.forEach((property: any) => {
                    result[property] = reducerState[property];
                });
                return result;
            }
        );
    const reducer: any = createReducerSelector(reducerState, ["details"]);
    const derivedState: any = useSelector(reducer);
    const { details } = derivedState;

    const [ info, setInfo ] = useState<any>();


    useEffect(() => {
        let info = {...details, ...details?.valuesJson};
        setInfo(info)
    }, [details])

    return (<>
        {
            record && record?.id ?
                <Offcanvas
                    direction="end"
                    isOpen={show}
                    id="offcanvasExample"
                    backdrop={'static'}
                    toggle={onCloseClick}
                    className="w-50"
                >
                    <OffcanvasHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={onCloseClick}>
                        <span className="m-0 me-2 text-light">
                            {record?.name}
                        </span>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <Col xxl={12} md={12} lg={12}>
                            <Card id="contact-view-detail">
                                <CardBody className="text-center">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto">
                                            <div className="col-md-auto">
                                                <div className="avatar-md">
                                                    <div className="avatar-title bg-white rounded-circle">
                                                        <UserAvatar img={info && info?.img} firstName={info?.name || info?.course_name || info?.first_name} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="avatar-lg p-0 rounded-circle profile-photo-edit">
                                                <Input
                                                    id="profile-img-file-input"
                                                    type="file"
                                                    className="profile-img-file-input"
                                                />
                                                <Label
                                                    htmlFor="profile-img-file-input"
                                                    className="profile-photo-edit avatar-xxs"
                                                >
                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                        <i className="ri-camera-fill"></i>
                                                    </span>
                                                </Label>
                                            </div>
                                        </div>
                                        <h5 className="mt-4 mb-1">{info?.name || `${info?.first_name ? info?.first_name : ""} ${info?.last_name ? info?.last_name : ""}`}</h5>
                                        <p className="text-muted">{info?.email}</p>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <div>
                            <UncontrolledAccordion toggle={() => { }} defaultOpen={previewAccordianItems?.length ? ['notes'] : ['about']}>
                                <AccordionItem>
                                    <AccordionHeader targetId="about">
                                        <h6 className="fw-bold fs-16">{props.t(`${translater}.details.activities.about_this`)}</h6>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="about">
                                        <PreviewInfo record={info} fieldsMetaData={fieldsMetaData} />
                                    </AccordionBody>
                                </AccordionItem>  
                                {previewAccordianItems && previewAccordianItems.length ?
                                    previewAccordianItems.map((item:any) => {
                                            return <AccordionItem>
                                            <AccordionHeader targetId={item.id}>
                                                <h6 className="fw-bold fs-16">{item.title}</h6>
                                            </AccordionHeader>
                                            <AccordionBody accordionId={item.id}>
                                                <item.component {...{
                                                        ...props.componentProps,
                                                    }}
                                                    record={info}
                                                    model={model}                                                    
                                                    studentId={studentId}
                                                    applicationId={applicationId}
                                                    userProfile={userProfile}
                                                />
                                            </AccordionBody>
                                        </AccordionItem>  
                                    })                                  
                                    : null}                              
                            </UncontrolledAccordion>
                        </div>
                    </OffcanvasBody>
                    <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
                        <Button
                            color="primary"
                            className="btn w-100"
                            onClick={() => onCloseClick()}
                        >
                            {t("common.offcanvas.import_cancel")}
                        </Button>
                    </div>
                </Offcanvas>
                : null
        }
    </>
    );
};

export default withTranslation()(PreviewModal);
