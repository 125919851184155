import { Link, useParams } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';

interface ConnectAccountProps {
    infomessage: string;
}

const ConnectAccount: React.FC<ConnectAccountProps> = ({ infomessage }) => {
    const { id } = useParams();

    return (
        <>
            <div className="text-ceter fw-bold">
                {infomessage}
                <Link to={`/user-preferences/${id}`} className="link-primary fw-bold" target="_blank" rel="noopener noreferrer">
                    &nbsp;{"Click here to add account"}&nbsp;
                    <FeatherIcon icon="external-link" className="icon-xs icon-dual-primary" />
                </Link>
            </div>
        </>
    )
}
export default ConnectAccount;