import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import AsyncCreatableSelect from "react-select/async-creatable";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Input, Label, Modal } from "reactstrap";
import { ApplicationState } from "store";
import Select from "react-select";
import { setResponse } from "store/auth/action";
import { roleListGetRequest } from "store/role/action";
import { userInviteRequest } from "store/user/action";
import { subagentUserListGetRequest, userUpdateRequest } from "store/subagents/action";
import { teamsListGetRequest } from "store/teams/action";

interface InviteUsersProps {
	inviteUser: boolean;
	setInviteUser: (event: boolean) => void;
	setName: (event: any) => void;
	name: any;
}

export interface SuggestionOption {
	readonly value: string;
	readonly label: string;
}

const InviteUser: React.FC<InviteUsersProps> = ({
	inviteUser,
	setInviteUser,
	setName,
	name
}) => {
	const { id } = useParams()
	const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
	const roleList = useSelector((state: ApplicationState) => state.role.list);
	const teamList = useSelector((state: ApplicationState) => state.teams.teams);
	const [emails, setEmails] = useState<any[]>([]);
	const [enabled, setEnabled] = useState<any>(false)
	const [selectedRole, setSelectedRole] = useState("");
	  const [teams, setTeams] = useState<any>([]);
	  const [selectedTeams, setSelectedTeams] = useState<any>([]);

	useEffect(() => {
		let filters = { pageSize: 100 }
		dispatch(roleListGetRequest(filters));
		dispatch(teamsListGetRequest());
	}, []);

	useEffect(() => {
		setEnabled(emails.length)
	}, [emails])

	useEffect(() => {
		roleList && roleList.filter((item: any) => item.title !== "Owner").map((item: any) => {
			if (item.title == 'Sub Agent') {
				setSelectedRole(item.id);
			}
		});
	}, [roleList]);
	
	useEffect(() => {
		const teams = teamList?.map(team => {
			return {
				label: team?.name,
				value: team?.id
			}
		})
		setTeams(teams);

	}, [teamList])

	const handleSelectChange = (newValue: any, actionMeta: any) => {
		setEmails(newValue);
	};

	const onSendInvite = (): void => {
		setEnabled(false)
		const emailArray = emails.map(item => item.value).join(',');
		const emailList = JSON.stringify(emailArray);
		const data = {
			"attributes": [
				{
					"key": "email",
					"value": emailList.slice(1, -1)
				},
				{
					"key": "role_Id",
					"value": selectedRole
				},
				{
					"key": "name",
					"value": name
				},
				{
				  "key": "teams",
				  "value": selectedTeams?.map((team: any) => team.value).join(",") || ""
				}
			]
		};
		const handleSuccess = (body: any): void => {
			setInviteUser(false);
			setName("")
			dispatch(setResponse(body));
			const data = {
				"attributes": [
					{
						"key": "subagent",
						"value": id
					}
				]
			};
			const handleupdateSuccess = (body: any) => {
				dispatch(subagentUserListGetRequest(id));
			}
			const handleupdateFailure = (error: any) => {
			}
			dispatch(userUpdateRequest(body.status, data, handleupdateSuccess, handleupdateFailure))
		};
		const handleError = (body: any): void => {
			const message = () => toast(`${body?.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
			message();
		};
		dispatch(userInviteRequest(data, handleSuccess, handleError));
	};

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			color: 'auto',
			fontWeight: 900,
			border: state.isFocused ? '0.5px solid #2A5F8D' : 'auto',
			borderRadius: 8,
			// backgroundColor: 'white',
			boxShadow: 'none',
			'&:hover': {
				border: '0.5px solid #2A5F8D',
			},
		}),

		multiValue: (styles: any) => {
			return {
				...styles,
				backgroundColor: "#2A5F8D",
			};
		},
		multiValueLabel: (styles: any) => ({
			...styles,
			color: "white",
		}),
		multiValueRemove: (styles: any) => ({
			...styles,
			color: "white",
		}),
	}

	const filterSuggestions = (inputValue: string) => {
		return emails.filter((i) =>
			i.label.toLowerCase().includes(inputValue.toLowerCase())
		);
	};

	const promiseOptions = (inputValue: string) =>
		new Promise<SuggestionOption[]>((resolve) => {
			setTimeout(() => {
				resolve(filterSuggestions(inputValue));
			}, 1000);
		});
	return (
		<>
			<Modal
				id="loginModals"
				tabIndex={1}
				isOpen={inviteUser}
				centered
			>
				<Card className="mb-0 card-light">
					<CardBody>
						<div className="bg-light">
							<h5>Invite User</h5>
						</div>
						<hr />
						<div className="vstack align-items-center">
							<div className="w-100">
								<div className="mb-2">
									<Label htmlFor="exampleInputrounded" className="form-label">
										Name
									</Label>
									<Input type="text" className="form-control" id="fullname" placeholder={"Enter invitee name"}
										name="fullname"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</div>
							</div>
							<div className="w-100">
								<div className="mb-2">
									<Label htmlFor="exampleInputrounded" className="form-label">
										Invitee Email
									</Label>
									<div className="w-100 select-element">
										<AsyncCreatableSelect
											onChange={handleSelectChange}
											styles={customStyles}
											isMulti={true}
											isClearable={true}
											options={emails}
											loadOptions={promiseOptions}
											placeholder="Enter invitee email"
										/>
									</div>
								</div>
							</div>
							<div className="d-flex flex-column gap-1">
								<Label htmlFor="exampleInputrounded" className="form-label">
									Assign Teams
								</Label>
								<div className="w-100 select-element">
									<Select
										isMulti
										placeholder="Select Teams"
										value={selectedTeams}
										onChange={setSelectedTeams}
										options={teams}
										classNamePrefix="js-example-data-array"
										isLoading={false}
									/>
								</div>
							</div>
						</div>
					</CardBody>
					<div className="card-footer">
						<div className="d-flex justify-content-between">
							<div>
								<Button
									className="btn-sm"
									color="danger"
									outline
									onClick={() => {
										setInviteUser(false);
									}}
								>
									Cancel
								</Button>
							</div>
							<div>
								<Button
									className="btn-sm"
									color="success"
									onClick={onSendInvite}
									disabled={!enabled}
								>
									Send Invite
								</Button>
							</div>
						</div>
					</div>
				</Card>
			</Modal>
		</>
	);
};

export default InviteUser;
