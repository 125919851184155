import { toast } from "react-toastify";
import { Dispatch } from "redux";
import isEqual from 'lodash/isEqual';
import config from "./config";
import { viewListGetRequest } from "store/views/action";
const { api } = config;
const DTS_API_STUDENT = api.DTS_API_STUDENT


export const levelhandle = (levelvalue: any) => {
	let level = ""
	switch (levelvalue) {
		case 1: level = 'Undergraduate';
			break
		case 2: level = 'Postgraduate';
			break
		case 3: level = 'Foundation';
			break
		case 4: level = 'Research';
			break
		default: level = 'NA';
			break
	}
	return level;
}

const errorGlobal: any = {
	500: {
		"message": "Server error, please try again later",
		"success": false
	},
	502: {
		"message": "Server error, please try again later",
		"success": false
	},
	400: {
		"message": "Invalid request, please check your input",
		"success": false
	},
	401: {
		"message": "You need to log in to access this page",
		"success": false
	},
	403: {
		"message": "Access denied, you don't have permission",
		"success": false
	},
	408: {
		"message": "Request timed out, please try again",
		"success": false
	},
	503: {
		"message": "Server is currently unavailable",
		"success": false
	},
	504: {
		"message": "Server timed out, please try again",
		"success": false
	},
	201: {
		"message": "Created Successfully",
		"success": true
	},
	200: {
		"message": "Request Successful",
		"success": true
	},
	204: {
		"message": "Deleted Successfully",
		"success": true
	},
	208: {
		"message": "You are trying to add duplicate Entry",
		"success": false
	}
}

export const responseHandler = async (
	response: Response,
	dispatch: Dispatch,
	typeSuccess: string,
	typeError: string,
	error: any = null,
	handleSuccess?: any,
	handleError?: any,
	method?: string,
	hideToster: boolean = false
): Promise<void> => {
	if (response?.status) {
		const httpResponse: any =
			error && error[response.status]
				? error[response.status]
				: errorGlobal[response.status];

		if (httpResponse?.success) {
			try {
				const contentType = response.headers.get("content-type") || "";
				let result: any;
				if (contentType.includes("application/octet-stream")) {
					// Get the binary data as a Blob
					const blob = await response.blob();
					// Convert the Blob into an object URL (a string)
					result = { url: URL.createObjectURL(blob) };
				} else {
					result = await response.json();
				}
				dispatch({
					type: typeSuccess,
					payload: result,
				});
				handleSuccess && handleSuccess(result);
			} catch (err) {
				dispatch({
					type: typeSuccess,
					payload: null,
				});
				handleSuccess && handleSuccess(null);
			}
		} else {
			dispatch({
				type: typeError,
				payload:
					httpResponse?.message ||
					"An unexpected error occurred, please try again later",
			});
			handleError && handleError();
		}
		const bgColor = httpResponse?.success ? "bg-success" : "bg-danger";
		if ((!method || method !== "GET") && !hideToster) {
			toast(
				`${httpResponse?.message ||
				"An unexpected error occurred, please try again later"
				}`,
				{
					position: "top-center",
					hideProgressBar: true,
					className: `${bgColor} text-white`,
				}
			);
		}
	} else {
		dispatch({
			type: typeError,
			payload: "An unexpected error occurred, please try again later",
		});
		handleError && handleError();
		if (!method || method !== "GET") {
			toast("An unexpected error occurred, please try again later", {
				position: "top-center",
				hideProgressBar: true,
				className: "bg-danger text-white",
			});
		}
	}
};

export const isJson = (str: any) => {
	try {
		let options = JSON.parse(str);
		return options
	} catch (e) {
		//Error
		//JSON is not okay
		return false;
	}
}

export const evaluateConditionalLogic = (conditional: any, formValues: any, rest?: any) => {
	let isPresent = null;
	if (conditional?.when && conditional?.condition && conditional?.values && conditional?.property) {
		const values = conditional?.values.map((item: any) => item.value);
		switch (conditional?.condition) {
			case 'EQ':
			case 'contains_exactly':
			case 'IN':
				isPresent = values?.some((item: any) => formValues?.[conditional?.when] === item);
				if (isPresent) return conditional.show
				break
			case 'NEQ':
			case 'is_none_of':
				isPresent = formValues?.[conditional?.when] ? values.length && values?.some((item: any) => formValues?.[conditional?.when] === item) : true;
				if (!isPresent) return conditional.show
				break
			case 'LIKE':
			case 'contains':
				isPresent = formValues?.[conditional?.when] && values.length && values?.some((item: any) => formValues?.[conditional?.when] && formValues?.[conditional?.when]?.some((formValue: any) => formValues?.[conditional?.when] === formValue));
				if (isPresent) return conditional?.show
				break
			case 'does_not_contain':
				isPresent = values?.some((item: any) => formValues?.[conditional?.when] && formValues?.[conditional?.when].some((formValue: any) => formValues?.[conditional?.when] === formValue));
				if (!isPresent) return conditional?.show
				break
			case 'is_empty':
				isPresent = !formValues?.[conditional?.when];
				if (isPresent) return conditional?.show
				break
			case 'is_not_empty':
				isPresent = formValues?.[conditional?.when];
				if (isPresent) return conditional?.show
				break
			case 'BETWEEN':
				if (formValues?.[conditional?.when]) {
					isPresent = formValues?.[conditional?.when] >= parseFloat(conditional?.value) && formValues?.[conditional?.when] <= parseFloat(conditional?.highValue);
				}
				if (isPresent && conditional?.show) return isPresent
		}
	}
};


export const createQueryLink = (filterObject: any, page?: any) => {
	const keys = Object.keys(filterObject);
	const values = Object.values(filterObject);
	let query = `${DTS_API_STUDENT}/properties?`;
	if (page) query += `page=${page}&`;
	for (let i = 0; i < keys.length; i++) {
		if (values[i]) query += `${keys[i]}=${values[i]}&`;
	}
	return query;
};


// Select value handler
export const handleSelectValue = (value: any, options: any) => {
	if (options && options.length && typeof value === 'string') {
		const foundItem = options.find((item: any) => item.value?.toLowerCase().replace(/\s+/g, ' ').trim() === value?.toLowerCase().replace(/\s+/g, ' ').trim());
		if (foundItem) {
			return foundItem.label;
		}
	}
	else if (options && options.length && value) {
		const foundItem = options.find((item: any) => item.value === value);
		if (foundItem) {
			return foundItem.label;
		}
	}
	return null; // or any default value you prefer
}


export const checkTheyOwn = (obj: any, id: any) => {
	if (obj && typeof obj === 'object' && Object.values(obj).includes(id)) {
		return true
	} else if (obj && typeof obj === 'object') {
		for (const key in obj) {
			if (typeof obj?.[key] === 'object') {
				let arr = obj?.[key] ? Object.values(obj?.[key]) : [];
				let istrue = arr.some((value: any) => (typeof value != 'object' && value === id))
				if (istrue) return true
				if (checkTheyOwn(obj[key], id)) return true;
			} else if (checkTheyOwn(obj[key], id)) return true;
		}
	} else if (obj && obj === id) {
		return true
	}
	else return false
}

export const convertCurrency = (
	amount: number | string | undefined | null,
	fromCurrency: string,
	toCurrency: string,
	parsedRates: any
): number | null => {
	try {
		if (amount == null) {
			return null; // Return a fallback value instead of throwing an error
		}
		const numericAmount = typeof amount === 'number' ? amount : Number(amount);
		if (isNaN(numericAmount)) {
			return null;
		}
		if (fromCurrency === toCurrency) {
			return numericAmount;
		}
		const fromRate = parsedRates[fromCurrency];
		const toRate = parsedRates[toCurrency];
		if (!fromRate || !toRate) {
			return null;
		}
		// Perform conversion
		const amountInBaseCurrency = numericAmount / fromRate;
		return amountInBaseCurrency * toRate;
	} catch (error) {
		return null; // Return a fallback value to prevent the page from breaking
	}
};

// objectUtils.ts
export interface ObjectDetails {
	length: number;
	emptyCount: number;
	nullCount: number;
	undefinedCount: number;
}

export const calculateObjectDetails = (data: Record<string, any>): ObjectDetails => {
	let length = Object.keys(data).length;
	let emptyCount = 0;
	let nullCount = 0;
	let undefinedCount = 0;

	for (const key in data) {
		const value = data[key];

		if (value === null) {
			nullCount += 1;
			undefinedCount += 1; // In JavaScript, null and undefined are often treated similarly
		} else if (value === undefined) {
			undefinedCount += 1;
		} else if (
			value === "" || // Empty string
			(Array.isArray(value) && value.length === 0) || // Empty array
			(typeof value === "object" && Object.keys(value).length === 0) // Empty object
		) {
			emptyCount += 1;
		}
	}

	return {
		length,
		emptyCount,
		nullCount,
		undefinedCount,
	};
};


export const getViewList = (dispatch: any, model: any, location: any) => {
	const controller = new AbortController();
	const signal = controller.signal;

	// Clear timer and cancel request on unmount
	const cleanup = () => {
		controller.abort("New request");
		clearTimeout(timer);
	};

	let parent: any = []
	parent = [{
		"key": "location",
		"keyLabel": 'Location',
		"condition": "IN",
		"conditionLabel": "is any of",
		"values": [location],
		"valuesLabel": [],
		"property": [],
		"quick": true,
	}
	]

	// Main logic wrapped in a timer
	const timer = setTimeout(() => {
		const filtersData: any = {
			filters: [{
				"quick": [],
				"advance": [],
				"search": {
					"term": model,
					"field": "model"
				}
			}],
			sorts: [
				{
					"field": 'createdAt',
					"order": 'desc'
				}
			],

		}
		if (parent?.length > 0) {
			filtersData.filters[0].parent = parent
		}
		dispatch(viewListGetRequest(model, filtersData, 0, 500, signal));
	}, 600);
	// Cleanup on unmount or effect rerun
	return cleanup;
}


// Table Data methods 
// Decide updating list
export const isArrayEqual = (list: any, list2: any) => {
	let a = isEqual(list, list2)
	return !a
}

