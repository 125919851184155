import {TeamActionTypes} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { responseHandler } from "utils";

const { api } = config;

const DTS_API_DOMAIN = api.DTS_API_DOMAIN

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;


export const teamCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: TeamActionTypes.POST_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/teams`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: TeamActionTypes.POST_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: TeamActionTypes.POST_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const teamGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: TeamActionTypes.GET_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_DOMAIN}/teams/${id}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: TeamActionTypes.GET_ERROR,
                    payload: body
                });
                handleError && handleError(body);
            } else {
                dispatch({
                    type: TeamActionTypes.GET_SUCCESS,
                    payload: body
                });
                handleSuccess && handleSuccess(body);
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle parsing error here
        }
    };
};

export const teamsListGetRequest: AppThunk = (queryParams, page, pageSize, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: TeamActionTypes.GET_ALL_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const response = await fetch(`${DTS_API_DOMAIN}/teams?${queryParams}`, {
            method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: TeamActionTypes.GET_ALL_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: TeamActionTypes.GET_ALL_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const teamUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: TeamActionTypes.PUT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/teams/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );  
        const responseData: any = await response.json()
        if(responseData?.status?.includes("[AUTHENTICATIONFAILED]"))   {
        }                         
        const error = {
            204: {
                "message": "Team updated Successfully",
                "success": true
            },
            200: {
                "message": "Team updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, TeamActionTypes.PUT_SUCCESS, TeamActionTypes.PUT_ERROR, error, handleSuccess, handleError, responseData);
    
    };
};

export const teamDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();

        dispatch({
            type: TeamActionTypes.DELETE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_DOMAIN}/teams/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const error = {
                204: {
                    "message": "Team deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "Team deleted Successfully",
                    "success": true
                }
            }  
            await responseHandler(response, dispatch, TeamActionTypes.DELETE_SUCCESS, TeamActionTypes.DELETE_ERROR, error, handleSuccess, handleError);
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: TeamActionTypes.DELETE_ERROR
            });
            handleSuccess();
        }
    };
};