import React, { Fragment, useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Table, Row, Col, Button, CardBody, Input, Label, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";


interface TableContainerProps {
    columns?: any;
    tableClass?: any;
    theadClass?: any;
    thClass?: any;
    rows?: any;
    cols?: any;
    btn_text?: any;
}

const FromLoader = ({
    rows,
    cols,
    btn_text
}: TableContainerProps) => {
    let innitialrows= rows || 2;
    let placeholdercolumn = cols || 2;
    return (
        <div>
            <div className="vstack gap-3">
                {[...Array(innitialrows)].map((_, index) => {
                            return <div className="hstack gap-2 w-100">
                            {[...Array(placeholdercolumn)].map((_, index) => {
                                return <div key={index} className="placeholder-glow col-6 gap-5">
                                    <span className="placeholder placeholder-lg col-6"></span>
                                    <span className="placeholder placeholder-lg col-6"></span>
                                </div>
                            })}</div>}
                        )}
            </div>
            {btn_text ?
            <div className="hstack justify-content-center mt-4 placeholder-glow">
                <Link to="#"  tabIndex={1} className="btn btn-primary btn-sm disabled placeholder placeholder-sm col-2 opacity-25"></Link>
            </div> 
            : null} 
        </div>
    );
};

export default withTranslation()(FromLoader);