import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import InvoicesListing from './Invoices';
import { withTranslation } from 'react-i18next';

const Invoices = ({t}: any) => {
	document.title = "Create Invoice | DTS";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                           
                    <BreadCrumb title={t("invoices.listing.breadcrumb.invoices")} pageTitle={t("invoices.listing.breadcrumb.invoices")}    icon={"ri-contacts-book-line"}/>
                    <Row>
                        <InvoicesListing />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Invoices);