import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import conditionsJson from "./conditionsJson.json";
import datesJson from "./datesJson.json";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { getOptions, optionsListGetRequest } from "store/auth/action";
import { ApplicationState } from "store";
import SelectDropdown from "../SelectDropdown/SelectDropdown";
import SelectDateDropdown from "../SelectDateDropdown/SelectDateDropdown";
import { Button, Badge } from "reactstrap";
import Filter from "./Filter";
import SelectTree from "../SelectTree/SelectTree";
import SelectRange from "../SelectRange/SelectRange";
import { getStatusOptions } from "helpers/workflowStatusHelper";
import { isJson } from "utils";

// Define the type for the options used in the Select component
interface Option {
    label: string;
    value: string;
    disabled?: boolean;
}

// Define types for Property and Filter
interface PropertyProps {
    key: string;
    label: string;
    type?: string;
    value: string;
    quick?: boolean;
    valueJson?: any;
}

interface FilterProps {
    key: string;
    keyLabel: string;
    condition: string;
    conditionLabel: string;
    values: string[];
    valuesLabel: any;
    property: PropertyProps;
    value?: number | string | null | undefined;
    highValue?: number | string | null | undefined;
    valueOption?: Option;
    quick?: boolean;
    parent?: any;
}

// Interface for TransformedNode
interface TransformedNode {
    label: any;
    value: any;
    createdBy: any;
    createdAt: any;
    updatedAt: any;
    deletedAt: any;
    parentId: any;
    parent: any;
    children?: TransformedNode[]; // Mark children as optional
}


const QuickFilter = ({ dataFields, columns, onFilter, filter, clearFilterParent, t, parentField, parentId}: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const optionsObj = useSelector((state: ApplicationState) => state.auth.optionData);
    const optionsLoading = useSelector((state: ApplicationState) => state.auth.optionsLoading);

    // State Variables
    const [properties, setProperties] = useState<PropertyProps[]>([]);
    const [groups, setGroups] = useState<any[]>([]);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const [selectedProperty, setSelectedProperty] = useState<PropertyProps | null>(null);
    const [options, setOptions] = useState<any[]>([{

    }]);
    const [filters, setFilters] = useState<FilterProps[]>([]);
    const [toggleAdvanceFilter, setToggleAdvanceFilter] = useState<boolean>(false);
    const [filtersCount, setFiltersCount] = useState<any>(0);
    const prevFilterRef: any = useRef();

 
    useEffect(() => {
        // Only update if filterGroups has changed
        if (JSON.stringify({filters, groups}) !== JSON.stringify(prevFilterRef.current)) {
  
            // Store a deep copy to avoid mutating references
            prevFilterRef.current = cloneDeep({filters, groups});
            let quickCount: any = filters.filter((item: any) => (!item.parent))
            let filtersCount = quickCount?.length || 0
            groups.forEach((group: any) => {
                filtersCount += group.filters.length
            })
            onFilter(filters, groups)
            setFiltersCount(filtersCount)
        }
    }, [filters, groups])

    // Update parent component when filter groups change
    useEffect(() => {
      // Only update if filterGroups has changed
      if (filter) {
        setFilters(filter?.quick);
        setGroups(filter?.advance);
      }
    }, [filter]);

    // Map data fields to properties on component load
    useEffect(() => {
        const mappedProperties: PropertyProps[] = dataFields
            ?.map((item: any) => {
                const valueJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson): item.valuesJson;
                if (valueJson?.filter && ['datasetselect', 'select', 'number', 'datetime', 'checkbox', 'selectboxes', 'treeselect'].includes(valueJson?.type)) {
                    return {
                        label: valueJson?.label,
                        value: valueJson?.key,
                        type: valueJson?.type || "datetime",
                        key: valueJson?.key,
                        quick: valueJson?.filter,
                        valueJson: valueJson || {},
                    };
                }
                return null;
            })
            .filter(Boolean) as PropertyProps[];
        setProperties(mappedProperties);
    }, [dataFields]);


    const handletypetext = (value: any) => {        
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            let filter ={
                "filters": [
                {
                    "search": {
                        "term": value,
                        "field": "all"
                    }
                }
                ],
                "sorts": [
                {
                    "field": "name",
                    "order": "asc"
                }
                ]
            }
            let filterObject=null
            if(selectedProperty?.valueJson?.model == 'sources' || selectedProperty?.valueJson?.responseType == 'treeselect') filterObject= {responseType:'treeselect'}
            dispatch(optionsListGetRequest(selectedProperty?.valueJson?.model, filter, 0, 20, filterObject)); 	
     
        }, 2000);
        setTypingTimeout(newTimeout);
    };

    // Update options based on selected property and options data from Redux
    useEffect(() => {
        if (selectedProperty && optionsObj && optionsObj?.[selectedProperty?.valueJson?.model]?.length) {
            if(selectedProperty.type === 'treeselect') {
                const createdNodes: TransformedNode[] = optionsObj?.[selectedProperty?.valueJson?.model].map(transformTree);
                setOptions(createdNodes);
            } else {
                const newOptions = optionsObj?.[selectedProperty?.valueJson?.model].map((option: any) => {
                    const optionsJson = option?.valuesJson &&  isJson(option?.valuesJson) ? JSON.parse(option.valuesJson): option.valuesJson;
                    let label = selectedProperty?.valueJson?.optionLabel ? selectedProperty?.valueJson?.optionLabel : "name";         
                    let labelvalue = selectedProperty?.valueJson?.model == "students" && selectedProperty?.valueJson?.optionLabel == "name" ? optionsJson?.[label] || (`${optionsJson?.["first_name"] || ""} ${optionsJson?.["last_name"] || ""}`) : optionsJson?.[label]
                    return { label: labelvalue, value: option.id, valuesJson: optionsJson, in_forms: true };
             });
                setOptions(newOptions);
            }
        }
    }, [optionsObj?.[selectedProperty?.valueJson?.model], selectedProperty]);

    // Function to find multiple nodes by a list of ids
    const transformTree = (node: any): TransformedNode => {
        // Parse the valuesJson field to extract the name
        const values = node?.valuesJson && isJson(node?.valuesJson) ? JSON.parse(node?.valuesJson): node?.valuesJson;
      
        // Create the base transformed node
        const transformedNode: TransformedNode = {
          label: values?.name,
          value: node?.id,
          createdBy: node?.createdBy,
          createdAt: node?.createdAt,
          updatedAt: node?.updatedAt,
          deletedAt: node?.deletedAt,
          parentId: node?.parentId,
          parent: node?.parent,
        };
      
        // Recursively transform children if they exist and are not empty
        if (node?.children && node?.children?.length > 0) {
          transformedNode.children = node?.children.map(transformTree);
        }
      
        return transformedNode;
    }

    // Clear all filter selections
    const clearFilter = () => {
        setSelectedProperty(null);
        setGroups([]);
        setFilters([])
        clearFilterParent && clearFilterParent()
    };

    // Handle property selection
    const onSelectProperty = (property: PropertyProps) => {
        if (property) {
            setSelectedProperty(property);
        } else {
            clearFilter();
        }
    };

    // Handle value selection for a filter
    const onSelectValue = (property: PropertyProps, value: PropertyProps[], quick: boolean = false) => {
        const conditionOptions = conditionsJson[property.type as keyof typeof conditionsJson];
        
        if (value.length === 0) {
            // Remove filter if value is empty
            const updatedFilters = filters.filter((filter) => filter.key !== property.key);
            setFilters(updatedFilters);
        } else {
            // Update or add filter
            const updatedFilters = filters.map((filter) => {
                if (property.key === filter.key) {
                    return {
                        ...filter,
                        values: value.map((item) => item.value),
                        valuesLabel: value,
                    };
                }
                return filter;
            });
    
            // Add new filter if not already present
            if (!updatedFilters.some((filter) => property.key === filter.key)) {
                updatedFilters.push({
                    key: property.key,
                    keyLabel: property.label,
                    condition: conditionOptions[0].value,
                    conditionLabel: conditionOptions[0].label,
                    values: value.map((item) => item.value),
                    valuesLabel: value,
                    property: property,
                    quick: quick,
                });
            }
    
            setFilters(updatedFilters);
        }
    };


    // Handle value selection for a filter
    const onSelectValueHighValue = (property: PropertyProps, value: any, highValue: any, valueOption: any, quick: boolean = false) => {
        const conditionOptions = conditionsJson[property.type as keyof typeof conditionsJson];
        
        if (value !== 0 && value !== '0' && !value) {
            // Remove filter if value is empty
            const updatedFilters = filters.filter((filter) => filter.key !== property.key);
            setFilters(updatedFilters);
        } else {
            // Update or add filter
            const updatedFilters = filters.map((filter) => {
                if (property.key === filter.key) {
                    return {
                        ...filter,
                        values: [],
                        valuesLabel: [],
                        value,
                        highValue,
                        valueOption
                    };
                }
                return filter;
            });
    
            // Add new filter if not already present
            if (!updatedFilters.some((filter) => property.key === filter.key)) {
                updatedFilters.push({
                    key: property.key,
                    keyLabel: property.label,
                    condition: conditionOptions[0].value,
                    conditionLabel: conditionOptions[0].label,
                    values: [],
                    valuesLabel: [],
                    value,
                    highValue,
                    property: property,
                    quick: quick,
                    valueOption
                });
            }
    
            setFilters(updatedFilters);
        }
    }

    // Clear a specific filter
    const onSelectValueClear = (property: PropertyProps) => {
        setFilters(filters.filter((filter) => filter.key !== property.key));
    };

    // Dispatch option fetch action if a model is associated with the selected property
    const onFocus = (property: PropertyProps) => {
        setOptions([])
        onSelectProperty(property);
        if (property?.valueJson?.model) {
            setOptions([])
            if (property?.valueJson?.model) {
                let filter ={
                    "filters": [
                      {
                        "quick": [],
                        "advance": [],
                        "search": null
                      }
                    ],
                    "sorts": [
                      {
                        "field": "name",
                        "order": "asc"
                      }
                    ]
                }
                let filterObject=null
                if(property?.valueJson?.model == 'sources' || selectedProperty?.valueJson?.responseType == 'treeselect') filterObject= {responseType:'treeselect'}
                dispatch(optionsListGetRequest(property?.valueJson?.model, filter, 0, 20, filterObject)); 	
            }
        }
    };

    // Render input field based on property type
    const renderValueInput = (property: PropertyProps) => {
        const filter = filters.find((filter) => property.key === filter.key);
        switch (property?.type) {
            case 'datasetselect':
                return (
                    <SelectDropdown
                        title="Select"
                        multi={true}
                        placeholder={property?.label}
                        property={property}
                        onFocus={() => onFocus(property)}
                        values={filter?.valuesLabel}
                        handleSearch={(value: any) => handletypetext(value)}
                        onChange={(newValues: PropertyProps[]) => onSelectValue(property, newValues, true)}
                        onClearFilter={() => onSelectValueClear(property)}                   
                        isSearchable
                        optionsLoading={optionsLoading}
                        options={optionsLoading ? [{ label: 'Loading...', value: null }] : options || []}
                    />
                );
            case 'select':
            case 'checkbox':
            case 'selectboxes':
                let valueOptions = property?.valueJson?.values || []
                let group = false;
                if(property?.valueJson?.process_flows?.length) {
                    const {options: groupOptions, group: groupStatus} = getStatusOptions(property?.valueJson?.process_flows)
                    valueOptions = groupOptions;
                    group = groupStatus;
                }
                return (
                    <SelectDropdown
                        title="Select"
                        multi={true}
                        options={valueOptions}
                        placeholder={property?.label}
                        property={property}
                        values={filter?.valuesLabel}
                        onChange={(newValues: PropertyProps[]) => onSelectValue(property, newValues, true)}
                        onClearFilter={() => onSelectValueClear(property)}
                        isSearchable
                        group={group}
                    />
                );
            case 'datetime':
                return (
                    <SelectDateDropdown
                        title="Select"
                        options={datesJson}
                        placeholder={property?.label}
                        property={property}
                        value={filter?.valueOption}
                        onChange={(value: any, highValue: any, valueOption: any) => onSelectValueHighValue(property, value, highValue, valueOption, true)}
                        onClearFilter={() => onSelectValueClear(property)}
                        isSearchable
                    />
                );
            case 'treeselect':
                return (
                    <SelectTree
                        placeholder={property?.label}
                        setValues={(values: any) => onSelectValue(property, values, true)}
                        onFocus={() => onFocus(property)}
                        values={filter?.valuesLabel}
                        showValues={false}
                        optionsLoading={optionsLoading}
                        options={optionsLoading ? [{ label: 'Loading...', value: null }] : options || []}
                    />
                );
            case 'number':
                return (
                    <SelectRange
                        placeholder={property?.label}
                        onChange={(value: any, highValue: any) => onSelectValueHighValue(property, value, highValue, null, true)}
                        value={filter?.value}
                        highValue={filter?.highValue}
                    />
                );
            default:
                return null;
        }
    };
    return (
        <div className="d-flex flex-row justify-content-between gap-2 p-3 pb-0">
            {/* Filter Offcanvas component */}
            <Filter
                show={toggleAdvanceFilter}
                onCloseClick={() => setToggleAdvanceFilter(false)}
                dataFields={columns}
                updateGroups={setGroups}
                quickFilters={filters && filters.length ? filters.filter((item: any) => (!item?.parent)): []}
                groups={groups}
            />
            {/* Render input fields for quick filters */}
            <div className="d-flex flex-wrap gap-2">
                {properties.map((property) => renderValueInput(property))}
            </div>
            {/* Action buttons */}
            <div className="d-flex gap-2 justify-content-end" style={{ width: '360px' }}>
                <div className="hstack align-items-start justify-content-end gap-2 w-auto pe-0">
                    <Button
                        size="sm"
                        color="danger"
                        className="btn-label btn-soft-danger"
                        onClick={clearFilter}
                    >
                        <i className="ri-close-circle-fill label-icon align-middle fs-16 me-2"></i>
                        {t("common.filter.quick.btn_clear_all")}
                    </Button>
                    <Button
                        size="sm"
                        onClick={() => setToggleAdvanceFilter(true)}
                        color="success"
                        className="btn-label btn-soft-success"
                    >
                        <i className="ri-filter-2-fill label-icon align-middle fs-16 me-2"></i>
                        {t("common.filter.quick.btn_advance")}({filtersCount})
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(QuickFilter);
