import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import moment from "moment";
import SimpleBar from "simplebar-react";
import FilePreview from "./FilePreview";

const DocumentViewDrawer = ({ fileInfo, props, show, onClose, onClickFileDownload, setDeleteModal, setFileDetailsOpen }: any) => {
    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={onClose}
            backdrop="static"
            className="w-50"
        >
            <OffcanvasHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={() => { onClose(!show) }}>
                <span className="m-0 me-2 text-white">
                    {props.t("student.modals.add_new_entry")}
                </span>
            </OffcanvasHeader>
            <OffcanvasBody>
                <Card className="border-0" style={{ boxShadow: 'none' }}>
                    <CardBody className="border-0">
                        <div className="live-preview vstack gap-2">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center pb-3 border-bottom border-bottom-dashed mb-3 gap-2">
                                    <h5 className="flex-grow-1 fw-semibold mb-0">File Preview</h5>
                                </div>
                                <div className="pb-3 border-bottom border-bottom-dashed mb-3">
                                    <div className="bg-light p-3 text-center rounded-3 border border-light mb-3">
                                        <div className="display-4 file-icon">
                                            <FilePreview fileInfo={fileInfo} />
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-icon btn-sm btn-ghost-success float-end fs-16"></button>
                                    <h5 className="fs-16 mb-1 file-name">{fileInfo.documentName}</h5>
                                    <p className="text-muted mb-0 fs-12"><span className="file-size">{fileInfo.documentSize}</span>, <span className="create-date">{fileInfo.createDate}</span></p>
                                </div>
                                <div>
                                    <h5 className="fs-12 text-uppercase text-muted mb-3">File Description :</h5>
                                    <div className="table-responsive">
                                        <table className="table table-borderless table-nowrap table-sm">
                                            <tbody>
                                                <tr>
                                                    <th scope="row" style={{ width: "35%" }}>File Name :</th>
                                                    <td className="file-name">{fileInfo.documentName}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">File Type :</th>
                                                    <td className="file-type">{fileInfo.documentExtension}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Size :</th>
                                                    <td className="file-size">{fileInfo.documentSize}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Created :</th>
                                                    <td className="create-date">{moment(fileInfo?.createdAt).format('lll')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="mt-auto border-top border-top-dashed py-3">
                                    <div className="hstack gap-2">
                                        <button type="button" className="btn btn-soft-primary w-100" onClick={() => onClickFileDownload(fileInfo)}><i className="ri-download-2-line align-bottom me-1"></i> {props.t("common.table.download")}</button>
                                        <button type="button" className="btn btn-soft-danger w-100 remove-file-overview" onClick={() => { setDeleteModal(true); setFileDetailsOpen(false) }}><i className="ri-close-fill align-bottom me-1"></i> {props.t("common.table.delete")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </OffcanvasBody>
        </Offcanvas>
    );
};

export default withTranslation()(DocumentViewDrawer);

