import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CardBody, Collapse } from 'reactstrap';

interface Options {
    label: string;
    value: string;
}

export interface Permission {
    id: string;
    name: string;
    type: string;
    description?: string;
    options?: string | Options[];
    value: any;
    permissions?: Permission[];
}

export interface SubItem {
    id: string | number;
    name: string;
    children?: SubItem[];
    permissions: Permission[];
}

export interface MenuItem {
    id: string | number;
    name: string;
    children?: SubItem[];
    permissions: Permission[];
}

interface CreateInputs {
    role: string;
}

interface ReviewProps extends WithTranslation {
    createInputs: CreateInputs;
    accessData: MenuItem[];
    CreatePermission: () => void;
}

/**
 * isSelfAssigned
 * Returns true if the permission itself is assigned.
 */
const isSelfAssigned = (perm: Permission): boolean => {
    return (
        perm.value &&
        perm.value.toString().toLowerCase() !== 'false' &&
        perm.value !== ''
    );
};

/**
 * getDisplayValue
 * Returns a display string for a permission.
 */
const getDisplayValue = (perm: Permission): string => {
    if (perm.type === 'dropdown') {
        let opts: Options[] = [];
        if (typeof perm.options === 'string') {
            try {
                opts = JSON.parse(perm.options);
            } catch (e) {
                opts = [];
            }
        } else {
            opts = perm.options || [];
        }
        const found = opts.find(
            opt =>
                opt.value.toLowerCase() === perm.value.toString().toLowerCase() ||
                opt.label.toLowerCase() === perm.value.toString().toLowerCase()
        );
        return found ? found.label : perm.value.toString();
    } else if (perm.type === 'boolean') {
        return perm.value === true ? 'Enabled' : 'Disabled';
    }
    return perm.value.toString();
};

/**
 * ExpandablePermissionItem Component
 * Recursively renders a single permission.
 */
const ExpandablePermissionItem: React.FC<{ permission: Permission; level?: number }> = ({
    permission,
    level = 0,
}) => {
    const [expanded, setExpanded] = useState(true);

    // Change: Render the permission only if it is directly enabled.
    // if any child was enabled. Now, if the parent itself is not enabled, return null.
    if (!isSelfAssigned(permission)) return null;

    // Determine whether to show child permissions.
    // You might adjust this logic if you want different behavior for children.
    const shouldRenderChildren =
        permission.type !== "boolean" ||
        (permission.type === "boolean" &&
            (permission.value === true || permission.value === "true"));

    return (
        <div style={{ marginLeft: level * 20 }} className="mb-2">
            <div className="card shadow-sm border-0 mb-1">
                <CardBody className="d-flex justify-content-between align-items-center py-2">
                    <div className="d-flex align-items-center">
                        {permission.permissions &&
                            permission.permissions.length > 0 &&
                            shouldRenderChildren && (
                                <span
                                    onClick={() => setExpanded(!expanded)}
                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                >
                                    <i className={`mdi ${expanded ? 'mdi-chevron-down' : 'mdi-chevron-right'} fs-5`} />
                                </span>
                            )}
                        <div>
                            <div className="fw-bold">{permission.name}</div>
                            {permission.description && (
                                <div className="text-muted small">{permission.description}</div>
                            )}
                        </div>
                    </div>
                    <div>
                        <span className="badge bg-primary">{getDisplayValue(permission)}</span>
                    </div>
                </CardBody>
            </div>
            {permission.permissions &&
                permission.permissions.length > 0 &&
                shouldRenderChildren && (
                    <Collapse isOpen={expanded}>
                        {permission.permissions.map(child => (
                            <ExpandablePermissionItem key={child.id} permission={child} level={level + 1} />
                        ))}
                    </Collapse>
                )}
        </div>
    );
};

/**
 * ReviewMenuNode Component
 * Recursively renders a menu (or sub–menu) node along with its assigned permissions.
 */
const ReviewMenuNode: React.FC<{ node: MenuItem | SubItem; level?: number }> = ({
    node,
    level = 0,
}) => {
    return (
        <div style={{ marginLeft: level * 20 }} className="mb-3">
            <div className="fw-bold mb-2">{node.name}</div>
            {node.permissions &&
                node.permissions.map((perm: Permission) => (
                    <ExpandablePermissionItem key={perm.id} permission={perm} level={level + 1} />
                ))}
            {node.children &&
                node.children.map((child: SubItem) => (
                    <ReviewMenuNode key={child.id} node={child} level={level + 1} />
                ))}
        </div>
    );
};

/**
 * Review Component
 * Displays the role name and recursively renders the entire permissions tree.
 */
const Review: React.FC<ReviewProps> = ({ createInputs, accessData, t, CreatePermission }) => {
    return (
        <div className="p-4">
            <div className="text-center mb-4">
                <h3>
                    <span className="badge bg-primary text-white px-3 py-2">
                        {createInputs.role}
                    </span>
                </h3>
            </div>
            <div className="text-center mb-3">
                <h4>{t('roles.review_permissions')}</h4>
            </div>
            {accessData && accessData.length > 0 ? (
                accessData.map(node => <ReviewMenuNode key={node.id} node={node} />)
            ) : (
                <div className="text-center text-muted mb-3">
                    <h5>No permissions assigned to this role.</h5>
                </div>
            )}
        </div>
    );
};

export default withTranslation()(Review);
