import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import html2pdf from 'html2pdf.js';
import config from "../../config";
import {
    Modal,
    ModalHeader,
    ModalBody,
    CardBody,
    Row,
    Col,
    Label,
    Table,
    Card,
} from "reactstrap";
import axios from "axios";
import { ApplicationState } from "store";
import { toast } from "react-toastify";
import DtsSpinners from "Components/Common/DtsSpinners";
import { disputeBulkCreateRequest } from 'store/disputes/action';
import { invoiceCreateRequest } from 'store/invoices/action';
import { paymentCreateRequest } from 'store/payments/action';

const { DTS_API_STUDENT } = config.api;

const PreviewModal: React.FC<any> = ({ show, onCloseClick, formValues, lineItems }: any) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)

    const contentRef = useRef(null);
    // const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    let fileno = formValues?.invoice_number ? formValues?.invoice_number : "";

    const convertToPdf = async (download = true) => {
        let content = contentRef.current;
        if (!content) {
            console.warn("Content element not found, waiting for modal to render...");
            // Wait a short period to allow the modal/content to mount
            await new Promise(resolve => setTimeout(resolve, 100));
            content = contentRef.current;
            if (!content) {
                console.error("Content element still not found after waiting.");
                return null;
            }
        }
        const options = {
            margin: [10, 10, 10, 10],
            filename: `Inv_${fileno}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, scrollY: 0, useCORS: true },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        const pdfInstance = html2pdf().set(options).from(content);
        const pdfBlob = await pdfInstance.toPdf().output("blob");

        if (download) {
            const blobUrl = URL.createObjectURL(pdfBlob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = `Inv_${fileno}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        return pdfBlob;
    };

    const uploadInvoice = async (file: any, isSendInvoice: boolean) => {
        if (file) {
            const formData = new FormData();
            formData.append('documentType', `Inv_${fileno}`);
            formData.append('file', file);
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "token": access_token
                },
            }
            await axios.post(`${DTS_API_STUDENT}/documents`, formData, config)
                .then((res: any) => {
                    if (res.key) {
                        onCreateInvoice(res.key, isSendInvoice);
                    } else {
                        const message = () => toast(isSendInvoice ? `Invoice sent Failed` : `Invoice saved Failed`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
                        message();
                        onCloseClick();
                        setLoading(false);
                    }
                })
        }
    }
    const onCreateInvoice = async (file_url: any, isSendInvoice: boolean) => {
        setLoading(true);
        const items = lineItems?.map((lineItem: any) => ({
            record: lineItem?.id,
            sub_amount: lineItem?.commission,
            tax: lineItem?.taxPercentage,
            tax_type: lineItem?.taxType,
            amount: lineItem?.itemAmount,
        })) ?? [];
        const invoiceData: any = {
            file_url: file_url,
            channel: formValues?.channel_id,
            balance: formValues?.invoice_amount,
            paid: 0,
            total: formValues?.invoice_amount,
            tax: formValues?.totaltax,
            subtotal: formValues?.subtotal,
            terms: formValues?.terms,
            bank: formValues?.bank_id,
            invoice_date: formValues?.invoiceDate,
            company: formValues?.company_id,
            currency: formValues?.currency?.value,
            invoice_number: formValues?.invoice_number,
            invoice_status: isSendInvoice ? 'sent' : 'saved',
            payment_status: 'unpaid',
            line_items: items
        };

        try {
            // 1. Create invoice
            const invoiceResponse: any = await new Promise((resolve, reject) => {
                dispatch(invoiceCreateRequest(invoiceData, resolve, reject));
            });

            // To capture outcomes of optional operations.
            let paymentResult: "success" | "failed" | null = null;
            let disputeResult: "success" | "failed" | null = null;

            // 2. Create payment if paidAmount > 0
            if (formValues?.paidAmount > 0) {
                const paymentData = {
                    invoice: invoiceResponse?.id,
                    payment_date: formValues?.invoiceDate,
                    amount: formValues?.paidAmount,
                    currency: formValues?.currency?.value,
                    payment_method: "other",
                    status: "completed",
                    reference_number: "",
                    notes: "Created during invoice creation",
                };

                try {
                    await new Promise((resolve, reject) => {
                        dispatch(paymentCreateRequest(paymentData, resolve, reject));
                    });
                    paymentResult = "success";
                } catch (error) {
                    paymentResult = "failed";
                }
            }

            // 3. Create disputes if any exist in lineItems
            const disputes = lineItems?.reduce((acc: any[], item: any) => {
                if (item?.settlement_type === 'dispute') {
                    acc.push({
                        currency: item.currency,
                        amount: item.dispute_amount,
                        status: "open",
                        type: item?.settlement_type,
                        note: item.dispute_note
                            ? item.dispute_note
                            : `Dispute for Application: ${item.name} and Channel: ${item.channels?.valuesJson?.name}`,
                        channel: item.channels?.id,
                        application: item.id,
                        title: `Dispute for Application: ${item.name} and Channel: ${item.channels?.valuesJson?.name}`,
                    });
                } else if(item?.settlement_type) {
                    acc.push({
                        currency: item.currency,
                        amount: item.difference,
                        status: "open",
                        type: item?.settlement_type,
                        note: `${capitalizeFirstLetter(item?.settlement_type)} for Application: ${item.name} and Channel: ${item.channels?.valuesJson?.name}`,
                        channel: item.channels?.id,
                        application: item.id,
                        title: `${capitalizeFirstLetter(item?.settlement_type)} for Application: ${item.name} and Channel: ${item.channels?.valuesJson?.name}`,
                    });
                }
                return acc;
            }, []);

            if (disputes && disputes.length > 0) {
                try {
                    await new Promise((resolve, reject) => {
                        dispatch(disputeBulkCreateRequest(disputes, resolve, reject));
                    });
                    disputeResult = "success";
                } catch (error) {
                    disputeResult = "failed";
                }
            }

            // 4. Build a composite message
            let messageText = isSendInvoice
                ? "Invoice sent successfully."
                : "Invoice saved successfully.";

            if (paymentResult !== null) {
                messageText += ` Payment ${paymentResult === "success" ? "completed" : "failed"}.`;
            }
            if (disputeResult !== null) {
                messageText += ` Dispute ${disputeResult === "success" ? "completed" : "failed"}.`;
            }

            // 5. Set toast style based on outcomes:
            // If any sub-operation fails, use an error style.
            const toastClass =
                paymentResult === "failed" || disputeResult === "failed"
                    ? 'bg-warning text-white'
                    : 'bg-success text-white';

            toast(messageText, {
                position: "top-center",
                hideProgressBar: true,
                className: toastClass,
            });

            onCloseClick();
            setLoading(false);
            // Wait 2 seconds before redirecting/closing so the toast is visible.
            setTimeout(() => {
                navigate('/invoices')
            }, 3000);
        } catch (error) {
            // Invoice creation failed – show error message.
            toast(
                isSendInvoice ? "Invoice sent failed." : "Invoice saved failed.",
                {
                    position: "top-center",
                    hideProgressBar: true,
                    className: 'bg-danger text-white',
                }
            );
            onCloseClick();
            setLoading(false);
        }
    };

    const capitalizeFirstLetter = (str: string) => {
        return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
    }


    const sendBlobAsFormData = async (isSendInvoice: any) => {
        setLoading(true);
        let pdfBlob = await convertToPdf()
        if (pdfBlob) {
            const file = await getPdfFile(pdfBlob);
            if (file) {
                await uploadInvoice(file, isSendInvoice);
            }
        }
    }

    const getPdfFile = async (pdfBlob: any) => {
        if (!pdfBlob) return null;
        // Create a File from the Blob
        const pdfFile = new File([pdfBlob], `Inv_${fileno}.pdf`, { type: 'application/pdf' });
        return pdfFile;
    };


    return (<>
        {loading ? <DtsSpinners /> :
            <Modal
                direction="end"
                isOpen={show}
                id="ModalExample"
                toggle={onCloseClick}
                size="lg"
            >
                <ModalHeader className="bg-light" toggle={onCloseClick}>
                </ModalHeader>
                <ModalBody>
                    <div ref={contentRef} id="content-id" >
                        <CardBody className="border-bottom border-bottom-dashed p-0">
                            <Row className="hstack justify-content-end">
                                <Col lg={12} md={12} xl={12} className="hstack justify-content-end">
                                    <div className="justify-content-end">
                                        <h1>INVOICE</h1>
                                        <span>#{formValues.invoice_number}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col xxl={12} xl={12} md={12} lg={12}>
                                    <Card>
                                        <CardBody className="border-bottom border-bottom-dashed py-2 px-2">
                                            <Row className="hstack justify-content-between">
                                                <Col lg={4} md={4} xl={4}>
                                                    <div className="profile-user mx-auto  mb-1 mt-2">
                                                        <Label for="profile-img-file-input" className="d-block">
                                                            <span
                                                                className="border border-dashed d-flex align-items-center justify-content-center rounded"

                                                            >{formValues?.company_name ?
                                                                formValues?.company_name
                                                                : null}
                                                            </span>
                                                        </Label>
                                                    </div>
                                                    <div>
                                                        <div className="vstack">
                                                            <Label for="companyAddress">Address</Label>
                                                            <Label for="companyAddress">{formValues?.company_address}</Label>
                                                        </div>
                                                        <div className="vstack">
                                                            <Label for="companyPostalCode">Postal Code</Label>
                                                            <Label for="companypostalcode">{formValues?.company_postal_code}</Label>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={7} md={7} xl={7} className="align-self-start mt-4">
                                                    <div className="mb-1 hstack justify-content-between">
                                                        <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Registration Number:</Label>
                                                        <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.company_registrationNo}</Label>
                                                    </div>
                                                    <div className="mb-1 hstack justify-content-between">
                                                        <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Email:</Label>
                                                        <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.company_email}</Label>
                                                    </div>
                                                    <div className="mb-1 hstack justify-content-between">
                                                        <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Website:</Label>
                                                        <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.company_website}</Label>
                                                    </div>
                                                    <div className="mb-1 hstack justify-content-between">
                                                        <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Contact Number:</Label>
                                                        <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.company_phone}</Label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardBody className="p-4 border-top border-top-dashed px-2">
                                            <Row className="w-100 hstack justify-content-between">
                                                <Col lg={5} sm={12} md={5} xl={5}>
                                                    <div>
                                                        <Label
                                                            for="billingName"
                                                            className="text-muted text-uppercase fw-semibold mb-1"
                                                        >
                                                            Billing Address
                                                        </Label>
                                                    </div>
                                                    <div className="mb-1">
                                                        <Label for="totalamountInput">{formValues?.channel_name}</Label>
                                                    </div>
                                                    <div className="mb-1">
                                                        <Label for="totalamountInput">{formValues?.channel_address}</Label>
                                                    </div>
                                                    <div className="mb-1">
                                                        <Label for="totalamountInput">{formValues?.channel_phone}</Label>
                                                    </div>
                                                    <div className="mb-1">
                                                        <Label for="totalamountInput">{formValues?.channel_email}</Label>
                                                    </div>
                                                    <div className="mb-1">
                                                        <Label for="totalamountInput">{formValues?.channel_taxNumber}</Label>
                                                    </div>
                                                </Col>
                                                <Col lg={5} sm={12} md={5} xl={5} className="align-self-start">
                                                    <div className="mb-1 hstack justify-content-between">
                                                        <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Invoice Date:</Label>
                                                        <Label className="text-muted mb-1 hstack justify-content-end w-50">{moment(formValues?.invoiceDate).format('DD-MM-YYYY')}</Label>
                                                    </div>
                                                    <div className="mb-1 hstack justify-content-between">
                                                        <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Invoice No:</Label>
                                                        <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.invoice_number}</Label>
                                                    </div>
                                                    <div className="mb-1 hstack justify-content-between">
                                                        <Label className="fw-bold mb-1 hstack justify-content-end w-50">Balance Due:</Label>
                                                        <Label className="fw-bold mb-1 hstack justify-content-end w-50">{formValues?.currency?.value}{" "}{formValues?.balanceDue}</Label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardBody className="p-4 px-2">
                                            <div className="table-responsive">
                                                <Table className="invoice-table table-borderless table-nowrap mb-0">
                                                    <thead className="align-middle">
                                                        <tr className="table-active">
                                                            <th scope="col" style={{ width: "50px" }}>
                                                                #
                                                            </th>
                                                            <th scope="col">Student Details</th>
                                                            <th scope="col" style={{ width: "120px" }}>
                                                                <div className="d-flex currency-select input-light align-items-center text-center ">
                                                                    Rate
                                                                </div>
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="text-center"
                                                                style={{ width: "200px" }}
                                                            >
                                                                Tax
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="text-center"
                                                                style={{ width: "150px" }}
                                                            >
                                                                Amount
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="newlink">
                                                        {lineItems && lineItems.length ? lineItems.map((item: any, index: any) => {
                                                            return <tr id="1" className="product">
                                                                <td scope="row" className="product-id">
                                                                    {index + 1}
                                                                </td>
                                                                <td className="text-start">
                                                                    <div className="mb-2">
                                                                        {item?.student?.name}
                                                                    </div>
                                                                    <div className='text-wrap' style={{ maxWidth: 300 }} >{item?.institutes?.name}<br /></div>
                                                                    <div className='text-wrap' style={{ maxWidth: 300 }} >{item.courses.name}<br /></div>
                                                                    {/* ({item?.courses?.currency}{item?.courses?.course_fee})<br /> */}
                                                                    (Fee: {item?.courses.currency}{item?.courses?.course_fee})<br />
                                                                    (Commission: {item?.courses.currency}{item?.originalcommission})<br />
                                                                    {item?.taxIncluded ? "Tax is included" : "Tax is excluded"}
                                                                    ({item?.taxPercentage}{item?.taxType == "percent" ? "%" : ""})
                                                                </td>
                                                                <td className="justify-content-center">
                                                                    <Label for="totalamountInput">{item?.commission}</Label>
                                                                </td>
                                                                <td className="text-end">
                                                                    {
                                                                        item.taxType == 'percent' ?
                                                                            <div className="text-center position-relative w-100">
                                                                                <Label for="totalamountInput">{item?.taxPercentage}%</Label>
                                                                            </div>
                                                                            :
                                                                            <div className="text-center position-relative w-100">
                                                                                <Label for="totalamountInput">{formValues?.currency?.value}{" "}{item?.taxPercentage}</Label>
                                                                            </div>
                                                                    }
                                                                </td>
                                                                <td className="text-end">
                                                                    <div className="text-center position-relative w-100">
                                                                        <Label for="totalamountInput">{formValues?.currency?.value}{" "}{item?.itemAmount}</Label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        }) : null}

                                                    </tbody>
                                                </Table>
                                            </div>
                                            <br />
                                            <br />
                                            <br />
                                            <br /><br /><br /><br /><br />
                                            <CardBody>
                                                <Row className="mt-1 justify-content-between">
                                                    <Col lg={5} md={5} xl={5}>
                                                        <Label>NOTES</Label>
                                                        <div className="mb-2">
                                                            <Label>{formValues.bank_name}</Label>
                                                        </div>
                                                        <div>
                                                            <Label>{formValues.account_name}</Label>
                                                        </div>
                                                        <div>
                                                            <Label>{formValues.account_number}</Label>
                                                        </div>
                                                        <div>
                                                            <Label>{formValues.ifsc}</Label>
                                                        </div>
                                                    </Col>
                                                    <Col lg={5} className="mt-1">
                                                        <div className="mb-1 hstack justify-content-between">
                                                            <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Subtotal:</Label>
                                                            <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.currency?.value}{" "}{formValues?.subtotal}</Label>
                                                        </div>
                                                        <div className="mb-1 hstack justify-content-between">
                                                            <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Tax:</Label>
                                                            <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.currency?.value}{" "}{formValues?.totaltax}</Label>

                                                        </div>
                                                        <div className="mb-1 hstack justify-content-between">
                                                            <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Total:</Label>
                                                            <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.currency?.value}{" "}{formValues?.invoice_amount}</Label>
                                                        </div>
                                                        <div className="mb-1 hstack justify-content-between">
                                                            <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Amount Paid:</Label>
                                                            <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.currency?.value}{" "}{formValues?.paidAmount ? formValues?.paidAmount : 0}</Label>
                                                        </div>
                                                        <div className="mb-1 hstack justify-content-between">
                                                            <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Balance Due:</Label>
                                                            <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.currency?.value}{" "}{formValues?.balanceDue}</Label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="mt-2 vstack">
                                                            <Label
                                                                for="exampleFormControlTextarea1"
                                                                className="form-label text-muted text-uppercase fw-semibold"
                                                            >
                                                                TERMS
                                                            </Label>
                                                            <Label for="totalamountInput">{formValues?.terms}</Label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                    </div>
                    <Row className="text-center gap-2">
                        <button
                            onClick={() => {
                                sendBlobAsFormData(false)
                            }}
                            className="btn btn-primary w-auto"
                        >
                            <i className="ri-article-line align-bottom me-1"></i>
                            Draft
                        </button>
                        <button type="submit" className="btn btn-success  w-auto" onClick={() => {
                            sendBlobAsFormData(true);
                        }}>
                            <i className="ri-printer-line align-bottom me-1"></i> Save and Send Invoice
                        </button>

                    </Row>
                </ModalBody>
            </Modal>
        }
    </>
    );
};

export default PreviewModal;
