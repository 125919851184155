import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Collapse, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { userGetRequest } from 'store/user/action';
import { permissionsGetRequest, roleGetRequest, roleNameListRequest } from 'store/role/action';
import ProfileCompleteness from "./User/ProfileCompleteness";
import CompanyProfile from "./Settings/CompanyProfile";
import { withTranslation } from "react-i18next";
import Profile from "./User/Profile";
import MeetingAccount from "./User/innerComponents/MeetingAccount";
import Review from "./User/Review";

interface Options {
    label: string;
    value: string;
}

interface Permission {
    name: string;
    options?: Options[];
    value: string;
}

interface Feature {
    description: string;
    editable: boolean;
    name: string;
    value: boolean;
    type: string;
    permissions: Permission[];
    options?: Options[];
}

interface SubItem {
    name: string;
    features: Feature[];
}

interface MenuItem {
    name: string;
    items: SubItem[];
}

interface CreateInputs {
    role: string;
    access: MenuItem[];
}

const UserProfile = ({ t }: any) => {
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const user = useSelector((state: ApplicationState) => state.auth?.userProfile);
    const roleDetails: any = useSelector((state: ApplicationState) => state.role.roleDetails);
    const [accessData, setAccessData] = useState<any>(null);
    const [verticalTab, setVerticalTab] = useState("1");
    const [openSubmenu, setOpenSubmenu] = useState<string | null>("general");
    const [createInputs, setCreateInputs] = useState<CreateInputs>({
        role: "",
        access: [],
    });

    useEffect(() => {
        dispatch(userGetRequest(id))
        dispatch(roleNameListRequest())
        if (roleDetails && roleDetails.categoriesWithPermission) {
            setAccessData(roleDetails.categoriesWithPermission)
        }
    }, [])

    useEffect(() => {
        if (roleDetails && roleDetails.categoriesWithPermission) {
            setAccessData(roleDetails.categoriesWithPermission)
        }
    }, [roleDetails, user])

    useEffect(() => {
        if (user && user.role) {
            dispatch(userGetRequest(id));
            dispatch(roleGetRequest(user.role.id))
            dispatch(permissionsGetRequest());
        }
    }, [user])

    const toggleVertical = (tab: string) => {
        setVerticalTab(tab);
    };

    const toggleSubmenu = (submenu: string) => {
        setOpenSubmenu(openSubmenu === submenu ? null : submenu);
    };

    return (
        <div className="">
            <Row className="mx-1 mb-3">
                <Col xxl={12} className="" style={{ padding: "1px" }}>
                    <Row>
                        <Col md={3} className="py-3" style={{ height: "80vh" }}>
                        <Card className="h-100">
                        <Nav pills className="flex-column" id="v-pills-tab">
                                <NavItem>
                                    <NavLink
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                        className="mb-2"
                                        onClick={() => toggleSubmenu("general")}
                                    >
                                        <span>{t("userpreferences.general")}</span>
                                        <i
                                            className={
                                                openSubmenu === "general"
                                                    ? "ri-subtract-line"
                                                    : "ri-add-line"
                                            }
                                        />
                                    </NavLink>
                                    <Collapse isOpen={openSubmenu === "general"}>
                                        <Nav className="flex-column ms-3">
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({ "mb-2": true, active: verticalTab === "1" })}
                                                    onClick={() => toggleVertical("1")}
                                                >
                                                    {t("userpreferences.profile")}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({ "mb-2": true, active: verticalTab === "2" })}
                                                    onClick={() => toggleVertical("2")}
                                                >
                                                    {t("userpreferences.connected_accounts")}
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </Collapse>
                                </NavItem>

                                {/* Access */}
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({ "mb-2": true, active: verticalTab === "3" })}
                                        onClick={() => toggleVertical("3")}
                                    >
                                        {t("userpreferences.access")}
                                    </NavLink>
                                </NavItem>
                                {user?.role?.title === "Owner" && (
                                    <NavItem>
                                        <NavLink
                                            style={{
                                                cursor: "pointer",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                            className="mb-2"
                                            onClick={() => toggleSubmenu("workspace_settings")}
                                        >
                                            <span>{t("userpreferences.workspace_settings")}</span>
                                            <i
                                                className={
                                                    openSubmenu === "workspace_settings"
                                                        ? "ri-subtract-line"
                                                        : "ri-add-line"
                                                }
                                            />
                                        </NavLink>
                                        <Collapse isOpen={openSubmenu === "workspace_settings"}>
                                            <Nav className="flex-column ms-3">
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({ "mb-2": true, active: verticalTab === "4" })}
                                                        onClick={() => toggleVertical("4")}
                                                    >
                                                        {t("userpreferences.company_profile")}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({ "mb-2": true, active: verticalTab === "5" })}
                                                        onClick={() => toggleVertical("5")}
                                                    >
                                                        {t("userpreferences.profile_completeness")}
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Collapse>
                                    </NavItem>
                                )}
                            </Nav>
                        </Card>
                          
                        </Col>
                        <div className="border-round w-75">
                            {/* <SimpleBar> */}
                                <TabContent
                                    activeTab={verticalTab}
                                    className="d-felx justify-content-center align-items-center text-muted ms-0 my-0 mt-3"
                                    id="v-pills-tabContent"
                                >
                                    <TabPane tabId="1" id="v-pills-home">
                                        <SimpleBar>
                                            <Profile />
                                        </SimpleBar>
                                    </TabPane>
                                    <TabPane tabId="2" id="v-pills-profile">
                                        <SimpleBar>
                                            <MeetingAccount />
                                        </SimpleBar>
                                    </TabPane>
                                    <TabPane tabId="3" id="v-pills-profile">
                                        <SimpleBar>
                                            {user && (user.role.title === 'Owner' || user.role.title === 'owner') ? (
                                                <>
                                                    <h4 className="">{t("userpreferences.access")}</h4>
                                                    <Card className="shadow-lg">
                                                    <div className="min-vh-100 d-flex justify-content-center align-items-center">
                                                      
                                                        <p className="fs-20 fw-bold">{t("userpreferences.owner_has_all")}</p>
                                                    
                                                    
                                                    </div>
                                                    </Card>
                                                    
                                                </>
                                            ) :
                                                <>
                                                    <Review createInputs={createInputs}
                                                        accessData={accessData} />
                                                </>
                                            }
                                        </SimpleBar>
                                    </TabPane>
                                    <TabPane tabId="4" id="v-pills-profile">
                                        {/* <SimpleBar> */}
                                            <CompanyProfile />
                                        {/* </SimpleBar> */}
                                    </TabPane>
                                    <TabPane tabId="5" id="v-pills-profile">
                                        {/* <SimpleBar> */}
                                            <ProfileCompleteness />
                                        {/* </SimpleBar> */}
                                    </TabPane>
                                </TabContent>
                            {/* </SimpleBar> */}
                        </div>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default withTranslation()(UserProfile);