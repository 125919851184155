import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Label, Button, ButtonGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem, Input } from "reactstrap";
import { Action } from "redux";
import { ApplicationState } from "store";
import { columnsGetRequest } from "store/report/action";
import DataSourceDrawer from "./DataSourceDrawer";
import buildRelationships from "helpers/relationshipBuilder";
import convertToTitleCase from "helpers/convertToTitleCase";

const ICONS: any = {
    "textfield": "ri-input-method-line",
    "textarea": "ri-file-text-line",
    "varchar": "ri-file-text-line",
    "phone": "ri-input-method-line",
    "email": "ri-input-method-line",
    "checkbox": "ri-checkbox-line",
    "selectboxes": "ri-checkbox-multiple-line",
    "datetime": "ri-calendar-line",
    "timestamp": "ri-time-line",
    "select": "ri-list-check",
    "datasetselect": "ri-database-2-line",
    "treeselect": "ri-database-2-line",
    "radio": "ri-radio-button-line",
    "number": "ri-hashtag",
    "file": "ri-file-upload-line"
}

interface DataPanelProps {
    dataSources: any;
    onAddColumn: (property: any, dataSource: any) => void;
    onAddFilters: (property: any, dataSource: any) => void;
    setDataSources: (property: any) => void;
    setModels: (property: any) => void;
    setRelationships: (property: any) => void;
    onModifyGroupBy: (property: any, dataSource: any) => void;
    onModifyValue: (property: any, dataSource: any) => void;
    onModifyCompareBy: (property: any, dataSource: any) => void;
    onModifyXAxis: (property: any, dataSource: any) => void;
    onModifyYAxis: (property: any, dataSource: any) => void;
    onAddField: (property: any, dataSource: any) => void;
    resetConfig: () => void;
    t: (key: string) => string;
}

const SKIPVALUES = ['id', 'values_json', "parent_id", "user_id"];

const DataPanel: React.FC<DataPanelProps> = ({
    dataSources,
    onAddColumn,
    onAddFilters,
    setDataSources,
    setModels,
    setRelationships,
    onModifyGroupBy,
    onModifyValue,
    onModifyCompareBy,
    onModifyXAxis,
    onModifyYAxis,
    onAddField,
    resetConfig,
    t
}) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const columns = useSelector((state: ApplicationState) => state.report.columns);
    const columnsLoading = useSelector((state: ApplicationState) => state.report.columns_loading);
    
    const [dataSourceToggle, setDataSourceToggle] = useState<boolean>(false);
    const [toggleData, setToggleData] = useState(true);
    const [selectedDataSource, setSelectedDataSource]: any = useState(null)
    const [filteredColumns, setFilteredColumns]: any = useState([])
    const [mesureColumns, setMesureColumns]: any = useState([])
    const [search, setSearch]: any = useState('')
    useEffect(() => {
        if(dataSources?.length && !selectedDataSource) {
            setSelectedDataSource(dataSources[0])
            setRelationships(buildRelationships(dataSources));
            const data = {
                "table_name": dataSources[0].value,
                "schema": dataSources[0].schema,
                "filters": null
            }
            dispatch(columnsGetRequest(data))
        }
    }, [dataSources])

    useEffect(() => {
        if(columns?.length && selectedDataSource) {
            // Filter out and sort the columns by 'column_name' or 'label', excluding certain values
            const mesureColumns: any = [];
            const sortedColumns = [...columns]
                .filter((item) => {
                    const columnValue = (item['column_name'] as string || item['label'] as string || '').toLowerCase();
                    if(columnValue === "id") {
                        const mesureColumn = {
                            ...item,
                            label: `Count of ${selectedDataSource?.label}`,
                            column_type: "number",
                            measure: true,
                            aggregation: {
                                label: "Count",
                                value: "COUNT"
                            }
                        }
                        mesureColumns.push(mesureColumn)
                    }
                    return !SKIPVALUES.includes(columnValue); // Exclude certain values
                })
                .sort((a, b) => {
                    const columnA = (a['column_name'] as string || a['label'] as string || '').toLowerCase();
                    const columnB = (b['column_name'] as string || b['label'] as string || '').toLowerCase();
                    return columnA.localeCompare(columnB);
                });
            setMesureColumns(mesureColumns);
            setFilteredColumns(sortedColumns);
        }
    }, [columns, selectedDataSource]);

    const onSearch = (search: string) => {
        setSearch(search)
        // Create a case-insensitive regex for searching
        const searchRegex = new RegExp(search, 'i');
        const mesureColumns: any = [];
    
        const filtered = columns
            .filter((item) => {
                const columnValue = (item['column_name'] as string || item['label'] as string || '').toLowerCase();
                if(columnValue === "id") {
                    const mesureColumn = {
                        ...item,
                        label: `Count of ${selectedDataSource?.label}`,
                        column_type: "number",
                        measure: true,
                        aggregation: {
                            label: "Count",
                            value: "COUNT"
                        }
                    }
                    mesureColumns.push(mesureColumn)
                }
                return (
                    searchRegex.test(columnValue) && !SKIPVALUES.includes(columnValue) // Exclude and search
                );
            })
            .sort((a, b) => {
                const columnA = (a['column_name'] as string || a['label'] as string || '').toLowerCase();
                const columnB = (b['column_name'] as string || b['label'] as string || '').toLowerCase();
                return columnA.localeCompare(columnB);
            });
    
        setMesureColumns(mesureColumns);
        setFilteredColumns(filtered);
    };

    const onDataSource = (primary: any, secondary: any[], tertiary: any[]) => {
        // Mark primary source
        let dataSources: any[] = [{ ...primary, primary: true }];
    
        // Mark and append secondary sources
        if (secondary?.length) {
            const secondarySources = secondary.map((source) => ({
                ...source,
                secondary: true, // Mark as secondary
            }));
            dataSources = [...dataSources, ...secondarySources];
        }
    
        // Mark and append tertiary sources
        if (tertiary?.length) {
            const tertiarySources = tertiary.map((source) => ({
                ...source,
                tertiary: true, // Mark as tertiary
            }));
            dataSources = [...dataSources, ...tertiarySources];
        }
    
        // Update state with data sources
        setDataSources(dataSources);
    
        // Set the first data source as the selected one
        setSelectedDataSource(dataSources[0]);
    
        // Build relationships based on all selected data sources
        setRelationships(buildRelationships(dataSources));
    
        // Dispatch an action to fetch columns for the selected table
        const data = {
            table_name: dataSources[0].value,
            schema: dataSources[0]?.schema,
            filters: null,
        };
        dispatch(columnsGetRequest(data));
        resetConfig();
    };    

    const onSelectDataSource = (dataSource: any) => {
        const data = {
            "table_name": dataSource?.value,
            "schema": dataSource?.schema,
            "filters": null
        }
        dispatch(columnsGetRequest(data))
        setSelectedDataSource(dataSource)
        setSearch('')
    }
    if(toggleData) {
        return (
            <div className="d-flex flex-column h-100 border-end">
                <div className="d-flex h-100  flex-column gap-2" style={{width: "350px"}}>
                    <div className="d-flex justify-content-between px-3 py-2 align-items-center bg-primary-subtle">
                        { dataSources?.length ?
                            <div className="d-flex gap-1">
                            <Button
                                size="sm"
                                color="primary"
                                className="btn-label"
                                onClick={() => setDataSourceToggle(true)}
                            >
                                    <i className="ri-database-2-line label-icon align-middle fs-16"></i>
                                    {dataSources?.length} {dataSources?.length <= 1 ? t('reports.data_bar.count_label') : t('reports.data_bar.count_label_p')}
                            </Button>
                        </div> : <h5 className="m-0 text-primary">{t('reports.data_bar.heading')}</h5> }
                        <Button color="primary" className="btn-icon btn-ghost-primary" onClick={() => setToggleData(false)}>
                            <i className="cursor-pointer ri-indent-decrease fs-4"></i>
                        </Button>
                    </div>
                    {dataSources?.length ? <div className="d-flex flex-column align-items-center justify-content-center gap-2 border-bottom border-primary-subtle pb-2">
                        <ButtonGroup>
                            <UncontrolledDropdown>
                                <DropdownToggle tag="button" className="btn btn-soft-dark">
                                    {selectedDataSource?.label} {selectedDataSource?.primary ? '(Primary)' : ''} <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    {
                                        dataSources.map((dataSource: any, dataSourceIndex: number) => <DropdownItem key={dataSourceIndex} onClick={() => onSelectDataSource(dataSource)}>{dataSource.label} {dataSource.primary ? '(Primary)' : ''}</DropdownItem>)
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </ButtonGroup>
                        <div className="form-icon">
                            <Input
                                type="text"
                                className="form-control form-control-sm form-control-icon fs-14 bg-light-subtle"
                                placeholder="Search fields..."
                                value={search}
                                onChange={(e) => onSearch(e.target.value)}
                            />
                            <i className="fs-14 ri-search-line text-muted"></i>
                        </div>
                    </div> : <div className="d-flex justify-content-center border-bottom border-dark-subtle pb-4">
                        <Button
                            color="primary"
                            className="btn-label"
                            onClick={() => setDataSourceToggle(true)}
                        >
                                <i className="ri-database-2-line label-icon align-middle fs-16"></i>
                                <Label className="mb-0 text-nowrap text-truncate text-nowrap-ellipsis">{t('reports.data_bar.btn_add_data_sources')}</Label>
                        </Button>
                    </div>}
                    {columnsLoading ? <div className="d-flex gap-2 flex-column h-100 overflow-x-hidden overflow-y-scroll pb-5 placeholder-glow">
                        <ListGroup flush>
                        {
                            Array.from({ length: 20 }, (_, index) => index + 1).map((count) => {
                                return (
                                    <ListGroupItem key={count} className="cursor-pointer d-flex list-group-item-action justify-content-between border-0">
                                        <div className="cursor-pointer d-flex list-group-item-action gap-2">
                                            <i className="placeholder" style={{height: "20px", width: "20px"}}></i>
                                            <Label className="cursor-pointer m-0 text-dark placeholder w-100"></Label>
                                        </div>
                                    </ListGroupItem>
                                )
                            })
                        }
                        </ListGroup>
                    </div> : <div className="d-flex gap-2 flex-column h-100 overflow-x-hidden overflow-y-scroll pb-5">
                        <h5 className="px-1">{t('reports.data_bar.measure_columnns')}</h5>
                        <ListGroup flush>
                        {
                            mesureColumns?.map((column: any, columnIndex: number) => {
                                return (
                                    <ListGroupItem key={columnIndex} className="cursor-pointer d-flex list-group-item-action justify-content-between border-0">
                                        <div className="cursor-pointer d-flex list-group-item-action gap-2">
                                            <i className={`${ICONS[column.column_type]}`}></i>
                                            <Label className="cursor-pointer m-0">{column.label ? column.label : convertToTitleCase(column.column_name)}</Label>
                                        </div>
                                        <ButtonGroup>
                                            <UncontrolledDropdown>
                                                <DropdownToggle tag="button" className="px-1 py-0 btn btn-ghost-primary">
                                                    <i className="ri-more-2-fill"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => onModifyValue(column, selectedDataSource)}>{t('reports.data_bar.add_to_value')}</DropdownItem>
                                                    <DropdownItem onClick={() => onModifyYAxis(column, selectedDataSource)}>{t('reports.data_bar.add_to_y_axis')}</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </ButtonGroup>
                                    </ListGroupItem>
                                )
                            })
                        }
                        </ListGroup>
                        <h5 className="px-1">{t('reports.data_bar.datasource_columns')}</h5>
                        <ListGroup flush>
                        {
                            filteredColumns?.map((column: any, columnIndex: number) => {
                                return (
                                    <ListGroupItem key={columnIndex} className="cursor-pointer d-flex list-group-item-action justify-content-between border-0">
                                        <div className="cursor-pointer d-flex list-group-item-action gap-2">
                                            <i className={`${ICONS[column.column_type]}`}></i>
                                            <Label className="cursor-pointer m-0">{column.json_field ? column.label : convertToTitleCase(column.column_name)}</Label>
                                        </div>
                                        <ButtonGroup>
                                            <UncontrolledDropdown>
                                                <DropdownToggle tag="button" className="px-1 py-0 btn btn-ghost-primary">
                                                    <i className="ri-more-2-fill"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => onAddColumn(column, selectedDataSource)}>{t('reports.data_bar.add_to_column')}</DropdownItem>
                                                    <DropdownItem onClick={() => onAddFilters(column, selectedDataSource)}>{t('reports.data_bar.add_to_filters')}</DropdownItem>
                                                    <DropdownItem onClick={() => onModifyGroupBy(column, selectedDataSource)}>{t('reports.data_bar.add_to_group_by')}</DropdownItem>
                                                    <DropdownItem onClick={() => onModifyValue(column, selectedDataSource)}>{t('reports.data_bar.add_to_value')}</DropdownItem>
                                                    <DropdownItem onClick={() => onModifyXAxis(column, selectedDataSource)}>{t('reports.data_bar.add_to_x_axis')}</DropdownItem>
                                                    <DropdownItem onClick={() => onModifyYAxis(column, selectedDataSource)}>{t('reports.data_bar.add_to_y_axis')}</DropdownItem>
                                                    {column.column_type === 'datetime' ? <DropdownItem onClick={() => onModifyCompareBy(column, selectedDataSource)}>{t('reports.data_bar.add_to_compare_by')}</DropdownItem> : null}
                                                    <DropdownItem onClick={() => onAddField(column, selectedDataSource)}>{t('reports.data_bar.add_to_field')}</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </ButtonGroup>
                                    </ListGroupItem>
                                )
                            })
                        }
                        </ListGroup>
                    </div>}
                </div>
                {dataSourceToggle && <DataSourceDrawer isOpen={dataSourceToggle} toggle={setDataSourceToggle} onDataSource={onDataSource} initData={dataSources} setAllModels={setModels} />}
            </div>
        );
    } else {
        return (
            <div className="d-flex flex-column border-end">
                <div className="d-flex justify-content-between p-2 py-4 align-items-center">
                    <Button color="primary" className="btn-icon btn-ghost-primary" onClick={() => setToggleData(true)}>
                        <i className="cursor-pointer ri-indent-increase fs-4"></i>
                    </Button>
                </div>
            </div>
        )
    }
};

export default withTranslation()(DataPanel);
