import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Input, Row, Button, Badge } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { useParams } from 'react-router-dom';
import { recommendationListGetRequest, recommendationUpdateRequest } from 'store/recommendation/action';
import { toast } from 'react-toastify';
import { currencyGetRequest } from 'store/currency/action';
import Recommend from './ShortListCourses/Recommend';
import UserAvatar from 'Components/Common/UserAvtar/ListingUserAvatar';


const Applications = (props: any) => {
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const applicationsMetadata = useSelector((state: ApplicationState) => state.applications.metaDataFields);
    const applicationList = useSelector((state: ApplicationState) => state.recommend.list);
    const paginationDetails = useSelector((state: ApplicationState) => state.recommend.pagination);
    const loading = useSelector((state: ApplicationState) => state.recommend.loading);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const [create, setCreate] = useState<boolean>(false);
    const [commissionAccess, setCommissionAccess] = useState<boolean>(false);
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [columns, setColumns] = useState<any>([])
    const [pageSize, setPageSize] = useState<number>(10)
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [access, setAccess] = useState<any>([]);
    const [metaData, setMetaData] = useState(applicationsMetadata);
    const [applications, setApplication] = useState([]);
    const [search, setsearch] = useState<string>("");
    const [shortlistId, setShortlistId] = useState('')
    const [isShortListOpen, setIsShortListOpen] = useState(false)
    const [triggerApi, setTriggerApi]: any = useState(false);
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
        }
    }, [paginationDetails])


    useEffect(() => {
        dispatch(currencyGetRequest())
    }, [])

    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, size: value })
        setPageSize(value)
    }

    useEffect(() => {
        setMetaData(applicationsMetadata);
    }, [applicationsMetadata]);


    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                newPageNo = 0;
                setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                newPageNo = lastPage - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
    };

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setViewAccess(true)
            setCreate(true)
            setCommissionAccess(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.applications;
            if (access && access?.length) {
                setAccess(access)
                setViewAccess(true)
                if (access && access.length) {
                    access.map((item: any, index: any) => {

                        if (item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false) : setCreate(true)
                        }

                    })
                }
            }
            else {
                setViewAccess(false)
            }
            let commission = subLevelPermissionsList?.commissions;
            if (commission && commission?.length) {
                setAccess(commission)
                setViewAccess(true)
                if (commission && commission.length) {
                    commission.map((item: any, index: any) => {
                        if (item.name.toLowerCase() === 'view') {
                            item.value === "" || item.value === "none" ? setCommissionAccess(false) : setCommissionAccess(true)
                        }

                    })
                }
            }
            else {
                setCommissionAccess(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const handleButtonClick = (id: any) => {
        const url: any = window.location.href;
        const queryString = url?.match(/^https?:\/\/[^/]+/);
        let baseUrl = queryString ? queryString[0] : 'https://app.zilter.io'
        let link = `${baseUrl}/recommendations/${userProfile?.tenant?.id}/${id}`
        navigator.clipboard.writeText(link).then(() => {
            toast('URL copied to clipboard', { position: 'top-center', hideProgressBar: true, className: 'bg-success text-white' });
        }).catch(err => {

        });
    };

    const updateRecommend = (id: any) => {
        const data = {
            isSend: true
        };

        const handleSuccess = (body: any) => {
            // Improved messages based on whether the recommendation was sent or saved
            const successMessage = "Your shortlisted courses have been successfully sent."
            toast(successMessage, {
                position: "top-center",
                hideProgressBar: true,
                className: 'bg-success text-white'
            });
            setTriggerApi((triggerApi: any) => !triggerApi)
        };

        const handleError = (error: any) => {
            const errorMessage = "Oops! Something went wrong while processing your recommendation. Please try again.";
            toast(errorMessage, {
                position: "top-center",
                hideProgressBar: true,
                className: 'bg-danger text-white'
            });
        };

        dispatch(recommendationUpdateRequest(id, data, handleSuccess, handleError));
    };

    useEffect(() => {
        let columns: any[] = [];
        if (applications && applications.length > 0) {
            let fields = applications[0];
            const keys = Object.keys(fields)
            keys.map((field) => {
                if (field === 'name') return columns[0] = {
                    Header: _.startCase(field),
                    accessor: field,
                    filterable: true,
                    disableFilters: true,
                    isSortable: true,
                    show: true,
                    Cell: (cellProps: any) => {
                        return <div className="d-flex align-items-center justify-content-between">
                            <div className="hstack flex-grow-1 gap-2 text-ellipsis w-75">
                                <div className='text-truncate text-primary cursor-pointer hstack gap-1' onClick={() => { setShortlistId(cellProps.row.original.id); setIsShortListOpen(true) }}>
                                    <UserAvatar img={''} firstName={cellProps.row.original[field]} />
                                    <span className='text-truncate text-primary cursor-pointer' style={{ maxWidth: 250, overflow: 'hidden', maxHeight: 50 }} title={cellProps.row.original[field]}>{cellProps.row.original[field]}</span>
                                    {cellProps.row.original['isSend'] == true || cellProps.row.original['isSend'] == 'true' ?
                                        <Badge color='warning' style={{ fontSize: 9 }} pill>SENT</Badge>
                                        :
                                        <Badge color='success' style={{ fontSize: 9 }} pill>SAVED</Badge>}
                                </div>
                            </div>
                            <div className='w-25'>
                                {
                                    field === "name" ? <div className={"btn_preview"}>
                                        <div className={"hstack justify-content-end gap-1"}>
                                            <Button
                                                size='sm'
                                                onClick={() => { handleButtonClick(cellProps.row.original.id) }}
                                                color="primary"
                                                title='Copy Link'
                                                className="btn-primary justfy-self-end hoverbutton ms-0">
                                                <i className="ri-file-copy-line label-icon align-middle"></i>
                                            </Button>

                                            {/* <Button
                                                size='sm'
                                                onClick={() => {updateRecommend(cellProps.row.original.id)}}
                                                color="primary"
                                                title={cellProps.row.original['isSend'] == true || cellProps.row.original['isSend'] == 'true' ? 
                                                    'Resend'
                                                    : 
                                                    'Send'}
                                                className="btn-primary justfy-self-end hoverbutton ms-0">
                                                <i className="ri-mail-send-line label-icon align-middle"></i>
                                            </Button>  */}

                                        </div>
                                    </div>
                                        : null
                                }
                            </div>

                        </div>

                    }
                }
                else if (field === 'created_by') return columns[1] = {
                    Header: _.startCase(field),
                    accessor: field,
                    filterable: true,
                    disableFilters: true,
                    isSortable: false,
                    show: true,
                    Cell: (cellProps: any) => {
                        return <span className="fw-semibold fs-14 text-ellipsis">
                            <span className='text-truncate' style={{ maxWidth: 200, overflow: 'hidden', maxHeight: 50 }} title={cellProps.row.original?.created_by?.name}>{cellProps.row.original?.created_by?.name}</span></span>
                    }
                }
            })
        }
        // Add the "Created At" column at the end
        columns.push({
            Header: "Created At",
            accessor: "createdAt",
            id: "createdAt",
            filterable: true,
            isSortable: false,
            disableFilters: true,
            show: true,
            Cell: ({ row }: { row: any }) => {
                const createdAt = row.original["createdAt"];
                return (
                    <div className="d-flex align-items-center justify-content-between" style={{ width: 200 }}>
                        {createdAt ? moment(createdAt).format("lll") : ""}
                    </div>
                );
            },
        });

        setColumns(columns);
    }, [applications]);



    useEffect(() => {
        setMetaData(applicationsMetadata)
    }, [applicationsMetadata != undefined])

    useEffect(() => {
        const parsedData: any = applicationList && applicationList.length ? applicationList.map((item: any) => {
            const valuesJson = item?.valuesJson ? item.valuesJson : {};
            const keys = Object.keys(valuesJson)
            let values = { ...valuesJson, ...item, name: valuesJson?.name || 'Recommendation', isSend: keys.includes('isSend') ? valuesJson?.isSend : true }
            return values
        }) : []
        let applications = parsedData && parsedData.length && parsedData
        setApplication(applications)
    }, [applicationList])

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        // Clear timer and cancel request on unmount
        const cleanup = () => {
            controller.abort("New request");
            clearTimeout(timer);
        };
        let parent: any = [{
            "key": 'student',
            "keyLabel": ['Student'],
            "condition": "IN",
            "conditionLabel": "is any of",
            "values": [id],
            "valuesLabel": [],
            "property": {},
            "quick": true,
        }]
        // Main logic wrapped in a timer
        const timer = setTimeout(() => {
            const filtersData: any = {
                filters: [{
                    quick: [],
                    advance: [],
                    search: null,
                    parent: []
                }],
                sorts: [
                    {
                        "field": "createdAt",
                        "order": "desc"
                    }
                ]
            }
            if (search) {
                filtersData.filters[0].search = {
                    term: search,
                    field: "name"
                }
            }
            if (parent?.length > 0) {
                filtersData.filters[0].parent = parent;
            }
            dispatch(recommendationListGetRequest(filtersData, pagination.page, pagination.size, signal));
        }, 600);
        // Cleanup on unmount or effect rerun
        return cleanup;
    }, [pagination?.page, pagination?.size, search, triggerApi])


    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            setTriggerApi((triggerApi: boolean) => !triggerApi)
        }
    };

    document.title = "DTS | Zilter";
    return (
        <React.Fragment>
            {isShortListOpen ?
                <Recommend shortlistId={shortlistId} setIsShortListOpen={setIsShortListOpen} />
                :
                <Row className='vstack gap-2'>
                    <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                        <div className="search-box">
                            <Input
                                type="text"
                                size={14}
                                className="search"
                                placeholder={props.t("student.details.application.search_for")}
                                onChange={(e) => setsearch(e.target.value)}
                                onKeyDown={handleKeyDown}
                                value={search}
                            />
                            {search === "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => setsearch("")}></i>}

                        </div>
                    </Col>
                    <Col md={12} sm={12} xl={12} xxl={12} lg={12}>
                        <Card>
                            <CardBody className="border border-dashed border-end-0 border-start-0 vstack gap-2">
                                <Row className='students-table'>
                                    <TableContainer
                                        columns={columns && columns.length ? columns.filter((column: any) => column.show == true) : []}
                                        data={applications || []}
                                        customPageSize={pageSize}
                                        tableClass="align-middle table-nowrap"
                                        theadClass="table-light text-muted"
                                        isPageSizeChange={true}
                                        setisDeleteOpen={setisDeleteOpen}
                                        pageChanged={pageChanged}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        onChangePageSize={onChangePageSize}
                                        pageSize={pageSize}
                                        props={props}
                                        loading={loading}
                                        handleButtonClick={handleButtonClick}
                                        setShortlistId={setShortlistId}
                                        setIsShortListOpen={setIsShortListOpen}
                                    />
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </React.Fragment>

    );
};
export default withTranslation()(Applications);