import React from "react";
import { Container, Row } from "reactstrap";
import Applications from "./Applications";

const Application = () => {
    document.title = "DTS | Zilter";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Applications />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Application;
