import { useEffect, useState } from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Label,
    Input,
    Row,
    Col,
    Card,
    Button,
} from "reactstrap";
import Select from "react-select";
import FeatherIcon from "feather-icons-react";
import SimpleBar from "simplebar-react";
import currencyOptions from "./currency.json";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { withTranslation } from "react-i18next";

const UuidPreview = ({ createInputs, setCreateInputs, fieldJson, setFieldJson, fieldType, t}: any) => {
    const [hover, setHover] = useState<any>(false)
    const [value, setValue] = useState<any>(false)
    const [prefix, setPrefix] = useState<any>('ID')
    const [autoincrement, setAutoincrement] = useState<any>(1)
    const [resetType, setResetType] = useState<any>(
        {
            label: "Yearly",
            value: "yearly"
        }
    )

    const resetOptions = [
        {
            label: "Yearly",
            value: "yearly"
        },
        {
            label: "Monthly",
            value: "monthly"
        }
    ]
    useEffect(() => {
        let newvalidate = fieldJson.uuidFormat
        let newuniquevalidation = { ...newvalidate, prefix: prefix, resetType: resetType?.value, autoincrement: autoincrement }
        let newfield = { ...fieldJson, uuidFormat: newuniquevalidation }
        setFieldJson(newfield);
        setCreateInputs({ ...createInputs, uuidFormat: newuniquevalidation })
    }, [prefix, resetType, autoincrement]);

    return (
        <div>
            <Row className="hstack gap-2">
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">
                    <div>
                        <Label className="fs-16 fw-semibold">{t("common.offcanvas.auto_increment_format")}</Label>
                    </div>
                </Col>
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">
                    <div>
                        <Label className="fs-14 fw-semibold">{t("common.offcanvas.prefix")}</Label>
                        <Input
                            defaultOptions
                            placeholder={t("common.offcanvas.enter_prefix")}
                            className="fs-14 fw-medium"
                            value={prefix}
                            onChange={(e: any) => setPrefix(e?.target?.value)}
                            name="choices-single-default"
                        ></Input>
                    </div>
                </Col>
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">
                    <div>
                        <Label className="fs-14 fw-semibold">{t("common.offcanvas.reset")}</Label>
                        <Select
                            placeholder={'Select field type'}
                            className="fs-14 fw-medium"
                            value={resetType}
                            onChange={(option: any) => setResetType(option)}
                            options={resetOptions}
                            name="choices-single-default"
                        ></Select>
                    </div>
                </Col>
                <Col lg={12} className="d-flex gap-2 flex-column justify-content-center">
                    <div>
                        <Label className="fs-14 fw-semibold">{t("common.offcanvas.auto_increment_by")}</Label><br />
                        <div className="input-step step-info" style={{ marginLeft: '5px', width: 200 }}>
                            <button
                                type="button"
                                className="minus"
                                onClick={() => {
                                    setAutoincrement(autoincrement - 1);
                                    // handleselected(autoincrement - 1, "autoincrement");
                                }}
                            >
                                –
                            </button>
                            <Input
                                type="number"
                                value={autoincrement}
                                className="w-100"
                                min="30"
                                max="1000"
                                onChange={(e) => setAutoincrement(e.target.value)}
                            />
                            <button
                                type="button"
                                className="plus"
                                onClick={() => {
                                    setAutoincrement(autoincrement + 1);
                                    // handleselected(autoincrement + 1, "autoincrement");
                                }}
                            >
                                +
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default withTranslation()(UuidPreview);
