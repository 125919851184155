import React, { useEffect, useState } from "react";
import { Button, Col, Label, Row, Table, Modal, ModalHeader, ModalBody } from "reactstrap";
import currency from "../../../../../common/currency";
import moment from "moment";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { SendEmail } from "store/applications/action";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { toast } from 'react-toastify';
import Select from "react-select";
import ReactDOMServer from "react-dom/server";
import config from "config";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { channelGetRequest } from "store/channels/action";
import { agencychangeformCreateRequest } from "store/agencychangeforms/action";
import Logo from "Components/Common/Logo/Logo";
import { tenantGetOpenRequest } from "store/user/action";
import { isJson } from "utils";

const { api } = config;
const DTS_URL = api.url

interface EmailBodyProps {
    studentName: string; // Name of the student
    reviewLink: string;  // Link for the student to review
}

const AgentChangeFormLetter = (props: any) => {
    const { onCloseClick, show, applicationsDetails, tenantId } = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const channel: any= useSelector((state: ApplicationState) => state.channels.details);
    const tenatDetails = useSelector((state: ApplicationState) => state.user.tenatDetails);
    const [disabled, setDisabled] = useState(false);
    const [tenant, setTenant] = useState<any>({})
    const [inputs, setInputs] = useState<any>({
        reason: '',
        last_name: '', 
        first_name: '', 
        date_of_birth: '', 
        country_of_nationality: '', 
        channel_name: '', 
        channel_address:'',
        proposed_agent: '',
        proposed_agent_address: '',
    })
    const [selectedSingle, setSelectedSingle] = useState<any>(null);

    const EmailBody: React.FC<EmailBodyProps> = ({ studentName, reviewLink }) => (
        <div>
            <p>Dear {studentName || "Student"},</p>
            <p>I hope this email finds you well.</p>
            <p>
                You have been provided a unique link to complete an important process.
                Kindly follow the steps below to review, submit, and complete your task:
            </p>
            <ol>
                <li>
                    <strong>Review and Verify Information:</strong> Ensure all the
                    details displayed are accurate and up-to-date.
                </li>
                <li>
                    <strong>Selfie Verification:</strong> You will be prompted to take a
                    selfie to verify your identity. Please follow the instructions
                    carefully.
                </li>
                <li>
                    <strong>Submit and Generate PDF:</strong> After completing the
                    review and verification, submit the information. A PDF containing
                    the completed details will be generated for your records.
                </li>
            </ol>
            <p>         <strong><a style={{ color: "blue" }} href={reviewLink}>Click here</a></strong>&nbsp;<span>to begin the process</span></p>
            <p>Thank you for your prompt attention to this matter.</p>

        </div>
    );   

    useEffect(() => {
        if(applicationsDetails?.id) {  
            let countryname = applicationsDetails?.valuesJson?.student?.country_of_nationality || applicationsDetails?.valuesJson?.student?.nationality || applicationsDetails?.valuesJson?.student?.country_of_origin          
            let country = countryname ? SingleOptions.filter((selected: any) => selected?.value.toLowerCase() === countryname.toLowerCase()) : []
            setSelectedSingle(country) 
            let newInputs = {id:applicationsDetails?.id, last_name: applicationsDetails?.valuesJson?.student?.last_name, first_name: applicationsDetails?.valuesJson?.student?.first_name, course_name: applicationsDetails?.valuesJson?.courses?.name,  date_of_birth: applicationsDetails?.valuesJson?.student?.date_of_birth, country_of_nationality: country[0]?.value, channel_name:{label: applicationsDetails?.valuesJson?.channel_name?.name, value: applicationsDetails?.valuesJson?.channel_name?.id},channel_address: applicationsDetails?.valuesJson?.channel_name?.address, studentId: applicationsDetails?.valuesJson?.student?.id }
            setInputs(newInputs)    
        }
    }, [applicationsDetails])

    useEffect(() => {
        if(inputs?.id && channel?.id) { 
            let newInputs = {...inputs, proposed_agent: {label: channel?.valuesJson?.name, value: channel?.id}, proposed_agent_address: channel?.valuesJson?.address ? channel?.valuesJson?.address : null }
            setInputs(newInputs)       
        }
    }, [channel?.id])

    useEffect(() => {
        if(inputs?.id &&  inputs?.proposed_agent?.value) {
            dispatch(channelGetRequest(inputs?.proposed_agent?.value))
        }
    }, [inputs?.proposed_agent?.value])
    
    useEffect(() => {
        dispatch(tenantGetOpenRequest(tenantId))
    }, [])
    
    useEffect(() => {
        let tenant = tenatDetails?.settings && isJson(tenatDetails?.settings) ? JSON.parse(tenatDetails?.settings) : tenatDetails?.settings;
        setTenant(tenant) 
    }, [tenatDetails])

    const onSend = () => {
        setDisabled(true)
        const handleSuccessAuth = (body : any) => {        
            onCloseClick()
            let countryname = applicationsDetails?.valuesJson?.student?.country_of_nationality || applicationsDetails?.valuesJson?.student?.nationality || applicationsDetails?.valuesJson?.student?.country_of_origin          
            let country = countryname ? SingleOptions.filter((selected: any) => selected?.value.toLowerCase() === countryname.toLowerCase()) : []
            setSelectedSingle(country)  
            let newInputs = {reason: null, id:applicationsDetails?.id, last_name: applicationsDetails?.valuesJson?.student?.last_name, first_name: applicationsDetails?.valuesJson?.student?.first_name,  date_of_birth: applicationsDetails?.valuesJson?.student?.date_of_birth, country_of_nationality: country[0]?.value, channel_name:{label: applicationsDetails?.valuesJson?.channel_name?.name, value: applicationsDetails?.valuesJson?.channel_name?.id} }
            setInputs(newInputs) 
                const toemail =
                    applicationsDetails?.valuesJson?.student?.email || "";
                const studentName =
                    applicationsDetails?.valuesJson?.student?.name || "Student";
                    
                const reviewLink = `${DTS_URL}/agent-letter/${tenantId}/${body?.id}`;

                // Convert React component to static HTML
                const emailBodyHTML = ReactDOMServer.renderToStaticMarkup(
                    <EmailBody studentName={studentName} reviewLink={reviewLink} />
                );
                const data = {
                    // parentId: emailinfo.id,
                    studentId: applicationsDetails?.valuesJson?.student?.id,
                    applicationId: applicationsDetails?.id,
                    to: toemail,
                    subject: "Action Required: Review, Submit, and Generate PDF with Selfie Verification",
                    body: emailBodyHTML
                }
               const handleSuccess = () => {
                    const message = () =>
                        toast(`Email Sent Successfully`, {
                            position: "top-center",
                            hideProgressBar: true,
                            className: "bg-success text-white"
                        });
                    message();
                }
                const handleFailure = () => {}
                dispatch(SendEmail(data, handleSuccess, handleFailure))
                setDisabled(false)
        }
        const handleFailureAuth = () =>{}
        dispatch(agencychangeformCreateRequest({...inputs, applicationId: applicationsDetails?.id}, handleSuccessAuth, handleFailureAuth))
    }

    const SingleOptions = currency.map(country => ({
        value: country.name, // Use the country's name as the value
        label: country.name, // Use the country's name as the label
    }));

    const handleSelectSingle = (selectedSingle: any) => {
        setSelectedSingle(selectedSingle);
    }

    const onChange = (option: any, key: any) => {  
        if(key === 'proposed_agent') {
            setInputs({...inputs, [key]: option})
        }
        if(isNaN(Date.parse(option)) === false) {
            setInputs({...inputs, [key]: option})
        }
        else {
            setInputs({ ...inputs, [key]: option })
        }
    };

    const allMandatoryFieldsFilled = () => {
        const keys = inputs && typeof inputs === 'object' ? Object.keys(inputs) : [];
        if(keys.length){
            for (const field of keys) {
                if (!inputs[field]) {
                    return false;
                }
            }
            return true;
        }
        else return false
    };

    return (
        <>
            {inputs?.id ?
                <Modal
                    isOpen={show}
                    fullscreen
                >
                    <ModalBody>
                        <div>
                            <Button type="button" color="primary" onClick={() => onCloseClick()} className="mt-4 ms-3">
                                Back
                            </Button>
                        </div>
                        <div className="p-3 mt-1 d-flex justify-content-center">
                            <Col xl={8}>
                                <div>
                                    <Row>
                                        <Label className="text-center mt-3 mb-5 fs-20">AGENT REQUEST FORM</Label>
                                    </Row>
                                    <div className="mx-5 mb-2">
                                        <Table className="table-bordered align-middle mb-0">
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "40%", whiteSpace: "nowrap" }}>First Name</td>
                                                    <td style={{ width: "60%" }}>{applicationsDetails?.valuesJson?.student?.first_name ?
                                                        applicationsDetails.valuesJson?.student?.first_name
                                                        :
                                                        <FormBuilder 
                                                            type={"textfield"}
                                                            defaultValue={inputs?.first_name}
                                                            value={inputs?.first_name}
                                                            name={"first_name"}
                                                            placeholder={"Enter First Name"}
                                                            className="w-100 h-100"
                                                            onChange={(e: any) => onChange(e, 'first_name')}
                                                            isEditState={true}
                                                            resetValue={inputs?.first_name}
                                                        />
                                                    }</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "40%", whiteSpace: "nowrap" }}>Last Name</td>
                                                    <td style={{ width: "60%" }}>{applicationsDetails?.valuesJson?.student?.last_name ?
                                                        applicationsDetails?.valuesJson?.student?.last_name
                                                        :
                                                        <FormBuilder 
                                                            type={"textfield"}
                                                            defaultValue={inputs?.last_name}
                                                            value={inputs?.last_name}
                                                            name={"last_name"}
                                                            placeholder={"Enter Last Name"}
                                                            className="w-100 h-100"
                                                            onChange={(e: any) => onChange(e, 'last_name')}
                                                            isEditState={true}
                                                            resetValue={inputs?.last_name}
                                                        />}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "40%", whiteSpace: "nowrap" }}>Date of Birth:</td>
                                                    <td style={{ width: "60%" }}>{applicationsDetails?.valuesJson?.student?.date_of_birth ? moment(applicationsDetails?.valuesJson.student?.date_of_birth).format("LL") :
                                                        <FormBuilder 
                                                            type={"datetime"}
                                                            defaultValue={inputs?.date_of_birth}
                                                            value={inputs?.date_of_birth}
                                                            name={"date_of_birth"}
                                                            placeholder={"Select Date of Birth"}
                                                            className="w-100 h-100"
                                                            onChange={(e: any) => onChange(e, 'date_of_birth')}
                                                            isEditState={true}
                                                            resetValue={inputs?.date_of_birth}
                                                        />}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "40%", whiteSpace: "nowrap" }}>Country of Nationality:</td>
                                                    <td style={{ width: "60%" }}>{applicationsDetails?.valuesJson?.student?.nationality ?
                                                        applicationsDetails?.valuesJson?.student?.nationality :
                                                        <Select
                                                            value={selectedSingle}
                                                            onChange={(selectedSingle: any) => {
                                                                handleSelectSingle(selectedSingle);
                                                                onChange(selectedSingle?.value, 'country_of_nationality')
                                                            }}
                                                            options={SingleOptions}
                                                        />}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "40%", whiteSpace: "nowrap" }}>Your Current Agent or ‘Applied
                                                        Direct’</td>
                                                    <td style={{ width: "60%" }}>
                                                        {applicationsDetails?.valuesJson?.channel_name?.name ? applicationsDetails?.valuesJson?.channel_name?.name :
                                                            <FormBuilder 
                                                            type={"datasetselect"}
                                                            defaultValue={inputs?.channel_name}
                                                            value={inputs?.channel_name}
                                                            name={"channel_name"}
                                                            placeholder={"Select Agency"}
                                                            className="w-100 h-100"
                                                            onChange={(e: any) => onChange(e, 'channel_name')}
                                                            isEditState={true}
                                                            resetValue={inputs?.channel_name}
                                                            rest={{
                                                                "label": "Channel",
                                                                "key": "channelId",
                                                                "placeholder": "Select Channel",
                                                                "quickCreate": true,
                                                                "type": "datasetselect",
                                                                "model": "channels",
                                                                "optionLabel": "name",
                                                                "validate": {
                                                                    "required": true
                                                                }
                                                            }}
                                                        />}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "40%", whiteSpace: "nowrap" }}>Address of Your Current Agent:</td>
                                                    <td style={{ width: "60%" }}>{applicationsDetails?.valuesJson?.channel_name?.address ? applicationsDetails?.valuesJson?.channel_name?.address :
                                                        <FormBuilder 
                                                            type={"textarea"}
                                                            defaultValue={inputs?.channel_address? inputs?.channel_address:''}
                                                            value={inputs?.channel_address?inputs?.channel_address:''}
                                                            name={"channel_address"}
                                                            placeholder={"Enter Address"}
                                                            className="w-100 h-100"
                                                            onChange={(e: any) => onChange(e, 'channel_address')}
                                                            isEditState={true}
                                                            resetValue={inputs?.channel_address}
                                                        />}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "40%", whiteSpace: "nowrap" }}>Your Proposed Agent:</td>
                                                    <td style={{ width: "60%" }}>{applicationsDetails?.valuesJson?.proposed_agent ? applicationsDetails?.valuesJson?.proposed_agent :
                                                        <FormBuilder 
                                                            type={"datasetselect"}
                                                            defaultValue={inputs?.proposed_agent}
                                                            value={inputs?.proposed_agent}
                                                            name={"proposed_agent"}
                                                            placeholder={"Select Agency"}
                                                            className="w-100 h-100"
                                                            onChange={(e: any) => onChange(e, 'proposed_agent')}
                                                            isEditState={true}
                                                            resetValue={inputs?.proposed_agent}
                                                            rest={{
                                                                "label": "Channel",
                                                                "key": "channelId",
                                                                "placeholder": "Select Channel",
                                                                "quickCreate": true,
                                                                "type": "datasetselect",
                                                                "model": "channels",
                                                                "optionLabel": "name",
                                                                "validate": {
                                                                    "required": true
                                                                }
                                                            }}
                                                        />}</td>

                                                </tr>
                                                <tr>
                                                    <td style={{ width: "40%", whiteSpace: "nowrap" }}>Address of Your Proposed Agent:</td>
                                                    <td style={{ width: "60%" }}>{
                                                        <FormBuilder 
                                                            type={"textarea"}
                                                            defaultValue={inputs?.proposed_agent_address? inputs?.proposed_agent_address:''}
                                                            value={inputs?.proposed_agent_address?inputs?.proposed_agent_address:''}
                                                            name={"proposed_agent_address"}
                                                            placeholder={"Enter Address"}
                                                            className="w-100 h-100"
                                                            onChange={(e: any) => onChange(e, 'proposed_agent_address')}
                                                            isEditState={true}
                                                            resetValue={inputs?.proposed_agent_address}
                                                            />
                                                        }</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "40%", whiteSpace: "nowrap" }}>Reason(s) for change of Agent:</td>
                                                    <td style={{ width: "60%" }}>
                                                          <FormBuilder 
                                                                type={"textarea"}
                                                                defaultValue={inputs?.reason ? inputs?.reason : ''}
                                                                value={inputs?.reason ? inputs?.reason : ''}
                                                                name={"reason"}
                                                                placeholder={"Enter Reason(s)"}
                                                                className="w-100 h-100"
                                                                onChange={(e: any) => onChange(e, 'reason')}
                                                                isEditState={true}
                                                            />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div className="mx-5">                                            
                                        <Row className="mt-3 mb-5">
                                            <Label className="mb-1 fs-14">
                                                Upon receipt of my completed form, I am aware and authorize the university to remove my
                                                current agent and change it in the student database to my proposed Agent ({inputs?.proposed_agent?.label}). Based on
                                                my previous Privacy Declaration, (as applicable) all future correspondence will be sent to {inputs?.proposed_agent?.label}.
                                            </Label>
                                        </Row>
                                        <div className="d-flex justify-content-between align-items-baseline">
                                            <div>
                                                <Label>Date: {moment().format("LL")}</Label>
                                            </div>
                                            <div className="">
                                                <Button type="button" color="primary" onClick={onSend} className="btn btn-sm" disabled={!allMandatoryFieldsFilled() || disabled}>
                                                    Send to student
                                                </Button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </div>
                    </ModalBody>
                </Modal>
                : null
            }</>
    );
};

export default AgentChangeFormLetter;
