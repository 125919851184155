import { Fragment, useEffect } from "react";
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { withTranslation } from 'react-i18next';
import { Table, Row } from "reactstrap";
import Paginator from "Components/Common/Paginator";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";

interface TableContainerProps {
    columns?: any;
    data?: any;
    tableClass?: any;
    trClass: any
    theadClass?: any;
    isPageSizeChange?: any;
    thClass?: any;
    customPageSize?: any;
    pageChanged: (e: any) => void,
    onChangePageSize: (e: any) => void,
    pagination: any;
    sortBy: string;
    sortOrder: string;
    props: any;
    dataLoading: any;
    checkbox?: any;
    handleSort: (e: any) => void;
}

const TableContainer = ({
    columns,
    data,
    isPageSizeChange,
    tableClass,
    theadClass,
    thClass,
    customPageSize,
    pageChanged,
    pagination,
    onChangePageSize,
    handleSort,
    sortOrder,
    sortBy,
    props,
    trClass,
    checkbox,
    dataLoading
}: TableContainerProps) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0, pageSize: customPageSize
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );
    const onChangeInSelect = (event: any) => {
        setPageSize(Number(event.target.value));
        onChangePageSize(event.target.value);
    };
    
    useEffect(() => {
        
    }, [data])
    return (
        <div>
            {dataLoading || (!page.length && data.length) ?
                <DtsTablePlaceholder
                    columns={columns}
                    theadClass={theadClass}
                    tableClass="align-middle table-nowrap"
                    thClass="border-bottom-1 table-soft-primary"
                />
                :
                <div>
                    <Row className="align-items-center mb-1 g-3 text-center text-sm-start">
                        <div className="col-sm">
                            <div className="text-muted">
                                {props.t("common.pagination.showing")}
                                <span className="fw-semibold ms-1">
                                    {data.length > 0 ? `${(pagination.offset ? pagination.offset : 0) + 1}-${Math.min((pagination.offset ? pagination.offset : 0) + pagination.size, pagination.elements)} ${props.t("common.pagination.of")} ${pagination.elements} ${props.t("common.pagination.results")}` : `0-0 of 0 results`}
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-auto">
                            <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                                currentPage={pagination?.page}
                                pageChanged={(e: any) => pageChanged(e)}
                                pageSize={pagination.size}
                                isPageSizeChange={isPageSizeChange}
                                onChangeInSelect={(e: any) => onChangeInSelect(e)}
                                pagination={pagination}
                                props={props}
                            />
                        </div>
                    </Row>
                    {!dataLoading && page && page.length ?
                        <div>
                            <Table responsive={true} hover {...getTableProps()} className={tableClass + 'm-0'}>
                                <thead className={theadClass}>
                                    {headerGroups.map((headerGroup: any) => (
                                        <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                                            {checkbox && <th className={thClass + " fw-bold"} >Checkbox</th>}
                                            {headerGroup.headers.map((column: any) => (
                                                <th key={column.id} className={thClass + " fw-bold"}
                                                    {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}

                                                >
                                                    <div onClick={() => handleSort(column.id)} className={"cursor-pointer" + `${column.accessor == 'course_name' ? '' : ' otherth'}`} title={column.Header}>
                                                        {column.render("Header")}
                                                        {sortBy === column.id && (
                                                            <span>
                                                                {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                                                            </span>
                                                        )}
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()} style={{ minHeight: 300 }}>
                                    {page.map((row: any) => {
                                        prepareRow(row);
                                        return (
                                            <Fragment key={row.getRowProps().key}>
                                                <tr style={{ verticalAlign: 'middle', maxHeight: 50 }}>
                                                    {checkbox && <td
                                                        style={{ verticalAlign: 'middle' }}
                                                        className="align-items-center justify-content-between"
                                                    >{checkbox(row?.original)}</td>}
                                                    {row.cells.map((cell: any) => {
                                                        return (
                                                            <td
                                                                key={cell.id}
                                                                style={{ verticalAlign: 'middle' }}
                                                                className="align-items-center justify-content-between"
                                                                {...cell.getCellProps()}
                                                            >
                                                                {cell.render("Cell")}
                                                            </td>
                                                        );
                                                    })
                                                    }
                                                </tr>
                                            </Fragment>
                                        );
                                    })}
                                </tbody>
                            </Table></div> :
                        <Table responsive={true} hover {...getTableProps()} className={tableClass + 'm-0'}>
                            <thead className={theadClass}>
                                {headerGroups && headerGroups.length ? headerGroups.map((headerGroup: any) => (
                                    <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column: any) => (
                                            <th key={column.id} className={thClass + " fw-bold"}
                                                {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}

                                            >
                                                <div onClick={() => handleSort(column.id)} className={"cursor-pointer" + `${column.accessor == 'course_name' ? '' : ' otherth'}`} title={column.Header}>
                                                    {column.render("Header")}
                                                    {sortBy === column.id && (
                                                        <span>
                                                            {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                                                        </span>
                                                    )}
                                                </div>
                                            </th>
                                        ))}
                                        <th className={thClass + " fw-bold"} >
                                            {props.t("common.pagination.actions")}
                                        </th>
                                    </tr>
                                )) : null}
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={headerGroups && headerGroups.length && headerGroups[0].headers.length + 1} className="p-5 fw-semibold fs-16 text-center">
                                        <div className="hstack p-5 justify-content-center fw-semibold fs-16 text-center w-100">
                                            {props.t("common.pagination.data_error")}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>}
                    <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                        <div className="col-sm">
                            <div className="text-muted">
                                {props.t("common.pagination.showing")}
                                <span className="fw-semibold ms-1">
                                    {data.length > 0 ? `${(pagination.offset ? pagination.offset : 0) + 1}-${Math.min((pagination.offset ? pagination.offset : 0) + pagination.size, pagination.elements)} ${props.t("common.pagination.of")} ${pagination.elements} ${props.t("common.pagination.results")}` : `0-0 of 0 ${props.t("common.pagination.results")}`}
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-auto">
                            <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                                currentPage={pagination?.page}
                                pageChanged={(e: any) => pageChanged(e)}
                                pageSize={pagination.size}
                                isPageSizeChange={isPageSizeChange}
                                onChangeInSelect={(e: any) => onChangeInSelect(e)}
                                pagination={pagination}
                                props={props}
                            />
                        </div>
                    </Row>
                </div>}
        </div>
    );
};

export default withTranslation()(TableContainer);