import {RecommendActionTypes, Recommend} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { responseHandler } from "utils";

const { api } = config;

const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const CreateRecommendations: AppThunk = (data, handleSuccess, handleError, hidemessage) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: RecommendActionTypes.POST_RECOMMEND_COURSES_REQUEST
        });
        const response = await fetch(`${DTS_API_STUDENT}/recommendations`, {
            method: "POST",
            headers:headers,
            body: JSON.stringify(data)
        });
               
        const error = {
            201: {
                "message": "Successfull",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, RecommendActionTypes.POST_RECOMMEND_COURSES_SUCCESS, RecommendActionTypes.POST_RECOMMEND_COURSES_ERROR, error, handleSuccess, handleError, "POST", hidemessage);

      
    };
};


export const confirmRecommendations: AppThunk = (tenant_id,recommendation_id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({
            type: RecommendActionTypes.POST_CONFIRMED_COURSES_REQUEST
        });
        const response = await fetch(`${DTS_API_STUDENT}/applications/open/${tenant_id}/${recommendation_id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: RecommendActionTypes.POST_CONFIRMED_COURSES_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: RecommendActionTypes.POST_CONFIRMED_COURSES_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};

export const getRecommendationsDetails: AppThunk = (tenant_id,recommend_id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: RecommendActionTypes.GET_RECOMMENDATIONS_DETAILS_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/recommendations/open/${tenant_id}/${recommend_id}`, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, RecommendActionTypes.GET_RECOMMENDATIONS_DETAILS_SUCCESS, RecommendActionTypes.GET_RECOMMENDATIONS_DETAILS_ERROR, null, null, null, "GET");
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
}; 


const recommendApplicationQuery = (id: any,filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/applications/shortlisted?studentId=${id}&`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const recommendApplicationList: AppThunk = (id,filterObject,page, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: RecommendActionTypes.GET_RECOMMENDATIONS_List_REQUEST
        });
        let searchquery = recommendApplicationQuery(id,filterObject, page)
        try {
            const response = await fetch(searchquery, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: RecommendActionTypes.GET_RECOMMENDATIONS_List_ERROR,
                    payload: body
                });
            } else {
                dispatch({
                    type: RecommendActionTypes.GET_RECOMMENDATIONS_List_SUCCESS,
                    payload: body
                });
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};  

export const getAuthorizationFormDetails: AppThunk = (tenant_id, model, id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (tenant_id) {
            headers.append('tenantId', tenant_id);
        }  
        dispatch({
            type: RecommendActionTypes.GET_AUTHORIZATIONFORM_DETAILS_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/models/open/${model}/${id}`, {
                method: "GET",
                headers: headers,
            });
            await responseHandler(response, dispatch, RecommendActionTypes.GET_AUTHORIZATIONFORM_DETAILS_SUCCESS, RecommendActionTypes.GET_AUTHORIZATIONFORM_DETAILS_ERROR, null, null, null, "GET");
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
}; 

export const recommendationListGetRequest: AppThunk = (data, page, size, signal) => {
    let requestbody = data ? data : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: RecommendActionTypes.GET_RECOMMENDATIONS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        try {
            const response = await fetch(`${DTS_API_STUDENT}/models/get/recommendations?page=${page?page:0}&pageSize=${size?size:10}`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(requestbody),
                signal
            }).catch((error) => {
                return false;
            });                                
            if(typeof response === 'object' && !Array.isArray(response) && response !== null) {           
                await responseHandler(response, dispatch, RecommendActionTypes.GET_RECOMMENDATIONS_SUCCESS, RecommendActionTypes.GET_RECOMMENDATIONS_ERROR, null, null, null, "GET");
            } 
        }
        catch (error) {
        }
    };
};

export const recommendationUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: RecommendActionTypes.PUT_RECOMMENDATIONS_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/models/recommendations/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );      
                              
        const error = {
            204: {
                "message": "Invoice updated Successfully",
                "success": true
            },
            200: {
                "message": "Invoice updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, RecommendActionTypes.PUT_RECOMMENDATIONS_SUCCESS, RecommendActionTypes.PUT_RECOMMENDATIONS_ERROR, error, handleSuccess, handleError);
    
    };
};