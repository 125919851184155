import React from 'react'
import { Button } from 'reactstrap';
import DashboardCharts from './DashboardCharts';
import { withTranslation } from 'react-i18next';

interface FProps {
    percentage: number
    file: File | null
    t: any
}
const Uploading: React.FC<FProps> = ({percentage, file, t}) => {
    return (
        <div className='m-4'>
          <div className="flex-shrink-0">
            <DashboardCharts
              seriesData={percentage}
              dataColors={'["--vz-success"]'}
            />
            <div className='title text-center'>{t("userpreferences.uploading")} </div>
            <div className='text-nowrap text-truncate text-nowrap-ellipsis'>
            {file?.name}
            </div>
          </div>            
        </div>
    )
}

export default withTranslation()(Uploading)
