import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import Form from "Components/Common/FormBuilder/UpdateForm";
import { useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { checkTheyOwn, getViewList, isJson } from "utils";
import { Input } from "reactstrap";
import FormLoader from "./FormLoader";
import { withTranslation } from "react-i18next";

interface FormField {
    name: string;
    label: string;
    type: string;
    options?: { value: string; label: string }[];
}


const UpdateInfo = ({ fields, details, model, getDetailsAction, updateAction, metaDataFields, metaLoading, t, tog_backdrop}: any) => {
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const [statusMetaData, setStatusMetaData] = useState<any>([]);
    const [edit, setEdit] = useState<any>(false);
    const [studentId, setStudentId] = useState('')
    const [applicationId, setApplicationId] = useState(null)
    const [searchValue, setSearchValue]= useState<any>('')
    const [loader, setLoader]= useState<any>({})

    const onUpdate = (keyValue: any, type?: any) => {
        const key = Object.keys(keyValue)[0];
        setLoader({[key]: true})
        const handleSuccess = (body: any) => {
            dispatch(getDetailsAction(id))
            setLoader({[key]: false})
            tog_backdrop();
        }
        dispatch(updateAction(id, keyValue, handleSuccess, () => { }))
        
    }
    useEffect(() => {       
        getViewList(dispatch, model, "about");
    }, [])

    useEffect(() => {
        handleGetList()
        if(details?.permissions) {
           setEdit(details?.permissions?.edit?.access)
        }
    }, [details])

    const handleGetList = () => {
        if(model == "students") {
            if (details && details.id) {
                setStudentId(details.id)
            }
        }
        else {
            if (details && details.id) {
                setApplicationId(details.id)
                let studentsId = details && details?.valuesJson.student && details?.valuesJson.student.id ? details?.valuesJson.student.id : "";
                setStudentId(studentsId)
            }
        }     
    }

    const handleViewAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    }
    const handleEditAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return false;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    };

    useEffect(() => {
        let newstatusMetaData: any = []
        metaDataFields && metaDataFields.map((field: any) => {
            let statusField = field?.valuesJson && isJson(field?.valuesJson) ? JSON.parse(field?.valuesJson) : {}               
            let permissionsJson = field?.permissionsJson && isJson(field?.permissionsJson) ? JSON.parse(field?.permissionsJson) : {}
            let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleViewAccess(permissionsJson) : true;
            let isFieldEditaccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson) : true;
            const key = statusField?.key || '';
            const label = statusField?.label || '';
            let isSearch =  searchValue === "" ? true :key.toLowerCase().includes(searchValue.toLowerCase()) || label.toLowerCase().includes(searchValue.toLowerCase());
            if (isSearch && isFieldviewaccess && !statusField?.process_flows) {
                if (isFieldEditaccess) {
                    let newField = { ...field, isEditAccess: true }
                    return newstatusMetaData.push(newField)
                }
                return newstatusMetaData.push(field)
            }
        })
        setStatusMetaData(newstatusMetaData)
    }, [metaDataFields, searchValue])

   return (
        <div>
            <form action="" className="d-flex flex-column justify-content-end h-100">
                  
                <div className="search-box p-2 ps-0 mb-2">
                    <Input className='' placeholder={t("common.filter.search.search_for_field")} style={{ padding: '1.2rem 0.9rem' }} onChange={(e: any) => setSearchValue(e.target.value)} value={searchValue}></Input>
                    {searchValue === "" ? null : <i className=" ri-close-circle-fill search-icon cursor-pointer text-danger fs-16" style={{ right: 12, left: 'auto', top: 0, position: "absolute", zIndex: 99 }} onClick={() => {setSearchValue("")}}></i>}
                </div> 
                <div className="live-preview vstack gap-2">
                    <div className="live-preview vstack gap-2">
                        <SimpleBar autoHide={false} className="simplebar-track-dark pe-2 pb-5" style={{ maxHeight: "500px" }}>
                            { !metaLoading ?
                                <Form dataFields={statusMetaData} onUpdate={onUpdate} showbtn={false} data={{...details, studentId: studentId, applicationId:applicationId}} formfor={[model]} fields={fields} isEditAccess={edit} updateLoading={loader} />
                                : <FormLoader  rows={8} cols={1} />
                            }
                            </SimpleBar>
                    </div>
                </div>
            </form>
        </div>

    );
};

export default withTranslation()(UpdateInfo);
