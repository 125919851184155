import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { useSelector, useDispatch } from "react-redux";
import SimpleBar from "simplebar-react";
import UpcommingEvents from "./UpcommingEvents";
import listPlugin from "@fullcalendar/list";
import { deleteGoogleEvent, getGoogleCalendarData, getCalendarEvents } from "store/calendarSync/action";
import { ApplicationState } from "store";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { studentGetRequest } from "store/student/action";
import DeleteModal from "../Views/innerComponent/DeleteModal";
import ConnectAccount from "./ConnectAccount";
import {
  meetingsCreateRequest,
  meetingsListGetRequest,
  meetingssMetadataGetRequest,
  meetingsUpdateRequest
} from "store/meetings/action";
import { isJson } from "utils";
import AddEntryModal from "../Activities/Meetings/innerComponent/AddEntryModal";

const BYDAY: any = { SU: 0, MO: 1, TU: 2, WE: 3, TH: 4, FR: 5, SA: 6 };

export interface SuggestionOption {
  readonly value: string;
  readonly label: string;
}

interface PostEvent {
  summary: string;
  location: string;
  description: string;
  eventDate: Date | null;
  startDateTime: Date | null;
  endDateTime: Date | null;
  attendees: string[];
}

// Helper: Extract a valid date value from a Google time object.
const getGoogleDateValue = (timeObj: any) => {
  if (!timeObj) return null;
  // Sometimes Google returns an object with a dateTime property that contains a "value".
  if (timeObj.dateTime) return timeObj.dateTime.value || timeObj.dateTime;
  return timeObj;
};

// Helper: Extract a valid date value from a Microsoft event time object.
const getMicrosoftDateValue = (timeObj: any) => {
  if (!timeObj) return null;
  return timeObj.dateTime;
};

const Meetings = ({ props, communicate, info, model, setTriggerApi, t }: any) => {
  const { id } = useParams();
  const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
  const dispatch: any = useDispatch();
  const googleConnect = useSelector((state: ApplicationState) => state.calendarSync.data);
  const eventData = useSelector((state: ApplicationState) => state.calendarSync?.events);
  const eventDataModel: any = useSelector((state: ApplicationState) => state.meetings.list);
  const meetingMetaData: any = useSelector((state: ApplicationState) => state.meetings.metaDataFields);
  const [event, setEvent] = useState<any>({});
  const [modal, setModal] = useState<boolean>(false);
  const [selectedNewDay, setSelectedNewDay] = useState(moment().format());
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [newStartTime, setNewStartTime] = useState(moment().add(30, "minutes").format());
  const [newEndTime, setNewEndTime] = useState(moment(newStartTime).add(60, "minutes").format());
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [statusOptions, setStatusOptions] = useState<{ label: string; value: string }[]>([]);
  const [detailsModal, setDetailsModal] = useState<any>(false);
  const [isAllMeetings, setIsAllMeetings] = useState<boolean>(false);
  const [eventsArr, setEvents] = useState<any>([]);
  const [emailData, setEmailData] = useState<any>({
    to: info?.email || info?.student?.email
  });
  const [postEvent, setPostEvent] = useState<PostEvent>({
    summary: "",
    location: "",
    description: "",
    eventDate: selectedNewDay,
    startDateTime: moment().add(30, "minutes").format(),
    endDateTime: moment(moment().add(30, "minutes").format()).add(60, "minutes").format(),
    attendees: [info.email || info?.student?.email]
  });

  useEffect(() => {
    const filterObject = { pageSize: 500 };
    const data = {
      filters: [
        {
          quick: [],
          advance: [],
          search: null,
          parent: [
            {
              key: "student",
              keyLabel: ["Student"],
              condition: "IN",
              conditionLabel: "is any of",
              values: [id],
              valuesLabel: [],
              property: {},
              quick: true
            }
          ]
        }
      ],
      sorts: [{ field: "dueDate", order: "desc" }]
    };
    dispatch(meetingsListGetRequest(data));
    dispatch(meetingssMetadataGetRequest(filterObject));
    dispatch(getGoogleCalendarData());
  }, [modal]);

  useEffect(() => {
    if (model === "students") {
      if (info.email) {
        setEmailData((prevState: any) => ({ ...prevState, to: info.email }));
        setPostEvent((prevState) => ({ ...prevState, attendees: [info.email] }));
      }
    } else {
      if (info?.student?.email) {
        setEmailData((prevState: any) => ({ ...prevState, to: info.student.email }));
        setPostEvent((prevState) => ({ ...prevState, attendees: [info.student.email] }));
      }
    }
  }, [info, model]);

  useEffect(() => {
    dispatch(studentGetRequest(id));
  }, [id]);

  // Merge backend meetings and connected calendar events (Google and Microsoft)
  useEffect(() => {
    const mergedEvents: any = [];

    // Process backend meetings
    eventDataModel?.forEach((meeting: { id: any; valuesJson: any; }) => {
      const { id, valuesJson } = meeting;
      const {
        status,
        start_time,
        end_time,
        location,
        event_name,
        description,
        meeting_type,
        invitee_email,
        location_address,
        link
      } = valuesJson || {};
      if (!status || !start_time) {
        console.warn(`Meeting with ID ${id} is missing status or start time. Skipping.`);
        return;
      }
      const startDate = new Date(start_time);
      if (isNaN(startDate.getTime())) {
        console.warn(`Backend meeting with ID ${id} has invalid start time. Skipping.`);
        return;
      }
      const endDate = end_time ? new Date(end_time) : null;
      mergedEvents.push({
        id,
        title: event_name,
        description,
        locationType: location,
        address: location_address,
        meetingLink: link,
        start: startDate.toISOString(),
        end: endDate && !isNaN(endDate.getTime()) ? endDate.toISOString() : null,
        className: "bg-info-subtle",
        meetingType: meeting_type,
        invitee: invitee_email?.email || "N/A",
        status: status || ""
      });
    });

    // Determine which provider is connected.
    const provider = googleConnect?.[0]?.provider || "google";

    // Process connected calendar events if "Show All Meetings" is enabled.
    if (isAllMeetings) {
      eventData?.forEach((ev: any) => {
        if (provider === "microsoft") {
          // Process all events as Microsoft events.
          const id = ev.id;
          const title = ev.subject;
          const startTime = getMicrosoftDateValue(ev.start);
          const endTime = getMicrosoftDateValue(ev.end);
          const meetingLink = ev.onlineMeeting ? ev.onlineMeeting.joinUrl : "";
          const locationType = ev.onlineMeeting ? "online" : "offline";
          const address = ev.location ? ev.location.displayName : "";
          const description = ev.body?.content || ev.description || "";
          if (!startTime) {
            console.warn(`Microsoft event with ID ${id} missing start time. Skipping.`);
            return;
          }
          const startDate = new Date(startTime);
          if (isNaN(startDate.getTime())) {
            console.warn(`Microsoft event with ID ${id} has invalid start time. Skipping.`);
            return;
          }
          const endDate = endTime ? new Date(endTime) : null;
          mergedEvents.push({
            id,
            title,
            description,
            locationType,
            address,
            meetingLink,
            start: startDate.toISOString(),
            end: endDate && !isNaN(endDate.getTime()) ? endDate.toISOString() : null,
            className: "bg-info-subtle",
            status: ev.status || ""
          });
          console.log("mergedEvents", mergedEvents);
        } else {
          // Process all events as Google events.
          // Check for recurring events.
          const { id, summary, start, end, hangoutLink, location, description, status, location_address, link, recurrence } = ev;
          if (recurrence && recurrence.length > 0) {
            const rule = recurrence[0].split(";").reduce((acc: any, item: string) => {
              const [key, value] = item.split("=");
              acc[key] = value;
              return acc;
            }, {});
            const byday = rule["BYDAY"] ? rule["BYDAY"].split(",").map((d: string) => BYDAY[d]) : [];
            const startTimeZoneShift = start?.dateTime?.timeZoneShift || 0;
            const endTimeZoneShift = end?.dateTime?.timeZoneShift || 0;
            const startValue = getGoogleDateValue(start);
            const endValue = getGoogleDateValue(end);
            if (!startValue) {
              console.warn(`Recurring Google event with ID ${id} missing start time. Skipping.`);
              return;
            }
            const startDate = new Date(startValue);
            if (isNaN(startDate.getTime())) {
              console.warn(`Recurring Google event with ID ${id} has invalid start time. Skipping.`);
              return;
            }
            mergedEvents.push({
              id,
              title: summary,
              description,
              daysOfWeek: byday,
              startTime: startValue
                ? new Date(startValue + startTimeZoneShift * 60 * 1000)
                    .toISOString()
                    .split("T")[1]
                    .split(".")[0]
                : "",
              endTime: endValue && endValue
                ? new Date(endValue + endTimeZoneShift * 60 * 1000)
                    .toISOString()
                    .split("T")[1]
                    .split(".")[0]
                : "",
              endRecur: rule.UNTIL,
              className: "bg-info-subtle",
              meetingLink: hangoutLink ? hangoutLink : link,
              status: status || ""
            });
          } else {
            const startValue = getGoogleDateValue(start);
            const endValue = getGoogleDateValue(end);
            if (!startValue) {
              console.warn(`Google event with ID ${id} missing start time. Skipping.`);
              return;
            }
            const startDate = new Date(startValue);
            if (isNaN(startDate.getTime())) {
              console.warn(`Google event with ID ${id} has invalid start time. Skipping.`);
              return;
            }
            const endDate = endValue ? new Date(endValue) : null;
            mergedEvents.push({
              id,
              title: summary,
              description,
              locationType: location,
              address: location_address,
              meetingLink: hangoutLink ? hangoutLink : link,
              start: startDate.toISOString(),
              end: endDate && !isNaN(endDate.getTime()) ? endDate.toISOString() : null,
              className: "bg-info-subtle",
              status: status || ""
            });
          }
        }
      });
    }

    setEvents(mergedEvents);
  }, [eventData, eventDataModel, isAllMeetings, googleConnect]);

  useEffect(() => {
    if (!meetingMetaData?.length) return;
    let extractedStatus: { label: string; value: string }[] = [];
    meetingMetaData.forEach((field: any) => {
      if (field?.valuesJson && isJson(field.valuesJson)) {
        const parsedJson = JSON.parse(field.valuesJson);
        if (Array.isArray(parsedJson.values)) {
          extractedStatus = parsedJson.values.map((item: any) => ({
            label: item.label,
            value: item.value
          }));
        }
      }
    });
    setStatusOptions(extractedStatus);
  }, [meetingMetaData, modal]);

  useEffect(() => {
    if (googleConnect?.length) {
      const email = googleConnect[0]?.email;
      const provider = googleConnect[0]?.provider;
      dispatch(getCalendarEvents(email, provider));
    }
  }, [googleConnect]);

  const toggle = () => {
    if (modal) {
      clearNewMeeting();
      setModal(false);
      handleClearEvent();
      setIsEdit(false);
    } else {
      setModal(true);
    }
  };

  const setOnIsAllMeetings = (isAll: boolean) => {
    setIsAllMeetings(isAll);
  };

  const clearNewMeeting = () => {
    setPostEvent({
      summary: "",
      location: "",
      description: "",
      eventDate: selectedNewDay,
      startDateTime: moment().add(30, "minutes").format(),
      endDateTime: moment(moment().add(30, "minutes").format()).add(60, "minutes").format(),
      attendees: [info?.email || info?.student?.email]
    });
    setEmailData({
      to: info?.email || info?.student?.email
    });
    setNewStartTime("");
    setNewEndTime("");
  };

  const handleDateClick = (arg: any) => {
    if (arg) {
      const selectedDate = moment(arg.date);
      let roundedStartTime = moment(selectedDate).startOf("hour");
      if (moment().isAfter(roundedStartTime)) {
        roundedStartTime.add(1, "hour");
      }
      const endTime = moment(roundedStartTime).add(30, "minutes");
      const formattedStartDateTime = roundedStartTime.format();
      const formattedEndDateTime = endTime.format();
      setPostEvent((prev: any) => ({
        ...prev,
        eventDate: formattedStartDateTime,
        startDateTime: formattedStartDateTime,
        endDateTime: formattedEndDateTime
      }));
      setSelectedNewDay(new Date(formattedStartDateTime));
      setNewStartTime(new Date(formattedStartDateTime));
      setNewEndTime(new Date(formattedEndDateTime));
    }
    toggle();
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleStatusUpdate = (eventId: any, newStatus: { label: string; value: string }) => {
    setDisabled(true);
    setEvent((prev: any) => ({ ...prev, status: newStatus.value }));
    const updatedData = { status: newStatus.value };
    const handleSuccess = () => {
      setDisabled(false);
      setDropdownOpen(false);
      setModal(false);
    };
    const handleFailure = () => {
      setDisabled(false);
    };
    dispatch(meetingsUpdateRequest(eventId, updatedData, handleSuccess, handleFailure));
  };

  const handleCreateEventClick = () => {
    toggle();
  };

  const str_dt = (date: any) => {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const d = new Date(date),
      month = monthNames[d.getMonth()],
      day = d.getDate(),
      year = d.getFullYear();
    return `${day < 10 ? "0" + day : day} ${month} ${year}`;
  };

  /**
   * When an event is clicked, display its details in a beautiful modal.
   * The modal shows:
   *   - Event Name
   *   - Date
   *   - Start - End Time
   *   - Location (online/offline)
   *   - Address (if available)
   *   - Link (clickable if available)
   *   - Description
   *   - A dropdown to change the meeting status (using flex for alignment)
   */
  const handleEventClick = (arg: any) => {
    const clickedEvent = arg.event;
    const startDate = clickedEvent.start;
    const endDate = clickedEvent.end || new Date(new Date(startDate).getTime() + 60 * 60 * 1000);
    const formattedDate = moment(startDate).format("DD MMM YYYY");
    const formattedStartTime = moment(startDate).format("hh:mm A");
    const formattedEndTime = moment(endDate).format("hh:mm A");

    setEvent({
      id: clickedEvent.id,
      title: clickedEvent.title,
      start: startDate,
      end: endDate,
      locationType: clickedEvent.extendedProps?.locationType || "N/A",
      address: clickedEvent.extendedProps?.address || "",
      meetingLink: clickedEvent.extendedProps?.meetingLink || "",
      description: clickedEvent.extendedProps?.description || "",
      formattedDate,
      formattedTime: `${formattedStartTime} - ${formattedEndTime}`,
      status: clickedEvent.extendedProps?.status || "Unknown"
    });
    setIsEdit(true);
    setDetailsModal(true);
  };

  const infomessage = "To create and view meetings you have to connect your calendar with your social account in your profile.";

  const handleDeleteEvent = (eventId: any) => {
    const email = googleConnect[0]?.email;
    const provider = googleConnect[0]?.provider;
    const handleSuccess = () => {
      setDeleteModal(false);
      dispatch(getCalendarEvents(email, provider));
    };
    dispatch(deleteGoogleEvent(email, eventId, handleSuccess));
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: eventsArr?.id || "",
      title: eventsArr?.title || "",
      category: event?.category || "",
      location: eventsArr?.location || "",
      description: eventsArr?.description || "",
      defaultDate: event?.defaultDate || [],
      eventDate: eventsArr?.eventDate || [],
      start: eventsArr?.start || [],
      end: eventsArr?.end || [],
      status: eventsArr?.status || ""
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
      location: Yup.string().required("Please Enter Your Location"),
      description: Yup.string().required("Please Enter Your Description"),
      eventDate: Yup.date().required("Event date is required"),
      start: Yup.date().required("Start Time is required"),
      end: Yup.date().required("End Time is required"),
      defaultDate: Yup.array().of(Yup.date()).required("Date range is required").min(2, "Select at least two dates")
    }),
    onSubmit: () => {
      let updatedDay: any = "";
      if (selectedNewDay) {
        updatedDay = new Date(selectedNewDay[1]);
        updatedDay.setDate(updatedDay.getDate() + 1);
      }
      validation.resetForm();
      setSelectedNewDay(null);
      toggle();
    }
  });

  const handleDateSelect = (selectInfo: any) => {
    const calendarApi = selectInfo.view.calendar;
    const start = selectInfo.startStr;
    const end = selectInfo.endStr;
    let title;
    if (title) {
      calendarApi.addEvent({
        title,
        start,
        end,
        allDay: false
      });
    }
  };

  const handleClearEvent = () => {
    setSelectedNewDay("");
    setNewStartTime("");
    setNewEndTime("");
  };

  document.title = "Calendar | Zilter";

  return (
    <React.Fragment>
      {modal && (
        <AddEntryModal
          show={modal}
          onCloseClick={() => setModal(false)}
          dataFields={meetingMetaData}
          setTriggerApi={setTriggerApi}
          props={props}
          createAction={meetingsCreateRequest}
          parentId={id}
          parent={"student"}
          to={emailData?.to}
        />
      )}
      <DeleteModal
        props={props}
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
        record={event}
        onDelete={() => handleDeleteEvent(event.id)}
      />
      {googleConnect && googleConnect.length > 0 ? (
        <div>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xl={3}>
                  <Card className="bg-light card-h-100 shadow-sm mb-3">
                    <CardBody>
                      {(userProfile?.subAgent?.enable_communication !== "false" ||
                        (userProfile?.subAgent?.enable_communication !== "false" && communicate)) && (
                        <Button
                          color="primary"
                          type="button"
                          className="text-nowrap btn btn-label w-100"
                          id="btn-new-event"
                          onClick={handleCreateEventClick}
                        >
                          <i className="ri-calendar-event-fill label-icon align-middle fs-16 me-2"></i>
                          {t("student.details.activities.create_new_event")}
                        </Button>
                      )}
                    </CardBody>
                  </Card>
                  <Card className="bg-light card-h-100 shadow-sm mb-3">
                    <CardBody>
                      <div className="form-check form-switch form-switch-lg" dir="ltr">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          checked={isAllMeetings}
                          onChange={() => setOnIsAllMeetings(!isAllMeetings)}
                        />
                        <div className="d-flex gap-2">
                          <Label className="form-check-label fw-bold fs-5">
                            {t("student.details.activities.show_all_meetings")}
                          </Label>
                        </div>
                      </div>
                      <div className="text-muted small">
                        <abbr title={isAllMeetings ? "Showing all meetings from your connected calendar." : "Showing only student meetings."}>
                          {t("student.details.activities.all_meetings_display_info_message")}
                        </abbr>
                      </div>
                    </CardBody>
                  </Card>
                  <div>
                    <h5 className="mb-1">{t("student.details.activities.upcoming_events")}</h5>
                    <p className="text-muted">{t("student.details.activities.dont_miss_scheduled_events")}</p>
                    <SimpleBar className="pe-2 me-n1 mb-3" style={{ height: "400px" }}>
                      <div id="upcoming-event-list">
                        {eventsArr &&
                          eventsArr.map((ev: any, key: any) => (
                            <div key={key}>
                              <UpcommingEvents event={ev} />
                            </div>
                          ))}
                      </div>
                    </SimpleBar>
                  </div>
                </Col>
                <Col lg={9}>
                  <Card className="bg-light card-h-100 shadow-sm">
                    <CardBody>
                      <FullCalendar
                        plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin, listPlugin]}
                        select={handleDateSelect}
                        initialView="dayGridMonth"
                        slotDuration={"00:15:00"}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek"
                        }}
                        events={eventsArr}
                        editable={true}
                        droppable={true}
                        selectable={true}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Modal isOpen={detailsModal} id="event-modal" centered>
                <ModalHeader
                  toggle={() => {
                    setDetailsModal(false);
                    setEvent(null);
                  }}
                  tag="h5"
                  className="p-3 bg-primary d-flex justify-content-between align-items-center"
                >
                  <span className="text-light">{event?.title || "Event Details"}</span>
                </ModalHeader>
                <ModalBody>
                  <div className="d-flex flex-column">
                    <div className="d-flex mb-2">
                      <div className="flex-shrink-0 me-2"><strong>Event Name:</strong></div>
                      <div className="flex-grow-1">{event?.title || "N/A"}</div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="flex-shrink-0 me-2"><strong>Date:</strong></div>
                      <div className="flex-grow-1">{event?.formattedDate || moment(event?.start).format("DD MMM YYYY")}</div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="flex-shrink-0 me-2"><strong>Time:</strong></div>
                      <div className="flex-grow-1">{event?.formattedTime || (event ? moment(event.start).format("hh:mm A") + " - " + moment(event.end).format("hh:mm A") : "N/A")}</div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="flex-shrink-0 me-2"><strong>Location:</strong></div>
                      <div className="flex-grow-1">{event?.locationType ? event.locationType.toUpperCase() : "N/A"}</div>
                    </div>
                    {event?.address && (
                      <div className="d-flex mb-2">
                        <div className="flex-shrink-0 me-2"><strong>Address:</strong></div>
                        <div className="flex-grow-1">{event.address}</div>
                      </div>
                    )}
                    <div className="d-flex mb-2">
                      <div className="flex-shrink-0 me-2"><strong>Link:</strong></div>
                      <div className="flex-grow-1">
                        {event?.meetingLink ? (
                          <a href={event.meetingLink} target="_blank" rel="noopener noreferrer">
                            {event.meetingLink}
                          </a>
                        ) : (
                          "Not Provided"
                        )}
                      </div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="flex-shrink-0 me-2"><strong>Description:</strong></div>
                      <div className="flex-grow-1">{event?.description || "No Description"}</div>
                    </div>
                    <div className="d-flex align-items-center mt-3">
                      <div className="flex-shrink-0 me-2"><strong>Status:</strong></div>
                      <div className="flex-grow-1">
                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                          <DropdownToggle color="secondary" size="sm">
                            {event?.status ? event.status.replace(/_/g, " ") : "Update Status"}
                          </DropdownToggle>
                          <DropdownMenu>
                            {statusOptions.map((status) => (
                              <DropdownItem key={status.value} onClick={() => handleStatusUpdate(event.id, status)}>
                                {status.label}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </div>
      ) : (
        <ConnectAccount infomessage={infomessage} />
      )}
    </React.Fragment>
  );
};

export default withTranslation()(Meetings);
