export interface StudentsMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface notes   {
    id: string,
    shortNote: string,
    detailedNote: string,
    createdBy: string,
    createdAt: string,
    replies: any,
    updatedAt: string,
    deletedAt: string
}
export interface Student {
    id: string,
    userId: string,
    valuesJson: any,
    notes: notes[],
    tasks: any,
    email: any
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

  export interface Footprints {
    id: string,
    studentId: string,
    valuesJson: string,
    createdAt: string
  }

export enum StudentActionTypes {
    GET_STUDENTMETADATA_REQUEST = "@@student/GET_STUDENTMETADATA_REQUEST",
    GET_STUDENTMETADATA_SUCCESS = "@@student/GET_STUDENTMETADATA_SUCCESS",
    GET_STUDENTMETADATA_ERROR = "@@student/GET_STUDENTMETADATA_ERROR",
    GET_STUDENTS_EMAILS_REQUEST = "@@student/GET_STUDENTS_EMAILS_REQUEST",
    GET_STUDENTS_EMAILS_SUCCESS = "@@student/GET_STUDENTS_EMAILS_SUCCESS",
    GET_STUDENTS_EMAILS_ERROR = "@@student/GET_STUDENTS_EMAILS_ERROR",
    GET_STUDENTS_ACTIVITY_REQUEST = "@@student/GET_STUDENTS_ACTIVITY_REQUEST",
    GET_STUDENTS_ACTIVITY_SUCCESS = "@@student/GET_STUDENTS_ACTIVITY_SUCCESS",
    GET_STUDENTS_ACTIVITY_ERROR = "@@student/GET_STUDENTS_ACTIVITY_ERROR",
    GET_STUDENT_REQUEST = "@@student/GET_STUDENT_REQUEST",
    GET_STUDENT_SUCCESS = "@@student/GET_STUDENT_SUCCESS",
    GET_STUDENT_ERROR = "@@student/GET_STUDENT_ERROR",
    GET_STUDENTS_REQUEST = "@@student/GET_STUDENTS_REQUEST",
    GET_STUDENTS_SUCCESS = "@@student/GET_STUDENTS_SUCCESS",
    GET_STUDENTS_ERROR = "@@student/GET_STUDENTS_ERROR",
    GET_FILTER_STUDENTS_REQUEST = "@@student/GET_FILTER_STUDENTS_REQUEST",
    GET_FILTER_STUDENTS_SUCCESS = "@@student/GET_FILTER_STUDENTS_SUCCESS",
    GET_FILTER_STUDENTS_ERROR = "@@student/GET_FILTER_STUDENTS_ERROR",
    PUT_STUDENT_REQUEST = "@@student/PUT_STUDENT_REQUEST",
    PUT_STUDENT_SUCCESS = "@@student/PUT_STUDENT_SUCCESS",
    PUT_STUDENT_ERROR = "@@student/PUT_STUDENT_ERROR",
    PUT_OPEN_STUDENT_REQUEST = "@@student/PUT_OPEN_STUDENT_REQUEST",
    PUT_OPEN_STUDENT_SUCCESS = "@@student/PUT_OPEN_STUDENT_SUCCESS",
    PUT_OPEN_STUDENT_ERROR = "@@student/PUT_OPEN_STUDENT_ERROR",
    POST_STUDENT_EMAIL_REQUEST = "@@student/POST_STUDENT_EMAIL_REQUEST",
    POST_STUDENT_EMAIL_SUCCESS = "@@student/POST_STUDENT_EMAIL_SUCCESS",
    POST_STUDENT_EMAIL_ERROR = "@@student/POST_STUDENT_EMAIL_ERROR",
    DELETE_STUDENT_REQUEST = "@@student/DELETE_STUDENT_REQUEST",
    DELETE_STUDENT_SUCCESS = "@@student/DELETE_STUDENT_SUCCESS",
    DELETE_STUDENT_ERROR = "@@student/DELETE_STUDENT_ERROR",
    POST_STUDENT_REQUEST = "@@student/POST_STUDENT_REQUEST",
    POST_STUDENT_SUCCESS = "@@student/POST_STUDENT_SUCCESS",
    POST_STUDENT_ERROR = "@@student/POST_STUDENT_ERROR",
    GET_APPLICATIONS_REQUEST = "@@application/GET_APPLICATIONS_REQUEST",
    GET_APPLICATIONS_SUCCESS = "@@application/GET_APPLICATIONS_SUCCESS",
    GET_APPLICATIONS_ERROR = "@@application/GET_APPLICATIONS_ERROR",
    POST_EXTERNALSTUDENT_REQUEST ="@@application/POST_EXTERNALSTUDENT_REQUEST",
    POST_EXTERNALSTUDENT_SUCCESS ="@@application/POST_EXTERNALSTUDENT_SUCCESS",
    POST_EXTERNALSTUDENT_ERROR ="@@application/POST_EXTERNALSTUDENT_ERROR",
    GET_STUDENTS_FOOTPRINTS_REQUEST = "@@student/GET_STUDENTS_FOOTPRINTS_REQUEST",
    GET_STUDENTS_FOOTPRINTS_SUCCESS = "@@student/GET_STUDENTS_FOOTPRINTS_SUCCESS",
    GET_STUDENTS_FOOTPRINTS_ERROR = "@@student/GET_STUDENTS_FOOTPRINTS_ERROR"
}

export interface StudentState {
    readonly list: Student[] | null;
    readonly details: Student | null;
    readonly pagination: Pagination | null;
    readonly emailspagination: any | null
    readonly metaDataFields: StudentsMetadata[];
    readonly emails: any;
    readonly activities: any;
    readonly applicationlist: any;
    readonly Applicationpagination: any;
    readonly activitiesPagination: any;
    readonly loading: boolean;
    readonly metaLoading: boolean;
    readonly dataLoading : boolean;
    readonly footprints: Footprints[] | null;
}
