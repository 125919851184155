import { Button, Modal, ModalBody } from "reactstrap";
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const DeleteModal = ({ props, show, onCloseClick, record, onDelete }: any) => {
    return (record && record.id ?
        <Modal backdrop="static" isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalBody className='text-center p-5'>
                <div className="mt-1">
                    <i className="ri-delete-bin-5-line display-5 text-danger"></i>
                    <h4 className="mb-2 mt-2">{record.name}</h4>
                    <p className="text-muted fs-15 mb-4">{props.t("properties.delete_disclaimer1")} {record.name}. {props.t("properties.delete_disclaimer2")}</p>
                    <div className="hstack gap-2 justify-content-center">
                        <Button className="btn btn-danger" onClick={() => onDelete()}>
                            {props.t("properties.delete")}
                        </Button>
                        <Button color="danger" onClick={() => onCloseClick()} outline>
                            {props.t("common.offcanvas.import_cancel")}
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal> : null
    ) as unknown as JSX.Element;
};

export default DeleteModal;