import {ImportActionTypes} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { responseHandler } from "utils";

const { api } = config;
const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const importProcessRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: ImportActionTypes.PUT_IMPORT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/import/process/mappings`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );                 
        const error = {
            204: {
                "message": "Thank you for your request, the file import will be processed in background.",
                "success": true
            },
            200: {
                "message": "Thank you for your request, the file import will be processed in background.",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, ImportActionTypes.PUT_IMPORT_SUCCESS, ImportActionTypes.PUT_IMPORT_ERROR, error, handleSuccess, handleError);
    
    };
};

export const importListGetRequest: AppThunk = (page, size) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: ImportActionTypes.GET_IMPORT_HISTORY_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(`${DTS_API_STUDENT}/import?page=${page}&pageSize=${size}`, {
            method: "GET",
            headers: headers,
        }).catch((error) => {
            return false;
        });
        if(typeof response === 'object' && !Array.isArray(response) && response !== null) {
            const body = await response?.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: ImportActionTypes.GET_IMPORT_HISTORY_ERROR,
                    payload: body_1
                });
            } else {
                dispatch({
                    type: ImportActionTypes.GET_IMPORT_HISTORY_SUCCESS,
                    payload: body_1
                });
            }
        }
    };
};