import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import {
    Offcanvas,
    OffcanvasBody,
    Card,
    CardHeader,
    CardBody,
    Button,
    Label
} from "reactstrap";
import AddFilter from "./AddFilter";

interface FilterProps {
    show: boolean;
    onCloseClick: () => void;
    dataFields: any[];
    updateGroups: (groups: any[]) => void;
    quickFilters: any[];
    groups: any[];
    t: (key: string) => string;
}

const Filter: React.FC<FilterProps> = ({ show, onCloseClick, dataFields, updateGroups, quickFilters, groups: initialGroups, t }) => {
    const [currentFilter, setCurrentFilter] = useState<any>(null);
    const [filterGroups, setFilterGroups] = useState<any[]>([]);
    const [selectedGroupIndex, setSelectedGroupIndex] = useState<number | null>(null);
    const [selectedFilterIndex, setSelectedFilterIndex] = useState<number | null>(null);
    const prevFilterGroupsRef: any = useRef();

    // Initialize filter groups from props if provided
    useEffect(() => {
        if (initialGroups?.length) {
            setFilterGroups(initialGroups);
        } else {
            setFilterGroups([])
        }
    }, [initialGroups]);

    // Update parent component when filter groups change
    useEffect(() => {
        // Only update if filterGroups has changed
        if (JSON.stringify(filterGroups) !== JSON.stringify(prevFilterGroupsRef.current)) {
            updateGroups(filterGroups);

            // Store a deep copy to avoid mutating references
            prevFilterGroupsRef.current = cloneDeep(filterGroups);
        }
    }, [filterGroups]);

    // Handle filter selection or editing
    const handleFilterSelection = (newFilter: any, groupIndex: number) => {
        const updatedGroups = [...filterGroups];
        
        if (selectedFilterIndex !== null && selectedGroupIndex !== null) {
            // Edit existing filter
            updatedGroups[selectedGroupIndex].filters[selectedFilterIndex] = newFilter;
        } else {
            // Add new filter
            updatedGroups[groupIndex].filters.push(newFilter);
        }

        setFilterGroups(updatedGroups);
        resetSelection();
    };

    // Handle removing a filter from a group
    const handleRemoveFilter = (groupIndex: number, filterIndex: number) => {
        const updatedGroups = [...filterGroups];
        updatedGroups[groupIndex].filters.splice(filterIndex, 1);
        setFilterGroups(updatedGroups);
    };

    // Handle adding a new filter to a group
    const handleAddFilter = (groupIndex: number) => {
        setSelectedGroupIndex(groupIndex);
        setSelectedFilterIndex(null);
        setCurrentFilter(null);
    };

    // Handle adding a new group of filters
    const handleAddGroup = () => {
        setFilterGroups([...filterGroups, { filters: [] }]);
        setCurrentFilter(null);
    };

    // Handle editing an existing filter
    const handleEditFilter = (groupIndex: number, filterIndex: number) => {
        const filterToEdit = filterGroups[groupIndex].filters[filterIndex];
        setCurrentFilter(filterToEdit);
        setSelectedGroupIndex(groupIndex);
        setSelectedFilterIndex(filterIndex);
    };

    // Reset filter and group selection state
    const resetSelection = () => {
        setSelectedGroupIndex(null);
        setSelectedFilterIndex(null);
        setCurrentFilter(null);
    };
    return (
        <Offcanvas
            isOpen={show}
            toggle={onCloseClick}
            direction="end"
            className="border border-0 shadow-lg"
            backdrop={'static'}
            style={{ width: '640px' }}
        >
            <div className="d-flex flex-row justify-content-between align-items-center bg-primary p-3" style={{ height: "60px" }}>
                <h5 className="text-light p-0 m-0">{t("common.filter.advance.drawer_heading")}</h5>
                <i className="text-light ri-close-line fs-4 cursor-pointer" onClick={onCloseClick}></i>
            </div>
            <OffcanvasBody className="p-0">
                <div className="d-flex h-100">
                    <div className="flex w-50 m-3 me-0 border-end border-dark-subtle">
                        <div className="d-flex flex-column h-100 overflow-x-scroll">
                            {/* Quick Filters Section */}
                            <div className="d-flex flex-column gap-3 me-3">
                                {quickFilters.length > 0 && (<div>
                                    <h4>{t("common.filter.advance.quick_section_heading")}</h4>
                                    <p>{t("common.filter.advance.quick_section_helper")}</p>
                                </div>)}
                                {quickFilters.map((filter, filterIndex) => (
                                    <div key={filterIndex}>
                                        <Label className="fw-semibold m-0"> {filter.keyLabel}: </Label>
                                        <div className="d-flex gap-2 flex-wrap">
                                            {filter.valuesLabel?.map((value: any, valueIndex: number) => (
                                                <h5 key={valueIndex} className="m-0">
                                                    <span className="d-flex align-items-center gap-2 badge bg-primary-subtle text-primary">
                                                        {value.label}
                                                    </span>
                                                </h5>
                                            ))}
                                            {filter.valueOption ? (
                                                <span className="d-flex align-items-center gap-2 badge bg-primary-subtle text-primary">
                                                {filter.valueOption.label}
                                            </span>
                                            ) : (filter.value === 0
                                                    || filter.value === '0'
                                                    || filter.value) 
                                                    && (filter.highValue === 0
                                                    || filter.highValue === '0'
                                                    || filter.highValue
                                                ) ? (
                                                <>
                                                    <span className="d-flex align-items-center gap-2 badge bg-primary-subtle text-primary">
                                                        {filter.value}
                                                    </span>
                                                    <span className="fw-semibold">
                                                    {t("common.filter.advance.to")}
                                                    </span>
                                                    <span className="d-flex align-items-center gap-2 badge bg-primary-subtle text-primary">
                                                        {filter.highValue}
                                                    </span>
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* Separator */}
                            {quickFilters.length > 0 && (
                                <div className="border-top d-flex align-items-center justify-content-center mt-5 me-3">
                                    <h5 className="bg-white px-3 fw-bold" style={{ marginTop: '-10px' }}>
                                    {t("common.filter.advance.and")}
                                    </h5>
                                </div>
                            )}
                            {/* Advanced Filters Section */}
                            <div className="d-flex flex-column gap-2 me-3">
                                <h4 className="pb-4">{t("common.filter.advance.advance_filter")}</h4>
                                {filterGroups.map((group, groupIndex) => (
                                    <React.Fragment key={groupIndex}>
                                        {/* Group Separator */}
                                        {groupIndex > 0 && (
                                            <div className="border-top d-flex align-items-center justify-content-center mt-5">
                                                <h5 className="bg-white px-3 fw-bold" style={{ marginTop: '-10px' }}>
                                                {t("common.filter.advance.or")}
                                                </h5>
                                            </div>
                                        )}
                                        <h6 className="fw-bold">{`${t("common.filter.advance.group")} ${groupIndex + 1}`}</h6>
                                        {group.filters.map((filter: any, filterIndex: number) => (
                                            <Card key={filterIndex} className="border border-color-primary">
                                                <CardHeader className="bg-light p-1 d-flex justify-content-end gap-2">
                                                    <i
                                                        className="text-primary ri-edit-2-fill fs-5 cursor-pointer"
                                                        onClick={() => handleEditFilter(groupIndex, filterIndex)}
                                                    ></i>
                                                    <i
                                                        className="text-primary ri-delete-bin-5-fill fs-5 cursor-pointer"
                                                        onClick={() => handleRemoveFilter(groupIndex, filterIndex)}
                                                    ></i>
                                                </CardHeader>
                                                <CardBody className="bg-primary-subtle">
                                                    <div>
                                                        <span className="fw-semibold">{filter.keyLabel} </span>
                                                        <span>{filter.conditionLabel} </span>
                                                        {filter.valuesLabel?.map((value: any, valueIndex: number) => (
                                                            <span key={valueIndex} className="fw-semibold">
                                                                {valueIndex === filter.valuesLabel.length - 1 && filter.valuesLabel.length !== 1
                                                                    ? `or ${value.label}`
                                                                    : value.label}
                                                                {valueIndex < filter.valuesLabel.length - 2 ? ', ' : ' '}
                                                            </span>
                                                        ))}
                                                        {filter.value instanceof Date && filter.highValue instanceof Date ? (
                                                            <span className="fw-semibold">
                                                                {filter.value.toDateString()} {t("common.filter.advance.and_small")} {filter.highValue.toDateString()}
                                                            </span>
                                                        ) : filter.value && filter.highValue ? (
                                                            <span className="fw-semibold">
                                                                {filter.value} {t("common.filter.advance.and_small")} {filter.highValue}
                                                            </span>
                                                        ) : filter.value instanceof Date ? (
                                                            <span className="fw-semibold">
                                                                {filter.value.toDateString()}
                                                            </span>
                                                        ) : filter.value ? (
                                                            <span className="fw-semibold">
                                                                {filter.value}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        ))}
                                        <Card className="border border-color-primary">
                                            <CardHeader className="bg-light p-2 d-flex justify-content-start">{t("common.filter.advance.and")}</CardHeader>
                                            <CardBody className="bg-primary-subtle">
                                                <Button
                                                    size="sm"
                                                    color="primary"
                                                    className="btn-label"
                                                    onClick={() => handleAddFilter(groupIndex)}
                                                    disabled={selectedGroupIndex !== null}
                                                >
                                                    <i className="ri-filter-3-line label-icon align-middle fs-16 me-2"></i>
                                                    {t("common.filter.advance.add_filter")}
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </React.Fragment>
                                ))}
                                {/* Group Separator */}
                                {filterGroups.length > 0 && (
                                    <div className="border-top d-flex align-items-center justify-content-center mt-5">
                                        <h5 className="bg-white px-3 fw-bold" style={{ marginTop: '-10px' }}>
                                        {t("common.filter.advance.or")}
                                        </h5>
                                    </div>
                                )}
                                {/* Add Filter Group Button */}
                                <div className="d-flex align-items-center justify-content-center mt-3 mb-5">
                                    <Button
                                        size="sm"
                                        color="primary"
                                        className="btn-label btn-soft-primary"
                                        onClick={handleAddGroup}
                                        disabled={selectedGroupIndex !== null}
                                    >
                                        <i className="ri-play-list-add-line label-icon align-middle fs-16 me-2"></i>
                                        {t("common.filter.advance.add_filter_group")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add Filter UI */}
                    {selectedGroupIndex !== null ? (
                        <AddFilter
                            filter={currentFilter}
                            dataFields={dataFields}
                            onFilterSelected={handleFilterSelection}
                            selectedGroup={selectedGroupIndex}
                            setSelectedGroupIndex={setSelectedGroupIndex}
                        />
                    ) : (
                        <div className="d-flex flex-column gap-2 w-50 bg-light p-3 align-items-center justify-content-center">
                            <h4>{t("common.filter.advance.click_on_add_filter")}</h4>
                        </div>
                    )}
                </div>
            </OffcanvasBody>
        </Offcanvas>
    );
};

export default withTranslation()(Filter);
