import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { handleSelectValue } from "utils";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import config from "config";
import { toast } from "react-toastify";
import axios from "axios";
import { withTranslation } from "react-i18next";
const { DTS_API_STUDENT, AWS_API } = config.api;


const StatusFormModel = ({ form, onToggle, onUpdate, details, studentId, applicationId, t }: any) => {
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const [formValues, setFormValues] = useState<any>({});
    const [fieldValues, setFieldValues] = useState<any>({});
    const [disabled, setdisabled] = useState<any>(false);
    const [formerror, setError] = useState<any>([]);
    const [data, setData] = useState<any>([]);    
    const [file, setFile] = useState(null);
    const [fileKey, setFileKey] = useState(null);

    const isFile = (file: any) => {
        return typeof file === 'object' && file !== null && 'size' in file && 'type' in file;
    }

    useEffect(() => {
        setData(details)
    }, [details])

    const SubmitUpload = async (selectedFiles: any, key: any) => {
        const file = selectedFiles && selectedFiles.length ? selectedFiles[0] : selectedFiles;
        if (file) {
                const formData = new FormData();
                formData.append('documentType', key);
                if(studentId){
                    formData.append('studentId', studentId);
                }
                if(applicationId){                    
                    formData.append('applicationId', applicationId);
                }
                formData.append('file', file);
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "token": access_token
                    },
                }
                await axios.post(`${DTS_API_STUDENT}/documents`, formData, config)
                .then((res: any) => {                    
                    if(res.key) {
                        setFormValues({ ...formValues, [key]: res.key })
                        const message = () => toast(`File uploaded successfully. Please check in documents section`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
                        message();
                    }                   
                })
        }
    }

    const onChange = async (option: any, key: any, field: any) => {
        if (isNaN(Date.parse(option)) === false) {
            setFormValues({ ...formValues, [key]: option })
        }        
        else if(isFile(option)) {
            const file = option && option.length ? option[0] : option;
            setFile(file)
            setFileKey(key)      
            await SubmitUpload(file, key)    
        }
        else if (field.type == 'selectboxes' || field.isMulti) {
            setFormValues({ ...formValues, [key]: option })
        }
        else if (typeof option == 'object') {
            setFormValues({ ...formValues, [key]: option.value })
        } else setFormValues({ ...formValues, [key]: option })
    };

    const allMandatoryFieldsFilled = () => {
        for (const field of form?.fields) {
            if (field && field.validate && field.validate.required && !formValues[field.key]) {
                return false;
            }
        }
        return true;
    };
    const formdisabled = Object.keys(formerror).length === 0;

    useEffect(() => {
        let newData: any = {};
        if (data) {
            let info = data?.valuesJson || data;
            form?.fields && form?.fields.length && form?.fields.map((item: any, key: any) => {
                let rendervalue = info?.[item.key];
                if (item?.mergeColumns?.columns) {
                    let value = item?.mergeColumns?.columns
                    let values = value.split(",")
                    let newValue = "";
                    values?.length && values.map((item: any) => {
                        newValue = info?.[item] ? `${newValue ? newValue : ""} ${info?.[item]}${item.mergeColumns?.separator || " "}` : ""
                    })
                    rendervalue = newValue;
                }
                if (item.optionLabel) {
                    rendervalue = info?.[item.key]?.[item.optionLabel] ? { label: info?.[item.key]?.[item.optionLabel], value: info?.[item.key]?.["id"] } : null
                }
                else if (item.isMulti || item.type == "selectboxes") {
                    rendervalue = info?.[item.key];
                }
                else if (item.type == 'select' || item.type == 'radio' || item.type == 'checboxes') {
                    rendervalue = handleSelectValue(info?.[item.key], item.values);
                }
                else if (item.type == 'file') {
                    rendervalue = typeof info?.[item.key] !== 'object' ? info?.[item.key] : '';
                }
                newData[item.key] = rendervalue;
            })
            setFormValues(newData)
            setFieldValues(newData)
        }
    }, [data, form?.fields])

    return (
        <Modal backdrop="static" isOpen={!!form} toggle={onToggle} centered={true}>
            <ModalHeader className="bg-light p-3" toggle={onToggle}>
            {t("common.modals.update_status")}
            </ModalHeader>
            <ModalBody className='text-left p-5'>
                {form?.fields?.length ? form?.fields.map((field: any) => {
                    return <FormBuilder
                        placeholder={field.placeholder}
                        name={field.key}
                        onChange={(e: any) => onChange(e, field.key, field)}
                        options={field.values && field.values.reverse() || []}
                        label={field.label}
                        type={field.type}
                        value={formValues[field.key]}
                        isEditState={true}
                        actionButtons={false}
                        defaultValue={formValues[field.key]}
                        Editable={true}
                        rest={field}
                        invalid={true}
                        formValues={formValues}
                        dataFields={form?.fields}
                        setdisabled={setdisabled}
                        setError={setError}
                        formerror={formerror}
                    />
                }) : null}
                {/* <QuickForm dataFields={form?.fields} btnAction={onUpdate} btn_text={'Save'} /> */}
            </ModalBody>
            <div className="modal-footer">
                <div className="d-flex gap-2 justify-content-between text-nowrap text-truncate text-nowrap-ellipsis">
                    <Button color="danger" onClick={onToggle}>{t("common.offcanvas.import_cancel")}</Button>
                    <Button
                        color="primary"
                        disabled={!allMandatoryFieldsFilled() || disabled || !formdisabled}
                        onClick={() => onUpdate(formValues, form)}
                    >
                        {t("common.offcanvas.update_status")}
                    </Button>
                </div>
            </div>
        </Modal>) as unknown as JSX.Element;
};

export default withTranslation()(StatusFormModel);