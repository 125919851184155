import { withTranslation } from 'react-i18next';
import Listing from 'Components/Common/Views/Listing';
import { disputeCreateRequest, disputeDelete, disputeGetRequest, disputeListGetRequest, disputesMetadataGetRequest, disputeUpdateRequest } from 'store/disputes/action';

const Dispute = (props: any) => {
    const { viewId, isCreateModal, setCreateOpen } = props;    
    return (
        <Listing 
            viewId={viewId}
            isCreateModal={isCreateModal}
            setCreateOpen={setCreateOpen}
            getListAction={disputeListGetRequest}
            deleteAction={disputeDelete}
            detailsGetAction={disputeGetRequest}
            metaDatagetRequest={disputesMetadataGetRequest}
            createAction={disputeCreateRequest}
            updateAction={disputeUpdateRequest}
            model={"disputes"}
            reducerState={"disputes"}
            translater={"dispute"}
            isEditDrawer={true}
            isViewDrawer={true}
        />
    );
};
export default withTranslation()(Dispute);


