import React, { useEffect, useState } from 'react';
import { Container, Input, Label } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { startCase } from 'lodash';
import { courseGetRequest, courseUpdateRequest } from 'store/course/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import UpdateForm from 'Components/Common/FormBuilder/UpdateForm';

const Details = (props: any) => {
    const { id, childId } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const details: any = useSelector((state: ApplicationState) => state.courses.details);
    const userProfile: any = useSelector((state: ApplicationState) => state.auth.userProfile);
    const [fields, setFields] = useState([])
    const [value, setValue] = useState(false)
    const [count, setCount] = useState(0)
    const [data, setData] = useState({})

    useEffect(() => {
        dispatch(courseGetRequest(childId || id))
    }, [])

    useEffect(() => {
        let fields: any = [];
        let info: any = null;
        if (userProfile?.tenant?.id && details?.valuesJson?.[userProfile?.tenant?.id]) {
            let tenantCourse = details?.valuesJson?.[userProfile?.tenant?.id]
            info = { ...details?.valuesJson, ...details, ...tenantCourse, ...tenantCourse?.fee_structure }
        }
        else {
            info = { ...details?.valuesJson }
        }
        let data = {};
        setValue(info?.['fee_structure'])
        if (info?.course_duration && info?.currency && info?.course_duration_period) {
            let count = parseInt(info?.course_duration) || 0;
            [...Array(count)].map((_, index) => {
                let ConditionalDefaultValue: any = null;
                let period = info?.course_duration_period ? `${info?.course_duration_period}`.replace("s", "").toLowerCase() : null;
                if (index === 0) {
                    ConditionalDefaultValue = {
                        "isDefaultValue": true,
                        "values": `${info?.course_fee}${info?.currency}`
                    }
                    data = { ...data, [`course_fee`]: info?.course_fee }
                }
                else {
                    ConditionalDefaultValue = {
                        "isDefaultValue": true,
                        "values": `${info?.['fee_structure']?.[`course_fee_${period}${index + 1}`]}${info?.currency}`
                    }
                    data = { ...data, [`course_fee_${period}${index + 1}`]: info?.['fee_structure']?.[`course_fee_${period}${index + 1}`] }
                }
                setData(data)
                let field = {
                    "label": period ? startCase(`${period} ${index + 1}`) : null,
                    "key": index === 0 ? `course_fee` : `course_fee_${period}${index + 1}`,
                    "placeholder": `Enter ${info?.course_duration_period} ${index + 1} Fee`,
                    "quickCreate": true,
                    "type": "textfield",
                    "ConditionalDefaultValue": ConditionalDefaultValue
                }
                let newField = JSON.stringify(field);
                let JsonnewField = { valuesJson: newField };
                fields.push(JsonnewField)
            })
            setCount(count)
        }
        setFields(fields)
    }, [details])

    const onUpdate = (formValues: any) => {
        const handleSuccess = (body: any) => {
            dispatch(courseGetRequest(childId || id))
        }
        dispatch(courseUpdateRequest(childId || id, formValues, handleSuccess, () => { }))
    }

    return (
        <React.Fragment>
            <Container fluid>
                {
                    count > 0 ?
                        <div>
                            <div className='py-2 px-4'>
                                <Input
                                    className={"form-check-input"}
                                    type={"checkbox"}
                                    checked={value}
                                    onChange={(e: any) => setValue(e.target.checked)}
                                />
                                <Label className="form-label fs-15 fw-semibold">{props.t("courses.details.activities.is_the_fee_different_for_every_year")} {`${details?.valuesJson?.course_duration_period}`}?</Label>
                            </div>
                            {
                                value ?
                                    <div>
                                        <div>
                                            <span>{props.t("courses.details.activities.note")}:</span>
                                            <ol>
                                                <li>{props.t("courses.details.activities.please_enter_respective")} {`${details?.valuesJson?.course_duration_period}`} {props.t("courses.details.activities.fee_in_respective")} {`${details?.valuesJson?.course_duration_period}`}.
                                                </li>
                                                <li>{props.t("courses.details.activities.please_enter_only_number_values")}
                                                </li>
                                                <li>{props.t("courses.details.activities.pleae_enter_values_in_currency")} {`${details?.valuesJson?.currency}`}.
                                                </li>
                                            </ol>
                                        </div>
                                        <UpdateForm BtnClassName={"w-auto btn-primary"} md={6} lg={6} sm={12} xl={6} xs={12} dataFields={fields} onUpdate={(values: any) => onUpdate(values)} btn_text={'Update'} disabled={false} theme={"primary"} data={data} />
                                    </div>
                                    : null
                            }
                        </div>
                        : null
                }
            </Container>
        </React.Fragment>
    );
};

export default withTranslation()(Details);