import {
    Button,
    Col,
    Input,
    Label,
    Row,
} from "reactstrap";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { noteCreateRequest, noteListGetRequest, notesMetadataGetRequest } from "store/notes/action";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { isJson } from "utils";
import Comments from "./Comments";
import { taskCreateRequest } from "store/tasks/action";
import AddTaskEntry from '../../../Activities/Tasks/innerComponent/AddEntryModal'
import DtsCKEditor from "Components/Common/FormBuilder/DtsCKEditor";

const noteType = [
    {
        options: [
            { label: "Counselling Session Summary", value: "COUNSELLING SESSION SUMMARY" },
            { label: "Follow-up call", value: "FOLLOW-UP CALL" },
            { label: "Incoming Call", value: "INCOMING CALL" },
            { label: "Attempt to book", value: "ATTEMPT TO BOOK" },
            { label: "Proof of Funds Details", value: "PROOF OF FUNDS DETAILS" },
            { label: "Course Notes", value: "COURSE NOTES" },
            { label: "Finance Notes", value: "FINANCE NOTES" },
            { label: "Others", value: "OTHERS" }
        ],
    },
];

const Notes = (props: any) => {
    const { model, details, studentId, applicationId, userProfile, parentField } = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const metaData = useSelector((state: ApplicationState) => state.notes.metaDataFields);

    const [transcribing, setTranscribing] = useState<"parent" | boolean>("parent");
    const [selectedAddNoteType, setSelectedAddNoteType] = useState(null);
    const [addShortNoteValue, setAddShortNoteValue] = useState("");
    const [newNote, setNewNote] = useState("");
    const [tasksModals, setTasksModals] = useState<boolean>(false);
    const [followupRequired, setFollowupRequired] = useState(false);
    const [isAddNoteButtonDisabled, setIsAddNoteButtonDisabled] = useState<boolean>(false);
    const [notesMetadata, setNotesMetadata] = useState([])
    const [isChecked, setIsChecked] = useState(false);
    const [note, setNote] = useState(""); // State to store final transcript
    const [previousWords, setPreviousWords] = useState<string[]>([]);

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
    } = useSpeechRecognition({
        transcribing: transcribing === "parent",  // Only enable transcribing when it's 'parent'
        clearTranscriptOnListen: true,  // Clear transcript when microphone is turned on
        commands: [  // Define custom speech commands if needed
            {
                command: 'stop',
                callback: () => stopListening(),
            },
        ]
    });

    useEffect(() => {
        const notesMetadata: any = [];
        metaData && metaData.length && metaData.map((field: any) => {
            let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : { ...field.valuesJson }
            if (field.valuesJson) return notesMetadata.push(item)
        })
        setNotesMetadata(notesMetadata)
    }, [metaData, details])

    useEffect(() => {
        resetTranscript();
        const filters = { pageSize: 500 }
        dispatch(notesMetadataGetRequest('notes', filters));
    }, []);

    const notesFilter = () => {
        let parent: any = [{
            "key": 'student',
            "keyLabel": ['Student'],
            "condition": "IN",
            "conditionLabel": "is any of",
            "values": [studentId],
            "valuesLabel": [],
            "property": {},
            "quick": true,
        }]
        if (applicationId) {
            let applicationFilter =
            {
                "key": 'application',
                "keyLabel": 'Application',
                "condition": "IN",
                "conditionLabel": "is any of",
                "values": applicationId ? [applicationId] : [],
                "valuesLabel": [],
                "property": {},
                "quick": true,
            }
            parent = [...parent, applicationFilter]
        }
        const filtersData: any = {
            "filters": [
                {
                    quick: [],
                    advance: [],
                    search: null,
                    parent: parent
                }
            ],
            "sorts": [
                {
                    "field": "createdAt",
                    "order": "desc"
                }
            ]
        }
        dispatch(noteListGetRequest(filtersData));
    }

    useEffect(() => {
        if (model === 'students' && studentId) {
            notesFilter()
        }
        else if (model === 'applications' && studentId && applicationId) {
            notesFilter()
        }
    }, [studentId, applicationId, model])

    useEffect(() => {
    if (listening && transcript) {
        const words = transcript.trim().split(" "); // Convert transcript to words

        // Find new words by slicing from previous transcript length
        const newWords = words.slice(previousWords.length).join(" ").trim();

        if (newWords) {
        setNote((prevNote) => `${prevNote?prevNote:''} ${newWords?newWords:''}`.trim());
        }

        setPreviousWords(words); // Store current words for next comparison
    }
    }, [transcript, listening]);

    useEffect(() => {
    if(note) {
        console.log("newNote", newNote, "note", note)
        setNewNote(note)
    }
    }, [note])

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    const startListening = () => {
        setTranscribing("parent");  // Set transcribing to 'parent' when listening starts
        SpeechRecognition.startListening({ continuous: true });
    };

    const stopListening = () => {
        setTranscribing(false);  // Stop transcribing when listening stops
        SpeechRecognition.stopListening();
        resetTranscript();
    };

    const handleAddNoteTypeChange = (selectedOption: any) => {
        setSelectedAddNoteType(selectedOption);
        setAddShortNoteValue(selectedOption.label);
    };

    const handleNewNoteChange = (
        input: React.ChangeEvent<HTMLTextAreaElement> | string,
        appendSpeech: boolean = false
    ) => {
        if(!listening){
            setNote(`${input?input:''}`.trim())
        }
    };

    const handleReset = () => {
        setNewNote("");
        setSelectedAddNoteType(null);
        setIsAddNoteButtonDisabled(false);
        resetTranscript();
        setAddShortNoteValue("");
    };

    const handleAddNote = () => {
        stopListening();
        setIsAddNoteButtonDisabled(true);
        const data = {
            shortNote: addShortNoteValue,
            student: studentId,
            application: applicationId,
            parent: "",
            note: newNote
        };
        const handleSuccess = (body: any): void => {
            createFollowUp();
            handleReset();
            notesFilter()
        };

        const handleError = (body: any): void => {
            // Handle error here if needed
        };

        dispatch(noteCreateRequest(data, handleSuccess, handleError));
    };

    const handleFollowupCheck = (event: React.ChangeEvent<HTMLInputElement> | boolean) => {
        if (typeof event === "boolean") {
            setIsChecked(event);
            setFollowupRequired(event);
        } else {
            const checked = event.target.checked;
            setIsChecked(checked);
            setFollowupRequired(checked);
        }
    };

    const createFollowUp = () => {
        if (followupRequired) {
            setTasksModals(true);
        }
    };

    const textEle = <div  style={{width:'95%'}}>
        <DtsCKEditor
            value={newNote || ""}
            onChange={(data: any) => {
                handleNewNoteChange(data)
            }}
            stopListening={stopListening}
            name={'body1'}
            type={'texteditor'}
            actionButtons={false}
            defaultValue={newNote}
        />
    </div>

    const checkkboxEle = <div className="form-check mb-0 align-self-center">
        <Input
            className="form-check-input"
            type="checkbox"
            checked={isChecked}
            onChange={handleFollowupCheck}
            id="formCheck6"
        />
        <Label className="form-check-label" htmlFor="formCheck6">
            {props.t("student.details.activities.follow_up_required")}
        </Label>
    </div>

    return (
        <React.Fragment>
            <AddTaskEntry
                show={tasksModals}
                onCloseClick={() => setTasksModals(false)}
                setTriggerApi={null}
                props={props}
                createAction={taskCreateRequest}
                userProfile={userProfile}
                studentId={studentId}
                applicationId={applicationId}
                model={model}
            />
            <Row className="vstack gap-3">
                <Col xs={12}>
                    <div>
                        {notesMetadata?.length > 0 &&
                            notesMetadata.map((item: any, index: number) => {
                                if (item.type === "textarea") return (
                                    <>
                                        {textEle}
                                    </>
                                )
                            })}
                    </div>
                </Col>
                <Col xs={12}>
                    <div className="d-flex justify-content-end gap-2">
                        {notesMetadata?.length > 0 &&
                            notesMetadata.map((item: any, index: number) => {
                                if (item.type === "checkbox") return (
                                    <>
                                        {checkkboxEle}
                                    </>
                                )
                            })}
                        <div>
                            {notesMetadata?.length > 0 &&
                                notesMetadata.map((item: any, index: number) => {
                                    if (item.type === "select") return (
                                        <Select
                                            key={index} // Unique key for the Select element
                                            placeholder={props.t("common.filter.search.select_note_type")}
                                            classNamePrefix="js-example-data-array"
                                            isLoading={false}
                                            options={item?.values} // Pass all options directly
                                            value={selectedAddNoteType}
                                            onChange={handleAddNoteTypeChange}
                                            menuPlacement="auto"
                                            menuPosition="fixed"
                                        />
                                    )
                                })}
                        </div>
                        {listening ? (
                            <Button
                                color="danger"
                                className="ms-2 btn-icon"
                                outline
                                type="button"
                                onClick={stopListening}
                            >
                                <i className="ri-mic-off-line"></i>
                            </Button>
                        ) : (
                            <Button
                                color="primary"
                                className="ms-2 btn-icon"
                                outline
                                type="button"
                                onClick={startListening}
                            >
                                <i className="ri-mic-line"></i>
                            </Button>
                        )}
                        <Button
                            color="primary"
                            className="ms-2 btn btn-primary"
                            disabled={
                                isAddNoteButtonDisabled ||
                                (!newNote || !selectedAddNoteType) &&
                                (!transcript || !selectedAddNoteType)
                            }
                            onClick={handleAddNote}
                        >
                            {props.t("student.details.activities.add_note")}
                        </Button>
                    </div>
                </Col>
            </Row>
            <Comments
                record={details}
                noteType={noteType}
                transcribing={transcribing}
                setTranscribing={setTranscribing}
                model={model}
                parentField={parentField}
                studentId={studentId}
                applicationId={applicationId}
                notesMetadata={notesMetadata}
                props={props}
                notesFilter={notesFilter}
            />
        </React.Fragment>
    );
};

export default withTranslation()(Notes);
