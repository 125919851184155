import React from "react";
import { Col, Row } from "reactstrap";
import { withTranslation } from 'react-i18next';

interface Step7Props {  
    t: (key: string) => string;
}

const Success: React.FC<Step7Props> = ({ t }: Step7Props) => {
  
  return (
    <div>
      <Row className="hstack gap-3 flex-column justify-content-center">
        <Col lg={12}>
          <div className="text-center">
            <div className="avatar-md mt-5 mb-4 mx-auto">
              <div className="avatar-title bg-light text-success display-4 rounded-circle">
                <i className="ri-checkbox-circle-fill"></i>
              </div>
            </div>
            <h5>{t("form.well_done")}</h5>
            <p className="text-muted">{t("form.success_statement")}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withTranslation()(Success);
