import React, { useEffect, useState } from 'react';
import { Col, Input, Row, Button, Card } from 'reactstrap';
import TableContainer from './TableComponent';
import moment from 'moment';
import DeleteModal from '../innerComponent/DeleteModal';
import { withTranslation } from 'react-i18next';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { useNavigate, useParams } from 'react-router-dom';
import { financeRulesListGetRequest, financeDelete, financeGetRequest, setCommissionFields } from "store/finance/action";
import { ToastContainer } from 'react-toastify';
import staticfields from "../Fields.json"
import { coursesMetadataGetRequest } from 'store/course/action';
import { isArrayEqual, isJson } from 'utils';
import UserAvatar from 'Components/Common/UserAvtar/ListingUserAvatar';
import AccessDenied from 'pages/AccessDenied/Denied';
import ListingLoader from 'Components/Common/FormBuilder/ListingLoader';
import { channelGetRequest } from 'store/channels/action';
import { universityGetRequest } from 'store/university/action';
import BreadCrumb from 'Components/Common/BreadCrumb';

const Finance = (props: any) => {
    const { id1, id2 } = useParams()
    const navigate = useNavigate()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const rulesList = useSelector((state: ApplicationState) => state.finance.rules);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const paginationDetails = useSelector((state: ApplicationState) => state.finance.pagination);
    const coursesMetadata = useSelector((state: ApplicationState) => state.courses.metaDataFields);
    const authLoading = useSelector((state: ApplicationState) => state.auth.meloading);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const channel: any = useSelector((state: ApplicationState) => state.channels.details);
    const university: any = useSelector((state: ApplicationState) => state.universities.details);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const [columns, setColumns] = useState<any>([])
    const [pageSize, setPageSize] = useState<number>(10)
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [search, setsearch] = useState<string>("");
    const [record, setrecord] = useState<any>({});
    const [finance, setFinance] = useState([]);
    const [financeJson, setfinanceJson] = useState<any>([]);
    const [filterObject, setFilterObject] = useState<any>({
        instituteId: id1,
        channelId: id2
    });
    const [levels, setLevels] = useState<any>([]);
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [access, setAccess] = useState<any>([]);
    const [create, setCreate] = useState<boolean>(false);
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )    

    useEffect(() => {
        dispatch(coursesMetadataGetRequest("courses", { pageSize: 500 }, 0));
    }, [])

    useEffect(() => {
        coursesMetadata && coursesMetadata.length && coursesMetadata.map((item: any) => {
            let field = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : {}
            if (item.id == "565432a0-1455-11ef-bfb2-0aab495878f3" || (field && field.key == "level")) {
                let options = field.values;
                let levelsfields: any = []
                options && options.map((item: any) => {
                    let level = {
                        "label": item.label,
                        "key": item?.label?.toLowerCase(),
                        "placeholder": `Enter ${item.label} Commission`,
                        "quickCreate": true,
                        "type": "groupinput",
                        "values": [
                            {
                                "label": "Fixed",
                                "value": "fixed",
                                "in_forms": true
                            },
                            {
                                "label": "Percent",
                                "value": "percent",
                                "in_forms": true
                            }
                        ]
                    }
                    levelsfields.push(level)
                })
                setLevels(levelsfields)
            }
        })
    }, [coursesMetadata])

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setViewAccess(true)
            setCreate(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.commissions;
            if (access && access?.length) {
                setAccess(access)
                setViewAccess(true)
                if (access && access.length) {
                    access.map((item: any, index: any) => {

                        if (item.name.toLowerCase() === 'create') {
                            item.value === "" || item.value === "none" ? setCreate(false) : setCreate(true)
                        }

                    })
                }
            }
            else {
                setViewAccess(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])
    
    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
        }
    }, [paginationDetails])

    useEffect(() => {
        const columns: any = []
        let newcolumn = {
            Header: 'Name',
            accessor: 'name',
            filterable: true,
            disableFilters: true,
            show: true,
            Cell: (cellProps: any) => {
                return <div className="d-flex align-items-center">
                    <div className="hstack flex-grow-1 gap-2">
                        <div className="flex-shrink-0 text-decoration-none hstack gap-2 fw-bold" >
                            <UserAvatar firstName={cellProps.row.original['name']} />
                            <span onClick={() => redirect(cellProps.row.original)} className='cursor-pointer text-primary'>{cellProps.row.original['name']}</span>
                        </div>
                    </div>
                </div>

            }
        }
        columns.push(newcolumn)
        if (columns && columns.length > 0) {
            columns.push({
                Header: "Channel",
                accessor: "channel",
                id: "channel",
                filterable: true,
                isSortable: false,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return <div className="d-flex align-items-center justify-content-between">
                        {cellProps.row.original["channel"]}
                    </div>
                }
            })
        }
        if (columns && columns.length > 0) {
            columns.push({
                Header: "Created At",
                accessor: "createdAt",
                id: "createdAt",
                filterable: true,
                isSortable: false,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return <div className="d-flex align-items-center justify-content-between">
                        {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll') :
                            ''}
                    </div>
                }
            })
        }
        setColumns(columns)

    }, [levels, coursesMetadata]);
    
    useEffect(() => {
        const parsedData: any = rulesList && rulesList.length ? rulesList.map((item: any) => {
            const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : item.valuesJson;
            let values = { ...valuesJson, ...item, id: item.id, createdAt: item.createdAt, channelId: item.channeld, channel: channel?.valuesJson?.name }
            return values
        }) : []
        let finance = parsedData && parsedData.length ? parsedData : []
        setFinance(finance)
        setfinanceJson(rulesList)
    }, [isArrayEqual(rulesList, financeJson)])    

    useEffect(() => {
        dispatch(financeRulesListGetRequest(filterObject, pagination.page, ""))
        dispatch(channelGetRequest(id2))
        dispatch(universityGetRequest(id1))
    }, [])

    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, pageSize: value })
        setPageSize(value)
        const filters = { ...filterObject, pageSize: value }
        setFilterObject(filters)
        dispatch(financeRulesListGetRequest(filters, 0, ""));
        setfinanceJson([])
    }

    const handleLeadClick = (lead: any) => {
        setrecord(lead)
        dispatch(financeGetRequest(lead.id))
    };

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                newPageNo = 0;
                setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                newPageNo = lastPage - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(financeRulesListGetRequest(filterObject, newPageNo, ""));
        setfinanceJson([])
    };

    const onDelete = () => {
        const handleSuccess = (body: any) => {
            dispatch(financeRulesListGetRequest(filterObject, pagination.page, ""))
            setisDeleteOpen(false)
        }
        const handleFailure = (body: any) => {
            setisDeleteOpen(false)
        }
        dispatch(financeDelete(record.id, handleSuccess, handleFailure))
    }

    const handleChange = (value: any) => {
        setsearch(value);
        const filters = { ...filterObject, search: value }
        setFilterObject(filters)


        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {
            dispatch(financeRulesListGetRequest(filters, 0, ""));
            setfinanceJson([])
        }, 2000);

        setTypingTimeout(newTimeout);

    };

    const createCommission = (create?: any) => {
        let dataFields: any = []
        let count = 0
        staticfields.map((item: any) => {
            let field = JSON.stringify(item);
            dataFields[count] = { valuesJson: field };
            count++;
        })
        dispatch(setCommissionFields({ key: 'institutes', dataFields: dataFields, subkey: 'channels', id2: id2 }))
        if (create === true) {
            navigate(`/finance/${id1}/create`)
        }
    }

    const redirect = (lead: any) => {
        createCommission()
        navigate(`/finance/${id1}/${lead.id}/edit`)
    }

    const goback = () => {
        navigate(-1);
    }

    document.title = "DTS | Zilter";
    return (
        <>
            {!authLoading ?
                viewAccess ?
                    <React.Fragment>
                        <ToastContainer />
                        {isDeleteOpen && <DeleteModal
                            show={isDeleteOpen}
                            onCloseClick={() => setisDeleteOpen(false)}
                            props={props}
                            record={record}
                            onDelete={onDelete}
                        />}

                        <Row className='h-100'>
                            <Col xl={12} lg={12} md={12} className='h-100'>
                                <div>
                                    <BreadCrumb pageTitle={`Institute: ${university?.valuesJson?.name}`} title={`Channel: ${channel?.valuesJson?.name}`} icon={"ri-group-fill"} />
                                </div>
                                <div>
                                    <Button color="primary" size='sm' className="w-auto btn-sm mb-2" onClick={() => goback()}>
                                        Back
                                    </Button>
                                </div>
                                <Card>
                                    <Row className='hstack gap-3'>
                                        <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                                            <div className="search-box">
                                                <Input
                                                    type="text"
                                                    size={14}
                                                    className="search"
                                                    placeholder={props.t("common.filter.search.search_for")}
                                                    onChange={(e) => handleChange(e.target.value)}
                                                    value={search}
                                                />
                                                {search === "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}

                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='hstack gap-2 justify-content-end'>
                                                <div className='hstack gap-2'>
                                                    <div className="flex-shrink-0 gap-2">
                                                        {
                                                            create ?
                                                                <Button
                                                                    onClick={() => createCommission(true)}
                                                                    color="primary"
                                                                    className="btn-label">
                                                                    <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                                                    {props.t("applications.applications_create_entry")}
                                                                </Button>
                                                                :
                                                                <Button
                                                                    color="primary"
                                                                    disabled
                                                                    className="btn-label">
                                                                    <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
                                                                    {props.t("applications.applications_create_entry")}
                                                                </Button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className=" m-4">
                                        <TableContainer
                                            columns={columns && columns.length ? columns.filter((column: any) => column.show == true) : []}
                                            data={finance || []}
                                            customPageSize={pageSize}
                                            tableClass="align-middle table-nowrap"
                                            isPageSizeChange={true}
                                            handleLeadClick={handleLeadClick}
                                            setisDeleteOpen={setisDeleteOpen}
                                            pageChanged={pageChanged}
                                            pagination={pagination}
                                            onChangePageSize={onChangePageSize}
                                            pageSize={pageSize}
                                            props={props}
                                            access={access}
                                            redirect={redirect}
                                        />
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </React.Fragment>
                    : <AccessDenied />
                : <ListingLoader
                    columns={columns}
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light text-muted"
                    thClass="border-bottom-1 table-soft-primary"
                />}</>
    );
};
export default withTranslation()(Finance);
