import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Button,
    Col,
    Nav,
    NavItem,
    Offcanvas,
    OffcanvasBody,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Input,
    Spinner,
} from 'reactstrap';
import classnames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import ImportModal from './ImportModal';
import Upload from './Upload';
import Mapping from './Mapping';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { ApplicationState } from 'store';
import config from "../../../../config";
import { importListGetRequest, importProcessRequest } from 'store/import/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import CommissionFor from './CommissionImport/CommissionFor';
const { DTS_API_STUDENT } = config.api;


const ImportFlow = (props: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch(); 
    const { model } = useParams();
    const { onCloseClick, show, t, setImportJson, importJson} = props;
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const [activeArrowTab, setactiveArrowTab] = useState<number>(1);
    const [passedarrowSteps, setPassedarrowSteps] = useState<number[]>([1]);
    const [selectedFiles, setselectedFiles] = useState<any>([]);
    const [disabled, setDisabled] = useState<any>([]);
    const [modal, setModal] = useState<any>('');
    const [mappings, setMappings] = useState<any>('');
    const [loading, setLoading] = useState<any>(false);
    const [finalMappings, setFinalMappings] = useState<any>([]);
    const [unMappedCount, setUnMappedCount] = useState<any>();
    const [extra, setExtra] = useState<any>({})

    const handleKey = (submodel: any) => {
        switch(submodel) {
            case 'channels': return 'channelId'
            case 'Institutes': return 'instituteId'
            case 'subagents': return 'subagentId'
        }
    }

    useEffect(() => {
        const url = window.location.href;
        const queryString = decodeURIComponent(url.split('?')[1]);
        if (queryString) {
            const params = new URLSearchParams(queryString);
            const model = params.get('model');
            const submodel = params.get('submodel');
            const id = params.get('id');
            if (model === 'commissions') {
                let fieldkey: any = handleKey(submodel)
                setImportJson({ ...importJson, model: model, commissionModel: submodel, submodelkey: fieldkey, [fieldkey]: id, commission: { commissionModel: submodel, [fieldkey]: id, submodelkey: fieldkey }})
            }
            else {
                setImportJson({ ...importJson, model: model})
            }
            setModal(model)
        }
    }, [])

    const toggleArrowTab = (tab: number) => {
        if (activeArrowTab !== tab) {
            const modifiedSteps = [...passedarrowSteps, tab];
            if (tab >= 1 && tab <= 4) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    };

    const uploadFile = async () => {
        setLoading(true)
        const file = selectedFiles && selectedFiles.length ? selectedFiles[0] : null;
            if (file) {
                const formData = new FormData();
                formData.append('file', file);
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "token": access_token
                    },
                }
                try {
                    await axios.post(`${DTS_API_STUDENT}/import/upload?model=${modal}`, formData, config)
                    .then((res: any) => { 
                        if(res?.mappings)   {
                            setMappings(res.mappings)    
                            setLoading(false)  
                            toggleArrowTab(activeArrowTab + 1);
                        }   
                        else {
                            const message = () => toast(`An unexpected error occurred, please check uploaded file and try again later`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
                            message();                     
                            setLoading(false)  
                        }           
                    })                    
                    
                }
                catch (error) {
                    console.error("Error downloading file:", error);
                    const message = () => toast(`An unexpected error occurred, please check file you are trying to upload and try again.`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
                    message();                     
                    setLoading(false)  
                }
            }
    }

    const finalMappingsRequest = () => {
        setLoading(true)
        const handleSuccess = () => {       
            setImportJson({})
            onCloseClick()            
            setLoading(false)
            dispatch(importListGetRequest());
        }
        const handleFailure = () => {
            setLoading(false)
            const message = () => toast(`An unexpected error occurred, please check file you are trying to upload and try again.`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
            message(); 
        }
        dispatch(importProcessRequest({mappings:finalMappings, extra:extra}, handleSuccess, handleFailure))
    }

    const nextClicked = (value: any) => {
        switch(value) {
            case 1: 
                if(importJson.model === 'commissions') {
                    toggleArrowTab(activeArrowTab + 0.1);
                }
                else {
                    toggleArrowTab(activeArrowTab + 1);
                }
                setDisabled(!importJson.model)
                break;
            case 1.1: 
                toggleArrowTab(1 + 1);
                break;
            case 2: 
                setLoading(true)
                uploadFile();  
                setDisabled(!selectedFiles.length)
                break;
            case 3: 
                setLoading(true)
                finalMappingsRequest()   
              
                break;
        }
        
    };
    const backClicked = () => {
        switch(activeArrowTab) {
            case 1.1: 
                if(importJson.model === 'commissions') {
                    toggleArrowTab(activeArrowTab - 0.1);
                }
                else {
                    toggleArrowTab(activeArrowTab - 1);
                }
                break;
            case 2:                 
                if(importJson.model === 'commissions') {
                    toggleArrowTab(2 - 0.9);
                }
                else {
                    toggleArrowTab(activeArrowTab - 1);
                }
                break;
            default:toggleArrowTab(activeArrowTab - 1);
        }
    };

    const importUnmapped = (value: any) => {
        setExtra({...extra, importUnmappedData:value, action: importJson.importAction});
    }

    useEffect(() => {
        // Re-render logic or no need to add any logic
        switch(activeArrowTab) {
            case 1: 
                setDisabled(!importJson?.model)
                break;
            case 1.1: 
                setDisabled(!importJson?.commissionModel)
                break;
            case 2: 
                setDisabled(!selectedFiles?.length)
                break;
            case 3: 
                if(unMappedCount > 0)  
                {
                    if(extra?.importUnmappedData) {
                        setDisabled(false)
                    }                    
                    else {
                        setDisabled(true)
                    }  
                }                     
                else {
                    setDisabled(false)
                }        
                break;
        }
      }, [activeArrowTab, importJson, selectedFiles, extra, unMappedCount]);

    return (
        <React.Fragment>
            <Offcanvas
                direction="end"
                isOpen={show}
                id="offcanvasWithBothOptions"
                toggle={() => {onCloseClick();}}
                className="w-100"
            >
                <OffcanvasBody id="offcanvasWithBothOptions">
                    <div className="hstack step-arrow-nav mb-4">
                        <Row className="w-100 hstack justify-content-center">
                            <Col lg={8} className="hstack justify-content-between">
                                <Nav
                                    className="w-100 nav-pills custom-nav nav-justified"
                                    role="tablist"
                                >
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            id="basic-info"
                                            className={classnames({
                                                active: activeArrowTab === 1,
                                                done: activeArrowTab <= 2 && activeArrowTab > 0,
                                            })}
                                        >
                                            {t('common.offcanvas.import_type')}
                                        </NavLink>
                                    </NavItem>
                                    {
                                        importJson?.model === "commissions" ?
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            id="commissions-info"
                                            className={classnames({
                                                active: activeArrowTab === 1.1,
                                                done: activeArrowTab <= 1.2 && activeArrowTab > 1,
                                            })}
                                        >
                                                {t('common.offcanvas.import_link_commission_for')}
                                        </NavLink>
                                    </NavItem> : null}
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            id="field-type"
                                            className={classnames({
                                                active: activeArrowTab === 2,
                                                done: activeArrowTab <= 3 && activeArrowTab > 1,
                                            })}
                                        >
                                             {t('common.offcanvas.import_upload')}                                            
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            id="rules"
                                            className={classnames({
                                                active: activeArrowTab === 3,
                                                done: activeArrowTab <= 4 && activeArrowTab > 2,
                                            })}
                                        >
                                            {t('common.offcanvas.import_map')}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </div>
                    <TabContent activeTab={activeArrowTab}>
                        <TabPane id="basic-info" tabId={1}>
                           {activeArrowTab == 1 && <Row className="hstack justify-content-center">
                                <Col lg={5}>
                                    <div className="hstack justify-content-center">
                                       <ImportModal setImportJson={setImportJson} importJson={importJson} setModal={setModal} modal={modal} />
                                    </div>
                                </Col>
                            </Row>}
                        </TabPane>
                        <TabPane id="basic-info" tabId={1.1}>
                        {activeArrowTab == 1.1 && <Row className="hstack justify-content-center">
                                <Col lg={5}>
                                    <div className="hstack justify-content-center">
                                       <CommissionFor setImportJson={setImportJson} importJson={importJson} setModal={setModal} modal={modal} />
                                    </div>
                                </Col>
                            </Row>}
                        </TabPane>
                        <TabPane id="field-type" tabId={2}>
                        {activeArrowTab == 2 && <Row className="hstack justify-content-center">
                                <Col lg={10}>
                                   <Upload selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} setImportJson={setImportJson} importJson={importJson} />
                                </Col>
                            </Row>}
                        </TabPane>
                        <TabPane id="rules" tabId={3}>
                        {activeArrowTab == 3 && <Row className="hstack justify-content-center">
                                <Col lg={8}>
                                    <Mapping unMappedCount={unMappedCount} mappings={mappings} loading={loading} setFinalMappings={setFinalMappings} setImportJson={setImportJson} importJson={importJson} setUnMappedCount={setUnMappedCount} />
                                </Col>
                            </Row>}
                        </TabPane>
                    </TabContent>
                </OffcanvasBody>
                <div className="offcanvas-footer bg-primary-subtle bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                    <div className="hstack gap-2">
                        {activeArrowTab > 1 && activeArrowTab <= 3 ? (
                            <Button
                                color="primary"
                                className="w-auto"
                                onClick={() => backClicked()}
                                outline={true}
                            >
                                {t('common.offcanvas.import_back')}
                            </Button>
                        ) : null}
                        <Link
                            to="#"
                            className="text-danger fw-bold w-auto"
                            onClick={() => {onCloseClick(); setactiveArrowTab(1); setImportJson({})}}
                        >
                            {t('common.offcanvas.import_cancel')}
                        </Link>
                    </div>
                    
                    <div className='hstack gap-2'>
                    {activeArrowTab === 3 && unMappedCount > 0 ?
                        <div className='vstack cursor-pointer'>
                            <h6 className='fw-bold'>You have {unMappedCount} unmapped column.</h6>
                            <div className='hstack'>
                                <Input type='checkbox' checked={extra.importUnmappedData} onClick={(e: any) => importUnmapped(e.target.checked) }/>
                                <div>Don't import data in ummapped column</div>
                            </div>
                        </div> : null}
                        <Button
                            color="primary"
                            className="btn btn-primary w-auto"
                            disabled={disabled}
                            onClick={() => nextClicked(activeArrowTab)}
                        >
                            {loading ? <><Spinner size="sm" className="flex-shrink-0">Loading... </Spinner>
                                <span className="flex-grow-1 ms-2">
                                    Processing Request...
                                </span> </> : <>{activeArrowTab === 3 ? t('common.offcanvas.import_finish') : t('common.offcanvas.import_next')}</>}
                            
                        </Button>
                    </div>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
};

export default withTranslation()(ImportFlow);
