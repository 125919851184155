import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Dashboards from './Dashboards';
import { withTranslation } from 'react-i18next';

const dashboard = ({t}: any) => {
    
    document.title="DTS | Zilter - Dashboards";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                           
                    <BreadCrumb title={t("dashboard.breadcrumb.dashboard")} pageTitle={t("dashboard.breadcrumb.dashboard")} icon={"ri-dashboard-2-line"} />
                    <Row>
                        <Dashboards />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(dashboard);
