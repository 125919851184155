import React, { useEffect, useState } from 'react';
import { Container, Label, Row } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from 'store';
import Form from './FormRender';
import { formGetRequest, getMataData } from 'store/customform/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useParams } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { isJson } from 'utils';
import { tenantGetOpenRequest } from 'store/user/action';
import Logo from 'Components/Common/Logo/Logo';
import { isMobile } from 'react-device-detect';

const FormRender = () => { 
	document.title = "Student Registration - Forms";
    const { tenantId , id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch(); 
    const formDetails = useSelector((state: ApplicationState) => state.customform.customformDetails);
    const metadata = useSelector((state: ApplicationState) => state.customform.metadata);
    const tenatDetails = useSelector((state: ApplicationState) => state.user.tenatDetails);
    const [formData, setFormData] = useState<any>({})    
    const [defaultValues, setDefaultValues] = useState<any>({})    
    const [title, setTile] = useState<any>("")
    const [description, setDescription] = useState<any>({})
    const [col, setColumn] = useState({})   
    const [tenant, setTenant] = useState<any>({})

    useEffect(() => {
        dispatch(getMataData(tenantId))
        dispatch(formGetRequest(tenantId,id))
        dispatch(tenantGetOpenRequest(tenantId))
      }, [])

      useEffect(() => {
        let tenant = tenatDetails?.settings && isJson(tenatDetails?.settings) ? JSON.parse(tenatDetails?.settings) : tenatDetails?.settings;
        setTenant(tenant) 
    }, [tenatDetails])

      useEffect(() => {
        let fields: any = [];
        let newFormData = formDetails && formDetails.valuesJson && isJson(formDetails.valuesJson) ? JSON.parse(formDetails.valuesJson) : {}
         newFormData && newFormData?.components && newFormData?.components.length && newFormData.components.map((component: any)=> {
            metadata && metadata.length && metadata.map((field: any) => {                         
                let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
                let newField = {...item, id: field.id}                
                if(component.id == field.id) 
                {
                    fields.push({...newField,  ...component.extras})
                }
            })
        })  
        setFormData(fields)
        setColumn(newFormData.column)
        setDefaultValues(newFormData.values)
        setTile(newFormData.title)
        setDescription(newFormData.description)
      }, [formDetails, metadata])
    return (
        <React.Fragment>
            <ToastContainer />
            <Container>  
                <Logo tenant={tenant} />
                <Row>
                    <div className={'hstack justify-content-center fw-bold fs-20 text-decoration-underline text-center'}>
                        <Label>{title}</Label>
                    </div>
                </Row>
                {description?.body ? <Row>
                    <div className='hstack justify-content-start m-3 border border-dark p-3 border-opacity-10'>
                        <div dangerouslySetInnerHTML={{ __html: description?.body}}></div>
                    </div>
                </Row> : null}
                <Form tenantId={tenantId} dataFields={formData || []} md={col} lg={col} xl={col} BtnClassName={"w-auto justify-content-end"}  btn_text={'Submit'} data={defaultValues} />
            </Container>
        </React.Fragment>
    );
}

export default FormRender;