interface ProcessFlow {
    configuration?: Configuration;
    value: string;
    label: string;
}

interface Status {
    value: string;
    label: string;
}

interface Configuration {
    statuses?: Status[];
    workflow?: Workflow;
    automation?: Automation;
}

interface AutomationCreated {
    status?: Status;
    default: AutomationDefault
}

interface AutomationDefault {
    status: Status
}

interface Automation {
    status?: string;
    created?: AutomationCreated;
}

interface Workflow {
    status?: string;
    flow?: Flow;
}

interface Flow {
    edges?: Edge[];
}

interface Edge {
    source: string;
    target: string;
}

interface Option {
    label: string;
    options: Status[];
    in_forms: boolean;
}

/**
 * Retrieves the available statuses and the current active status
 * based on the current process flow and current status.
 *
 * @param processFlows - List of all process flows
 * @param currentStatus - The current status value
 * @param currentProcessFlow - The value of the current process flow (optional)
 * @returns An object containing filtered available statuses and the current status
 */
const getAvailableStatuses = (
    processFlows: ProcessFlow[], 
    currentStatus: string, 
    currentProcessFlow?: string,
    requiredAll?: boolean
) => {

    // If no process flows are provided, return empty statuses and null status
    if (!processFlows?.length) {
        return { statuses: [], status: null };
    }

    // Select the current process flow based on currentProcessFlow value; if not found, default to the first one
    let selectedProcessFlow = processFlows.find(flow => flow.value === currentProcessFlow) || processFlows[0];

    // If no statuses are defined in the selected process flow, return empty statuses and null status
    const availableStatuses = selectedProcessFlow.configuration?.statuses || [];
    if (!availableStatuses.length) {
        return { statuses: [], status: null };
    }

    // Find the active status based on the current status value
    const activeStatus: any = availableStatuses.find(status => status?.value === currentStatus);

    // Retrieve the workflow configuration from the selected process flow
    const workflowConfig = selectedProcessFlow.configuration?.workflow;

    // If no workflow edges are defined or no active status found, return all statuses
    if (!workflowConfig?.flow?.edges?.length || !activeStatus || !workflowConfig?.status || requiredAll) {
        return { statuses: availableStatuses, status: activeStatus, snapshot: activeStatus?.snapshot_view?.value };
    }

    // Filter the target statuses based on edges where the source matches the current status
    const targetStatuses = workflowConfig.flow.edges
        .filter(edge => edge.source === currentStatus)
        .map(edge => edge.target);

    // Return only the statuses that match the target statuses in the workflow
    const filteredStatuses = availableStatuses.filter(status =>
        targetStatuses.includes(status.value)
    );
    return { statuses: filteredStatuses, status: activeStatus, snapshot: activeStatus?.snapshot_view?.value };
};

/**
 * Retrieves the status to be used during the creation of a process flow.
 * It checks for an automation-created status and falls back to the default or first status.
 *
 * @param processFlow - The process flow object containing configuration details
 * @returns An object containing the created status or the first available status
 */
const getCreateStatus = (processFlow: ProcessFlow) => {
    // Destructure configuration and fall back to empty object if undefined
    const { configuration = {} } = processFlow || {};
    const { statuses = [], automation = {} } = configuration;

    // Return null status if no statuses are defined
    if (!statuses.length) {
        return { status: null };
    }

    // Return the created status from automation if it exists, otherwise return the first status
    if (automation.created?.status) {
        return { status: automation.created?.default?.status };
    }

    // Return the first status as fallback
    return { status: statuses[0] };
};

/**
 * Retrieves status options from the provided process flows.
 * If there's only one process flow, it directly returns its statuses.
 * If there are multiple process flows, it groups statuses by their respective process flow labels.
 *
 * @param processFlows - An array of process flow objects
 * @returns An array of status options grouped by process flow label
 */
const getStatusOptions = (processFlows: ProcessFlow[]) => {
    // If no process flows are provided, return an empty array
    if (!processFlows?.length) {
        return {options: [], group: false};
    }

    // If there's only one process flow, return its statuses directly
    if (processFlows.length === 1) {
        const statuses = (processFlows[0].configuration?.statuses || []).map(status => ({
            ...status,
            in_forms: true,
        }));
        return { options: statuses, group: false };
    }

    // Initialize an empty array to hold grouped status options
    const options: Option[] = [];

    // Iterate over each process flow to extract and group statuses by label
    processFlows.forEach((processFlow: ProcessFlow) => {
        const statuses = (processFlow.configuration?.statuses || []).map(status => ({
            ...status,
            in_forms: true,
        }));
        
        // If the process flow has statuses, add them to the options array grouped by the process flow label
        if (statuses.length) {
            options.push({
                label: processFlow?.label,
                options: statuses,
                in_forms: true,
            });
        }
    });

    // Return the grouped status options
    return {options: options, group: true};
};

/**
 * Retrieves the first matching status based on the provided label from the process flows.
 * If no match is found, it returns null.
 *
 * @param processFlows - An array of process flow objects.
 * @param label - The label to search for in process flow statuses.
 * @returns The matching status object or null if not found.
 */
const getStatusByLabel = (processFlows: ProcessFlow[], label: string) => {
    // If no process flows are provided, return null
    if (!processFlows?.length) {
        return null;
    }

    for (const processFlow of processFlows) {
        const statuses = processFlow.configuration?.statuses || [];
        const matchingStatus = statuses.find(status => status?.label === label);
        if (matchingStatus) {
            return matchingStatus; // Return the first matching status found
        }
    }

    return null; // Return null if no matching status is found
};


export {
    getAvailableStatuses,
    getCreateStatus,
    getStatusOptions,
    getStatusByLabel
};
