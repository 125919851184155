
import { useEffect, useState } from "react";
import {
	Badge,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Input,
	InputGroup,
	Label,
	Row,
	UncontrolledDropdown,
} from "reactstrap";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import TableContainer from "./TableComponent";
import DeleteModal from "./DeleteModal";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { userDelete, userInviteRequest, usersListGetRequest } from "store/user/action";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { withTranslation } from "react-i18next";
import InviteUser from "./InviteUser";
import { roleNameListRequest } from "store/role/action";
import Select from "react-select";
import AccessDenied from "pages/AccessDenied/Denied";
import ListingLoader from "Components/Common/FormBuilder/DtsTablePlaceholder";
import UserAvatar from "Components/Common/UserAvtar/ListingUserAvatar";
import { checkTheyOwn } from "utils";

interface SortConfig {
	[key: string]: {
		direction: 'asc' | 'desc';
	};
}

const UsersTable = (props: any) => {
	const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
	const userList = useSelector((state: ApplicationState) => state.user.users);
	const paginationDetails = useSelector((state: ApplicationState) => state.user.pagination);
	const roles = useSelector((state: ApplicationState) => state.role.list);
	const me = useSelector((state: ApplicationState) => state.user.user);
	const navigate = useNavigate();
	const [record, setrecord] = useState<any>({});
	const [focusid, setfocused] = useState<number>(-1);
	const [visibleColumns, setvisibleColumns] = useState<any>([]);
	const [columns, setColumns] = useState<any>([])
	const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
	const [editModal, setEditOpen] = useState<boolean>(false);
	const [id, setId] = useState<string>("")
	const [inviteUser, setInviteUser] = useState<boolean>(false);
	const [role, setRole] = useState<any[]>([])
	const [pageSize, setPageSize] = useState<any | null>(10)
	const [confirmationStatus, setConfirmationStatus] = useState<any>("ALL")
	const [roleFilter, setRoleFilter] = useState<any>()
	const [roleId, setRoleId] = useState<string>("");
	const [filterSet, setFilterSet] = useState<boolean>(false);
	const [rolefilterSet, setRolefilterSet] = useState<boolean>(false);
	const [sortConfig, setSortConfig] = useState<SortConfig>({});
	const [searchQuery, setSearchQuery] = useState("");
	const [sortBy, setSortBy] = useState<string>("createdAt");
	const [sortOrder, setSortOrder] = useState<string>("desc");
	const [selectedRole, setSelectedRole] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState(null)
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const [statusMenuOpen, setStatusMenuOpen] = useState(false)
	const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
	const authLoading = useSelector((state: ApplicationState) => state.auth.meloading);
	const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
	const [currentPage, setCurrentPage] = useState(0);
	const [usersQueryParams, setUsersQueryParams] = useState('')
	const [viewAccess, setViewAccess] = useState<boolean>(false);
	const [access, setAccess] = useState<any>([]);
	const [create, setCreate] = useState<boolean>(false);
	const [edit, setEdit] = useState<any>(null);
	const [deleteaccess, setDeleteAccess] = useState<any>(null);
	const [pagination, setPagination] = useState<any>(null);
	const toggleIcon = (field: string) => {
		setSortConfig((prevState) => {
			const currentDirection = prevState[field]?.direction;
			const newDirection = currentDirection === 'asc' ? 'desc' : 'asc';
			return { ...prevState, [field]: { direction: newDirection } };
		});
	};

	const status = [
		{
			options: [
				{ label: "All", value: "ALL" },
				{ label: "Unconfirmed", value: "UNCONFIRMED" },
				{ label: "Confirmed", value: "CONFIRMED" },
			],
		},
	];

	useEffect(() => {
		let filter = `status=ALL`;
		const savedFilters = localStorage.getItem("filters");

		if (savedFilters) {
			dispatch(usersListGetRequest(JSON.parse(savedFilters)));
		} else {
			dispatch(usersListGetRequest(filter));
		}
	}, []);

	useEffect(() => {
		const roleValues: any = [];
		roles && roles.map((item: any) => {
			roleValues.push({ value: item.id, label: item.title });
		});
		setRole(roleValues);
		if (roles && roles.length > 0) {
			// Assuming the roleId is the first role in the list
			setRoleId(roles[0].id);
		}
	}, [roles]);

	useEffect(() => {
		let columns: any = [];
		if (userList && userList.length > 0) {
			const keys = Object.keys(userList[0])
			const fieldsCols: any =
				keys.map((fields: any, index: any) => {
					const isSorted = sortConfig[fields]?.direction;
					const isIconUp = isSorted === 'asc';
					if (fields == "id")
						return {
							Header: _.startCase(fields),
							accessor: fields,
							filterable: true,
							isSortable: false,
							className: "hoverable",
							disableFilters: true,
							show: false,
						};
					else if (fields == "name")
						return {
							Header: (
								<div className="cursor-pointer text-primary d-flex align-items-center" key={columns.id}
									onClick={(e) => {
										setCurrentPage(0);
										handleselected(fields, "column")
										e.stopPropagation();
										toggleIcon(fields);
									}}
									{...columns.isSortable ? columns.getSortByToggleProps() : {}}>
									{_.startCase(fields)}
									<span>
										<i
											className={`lh-base ri-arrow-${isIconUp ? 'up' : 'down'}-s-fill`}
										></i>
									</span>
								</div>
							),
							keys: { columns },
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								let they_own = checkTheyOwn(cellProps.row.original, userProfile?.id)
								const { profilePic, name, email } = cellProps.row.original;
								return (
									<div style={{ minWidth: 400 }} className="d-flex gap-5 align-items-center justify-content-between">
										<div className="d-flex align-items-center gap-2">
											<div className="flex-shrink-0">
												{profilePic ? (
													<img
														src={profilePic}
														alt=""
														className="avatar-sm img-thumbnail rounded-circle"
													/>
												) : (
													<UserAvatar img={profilePic} firstName={name} />
												)}
											</div>
											<div className="flex-grow-1 ms-3">
												<span
													className="cursor-pointer text-decoration-none text-ellipsis"
													onClick={() => {
														handleEditClick(cellProps.row.original.id);
													}}
												>
													{name || email}
												</span>
												<p className="text-muted fs-12 fw-light mb-0 text-decoration-none text-ellipsis">
													{email}
												</p>
											</div>
										</div>
										<div className="me-2">
											<ButtonGroup>
												<UncontrolledDropdown>
													<DropdownToggle
														color="primary"
														size="sm"
														title="Manage User"
														className="btn-primary justfy-self-end hoverbutton ms-1"
													>
														<i className="ri-settings-2-fill label-icon align-middle"></i>
													</DropdownToggle>
													<DropdownMenu>
														{edit && (edit?.toLowerCase() == "all" || (edit?.toLowerCase() == "they_own" && they_own)) ? (
															<DropdownItem
																onClick={() => {
																	onClickrow(cellProps.row.original.id, index);
																	setEditOpen(true);
																	handleEditClick(cellProps.row.original.id);
																	setId(cellProps.row.original.id);
																}}
															>
																{props.t("common.table.edit")}
															</DropdownItem>
														) : null}
														{deleteaccess && (deleteaccess?.toLowerCase() == "all" || (deleteaccess?.toLowerCase() == "they_own" && they_own)) ? (
															<DropdownItem
																disabled={
																	cellProps.row.original.id === me?.id ||
																	cellProps.row.original.role?.title === "Owner"
																}
																onClick={() => {
																	setPageSize(pageSize);
																	setCurrentPage(currentPage);
																	onClickrow(cellProps.row.original, index);
																	setisDeleteOpen(true);
																}}
															>
																{props.t("common.table.delete")}
															</DropdownItem>
														) : null}
														{create ? (
															<DropdownItem
																disabled={
																	cellProps.row.original.id === me?.id ||
																	cellProps.row.original.confirmationStatus === "CONFIRMED"
																}
																onClick={() => {
																	handleResendInvite(
																		cellProps.row.original?.role?.id,
																		cellProps.row.original?.email,
																		cellProps.row.original?.name
																	);
																}}
															>
																{props.t("common.table.resend_invite")}
															</DropdownItem>
														) : null}
													</DropdownMenu>
												</UncontrolledDropdown>
											</ButtonGroup>
										</div>
									</div>
								);
							},
						};
					else if (fields == "confirmationStatus")
						return {
							Header: (<div className="cursor-pointer text-primary"
								onClick={(e) => {
									setCurrentPage(0);
									setSortBy(fields);
									handleselected(fields, "column")
									e.stopPropagation();
									toggleIcon(fields);
								}}
								{...columns.isSortable ? columns.getSortByToggleProps() : {}}> {_.startCase(fields)} <span>
									<i
										className={`lh-base ri-arrow-${isIconUp ? 'up' : 'down'}-s-fill`}

									></i>
								</span></div>),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								return <div className="">{cellProps.row.original.confirmationStatus}</div>;
							},
						};
					else if (fields == "phoneNumber")
						return {
							Header: (<div className="cursor-pointer text-primary"
								onClick={(e) => {
									setCurrentPage(0);
									setSortBy(fields);
									handleselected(fields, "column");
									e.stopPropagation();
									toggleIcon(fields);
								}}
								{...columns.isSortable ? columns.getSortByToggleProps() : {}}> {_.startCase(fields)}<span>
									<i
										className={`lh-base ri-arrow-${isIconUp ? 'up' : 'down'}-s-fill`}

									></i>
								</span></div>),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								return <div>{cellProps.row.original.phoneNumber}</div>;
							},
						};
					else if (fields === "role") {
						return {
							Header: _.startCase(fields),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								const role = cellProps.row.original?.role;
								return (
									<div>
										{role?.title}
										{role?.deletedAt !== null && <span className="text-muted"> (deactivated)</span>}
									</div>
								);
							},
						};
					}
					else if (fields === "teams") {
						return {
							Header: (
								<div
									className="cursor-pointer text-primary"
									onClick={(e) => {
										setCurrentPage(0);
										setSortBy(fields);
										handleselected(fields, "column");
										e.stopPropagation();
										toggleIcon(fields);
									}}
									{...(columns.isSortable ? columns.getSortByToggleProps() : {})}
								>
									{_.startCase(fields)}
									<span>
										<i
											className={`lh-base ri-arrow-${isIconUp ? 'up' : 'down'}-s-fill`}

										></i>
									</span>
								</div>
							),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								const teams = cellProps.row.original?.teams;
								return teams?.map((team: any) => {
									return (
										<h5>
											<Badge color="primary"> {team?.name} {team?.deletedAt !== null && <span className="text-muted"> (Deleted)</span>} </Badge>
										</h5>
									);
								})
							},
						};
					}
					else if (fields == "tenant")
						return {
							Header: _.startCase(fields),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								return <div>{cellProps.row.original.tenant.companyName}</div>;
							},
						};
					else if (fields == "createdAt")
						return {
							Header: (<div className="cursor-pointer text-primary"
								onClick={(e) => {
									handleselected(fields, "column")
									e.stopPropagation();
									toggleIcon(fields);
									setCurrentPage(0);
									setSortBy(fields);
								}}
								{...columns.isSortable ? columns.getSortByToggleProps() : {}}> {_.startCase(fields)}<span>
									<i
										className={`lh-base ri-arrow-${isIconUp ? 'up' : 'down'}-s-fill`}

									></i>
								</span></div>),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								return <div>{moment(cellProps.row.original.createdAt).format('lll')}</div>;
							},
						};
					else if (fields == "updatedAt")
						return {
							Header: (<div className="cursor-pointer text-primary"
								onClick={(e) => {
									setCurrentPage(0);
									setSortBy(fields);
									handleselected(fields, "column")
									e.stopPropagation();
									toggleIcon(fields);
								}}
								{...columns.isSortable ? columns.getSortByToggleProps() : {}}> {_.startCase(fields)}<span>
									<i
										className={`lh-base ri-arrow-${isIconUp ? 'up' : 'down'}-s-fill`}

									></i>
								</span></div>),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							show: true,
							Cell: (cellProps: any) => {
								return <div>{moment(cellProps.row.original.updatedAt).format('lll')}</div>;
							},
						};
					else
						return {
							Header: _.startCase(fields),
							isSortable: true,
							accessor: fields,
							filterable: true,
							disableFilters: true,
							className: "hoverable",
							show: true,
							Cell: (cellProps: any) => {
								switch (fields.type) {
									case "text":
									case "email":
									case "password":
									case "number":
									case "date":
										return (
											<div className="d-flex align-items-center text-primary" key={index}>
												<Label
													className="hstack py-1 form-label"
													htmlFor={cellProps.row.original.name}
												>
													{cellProps.row.original.label}
												</Label>
											</div>
										);
									case "textarea":
										return (
											<div key={index}>
												<Label
													className="hstack py-1 form-label"
													htmlFor={cellProps.row.original.name}
												>
													{cellProps.row.original.label}
												</Label>
												<textarea
													className="form-control"
													onClick={() => {
														onClickrow(cellProps.row.original, index);
													}}
													id={cellProps.row.original.name}
													name={cellProps.row.original.name}
													value={cellProps.cell.value || ""}
												></textarea>
											</div>
										);
									default:
										return <div key={index}>--</div>;
								}
							},
						};
				})
			setvisibleColumns(fieldsCols);
			columns.push.apply(columns, fieldsCols);
			setColumns(columns.filter((x: any) => x.Header !== "Id" && x.Header !== "Email" && x.Header !== "Tenant" && x.Header !== "Current Stage" && x.Header !== "Sub Agent" && x.Header !== "Error Message" && x.Header !== "Profile Pic" && x.Header !== "Values Json" && x.Header !== "Subagent" && x.Header !== "Subscription" && x.Header !== "Created By"));
		}
	}, [create, deleteaccess, edit, userList]);

	useEffect(() => {
		let userRole = userProfile?.role?.title;
		if (userRole === "Owner") {
			setViewAccess(true)
			setCreate(true)
			setEdit("all")
			setDeleteAccess("all")
		}
		else if (subLevelPermissionsList && subLevelPermissionsList.users) {
			let access = subLevelPermissionsList.users;
			if (access && access?.length) {
				setAccess(access)
				if (access && access.length) {
					access.map((item: any, index: any) => {
						if (item.name.toLowerCase() == 'view') {
							item.value == "" || item.value == "none" ? setViewAccess(false) : setViewAccess(true)
						}
						if (item.name.toLowerCase() == 'invite') {
							item.value == "" || item.value == "none" ? setCreate(false) : setCreate(true)
						}
						if (item.name.toLowerCase() == 'edit') {
							item.value == "" || item.value == "none" ? setEdit(null) : setEdit(item.name)
						}
						if (item.name.toLowerCase() == 'delete') {
							item.value == "" || item.value == "none" ? setDeleteAccess(null) : setDeleteAccess(item.name)
						}
					})
				}
			}
			else {
				setViewAccess(false)
			}
		}
	}, [subLevelPermissionsList, userProfile])

	useEffect(() => {
		if (paginationDetails) {
			setPagination(paginationDetails);
		}
	}, [paginationDetails])

	const handleselected = (option: any, name: string) => {
		let queryParams: any = new URLSearchParams({});
		switch (name) {
			case 'next-page':
				setPageSize(pageSize)
				setCurrentPage(currentPage + 1)
				if (sortOrder) queryParams.set('sortOrder', sortOrder)
				if (sortBy) queryParams.set('sortBy', sortBy);
				queryParams.set('page', currentPage + 1);
				if (searchQuery) queryParams.set('search', searchQuery);
				if (pageSize) queryParams.set('pageSize', pageSize);
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				break;
			case 'previous-page':
				setPageSize(pageSize)
				setCurrentPage(currentPage - 1)
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				if (sortOrder) queryParams.set('sortOrder', sortOrder)
				if (sortBy) queryParams.set('sortBy', sortBy);
				queryParams.set('page', currentPage - 1);
				if (searchQuery) queryParams.set('search', searchQuery);
				if (pageSize) queryParams.set('pageSize', pageSize);
				break;
			case 'page-number':
				setPageSize(pageSize)
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				if (sortOrder) queryParams.set('sortOrder', sortOrder)
				if (sortBy) queryParams.set('sortBy', sortBy);
				if (pageSize) queryParams.set('pageSize', pageSize)
				queryParams.set('page', option);
				break;
			case 'page-size':
				let page = currentPage;
				page = 0
				if (sortOrder) queryParams.set('sortOrder', sortOrder)
				sortBy && queryParams.set('sortBy', sortBy);
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				queryParams.set('page', page);
				queryParams.set('pageSize', option);
				break;
			case 'search':
				queryParams.set('search', option);
				if (sortBy) queryParams.set('sortBy', sortBy);
				if (pageSize) queryParams.set('pageSize', pageSize)
				queryParams.set('page', 0);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				break;
			case 'clear-search':
				setSearchQuery('')
				if (sortBy) queryParams.set('sortBy', sortBy);
				if (pageSize) queryParams.set('pageSize', pageSize)
				queryParams.set('page', 0);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				break;
			case 'confirmation-status':
				if (option == "ALL") {
					setRoleFilter(roleFilter);
					setFilterSet(true);
					currentPage && queryParams.set('page', 0)
					if (pageSize) queryParams.set('pageSize', pageSize)
					roleFilter && queryParams.set('roleId', roleFilter?.value);
					queryParams.set('status', `ALL`)
				}
				else {
					setRoleFilter(roleFilter);
					setFilterSet(true);
					currentPage && queryParams.set('page', 0)
					if (pageSize) queryParams.set('pageSize', pageSize)
					queryParams.set('status', option);
					roleFilter && queryParams.set('roleId', roleFilter?.value);
				}
				break;
			case 'role-filter':
				setConfirmationStatus(confirmationStatus);
				setRolefilterSet(true);
				confirmationStatus && queryParams.set('status', confirmationStatus);
				currentPage && queryParams.set('page', 0)
				if (pageSize) queryParams.set('pageSize', pageSize)
				queryParams.set('roleId', option);
				break;
			case 'column': //column-phone
				setSortBy(option);
				if (sortBy === option) {
					setSortOrder(sortOrder === "asc" ? "desc" : "asc");
				} else {
					setSortOrder("asc");
				}
				confirmationStatus && queryParams.set('status', confirmationStatus);
				currentPage && queryParams.set('page', 0)
				if (pageSize) queryParams.set('pageSize', pageSize)
				roleFilter && queryParams.set('roleId', roleFilter?.value);
				searchQuery && queryParams.set('search', searchQuery);
				queryParams.set('sortBy', option);
				queryParams.set('sortOrder', sortOrder === "asc" ? "desc" : "asc");
				break;

			default:
				// handle default case if needed  column-confirmation
				break;
		}
		setUsersQueryParams(queryParams.toString())
		// let page = currentPage == 0 ? 0 : 
		dispatch(usersListGetRequest(queryParams.toString()));
	};

	const handleRoleSearch = (option: any, name: string) => {
		let queryParams: any = new URLSearchParams({});
		switch (name) {
			case 'searchRole':
				currentPage && queryParams.set('page', 0)
				if (pageSize) queryParams.set('pageSize', pageSize)
				if (option) {
					queryParams.set('search', option.toString());
				}
				break;
			case 'searchStatus':
				currentPage && queryParams.set('page', 0)
				if (pageSize) queryParams.set('pageSize', pageSize)
				if (option) {
					queryParams.set('search', option.toString());
				}
				break;
		}
		dispatch(roleNameListRequest(queryParams.toString()));
	}

	const onClickrow = (data: any, index: any) => {
		setrecord(data);
		// setInputs(data);
		setfocused(index);
	};

	const onDelete = (userId: any) => {
		let queryParams: any = new URLSearchParams({});
		const handleSuccess = (body: any): void => {
			pageSize && queryParams.set('pageSize', pageSize)
			currentPage && queryParams.set('page', currentPage)
			dispatch(usersListGetRequest(queryParams.toString()));

			setisDeleteOpen(false);
			// const message = () => toast(`${body?.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
			// message();
		}
		const handleError = (body: any): void => {
			const message = () => toast(`${body?.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
			message();
		};
		dispatch(userDelete(record.id, handleSuccess, handleError));
	}

	const clearFilters = async () => {
		let filter = `status=ALL`
		setSelectedRole(null)
		setSelectedStatus(null)
		setCurrentPage(0)
		setPageSize(10)
		setSearchQuery('');
		setConfirmationStatus('ALL');
		setRoleFilter('');
		setFilterSet(false);
		setRolefilterSet(false);

		await dispatch(usersListGetRequest(filter));

	};

	const handleEditClick = (id: string) => {
		if(usersQueryParams) localStorage.setItem("filters", JSON.stringify(usersQueryParams));
		navigate(`/users/${id}`);
	};

	const handleSendInvite = () => {
		setInviteUser(true);
	}

	const handleResendInvite = (id: any, email: string, name: any) => {
		const data = {
			"attributes": [
				{
					"key": "email",
					"value": email
				},
				{
					"key": "role_Id",
					"value": id
				},
				{
					"key": "name",
					"value": name
				}
			]
		};
		const handleSuccess = (body: any): void => {
			const message = () => toast(`Invite resent successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
			message();
		}
		const handleError = (body: any): void => {
			const message = () => toast(`${body.status}`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
			message();
		}
		dispatch(userInviteRequest(data, handleSuccess, handleError));
	}

	const handleInputChange = (inputValue: any) => {
		handleRoleSearch(inputValue, "searchRole");
		handleRoleSearch(inputValue, "searchStatus");
	};

	const handleRoleSelect = (sortBy: any) => {
		setRoleFilter(sortBy);
		setSelectedRole(sortBy);
		setMenuIsOpen(false); // Close the menu when an option is selected

		handleselected(sortBy?.value, "role-filter");
	};



	const handleStatusSelect = (sortBy: any) => {
		handleselected(sortBy?.value, "confirmation-status");
		setConfirmationStatus(sortBy?.value);
		setSelectedStatus(sortBy);
		setStatusMenuOpen(false)
	};

	const pageChanged = (pageVal: any) => {
		const { pages, page } = pagination;
		const lastPage = pages;
		let newPageNo = 0;
		let queryParams: any = new URLSearchParams({});
		switch (pageVal) {
			case 'prev':
				if (page >= 1) {
					newPageNo = page - 1;
					setPagination({ ...pagination, page: newPageNo });
					queryParams.set('page', newPageNo);
					queryParams.set('pageSize', pagination?.size);
				}
				break;
			case 'next':
				if (page < lastPage) {
					newPageNo = page + 1;
					setPagination({ ...pagination, page: newPageNo });
					queryParams.set('page', newPageNo);
					queryParams.set('pageSize', pagination?.size);
				}
				break;
			case 'first':
				newPageNo = 0;
				setPagination({ ...pagination, page: 0 });
				queryParams.set('page', 0);
				queryParams.set('pageSize', pagination?.size);
				break;
			case 'last':
				newPageNo = lastPage - 1;
				setPagination({ ...pagination, page: newPageNo });
				queryParams.set('page', newPageNo);
				queryParams.set('pageSize', pagination?.size);
				break;
			default:
				newPageNo = pageVal - 1;
				setPagination({ ...pagination, page: newPageNo });
				queryParams.set('page', newPageNo);
				queryParams.set('pageSize', pagination?.size);
				break;
		}

		setUsersQueryParams(queryParams.toString())
		// let page = currentPage == 0 ? 0 : 
		dispatch(usersListGetRequest(queryParams.toString()));
	};

	// Handle PageSize Change
	const onChangePageSize = (value: any) => {
		setPageSize(value)
		setPagination({ ...pagination, size: value, page: 0 })
		let queryParams: any = new URLSearchParams({});
		queryParams.set('page', 0);
		queryParams.set('pageSize', value);
		setUsersQueryParams(queryParams.toString())
		dispatch(usersListGetRequest(queryParams.toString()));
	}

	return (
		<> {!authLoading ?
			viewAccess ? <div>
				<ToastContainer />
				<DeleteModal
					show={isDeleteOpen}
					onCloseClick={() => setisDeleteOpen(false)}
					onDelete={onDelete}
					record={record}
					props={props}
				/>
				<InviteUser
					usersQueryParams={usersQueryParams}
					inviteUser={inviteUser}
					setInviteUser={setInviteUser}
				/>
				<Row className='hstack justify-content-between gap-3 pb-3'>
					<Col md={3} sm={12} xl={3} xxl={3} lg={3}>
						<div className="search-box">
							<InputGroup className='border rounded'>
								<ButtonGroup>
									<UncontrolledDropdown>
										<DropdownToggle tag="button" className="btn btn-light">
											All
										</DropdownToggle>
									</UncontrolledDropdown>
								</ButtonGroup>
								<Input className='border-0' placeholder={props.t("common.filter.search.search")} style={{ padding: '1.2rem 0.9rem' }} onChange={(e) => {
									setSearchQuery(e.target.value);
									handleselected(e.target.value, "search");
								}} value={searchQuery}></Input>
							</InputGroup>
							{searchQuery === "" ? <i className="ri-search-line search-icon fs-16" style={{ right: 8, left: 'auto', top: 0, position: "absolute", zIndex: 99 }}></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer text-danger fs-16" style={{ right: 8, left: 'auto', top: 0, position: "absolute", zIndex: 99 }} onClick={(e) => {
								setSearchQuery("");
								handleselected("", "search");
							}}></i>}
						</div>
					</Col>
					<Col md={3} sm={12} xl={3} xxl={3} lg={3}>
						<div className='hstack gap-2 justify-content-end gap-2'>
							<div className='hstack gap-2'>
								{
									create ?
										<Button
											onClick={() => handleSendInvite()}
											color="primary"
											size="sm"
											className="btn-label">
											<i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
											{props.t("users.invite_user")}
										</Button>
										:
										<Button
											color="primary"
											disabled
											size="sm"
											className="btn-label">
											<i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
											{props.t("users.invite_user")}
										</Button>
								}
							</div>
						</div>
					</Col>
				</Row>
				<div className="hstack justify-content-between">
					<div className="hstack justify-content-start gap-3">
						<div className="w-auto select-element" style={{ minWidth: 180 }}>
							<Select
								placeholder={props.t("users.filter_by_status")}
								value={selectedStatus}
								// onInputChange={() => handleInputChange}
								isClearable={true}
								onChange={handleStatusSelect}
								options={status}
								classNamePrefix="js-example-data-array"
								isLoading={false}
								menuIsOpen={statusMenuOpen}
								onMenuOpen={() => setStatusMenuOpen(true)}
								onMenuClose={() => setStatusMenuOpen(false)}
							/>
						</div>
						<div className="w-auto select-element" style={{ minWidth: 180 }}>
							<Select
								placeholder={props.t("users.filter_by_role")}
								value={selectedRole}
								isClearable={true}
								onInputChange={handleInputChange}
								onChange={handleRoleSelect}
								options={role}
								classNamePrefix="js-example-data-array"
								isLoading={false}
								menuIsOpen={menuIsOpen}
								onMenuOpen={() => setMenuIsOpen(true)}
								onMenuClose={() => setMenuIsOpen(false)}
							/>

						</div>
					</div>
					<div className="">
						<Button
							size="sm"
							color="danger"
							className="btn-label btn-soft-danger"
							onClick={() => {
								clearFilters()
							}}
						>
							<i className="ri-close-circle-fill label-icon align-middle fs-16 me-2"></i>
							{props.t("common.filter.quick.btn_clear_all")}
						</Button>
					</div>
				</div>
				<Card className="students-table pt-1  border-0 shadow-none">
					<CardBody className="table-card gridjs-border-none mt-n2 border-0">
						<TableContainer
							tableClass="align-middle table-nowrap"
							theadClass="table-light text-muted"
							thClass="border-bottom-1 table-soft-primary"
							columns={columns}
							data={userList || []}
							paginations={pagination}
							props={props}
							pageChanged={pageChanged}
							onChangePageSize={onChangePageSize}
						/>
					</CardBody>
				</Card></div>
				: <AccessDenied />
			: <ListingLoader
				columns={columns}
				tableClass="align-middle table-nowrap"
				theadClass="table-light text-muted"
				thClass="border-bottom-1 table-soft-primary" />
		}
		</>
	);
};
export default withTranslation()(UsersTable);
