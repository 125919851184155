import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "reactstrap";
import country from '../../../Components/Common/FormBuilder/countrycode.json'


interface SignUpState {
    email: string;
    otp: string;
    full_name: string;
    phone_number: string;
    company_name: string;
    company_website: string;
    password: string;
    people_count: string;
    countrycode: any;
}

interface Step4Props {
    toggleArrowTab: () => void;
    inputs: SignUpState;
    setInputs: React.Dispatch<React.SetStateAction<SignUpState>>;
    onCreate: () => void;
    t: (key: string) => string;
    OnUpdate: (object: any) => void;
    loading: boolean;
    isInitialValue: boolean,
    setIsInitialValue: (value: boolean) => void;
}

const Step4: React.FC<Step4Props> = ({ toggleArrowTab, inputs, setInputs, onCreate, t, OnUpdate, loading, setIsInitialValue }: Step4Props) => {
    
    const [disabled, setDisabled] = useState<boolean>(true);
    const [rest, setRest] = useState<any>({
        validate: {required: true}
    });
    useEffect(() => {
        var atIndex = inputs.email && inputs.email.indexOf('@') ? inputs.email.indexOf('@') : -1;
        var dotIndex = inputs.email && inputs.email.indexOf('.', atIndex) ? inputs.email.indexOf('.', atIndex): -1;
        if (atIndex !== -1 && dotIndex !== -1) {
            var textBetween = inputs.email.substring(atIndex + 1, dotIndex);
            textBetween !=="gmail" && textBetween !=="yopmail" ? setValues(textBetween) : setInputs({...inputs, company_name: inputs.company_name !== "" ? inputs.company_name:""})
        } 
    }, [inputs.email])

    const setValues = (textBetween: any) => {
        if(inputs.company_name !== "") {
            setInputs({...inputs, company_name: textBetween, company_website: `${textBetween}.com` })
            setIsInitialValue(true) 
        }
    }
    useEffect(() => {
        setDisabled(inputs.full_name !="" && inputs.phone_number !="" && /^[0-9]+$/.test(inputs.phone_number) && inputs.countrycode !="")
    }, [inputs.phone_number, inputs.countrycode, inputs.full_name])

    const onClickUpdate = () => {
        const attributes = [
            {
                "key": "name",
                "value": inputs.full_name
            },
            {
                "key": "phone_number",
                "value": `${inputs.countrycode}${inputs.phone_number}`
            }
        ]
        OnUpdate(attributes)
    }
    const onChange = (value: any) => {
        if(/[!@#$%^&*(),.?":{}|<>]/.test(value)) return
        else setInputs({ ...inputs, full_name: value })
    }

    
    const onhandleChange = (value: any) => {
        let dial_code: any = ""
        let restnumber=""
        if(value) {
            country.map((item: any) => {
                if (value.includes(item.dial_code)) {
                    return dial_code = item.dial_code
                }                    
            })         
            restnumber = value.slice(dial_code.length)      
        }       
        setInputs({...inputs, phone_number: restnumber, countrycode:dial_code})
    }

    return (
        <div>
            <Row className="hstack gap-3 flex-column justify-content-center">
                <Col lg={12}>
                    <div className="hstack fs-32 fw-bold justify-content-center">
                        <h1 className="fw-bold">{t("signup.what_is_your_name")}?</h1>
                    </div>
                </Col>
                <Col lg={4} md={5} sm={12} xs={12}>
                    <div className="mb-3">
                        <FormBuilder 
                            label={t("signup.full_name")}
                            type={"textfield"}
                            defaultValue={inputs.full_name}
                            value={inputs.full_name}
                            name={inputs.full_name}
                            placeholder={t("signup.enter_full_name")}
                            className="w-100 h-100"
                            onChange={(value: any) => onChange(value)}
                            isEditState={true}
                            rest={rest}
                        />
                    </div>
                    <div className="mb-3">
                       <FormBuilder 
                            label={t("signup.phone_number")}
                            type={"phone"}
                            defaultValue={inputs.phone_number}
                            value={inputs.phone_number}
                            name={"phone_number"}
                            placeholder={t("signup.enter_phone_number")}
                            className="w-100 h-100"
                            onChange={(value: any) => {   
                                onhandleChange(value)                             
                            }}
                            isEditState={true}
                            rest={rest}
                        />
                    </div>
                </Col>
                <Col lg={4} md={5} sm={12} xs={12}>
                    <button type="button" className="w-100 btn btn-primary btn-icon waves-effect waves-light" onClick={onClickUpdate} disabled={!disabled}>{loading ?  <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">
                                Loading...
                            </span>
                            <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                        </span> : t("signup.next")}</button>{" "}
                </Col>
            </Row>
        </div>
    );
};

export default Step4;
