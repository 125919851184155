import React, { useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ApplicationState } from 'store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { toast } from "react-toastify";
import { Action } from 'redux';
import { Button, Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row, Table } from "reactstrap";
import { dashboardsGetRequest, dashboardDeleteRequest } from "store/report/action";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import moment from "moment";
import { Dashboard } from "store/report/types";
import DeleteModel from "./Common/DeleteModel";
import Paginator from "Components/Common/PaginatorFix";

const Dashboards: React.FC = (props: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const dashboardsLoading = useSelector((state: ApplicationState) => state.report.dashboards_loading);
    const dashboards = useSelector((state: ApplicationState) => state.report.dashboards);
    const dashboardsPagination = useSelector((state: ApplicationState) => state.report.dashboards_pagination);
    const navigate = useNavigate();
    const [pagination, setPagination]: any = useState({ page: 1, size: 10 });

    const [search, setSearch] = useState<string>('');
    const [open, setOpen] = useState<string>('');
    const [selectedDelete, setSelectedDelete] = useState<Dashboard | null>(null);

    useEffect(() => {
        // Clear any existing timer before setting a new one
        const timer = setTimeout(() => {
            const page = pagination?.page || 1;
            const size = pagination?.size || 10;
            
            // Dispatch with the AbortController signal
            dispatch(dashboardsGetRequest(search, page, size));
        }, 600);
    
        // Cleanup function: clear timeout and abort request on unmount or dependencies change
        return () => {
            clearTimeout(timer);
        };
    }, [search]);

    const getDashboards = (page: number, size: number) => {
        dispatch(dashboardsGetRequest(search, page, size));
    }

    useEffect(() => {
        if(dashboardsPagination) {
            setPagination(dashboardsPagination)
        }
    }, [dashboardsPagination])

    // Handle search input change
    const onSearch = (value: any) => {
        setPagination({ ...pagination, page: 0 });
        setSearch(value)
    }

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                    getDashboards(newPageNo, pagination.size);
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                    getDashboards(newPageNo, pagination.size);
                }
                break;
            case 'first':
                newPageNo = 1;
                setPagination({ ...pagination, page: 1 });
                getDashboards(newPageNo, pagination.size);
                break;
            case 'last':
                newPageNo = lastPage;
                setPagination({ ...pagination, page: newPageNo });
                getDashboards(newPageNo, pagination.size);
                break;
            default:
                newPageNo = pageVal;
                setPagination({ ...pagination, page: newPageNo });
                getDashboards(newPageNo, pagination.size);
                break;
        }
    };

    const onChangePageSize = (event: any) => {
        setPagination({ ...pagination, size: event.target.value })
        getDashboards(pagination.page, event.target.value);
    }

    const onCreate =  () => {
        navigate('/dashboards/create');
    }

    const onView =  (id: string) => {
        navigate(`/dashboard?id=${id}`);
    }

    const onEdit =  (id: string) => {
        navigate(`/dashboards/edit/${id}`);
    }

    const onDeleteReport = () => {
        const handleSuccess = () => {
            toast(`Dashboard deleted Successfull.`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            getDashboards(pagination.page, pagination.size);
            setSelectedDelete(null);
        }
        const handleError = () => {
            toast(`Dashboard deletion error.`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
        }
        dispatch(dashboardDeleteRequest(selectedDelete?.id, handleSuccess, handleError));
    }

    if(dashboardsLoading) {
        return (
            <DtsTablePlaceholder
                columns={[]}
                tableClass="align-middle table-nowrap"
                thClass="border-bottom-1 table-soft-primary"
            />
        )
    }
    return (
        <React.Fragment>
            <Card>
                <CardBody className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center search-box">
                            <Input
                                type="text"
                                size={14}
                                className="search"
                                placeholder={props.t("common.filter.search.search_dashboards")}
                                onChange={(e) => onSearch(e.target.value)}
                                value={search}
                                style={{width: '240px'}}
                            />
                            {search === "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => setSearch("")}></i>}
                        </div>
                        <div>
                            <Button
                                color="primary"
                                className="btn-label"
                                onClick={() => onCreate()}
                            >
                                    <i className="ri-server-line label-icon align-middle fs-16 me-2"></i>
                                    {props.t("dashboard.create_dashboard")}
                            </Button>
                        </div>
                    </div>
                    {dashboards?.length ? <div className="d-flex flex-column gap-2">
                        <div className="table-responsive">
                        <Table className="align-middle table-nowrap">
                            <thead className='table-light text-muted position-sticky top-0'>
                                <tr>
                                    <th scope="col" className="border-bottom-1 table-soft-primary fw-bold">Name</th>
                                    <th scope="col" className="border-bottom-1 table-soft-primary fw-bold">Chart</th>
                                    <th scope="col" className="border-bottom-1 table-soft-primary fw-bold">Created at</th>
                                    <th scope="col" className="border-bottom-1 table-soft-primary fw-bold">Updated at</th>
                                    <th scope="col" className="border-bottom-1 table-soft-primary fw-bold">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dashboards.map((dashboard: Dashboard, reportIndex: number) => {
                                    return (
                                        <tr key={reportIndex}>
                                            <td className='align-middle text-dark fw-semibold'>
                                                <Link to={`/dashboard?id=${dashboard?.id}`} className="text-primary fw-semibold">{dashboard?.name}</Link>
                                            </td>
                                            <td className='align-middle text-dark fw-semibold'>Count</td>
                                            <td className='align-middle text-dark fw-semibold'>{moment(dashboard?.created_at).format('lll')}</td>
                                            <td className='align-middle text-dark fw-semibold'>{moment(dashboard?.updated_at).format('lll')}</td>
                                            <td>
                                                <ul className="list-inline hstack gap-2 mb-0">
                                                    <li className="list-inline-item">
                                                        <Dropdown isOpen={open === dashboard.id} toggle={() => open === dashboard.id ? setOpen("") : setOpen(dashboard.id)}>
                                                            <DropdownToggle
                                                                href="#"
                                                                className="btn btn-soft-primary btn-sm dropdown"
                                                                tag="button"
                                                            >
                                                                <i className="ri-more-fill"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu  container="body" className="dropdown-menu-center">
                                                                <DropdownItem
                                                                    onClick={() => onView(dashboard.id)}
                                                                    className="dropdown-item">
                                                                    <div className="d-flex gap-2 align-items-center">
                                                                        <Button size="sm" color="info" className="btn-icon btn-soft-info">
                                                                            <i className="ri-eye-fill" />
                                                                        </Button>
                                                                        {props.t("common.table.view")}
                                                                    </div>                                                        
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() => onEdit(dashboard.id)}
                                                                    className="dropdown-item edit-item-btn fs-12">
                                                                    <div className="d-flex gap-2 align-items-center">
                                                                        <Button size="sm" color="primary" className="btn-icon btn-soft-secondary">
                                                                            <i className="ri-pencil-fill" />
                                                                        </Button>
                                                                        {props.t("common.table.edit")}
                                                                    </div>
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() => setSelectedDelete(dashboard)}
                                                                    className="dropdown-item remove-item-btn fs-12">
                                                                    <div className="d-flex gap-2 align-items-center">
                                                                        <Button size="sm" color="danger" className="btn-icon btn-soft-danger">
                                                                            <i className="ri-delete-bin-fill" />
                                                                        </Button>
                                                                        {props.t("common.table.delete")}
                                                                    </div>
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        </div>
                        <Row className="align-items-center g-3 text-center text-sm-start">
                            <div className="col-sm">
                                <div className="text-muted">
                                    <span className="fw-semibold ms-1">
                                        {pagination ? `${pagination.offset + 1}-${Math.min(pagination.offset + pagination.size, pagination.elements)} ${props.t("common.pagination.of")} ${pagination.elements} ${props.t("common.pagination.results")}` : `0-0 of 0 ${props.t("common.pagination.results")}`}
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                                    currentPage={pagination?.page}
                                    pageChanged={(e: any) => pageChanged(e)}
                                    pageSize={pagination?.size}
                                    isPageSizeChange={true}
                                    onChangeInSelect={(e: any) => onChangePageSize(e)}
                                    pagination={pagination}
                                />
                            </div>
                        </Row>
                        </div> :
                        <div>
                            <Table className="align-middle table-nowrap">
                                <thead className='table-light text-muted position-sticky top-0'>
                                    <tr>
                                        <th scope="col" className="border-bottom-1 table-soft-primary fw-bold">Name</th>
                                        <th scope="col" className="border-bottom-1 table-soft-primary fw-bold">Created At</th>
                                        <th scope="col" className="border-bottom-1 table-soft-primary fw-bold">Updated At</th>
                                    </tr>
                                </thead>
                            </Table>
                            <div className="d-flex flex-column align-items-center justify-content-center w-100 gap-3" style={{ height: '300px'}}>
                                <Label>{props.t("dashboard.no_dashboard_message")}</Label>
                                <div>
                                    <Button
                                        color="primary"
                                        className="btn-label"
                                        onClick={() => onCreate()}
                                    >
                                            <i className="ri-server-line label-icon align-middle fs-16 me-2"></i>
                                            {props.t("dashboard.create_dashboard")}
                                    </Button>
                                </div>
                            </div>
                        </div>}
                </CardBody>
            </Card>
            {
                selectedDelete ?
                    <DeleteModel
                        isOpen={!!selectedDelete}
                        toggle={() => setSelectedDelete(null)}
                        report={selectedDelete}
                        onDelete={() => onDeleteReport()}
                    /> : null
            }
        </React.Fragment>
    );
};
export default withTranslation()(Dashboards);
