import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardBody, Label } from 'reactstrap';
import { fields } from '../Fields/tierFields'
import Common from '../Common/Common';

const TierSubCompoenent = (props: any) => {  
    const { dataFields, setCount, count, number, setTierJson, tierJson, onAddRule, inputs, levels, selectedMonths, selectedYears, selectRuleType } = props; 
    const [addedDataFields, setAddedDataFields] = useState<any>([]);

    const OnAddEntry = (formValues: any, key: any) => {
        delete formValues['clone_from_basic']
        let newTierjson = {...tierJson, [key]: {...formValues, active: true}, ruleType: selectRuleType?.value,}
        setTierJson(newTierjson)
        onAddRule(newTierjson, 'tier')
    } 

    const OnDeleteEntry = (key: any) => {
        let newTierjson = {...tierJson}
        delete newTierjson[key]   
        setTierJson(newTierjson)
        onAddRule(newTierjson, 'tier')
    } 

    useEffect(() =>{
        let staticfields: any = []
        fields && fields.length && fields.map((field: any) => {
            let staticfield = JSON.stringify(field);
            let newField = { valuesJson: staticfield };
            staticfields.push(newField)
        })
        setAddedDataFields([...staticfields, ...dataFields])
    }, [dataFields])

    
    return (
        <React.Fragment>
            <Card className='border border-dashed border-end-0 border-start-0 vstack gap-2'>
                <CardBody>
                    <div>
                        <div className='vstack'>
                            <div className='hstack justify-content-between'>
                                <Label className='fw-bold'>Slab {number + 1}</Label>  
                                <div className='hstack gap-2'>                                  
                                    <button onClick={() => {setCount(count - 1); OnDeleteEntry(`slab${number + 1}`)}} className="btn btn-sm btn-soft-danger remove-list fs-12">
                                        <i className="ri-delete-bin-fill"></i>{" "}
                                    </button>{" "}
                                </div>     
                            </div>
                        </div>
                        <div className='p-2'>
                            <Common levels={levels} BtnClassName={"w-auto btn-primary"} md={6} lg={6} sm={12} xl={6} xs={12} dataFields={addedDataFields} btnAction={(values: any) => OnAddEntry(values, `slab${number + 1}`)} btn_text={inputs?.tier?.[`slab${number + 1}`] ? 'Update Slab' : 'Save Slab'} disabled={false} theme={"primary"} data={inputs?.tier?.[`slab${number + 1}`]} commissionJson={inputs} tier={true}/>                  
                         </div> 
                     </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(TierSubCompoenent);
