import React, { useEffect, useState } from 'react';
// import DropdownTreeSelect from 'react-dropdown-tree-select';
import DropdownTreeSelect from '../../Common/Custom-Treeseletct/TreeSelect';
import 'react-dropdown-tree-select/dist/styles.css';
import { Label, FormFeedback, Button, ButtonGroup, Spinner } from "reactstrap";
import { optionsListGetRequest } from 'store/auth/action';
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import {  ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { isJson } from 'utils';

interface Option {
    label: string;
    value: string;
}
interface DtsSelectInputProps {
    label?: string;
    type?: string;
    value?: string;
    placeholder?: string;
    onChange: (value: string) => void;
    isEditable?: boolean;
    name?: string;
    errorText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    actionButtons?: boolean;
    defaultValue?: any;
    rest?: any;
    invalid?: any;
    formValues?: any;
    dataFields?: any;
    options?: any;
    onSaveClick?: any;
    setdisabled?: any;
    setError?: any;
    formerror?: any;
    resetvalue?: any;
    loading?: boolean;
}


const DtsSelectInput: React.FC<DtsSelectInputProps> = ({loading, resetvalue,setdisabled, onSaveClick, options, formValues, dataFields, invalid, rest, defaultValue, label, type, value, placeholder, onChange, isEditable, name, icon, iconPosition, errorText, actionButtons, readOnly, disabled }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [hover, setHover] = useState<any>(false)
    const [formoptions, setOptions] = useState<any>(false)
    const [defaultoption, setDefaultOption] = useState<any>(null)
    const optionHierachy = useSelector((state: ApplicationState) => state.auth.optionData);
    const optionsLoading = useSelector((state: ApplicationState) => state.auth.optionsLoading);
    const [resetValue, setResetValue] = useState<any>(null)
    const [errormsg, setErrormsg] = useState();
    const onhandleChange = (option: any) => {  
        if(option == null){
            onChange("");
            setDefaultOption(option)
        } 
        else {
            onChange(option);
            setDefaultOption(option)
        }
    }

    useEffect(() => {
        if(rest && rest.validate) {           
            if(errormsg)
            { 
                if (typeof setdisabled === 'function') {
                    setdisabled(true)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.add('d-block');
            } 
            else {
                if (typeof setdisabled === 'function') {
                    setdisabled(false)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.remove('d-block');
            }            
        }
    },[value])

    useEffect(() => {
        if (rest && rest.validate && rest.validate.required) {
            if (value === '') {
                const inputdiv: any = document.getElementById(`${name}input`);
                if (inputdiv) inputdiv.classList.add('d-block');
            }
            else {
                const inputdiv: any = document.getElementById(`${name}input`);
                if (inputdiv) inputdiv.classList.remove('d-block');
            }
        }
    }, [value])


    useEffect(() => {
        if (rest && rest.validate && rest.validate.required) {
            const feedback: any = document.getElementById(`starred${name}`);
            if (feedback) feedback.classList.remove('d-none');
        }
    }, [rest])


    const onFocus = () => {
        setOptions([])
        if (rest && rest.model) {            
            let filter ={
                "filters": [
                  {
                    "quick": [],
                    "advance": [],
                    "search": null
                  }
                ],
                "sorts": [
                  {
                    "field": "name",
                    "order": "asc"
                  }
                ]
            }   
             let filterObject=null
            if(rest.model === 'sources' || rest.responseType === 'treeselect') filterObject= {responseType:'treeselect'}
            dispatch(optionsListGetRequest(rest.model, filter, 0, 500, filterObject)); 	
        }
    }
    const mapconvertToOptions = (data: any, resetvalue: any) => {
        data?.length && data.map((item: any) => { 
            const values =item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : item.valuesJson         
            let label = rest?.optionLabel ? rest?.optionLabel : "name";       
            const option: any = { label: values?.[label], value: item.id };    
            if(option.value === resetvalue || option.label === resetvalue || option.value === resetvalue?.value) {
                setDefaultOption(option)
            }
            else if(resetvalue?.value) {
                setDefaultOption(resetvalue)
            }
            if (item.children && item.children.length > 0) {                
                mapconvertToOptions(item.children, resetvalue);
            }
        });
    }

    useEffect(() => {
        if(resetvalue) {
            setDefaultOption(resetvalue)
            if(optionHierachy && optionHierachy?.[rest?.model]?.length) {
                mapconvertToOptions(optionHierachy?.[rest?.model]?.length,resetvalue);
                setResetValue(resetvalue)
            }
        }
        else {
            setResetValue(resetvalue)
            setDefaultOption(null)
        }        
    }, [resetvalue])

    useEffect(() => {
        if(defaultValue == "c017ce92-15ae-47b1-9ab1-197895ce94d6") {
            setDefaultOption({label: 'Sub-Agents', value:  "c017ce92-15ae-47b1-9ab1-197895ce94d6"});
        }
        else {
            options && options.length &&  options.map((item: any) =>{
                if(item.value === defaultValue || item.label === defaultValue || item.value === defaultValue?.value) {
                    let defaultoption = item;
                    setDefaultOption(defaultoption);
                }
            })
        }
    }, [defaultValue])


    useEffect(() => {
        onFocus()
    }, [])

    const onCheckClick = (e: any) => {
        e.preventDefault();
        onSaveClick();
        setHover(false)
    }
   const select = (value: any) => {
        onChange(value)  
   }

   const convertToOptions = (data: any) => {
    return data.map((item: any) => {           
        const values = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson) : item.valuesJson;
        let label = rest?.optionLabel ? rest?.optionLabel : "name";   
        const option: any = { label: values?.name, value: item.id }; 
        if(option.value === defaultValue || option.label === defaultValue || option.value === defaultValue?.value) {
            setDefaultOption(option)
        }  
        if (item.children && item.children.length > 0) {
            let childrens = convertToOptions(item.children);
            return { ...option, children: childrens }
        }                   
        else if (defaultValue?.value) {
            setDefaultOption(defaultValue);
        }
        return option;
    });
}
    
    useEffect(() => {
        if (optionHierachy && optionHierachy?.[rest?.model]?.length) {
            let options = convertToOptions(optionHierachy?.[rest?.model])
            setOptions(options)
        }
    }, [optionHierachy?.[rest?.model], hover, defaultValue])
 
    return (
        <div className=''>
            {
                actionButtons ?
                    <div>
                        {hover ?
                            <div>
                                {label ? <Label htmlFor={name} className="form-label fw-bold fs-14">{label}<span id={`starred${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label> : null}
                                <div className="d-flex border rounded-3 align-items-center">
                                <DropdownTreeSelect loading={optionsLoading}  label={rest?.label} onFocus={() =>onFocus()} options={formoptions} selectedOption={defaultoption} setSelectedOption={select} />
                                    <ButtonGroup>
                                        <Button onClick={(e) => { e.preventDefault(); setHover(false); onhandleChange(resetvalue);}} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-close-fill" /> </Button>
                                        <Button onClick={(e) => {onCheckClick(e);}} color="primary" className="btn-icon"> <i className="ri-check-fill" /> </Button>
                                    </ButtonGroup>
                                </div>   
                                {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}
                            </div>
                            : <div>
                                {label ? <Label htmlFor={name} className="form-label fw-bold fs-14 mb-0">{label}<span id={`starred${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label> : null}
                                <div className="hstack justify-content-between topbar-user">
                                    <div className="fs-14 fw-medium form-control border-0 text-nowrap text-truncate text-nowrap-ellipsis topbar-user">
                                        <span style={{opacity:1}}>
                                            {defaultoption?.label ? defaultoption.label : resetvalue?.label ? resetvalue?.label : resetvalue && typeof resetvalue !== 'object' ? defaultValue : '' }
                                        </span>
                                    </div>
                                    {
                                        readOnly ? null
                                            :  <Button disabled={loading} onClick={() => setHover(true)} color="primary" className="btn-icon btn-soft-primary" id={`btn${name}`}> 
                                            {loading ? <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> : <i className="ri-edit-2-fill" /> } 
                                        </Button>
                                    }

                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div>
                        {label ? <Label htmlFor={name} className="form-label fw-bold fs-14">{label}<span id={`starred${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label> : null}
                         <DropdownTreeSelect loading={optionsLoading}  label={rest?.label} onFocus={() =>onFocus()} options={formoptions} selectedOption={defaultoption} setSelectedOption={select} />
                        {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}
                    </div>
            }
        </div>
    );
};

export default DtsSelectInput;

