import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Input, Label, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { dashboardSaveRequest } from "store/report/action";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { useNavigate } from "react-router";
import { ApplicationState } from "store";

interface SaveModelProps {
    reports: any;
    saveModel: boolean;
    setSaveModel: (saveModel: boolean) => void;
    t: (key: string) => string;
}

const SaveModel: React.FC<SaveModelProps> = ({
    reports,
    saveModel,
    setSaveModel,
    t,
}) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const loading = useSelector((state: ApplicationState) => state.report.dashboard_save_loading)
    const navigate = useNavigate();
    
    const [dashboardName, setDashboardName] = useState('')

    const onSaveReport = () => {
        const reportAssociations = reports.map((report: any) => {
            return {
                report_id: report?.report?.id,
                layout: {
                    key: report.key,
                    layout: report.layout,
                    title: report.title,
                }
            }
        })
        const data = {
            name: dashboardName,
            config: {},
            report_associations: reportAssociations
        }
        const handleSuccess = () => {
            setSaveModel(false);
            toast(`Dashboard saved successfully.`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            navigate('/dashboards');
        }
        const handleError = () => {
            toast(`Dashboard saving report.`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
        }
        dispatch(dashboardSaveRequest(data, handleSuccess, handleError))
    }
    return (
        <>
            <ToastContainer />
            <Modal isOpen={saveModel} toggle={() => loading ? null : setSaveModel(false)} backdrop={'static'} centered>
                <ModalHeader className="bg-light p-3" toggle={() => loading ? null : setSaveModel(false)}>
                {t("dashboard.save_dashboard")}
                </ModalHeader>
                    <ModalBody>
                        <div className="d-flex flex-column gap-2 p-3">
                            <div className="form-group mb-3">
                                <Label className="form-label fw-bold fs-6">
                                    Name
                                </Label>
                                <Input
                                    type="text"
                                    placeholder='Enter dashboard name'
                                    onChange={(event) => setDashboardName(event?.target.value)}
                                    value={dashboardName}
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <div className="modal-footer">
                        <div className="hstack gap-2 justify-content-end">
                            <Button color="danger"
                                disabled={loading}
                                onClick={() => {
                                    setSaveModel(false)
                                }}
                            >{t("common.offcanvas.import_cancel")}</Button>
                            {loading ? 
                            <Button color="primary" className="btn-label btn-load" disabled>
                                <i className="ri-tools-fill label-icon align-middle fs-16 me-2"></i>
                                <span className="d-flex align-items-center">
                                    <span className="flex-grow-1 me-2">
                                    {t("common.offcanvas.saving")}
                                    </span>
                                    <Spinner size="sm" className="flex-shrink-0" role="status"> {t("common.offcanvas.saving")} </Spinner>
                                </span>
                            </Button> : <Button
                                onClick={() => onSaveReport()}
                                disabled={!dashboardName}
                                color="primary"
                                className="btn-label">
                                    <i className="ri-tools-fill label-icon align-middle fs-16 me-2"></i>
                                    {t("common.offcanvas.save")}
                            </Button>}
                        </div>
                    </div>
            </Modal>
        </>
    );
};

export default withTranslation()(SaveModel);
