import {InvoiceActionTypes} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { createQueryLink, responseHandler } from "utils";

const { api } = config;

const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const invoiceGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: InvoiceActionTypes.GET_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/models/invoices/${id}`, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, InvoiceActionTypes.GET_SUCCESS, InvoiceActionTypes.GET_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const invoicesMetadataGetRequest: AppThunk = (model, filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: InvoiceActionTypes.GET_METADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        if (model) {
            headers.append('Properties-Model', model);
        }  
        const searchQuery =   createQueryLink(filterObject)    
        try {  
            const response = await fetch(searchQuery, {
                method: "GET",
                headers: headers
            });    
            await responseHandler(response, dispatch, InvoiceActionTypes.GET_METADATA_SUCCESS, InvoiceActionTypes.GET_METADATA_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const invoiceUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: InvoiceActionTypes.PUT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/models/invoices/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );                                 
        const error = {
            204: {
                "message": "Invoice updated Successfully",
                "success": true
            },
            200: {
                "message": "Invoice updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, InvoiceActionTypes.PUT_SUCCESS, InvoiceActionTypes.PUT_ERROR, error, handleSuccess, handleError);
    
    };
};

export const invoiceDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: InvoiceActionTypes.DELETE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/models/invoices/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const error = {
                204: {
                    "message": "Invoice deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "Invoice deleted Successfully",
                    "success": true
                }
            }  
            await responseHandler(response, dispatch, InvoiceActionTypes.DELETE_SUCCESS, InvoiceActionTypes.DELETE_ERROR, error, handleSuccess, handleError);
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: InvoiceActionTypes.DELETE_ERROR
            });
            handleSuccess();
        }
    };
};

export const invoiceListGetRequest: AppThunk = (filters,pageno, pageSize, signal) => {
    let requestbody = filters ? filters : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: InvoiceActionTypes.GET_ALL_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        try {           
            const response = await fetch(`${DTS_API_STUDENT}/models/get/invoices?page=${pageno?pageno:0}&pageSize=${pageSize?pageSize:10}`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(requestbody),
                signal
            }).catch((error) => {
                return false;
            });                    
            if(typeof response === 'object' && !Array.isArray(response) && response !== null) {           
                await responseHandler(response, dispatch, InvoiceActionTypes.GET_ALL_SUCCESS, InvoiceActionTypes.GET_ALL_ERROR, null, null, null, "GET");
            } 
        }
        catch (error) {
        }
    };
};

export const invoiceCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: InvoiceActionTypes.POST_REQUEST
        });
        try {
            const response = await fetch(
                `${DTS_API_STUDENT}/models/invoices`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "token": token
                    },
                    body: JSON.stringify(data)
                }
            );
            const error = {
                208: {
                    "message": "Invoice already exist",
                    "success": false
                },
                201: {
                    "message": "Invoice created Successfully",
                    "success": true
                },        
                200: {
                    "message": "Invoice created Successfully",
                    "success": true
                }
            }
            await responseHandler(response, dispatch, InvoiceActionTypes.POST_SUCCESS, InvoiceActionTypes.POST_ERROR, error, handleSuccess, handleError);

        } catch (error) {
            dispatch({ type: InvoiceActionTypes.POST_ERROR, payload: error });
            handleError();
        }
    };
};

export const invoiceBulkCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: InvoiceActionTypes.POST_REQUEST
        });
        try {
            const response = await fetch(
                `${DTS_API_STUDENT}/models/invoices/bulk`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "token": token
                    },
                    body: JSON.stringify(data)
                }
            );
            const error = {
                208: {
                    "message": "Invoice already exist",
                    "success": false
                },
                201: {
                    "message": "Invoice created Successfully",
                    "success": true
                },        
                200: {
                    "message": "Invoice created Successfully",
                    "success": true
                }
            }
            await responseHandler(response, dispatch, InvoiceActionTypes.POST_SUCCESS, InvoiceActionTypes.POST_ERROR, error, handleSuccess, handleError);

        } catch (error) {
            dispatch({ type: InvoiceActionTypes.POST_ERROR, payload: error });
            handleError();
        }
    };
};

export const InvoiceStudentUploadRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: InvoiceActionTypes.POST_STUDENT_INVOICES_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/applications/invoices/file-upload/${id}`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );                                 
        const error = {
            204: {
                "message": "Source updated Successfully",
                "success": true
            },
            200: {
                "message": "Source updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, InvoiceActionTypes.POST_STUDENT_INVOICES_SUCCESS, InvoiceActionTypes.POST_STUDENT_INVOICES_ERROR, error, handleSuccess, handleError, "GET");
    
    };
};
