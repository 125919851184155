import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Accordion, AccordionItem, AccordionHeader, AccordionBody, Col, Row, Badge } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { allGroupsWithToken, propertyListGetRequest } from 'store/properties/action';
import { userGetRequest } from 'store/auth/action';
import { useParams } from 'react-router-dom';
import UpdateFormCol from 'Components/Common/FormBuilder/UpdateFormCol';
import moment from 'moment';
import { checkTheyOwn, isJson } from 'utils';
import ProgressBar from '../ProgressBar';

const Overview = ({ t, status, model, details, getDetailsAction, updateAction, metaDataFields, studentId, applicationId }: any) => {
    const { id } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const propertiesList = useSelector((state: ApplicationState) => state.properties.list);
    const me = useSelector((state: ApplicationState) => state.user.user);
    const groups = useSelector((state: ApplicationState) => state.properties.allGroups);
    const [completedSteps, setCompletedSteps] = useState<any>([]);
    const [activeVerticalTab, setActiveVerticalTab] = useState<any | null>(1);
    const [formData, setFormData] = useState<{ [key: number]: any }>({});
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const [statusMetaData, setStatusMetaData] = useState<any>([]);
    const [edit, setEdit] = useState<any>(false);
    const [info, setInfo] = useState<any>({});
    const [parsedProperties, setParsedProperties] = useState<[]>([]);
    const [parsedValuesJson, setParsedValuesJson] = useState<[]>([]);
    const [loader, setLoader]= useState<any>({})


    const handleselectValue = (value: any, options: any) => {
        if (options && options.length) {
            const foundItem = options.find((item: any) => item.value === value);
            if (foundItem) {
                return foundItem.label;
            }
        }
    }

    useEffect(() => { dispatch(propertyListGetRequest(model, { pageSize: "300" }));
    }, [])

    useEffect(() => {
        let parsedData: any = [];
        propertiesList.forEach((item: { valuesJson: string; id: any; [key: string]: any }) => {
            if (item.valuesJson) {
                try {
                    const values = JSON.parse(item.valuesJson);
                    if (values.profileCompleteness === true) {
                        parsedData.push({
                            ...item 
                        });
                    }
                } catch (error) {
                    console.error("Error parsing valuesJson", error);
                }
            }
        });
        setParsedProperties(parsedData);
    }, [propertiesList]);
    
    useEffect(() => {
        let parsedData: any = []; // Initialize parsedData as an array
    
        propertiesList.forEach((item: { valuesJson: string; id: any; [key: string]: any }) => {
            if (item.valuesJson) {
                try {
                    const parsedValues = JSON.parse(item.valuesJson);
                    if (parsedValues.profileCompleteness === true) {
                        parsedData.push({
                            ...parsedValues,
                        });
                    }
    
                } catch (error) {
                    console.error("Error parsing valuesJson", error);
                }
            }
        });
    
        // Set the parsed properties to state
        setParsedValuesJson(parsedData);
    }, [propertiesList]);
    
    useEffect(() => {
        if(details && details.id) {
            let recordDetails = details.valuesJson;
            let newData: any = {}
                groups && groups.length  && 
                groups.map((item: any, index: any) => {
                    item && item.metadata && item.metadata.map((meta: any) => {
                        let field = meta?.valuesJson && isJson(meta?.valuesJson) ? JSON.parse(meta?.valuesJson) : {}
                        let rendervalue = recordDetails[field.key];
                        if (field?.mergeColumns?.columns) {
                            let value = field?.mergeColumns?.columns
                            let values = value.split(",")
                            let newValue = "";
                            values?.length && values.map((item: any) => {
                                newValue = recordDetails?.[item] ? `${newValue ? newValue : ""} ${recordDetails?.[item]}${field.mergeColumns?.separator || " "}` : ""
                            })
                            rendervalue = newValue;
                        }
                        if (field.type == 'select' || field.type == 'radio' || field.type == 'checboxes') {
                            rendervalue = handleselectValue(recordDetails?.[field.key], field.values);
                        }
                        else if (field.optionLabel) {
                            rendervalue = recordDetails?.[field.key] && recordDetails?.[field.key]?.[field.optionLabel] ? recordDetails?.[field.key]?.[field.optionLabel] : 'NA'
                        }
                        newData[field.key]= rendervalue;
                    });
                })     
                setInfo({...newData,id:details.id, studentId:details.id})                   
        }
      }, [details])

      
    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setEdit(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.[model];
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'edit') {
                            if(item.value == "they_own") {
                                if(details) {
                                    let they_own = checkTheyOwn(details, userProfile?.id)
                                    setEdit(they_own)
                                }
                            }
                            else if (item.name.toLowerCase() == 'edit') {
                                item.value == "" || item.value == "none" ? setEdit(false) : setEdit(true)
                            }
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile, details])


    const handleViewAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    }
    const handleEditAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return false;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    };

    const steps = groups && groups.length  ? [
        ...groups.map((item: any, index: any) => {
            const parsedFields = item && item.metadata && item.metadata.map((meta: any) => ({
                ...meta,
                valuesJson: isJson(meta.valuesJson) ? meta.valuesJson : "{}"
            }));
            let newstatusMetaData: any = [];
            parsedFields && parsedFields.map((field: any) => {
                let statusField = field?.valuesJson && isJson(field?.valuesJson) ? JSON.parse(field?.valuesJson) : {}
                let permissionsJson = field?.permissionsJson && isJson(field?.permissionsJson) ? JSON.parse(field?.permissionsJson) : {}
                let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleViewAccess(permissionsJson) : true;
                let isFieldEditaccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson) : true;
                if (isFieldviewaccess && !statusField?.process_flows) {
                    if (isFieldEditaccess) {
                        let newField = { ...field, isEditAccess: true }
                        return newstatusMetaData.push(newField)
                    }
                    return newstatusMetaData.push(field)
                }
            })
            return {
                label: item.groupTitle,
                tab: (index + 1).toString(),
                fields: newstatusMetaData
            };
        }),
        {
            tab: (Array.isArray(groups) ? (groups.length + 1) : 1).toString(),
            fields: statusMetaData
        }
    ]: []


    useEffect(() => {
        let filterObject = {pageSize: 100}
        dispatch(allGroupsWithToken(model, filterObject));
        dispatch(userGetRequest());
    }, [dispatch]);

    useEffect(() => {
        if (me) {
            let filterObject = {pageSize: 100}
            dispatch(allGroupsWithToken(model, filterObject));
        }
    }, [dispatch, me]);

    const toggleVerticalTab = (tab: string) => {
        setActiveVerticalTab((prevTab: string) => (prevTab === tab ? null : tab));
        if (!completedSteps.includes(tab)) {
            setCompletedSteps([...completedSteps, tab]);
        }
    };

    const onUpdate = (formValues: any, type?:any) => {
        const key = Object.keys(formValues)[0];
        setLoader({[key]: true})
        const updatedFormData = {
            ...formData,
            [activeVerticalTab!]: { valuesJson: JSON.stringify(formValues) }
        };

        const combinedFormData = Object.keys(updatedFormData).reduce((acc: any, key: any) => {
            try {
                const valuesJson = updatedFormData[key].valuesJson;
                if (valuesJson) {
                    const parsedData = JSON.parse(valuesJson);
                    return { ...acc, ...parsedData };
                }
            } catch (error) {
                console.error(`Error parsing JSON for tab ${key}:`, error);
            }
            return acc;
        }, {});

        setFormData(updatedFormData);

        const handleSuccess = (body: any) => {
            dispatch(getDetailsAction(id))
            setLoader({[key]: false})
        };

        let istrue = formValues && Object.keys(formValues).length === 0
        if(istrue || !formValues){
            return
        }
        else {
            dispatch(updateAction(id, combinedFormData, handleSuccess, () => { }));
        }
    };

      useEffect(() => {
        dispatch(getDetailsAction(id))
      }, [])

    return (
        <React.Fragment>
            <Row className="g-0 text-center pb-1">
                <Col xs={6} sm={3} className="p-3 border border-dashed border-start-0 border-top-0 border-bottom-0">
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-primary-subtle rounded-circle fs-2">
                            <i className="ri-exchange-fill text-primary"></i>
                            </span>
                        </div>
                        <div>
                            <h6 className="mb-1">{status?.label}</h6>
                            <p className="text-muted mb-0">{t("student.details.overview.status")}</p>
                        </div>
                    </div>
                </Col>
                <Col xs={6} sm={3} className="p-3 border border-dashed border-start-0 border-top-0 border-bottom-0">
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-primary-subtle rounded-circle fs-2">
                            <i className="ri-group-fill text-primary"></i>
                            </span>
                        </div>
                        <div>
                            <div className="d-flex flex-wrap gap-1 justify-content-center">
                                {status?.roles?.map((role: any) => {
                                    return (
                                        <Badge className='' color="primary">
                                            {role?.label}
                                        </Badge>
                                    )
                                })}
                            </div>
                            <p className="text-muted mb-0">{t("student.details.overview.role")}</p>
                        </div>
                    </div>
                </Col>
                <Col xs={6} sm={3} className="p-3 border border-dashed border-start-0 border-top-0 border-bottom-0">
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-primary-subtle rounded-circle fs-2">
                            <i className="ri-terminal-box-fill text-primary"></i>
                            </span>
                        </div>
                        <div>
                            <h6 className="mb-1">{details?.updatedAt ? moment(details?.updatedAt).format('MMMM Do YYYY') : "No Activities"}</h6>
                            <p className="text-muted mb-0">{t("student.details.overview.last_activity_date")}</p>
                        </div>
                    </div>
                </Col>
                <Col xs={6} sm={3}  className="p-3 border border-dashed border-start-0 border-top-0 border-bottom-0 border-end-0">
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-primary-subtle rounded-circle fs-2">
                            <i className="ri-git-repository-commits-fill text-primary"></i>
                            </span>
                        </div>
                        <div>
                            <h6 className="mb-1">{moment(details?.createdAt).format('MMMM Do YYYY')}</h6>
                            <p className="text-muted mb-0">{t("student.details.overview.created_date")}</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="g-0 text-center pb-1">
                <ProgressBar model={model} getDetailsAction={getDetailsAction} updateAction={updateAction} metaDataFields= {metaDataFields} details={details} dataFields={parsedProperties} parsedValuesJson={parsedValuesJson}/>
            </Row>
            <Accordion className="custom-accordionwithicon accordion-fill-primary" open={activeVerticalTab} toggle={toggleVerticalTab}>
                {steps && steps.length ? steps.map(({ tab, fields, label }: any) => {
                    const halfLength = fields && fields.length && Math.ceil(fields.length / 2);
                    const firstHalf = fields && fields.length && fields.slice(0, halfLength);
                    const secondHalf = fields && fields.length && fields.slice(halfLength);

                    if (fields && fields.length > 0) {
                        return (
                            <AccordionItem key={tab}>
                                <AccordionHeader targetId={tab}>
                                    {label}
                                </AccordionHeader>
                                <AccordionBody accordionId={tab}>
                                    <Row>
                                        {firstHalf.map((field: any, index: any) => (
                                            <Col xxl={6} lg={6} md={6} key={index}>
                                                <div>
                                                    <UpdateFormCol
                                                        dataFields={[field]}
                                                        onUpdate={onUpdate}
                                                        showbtn={false}
                                                        data={{...details, studentId: studentId, applicationId:applicationId}}
                                                        formfor={model}
                                                        isEditAccess={edit}
                                                        updateLoading={loader}
                                                    />
                                                </div>
                                            </Col>
                                        ))}
                                        {secondHalf.map((field: any, index: any) => (
                                            <Col  xxl={6} lg={6} md={6} key={index}>
                                                <div>
                                                    <UpdateFormCol
                                                        dataFields={[field]}
                                                        onUpdate={onUpdate}
                                                        showbtn={false}
                                                        data={{...details, studentId: studentId, applicationId:applicationId}}
                                                        formfor={model}
                                                        isEditAccess={edit}
                                                        updateLoading={loader}
                                                    />
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </AccordionBody>
                            </AccordionItem>
                        );
                    } else {
                        return null;
                    }
                }): <h6 className='text-center pt-3'>You don't have access to this section.</h6>}
            </Accordion>
        </React.Fragment>
    );
};

export default withTranslation()(Overview);
