import { Button, Input, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Select from "react-select";
import { useEffect, useState } from "react";
import { optionsListGetRequest } from "store/auth/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { isJson } from "utils";
import { components } from "react-select";
import classnames from "classnames";
import _ from "lodash";

const LoadOptions = ({ props, show, onCloseClick, record, onLoad }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const modelOptions = useSelector((state: ApplicationState) => state.auth.optionData);
    const optionsLoading = useSelector((state: ApplicationState) => state.auth.optionsLoading);
    const [preset, setPreset] = useState({ label: '', value: '' });
    const [options, setOptions] = useState([])
    const [startYear, setStartYear] = useState<any>(2000)
    const [endYear, setEndYear] = useState<any>(2010)
    const [countryCount, setCountryCount] = useState(0)
    const [error, setError] = useState(false)
    const [tab, setTab] = useState("1")
    const [pasteData, setPasteData] = useState("")
    const [delimeter, setDelimeter] = useState("")
    const presetOptions = [{
        label: `Countries(${optionsLoading ? 'Loading...' : countryCount})`,
        value: 'countries',
        isDisabled: countryCount > 0 ? false : true
    },
    {
        label: `Currencies(${optionsLoading ? 'Loading...' : countryCount})`,
        value: 'currencies',
        isDisabled: countryCount > 0 ? false : true
    },
    {
        label: 'Days of Week(7)',
        value: 'weekdays'
    },
    {
        label: 'Months of Year(12)',
        value: 'months'
    },
    {
        label: 'Years',
        value: 'years'
    },
    {
        label: 'Other',
        value: 'other'
    }
    ]

    useEffect(() => {
        let filter = {
            "filters": [
                {
                    "quick": [],
                    "advance": [],
                    "search": null
                }
            ],
            "sorts": [
                {
                    "field": "name",
                    "order": "asc"
                }
            ]
        }
        dispatch(optionsListGetRequest('countries', filter, 0, 500, null));
    }, []);

    useEffect(() => {
        setCountryCount(modelOptions['countries']?.length)
    }, [modelOptions['countries']])
    
    useEffect(() => {
        createOptions(pasteData)
    }, [delimeter])    

    useEffect(() => {
        setOptions([])
        setPasteData('')
    }, [tab, preset])

    useEffect(() => {
        let options = []
        let data = modelOptions['countries'];
        if (preset?.value) {
            switch (preset?.value) {
                case 'countries': options = data?.length && data?.map((item: any, index: any) => {
                    let valuesJson = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) : item?.valuesJson
                    return {
                        id: index + 1,
                        img: valuesJson?.iso2?.toLowerCase(),
                        label: valuesJson.name,
                        value: item?.toLowerCase()?.replace(/\s+/g, '_'),
                        in_forms: true
                    }
                })
                    setOptions(options)
                    break;
                case 'currencies': options = data?.length && data?.map((item: any, index: any) => {
                    let valuesJson = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) : item?.valuesJson
                    return {
                        id: index + 1,
                        img: valuesJson?.iso2?.toLowerCase(),
                        label: `${valuesJson?.name} ${valuesJson?.currency} (${valuesJson?.currency_symbol})`,
                        value: valuesJson?.currency,
                        in_forms: true
                    }
                })
                    setOptions(options)
                    break
                case 'weekdays':
                    data = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
                    options = data?.length && data?.map((item: any, index: any) => {
                        return {
                            id: index + 1,
                            label: item,
                            value: item?.toLowerCase()?.replace(/\s+/g, '_'),
                            in_forms: true
                        }
                    })
                    setOptions(options)
                    break;
                case 'months':
                    data = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
                    options = data?.length && data?.map((item: any, index: any) => {
                        return {
                            id: index + 1,
                            label: item,
                            value: item?.toLowerCase()?.replace(/\s+/g, '_'),
                            in_forms: true
                        }
                    })
                    setOptions(options)
                    break;
                case 'years':
                    data = Array.from({ length: parseInt(endYear) - parseInt(startYear) + 1 }, (_, i) => parseInt(startYear) + i);
                    options = data?.map((item: any) => {
                        return { id: item, label: item?.toString(), value: item?.toString(), in_forms: true };
                    });
                    setOptions(options);
                    break;
                case 'other':
                    setTab('2')
                    break;
                default: setOptions([])
            }
        }
    }, [preset])

    useEffect(() => {
        if (startYear > endYear) {
            setError(true)
        }
        else {
            setError(false)
        }
        let data = Array.from({ length: parseInt(endYear) - parseInt(startYear) + 1 }, (_, i) => parseInt(startYear) + i);
        let options: any = data?.map((item: any) => {
            return { id: item, label: item?.toString(), value: item?.toString(), in_forms: true };
        });
        setOptions(options);
    }, [endYear, startYear])

    const detectDelimiter = (text: string) => {
        const delimiters = [",", ";", "\t", "\n"]; // Common delimiters
        let detectedDelimiter = ","; // Default

        delimiters.forEach((delim) => {
            if (text.includes(delim)) {
                detectedDelimiter = delim;
            }
        });

        return detectedDelimiter;
    };

    const createOptions = (data: any) => {
        const delimiterCharacter = delimeter ? delimeter : detectDelimiter(data);
        const values = data.split(delimiterCharacter).map((val: any) => val.trim()).filter(Boolean);

        // Convert values into dropdown options
        const dropdownOptions: any = values.map((value: any, index: any) => ({
            id: index + 1,
            label: _.startCase(value),
            value: value?.toLowerCase()?.replace(/\s+/g, '_'),
            in_forms: true
        }));
        setOptions(dropdownOptions);
    }

    const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
        event.preventDefault(); // Prevent default paste behavior
        const pastedData = event.clipboardData.getData("text");
        setPasteData(pastedData);
        createOptions(pastedData)

    };

    const handleChange = (event: any) => {
        event.preventDefault(); // Prevent default paste behavior
        const pastedData = event.target.value;
        setPasteData(pastedData);
        createOptions(pastedData)
    }

    const handleError = (date: any, name: any) => {
        if (name === 'startYear') {
            if (date > endYear) {
                setError(true)
            }
            else {
                setError(false)
            }
        }
        else {
            if (startYear > date) {
                setError(true)
            }
            else {
                setError(false)
            }
        }
    }

    const CustomOption = (props: any) => {
        return (
            <components.Option {...props}>
                <div className='hstack justify-content-between'>
                    {props.children}
                    {props.isSelected && <i className="ri-check-line fs-20" />}
                </div>
            </components.Option>
        );
    };

    return (
        <Modal backdrop="static" isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={onCloseClick}>
                <span className='text-light'>{'Preset'}</span>
            </ModalHeader>
            <ModalBody className='text-center p-5'>
                <Nav pills className="nav nav-pills arrow-navtabs nav-primary bg-light mb-3">
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer", fontWeight: 600 }}
                            className={classnames({
                                active: tab === "1",
                            })}
                            onClick={() => {
                                setTab("1");
                            }}
                        >Presets
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer", fontWeight: 600 }}
                            className={classnames({
                                active: tab === "2",
                            })}
                            onClick={() => {
                                setTab("2");
                            }}
                        >
                            Paste your own
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent
                    activeTab={tab}
                    className="text-muted p-0"
                >
                    <TabPane tabId="1">
                        {tab === "1" &&
                            <div className="mt-1 vstack gap-2">
                                <div>
                                    <Label className="fs-16 fw-semibold">Load Preset</Label>
                                    <Select
                                        defaultOptions
                                        placeholder={'Select field type'}
                                        className="fs-16 fw-medium"
                                        value={preset}
                                        onChange={(option: any) => setPreset(option)}
                                        components={{ Option: CustomOption }}
                                        options={presetOptions}
                                        name="choices-single-default"
                                    ></Select>
                                </div>
                                {
                                    preset?.value === 'years' ? <div className='text-left'>
                                        <div className="hstack gap-2">
                                            <div>
                                                <Label className="fs-16 fw-semibold">Start Year</Label>
                                                <Input
                                                    type="number"
                                                    placeholder={'Enter Start Year'}
                                                    className="fs-16 fw-medium"
                                                    value={startYear}
                                                    onChange={(e: any) => { setStartYear(e.target.value); handleError(e.target.value, 'startYear') }}
                                                    name="choices-single-default"
                                                />
                                            </div>
                                            <div>
                                                <Label className="fs-16 fw-semibold">End Year</Label>
                                                <Input
                                                    type="number"
                                                    placeholder={'Enter Start Year'}
                                                    className="fs-16 fw-medium"
                                                    value={endYear}
                                                    onChange={(e: any) => { setEndYear(e.target.value); handleError(e.target.value, 'endYear') }}
                                                    name="choices-single-default"
                                                />
                                            </div>
                                        </div>
                                        {error ? <p className="text-left fs-12 mt-1 text-danger">End Year must be greater than start Year </p> : null}
                                    </div>
                                        : null
                                }
                            </div>
                        }
                    </TabPane>
                    <TabPane tabId="2">
                        {tab === "2" &&
                            <div className="mt-1 vstack gap-2">
                                <div className="text-left">
                                    <Label>Paste your option on their own line or add delimiter</Label>
                                    <textarea onPaste={handlePaste} rows={10} className="w-100 fs-16 fw-medium" value={pasteData} onChange={(e: any) => handleChange(e)} />
                                </div>
                                <div className="text-left">
                                    <Label>Add delimiter(Optional)</Label>
                                    <Input className="fs-16 fw-medium" onChange={(e: any) => setDelimeter(e.target.value)} />
                                </div>
                            </div>}
                    </TabPane>
                </TabContent>
                <div className="hstack gap-2 justify-content-center pt-1">
                    <Button className="btn btn-primary" onClick={() => { onLoad(options); onCloseClick() }} disabled={error}>
                        Load Options
                    </Button>
                    <Button color="danger" onClick={() => onCloseClick()} outline>
                        Cancel
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    ) as unknown as JSX.Element;
};

export default LoadOptions;