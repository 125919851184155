import React, { useState } from 'react'
import axios from 'axios'
import New from './New'
import Uploading from './Uploading'
import { ApplicationState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import config from "../../../../../config"
import { userGetRequest } from 'store/auth/action'
import { toast } from 'react-toastify'
const { DTS_API_STUDENT } = config.api;

interface FProps {
    selectedFiles: any;
    setSelectedFiles: (event: any) => void;
    onCloseClick: () => void;
    fileInfo: any;
    setFileInfo: (event: any) => void;
    model: string;
    downloadBulkTemplate: any;
}

const Upload: React.FC<FProps> = ({
    selectedFiles,
    setSelectedFiles,
    onCloseClick,
    fileInfo,
    setFileInfo,
    model,
    downloadBulkTemplate
}
) => {
    const dispatch: any = useDispatch();
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const [percentage, setPercentage] = useState(0)
    const [uploading, setUploading] = useState('not_started')

    const SubmitUpload = async () => {
        const file = selectedFiles && selectedFiles.length ? selectedFiles[0] : null;
        setFileInfo(file)
        if (file) {
            setUploading('started');
            const formData = new FormData();
            formData.append('file', file);
            formData.append('model', model);
            setUploading('started');
            const config = {
                onUploadProgress: function (progressEvent: any) {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total,
                    )
                    setPercentage(percentCompleted)
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "token": access_token
                },
            }
            await axios.post(`${DTS_API_STUDENT}/models/upload/${model}`, formData, config)
                .then((res: any) => {
                    if (res == 'We have received request to process bulk upload. You will receive the detailed status over an email') {
                        const message = () => toast(`We have received request to process bulk upload. You will receive the detailed status over an email`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
                        message();
                        setPercentage(100)
                        setUploading('completed')
                        onCloseClick()
                        setSelectedFiles([])
                        dispatch(userGetRequest());
                    }

                })
        }
    }

    const onClickFileDownload = (file: any) => {
        const handleSuccess = async (body: any) => {
            try {
                // Create a Blob from the response data
                const fileBlob = await body.blob();

                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(fileBlob);

                // Create a temporary <a> element to trigger the download
                const tempLink = document.createElement("a");
                tempLink.href = url;
                tempLink.setAttribute("download", file.documentName); // Set the desired filename for the downloaded file

                // Append the <a> element to the body and click it to trigger the download
                document.body.appendChild(tempLink);
                tempLink.click();

                // Clean up the temporary elements and URL
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("Error downloading file:", error);
            }
        }
        const handleFailure = () => {

        }
        dispatch(downloadBulkTemplate(model, handleSuccess, handleFailure))
    }


    let component = null;
    switch (uploading) {
        case 'not_started':
            component = <New handleDownloadTemplate={onClickFileDownload} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} SubmitUpload={SubmitUpload} />
            break;
        case 'started':
            component = <Uploading file={fileInfo} percentage={percentage} />
            break;
    }
    return (
        <div>
            {component}
        </div>
    )
}
export default Upload