import React, { FC, useState } from 'react';
import { DropdownItem, Tooltip } from 'reactstrap';

interface Permission {
    access: boolean;
    type: string;
    help?: string;
}

interface ActionItemProps {
    permission: Permission;
    onClick: () => void;
    icon: string;
    label: string;
    btnClassName: string;
    tooltipId: string;
    tooltipMessage?: string;
}

const ActionItem: FC<ActionItemProps> = ({
    permission,
    onClick,
    icon,
    label,
    btnClassName,
    tooltipId,
    tooltipMessage = '',
}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(prev => !prev);

    return (
        <div id={tooltipId} style={{ display: 'inline-block' }}>
            <DropdownItem
                disabled={!permission.access}
                onClick={permission.access ? onClick : undefined}
                className="dropdown-item fs-12 hstack justify-content-between"
                style={{
                    opacity: permission.access ? 1 : 0.5,
                    cursor: permission.access ? 'pointer' : 'not-allowed',
                }}
            >
                <div className="d-flex align-items-center gap-2">
                    <button className={`btn btn-sm ${btnClassName}`}>
                        <i className={icon} />
                    </button>
                    <span className="text-primary">{label}</span>
                </div>
                {!permission.access && permission.type === 'lock' && <i className="ri-lock-2-fill fs-18" />}
            </DropdownItem>

            {!permission.access && (
                <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target={tooltipId}
                    toggle={toggle}
                >
                    {tooltipMessage}
                </Tooltip>
            )}
        </div>
    );
};

export default ActionItem;