import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
    UncontrolledDropdown,
    Modal,
    ModalBody,
    Input,
    ModalHeader,
    Row,
    Col,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useParams } from "react-router-dom";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import DtsEmailsInputs from "Components/Common/FormBuilder/DtsEmailsInputs";
import config from "config";
import { emailCreateRequest } from "store/emails/action";
import { ApplicationState } from "store";
import DtsCKEditor from "Components/Common/FormBuilder/DtsCKEditor";
const { DTS_API_STUDENT } = config.api;

export interface SuggestionOption {
    readonly value: string;
    readonly label: string;
}

interface FProps {
    emailModals: boolean;
    setEmailModals: (event: boolean) => void;
    info: any;
    record: any;
    model: any;
    to: any;
    setTriggerApi: any,
    studentId: any,
    applicationId: any,
    t: any
}

const EmailModal: React.FC<FProps> = ({
    emailModals,
    setEmailModals,
    to,
    setTriggerApi,
    studentId,
    applicationId,
    t
}) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const userProfile: any = useSelector((state: ApplicationState) => state.auth.userProfile);
    const { id } = useParams();
    const [isccOn, setisccOn] = useState(false)
    const [isBccOn, setisBccOn] = useState(false)
    const [disabled, setdisabled] = useState(true)
    const [emailData, setEmailData] = useState<any>({
        student: studentId,
        application: applicationId,
        to: to
    })

    const toggle = () => {
        if (emailModals) {
            setEmailModals(false);
        } else {
            setEmailModals(true);
        }
    };

    const onSend = () => {
        const data = { ...emailData, parent: "", from: userProfile?.id }
        const handleSuccess = () => {
            setEmailModals && setEmailModals(false);
            setTriggerApi && setTriggerApi((triggerApi: boolean) => !triggerApi)

        }
        const handleFailure = () => { }
        dispatch(emailCreateRequest(data, handleSuccess, handleFailure))
    }

    useEffect(() => {
        if (isBccOn) {
            const getID = document.getElementById('BccRecipientsCollapse') as HTMLElement
            if (getID) getID.classList.add("show");
        }
        else {
            const getID = document.getElementById('BccRecipientsCollapse') as HTMLElement
            if (getID) getID.classList.remove("show");
        }
        if (isccOn) {
            const getID = document.getElementById('CcRecipientsCollapse') as HTMLElement
            if (getID) getID.classList.add("show");
        }
        else {
            const getID = document.getElementById('CcRecipientsCollapse') as HTMLElement
            if (getID) getID.classList.remove("show");
        }
    }, [isBccOn, isccOn])


    return (
        <React.Fragment>
            <Modal backdrop="static" id="composemodal" className="modal-lg" isOpen={emailModals} toggle={toggle} centered>
                <ModalHeader className="p-3 bg-light" toggle={() => { setEmailModals(false); }}>
                    {t("student.details.activities.new_message")}
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="mb-3 position-relative">
                            <Row className="hstack mb-3 position-relative">
                                <Col md={10} lg={10} className="pe-0">
                                    <DtsEmailsInputs placeholder={"To"} hideCross={true} disabled={true} defaultValue={[`${emailData.to}`]} setdisabled={setdisabled} onChange={(values: any) => setEmailData({ ...emailData, to: values })} />
                                </Col>
                                <Col md={2} lg={2} className="ps-0 hstack justify-content-end">
                                    <div className="d-flex">
                                        <button
                                            className="btn btn-link text-reset fw-semibold px-2"
                                            type="button"
                                            onClick={() => setisccOn(!isccOn)}
                                        >
                                            Cc
                                        </button>
                                        <button
                                            className="btn btn-link text-reset fw-semibold px-2"
                                            type="button"
                                            onClick={() => setisBccOn(!isBccOn)}
                                        >
                                            Bcc
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {
                            isccOn ?
                                <div className="collapse" id="CcRecipientsCollapse">
                                    <div className="mb-3">
                                        <label>Cc:</label>
                                        <DtsEmailsInputs placeholder={"Cc"} setdisabled={setdisabled} onChange={(values: any) => setEmailData({ ...emailData, cc: values })} />
                                    </div>
                                </div> :
                                null}
                        {
                            isBccOn ? <div className="collapse" id="BccRecipientsCollapse">
                                <div className="mb-3">
                                    <label>Bcc:</label>
                                    <DtsEmailsInputs placeholder={"Bcc"} setdisabled={setdisabled} onChange={(values: any) => setEmailData({ ...emailData, bcc: values })} />
                                </div>
                            </div>
                                : null
                        }

                        <div className="mb-3">
                            <Input type="text" value={emailData.subject || ""} className="form-control" placeholder={t("student.details.activities.subject")} onChange={(e: any) => setEmailData({ ...emailData, subject: e.target.value })} />
                        </div>
                        <div>
                        <DtsCKEditor
                            value={emailData.body ? emailData.body : ""}
                            onChange={(data: any) => {
                                setEmailData({ ...emailData, body: data })
                            }}
                            name={'body'}
                            type={'texteditor'}
                            actionButtons={false}
                            defaultValue={emailData.body}
                        />
                        </div>
                    </div>
                </ModalBody>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-ghost-danger"
                        onClick={() => {
                            setEmailModals(false);
                        }}
                    >
                        {t("common.action_button.discard")}
                    </button>

                    <UncontrolledDropdown className="btn-group">
                        <button
                            type="button"
                            className="btn btn-success"
                            disabled={disabled || !emailData.subject || !emailData.body}
                            onClick={() => {
                                onSend();
                            }}
                        >
                            {t("common.action_button.send")}
                        </button>
                    </UncontrolledDropdown>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default withTranslation()(EmailModal);
