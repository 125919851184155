import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { AccordionBody, AccordionHeader, AccordionItem, Col, Modal, ModalBody, ModalHeader, Row, UncontrolledAccordion } from 'reactstrap';
import TableComponent from '../TableComponent';
import _ from 'lodash';
import FeatherIcon from 'feather-icons-react';
import DtsTablePlaceholder from 'Components/Common/FormBuilder/DtsTablePlaceholder';
import FixModal from './FixErrorModal'
import { isJson } from 'utils';


const Mapping = (props: any) => {    
    const { mappings, loading, setFinalMappings, importJson, setUnMappedCount, unMappedCount} = props;
    const [ columns, setColumns] = useState<any>([])
    const [updateRow, setUpdateRow] = useState<any>(null);
    const [mapping, setMappings] = useState<any>(null);
    const [errors, setError] = useState<any>(null);
    const [record, setRecord] = useState<any>(null);
    const [FixErrorModal, setFixErrorModal] = useState<any>(false);

    const onUpdate = (existingData: any, option: any, key: any, id: any) => { 
        let newRecord = { ...existingData, [key]: option }   
        if(key === 'field') {
            if(option) {
                newRecord = { ...existingData, [key]: option, mapped: true }
            }
            else {
                newRecord = { ...existingData, [key]: option, mapped: false }
            }
        }
        setUpdateRow(newRecord);
        let newMappings = [...mapping];
        const index = newMappings.findIndex((object: any) => object.id === id);
        if (index !== -1) {
            newMappings.splice(index, 1, newRecord);
        }
        setMappings(newMappings)
        const unMappedCount =  newMappings && newMappings.length ?  newMappings.filter((item: any) => !item.mapped) : [];
        setUnMappedCount(unMappedCount.length)
    }

    const onUpdateCorrection = (existingData: any, id: any) => {  
        let newRecord = { ...existingData}   
        setUpdateRow(newRecord);
        let newMappings = [...mapping];
        const index = newMappings.findIndex((object: any) => object.id === id);
        if (index !== -1) {
            newMappings.splice(index, 1, newRecord);
        }
        setMappings(newMappings)
    }

    useEffect(() => {
        if(mappings && mappings.length) {
            let fields = mappings[0];  
            const keys = Object.keys(fields)
            const columns: any = []
            if(keys && keys.length) {
                keys.map((field,index) => { 
                    if(field === 'validationErrors' || field ===  'fileName'  || field === 'hasErrors') return null
                    let column = {
                            Header: _.startCase(field),
                            accessor: field,
                            filterable: true,
                            disableFilters: true,
                            isSortable: false,
                            show: true,
                            Cell: (cellProps: any) => { 
                                let render = null
                                if(field === 'mapped') {
                                    if(cellProps?.row.original?.validationErrors) {
                                        render = parseInt(cellProps?.row.original?.validationErrors?.length) - parseInt(cellProps?.row.original?.validationCorrection?.length) > 0
                                            ? `${parseInt(cellProps?.row.original?.validationErrors?.length) - parseInt(cellProps?.row.original?.validationCorrection?.length)} Error Exist` : null
                                    }
                                    return <div className="d-flex align-items-center justify-content-between">                                
                                                <div className="hstack flex-grow-1  gap-2">
                                                    <div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2" >                                  
                                                        {cellProps.row.original[field] && !cellProps.row.original['hasErrors'] ? <FeatherIcon icon="check-circle" className="icon-md icon-dual-success cursor-pointer" /> : null
                                                         }
                                                        {cellProps.row.original['hasErrors'] ? 
                                                            cellProps.row.original.hasOwnProperty('validationCorrection') ? 
                                                            <div className='vstack justify-content-center gap-1 cursor-pointer' onClick={() => {setFixErrorModal(true); setRecord(cellProps.row.original)}}>
                                                                <FeatherIcon icon="check-circle" className="icon-md icon-dual-success cursor-pointer" />
                                                                <span className={'text-success fw-semibold pt-2'}>
                                                                    {cellProps?.row.original?.validationCorrection?.length} Error fixed
                                                                    </span>
                                                                <span className={cellProps?.row.original?.validationCorrection?.length === cellProps?.row.original?.validationErrors?.length ? 'text-success fw-semibold pt-2' : 'text-danger fw-semibold pt-2'}>
                                                                       {render}
                                                                    </span>
                                                            </div>
                                                            :
                                                        <div className='vstack justify-content-center gap-1 cursor-pointer' onClick={() => {setFixErrorModal(true); setRecord(cellProps.row.original)}}>
                                                            <FeatherIcon icon="alert-circle" className="icon-md icon-dual-warning cursor-pointer" />
                                                            <span className='text-danger fw-semibold'>{cellProps?.row.original?.validationErrors?.length} Error</span>
                                                        </div> : null}
                                                    </div>
                                                </div>
                                        </div>    
                                
                                }
                                else return <div className="d-flex align-items-center justify-content-between">                                
                                            <div className="hstack flex-grow-1  gap-2">
                                                <div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2" >                                  
                                                    {cellProps.row.original[field]}
                                                </div>
                                            </div>
                                    </div>                
                            }
                        }
                    columns.push(column)
                })
                setColumns(columns)
            } 
        }
        const errorCount =  mappings && mappings.length ?  mappings.filter((item: any) => item.hasErrors) : [];
        setError(errorCount)
        const unMappedCount =  mappings && mappings.length ?  mappings.filter((item: any) => !item.mapped) : [];
        setUnMappedCount(unMappedCount.length)
        const parsedData: any = mappings && mappings.length ? mappings.map((item: any, index: any) => {
            const options = item?.suggestedFields && item?.suggestedFields?.length ? item?.suggestedFields?.map((item: any) => {
                let field = item && item?.fieldValuesJson && isJson(item?.fieldValuesJson) ? JSON.parse(item?.fieldValuesJson) : {}
                return {field :field, value: field.key, label: field.label }
            }) : null;                             
            let values = { ...item, id: index, field: options && options.length ? options[0] : null, overwrite: importJson.importAction === 'create' ? false : true, options: options }
             return values
         }) : []
         let mappingsData = parsedData && parsedData.length ? parsedData : []
         setMappings(mappingsData)
    }, [mappings]); 

    useEffect(() => {
        setFinalMappings(mapping)
    }, [mapping]) 

    const handleAction = () => {
        switch(importJson.importAction) {
            case 'create-update': 
            return <Row className='hstack justify-content-around gap-4'>
                <Col md={4} lg={4} xl={4} className='p-3 gap-2 border border-primary border-opacity-25'>
                    New {importJson.model} will be created. If already exist found then that {importJson.model} will be updated.
                </Col>
            </Row>
            case 'create': 
            return   <Row className='hstack justify-content-center gap-4'>
                <Col md={4} lg={4} xl={4} className='p-3 gap-2 border border-primary border-opacity-25'>
                    Existing {importJson.model} won't be updated.
                </Col>
            </Row>
            case 'update': 
            return   <Row className='hstack justify-content-around gap-4'>
                <Col md={4} lg={4} xl={4} className='p-3 gap-2 border border-primary border-opacity-25'>  
                    <div>                            
                        New {importJson.model} won't be created.
                    </div>     
                </Col>
            </Row>
        }
    }
    return (
        <React.Fragment>
            <Modal>
                <ModalBody>

                </ModalBody>
            </Modal>
            {FixErrorModal && <FixModal show={FixErrorModal} onCloseClick={() => setFixErrorModal(false)} t={props.t} record={record} onUpdateCorrection={onUpdateCorrection} />}

            <div className='vstack gap-3'>
                <h3 className='text-center'>Map columns in your file to {importJson.model} fields.</h3>
                <div>
                    <div className='vstack justify-content-start border border-primary border-opacity-25 gap-3 p-2 px-3'>
                        <h5 className='text-center'>Mapping Guide.</h5>
                        <div>
                            {handleAction()}
                        </div>
                    </div>
                </div>
                <div className='hstack justify-content-start border border-primary border-opacity-25 gap-3 p-2 px-3'>
                    {!loading ? <h5 className='text-center'><span>{`${errors?.length} error, ${unMappedCount ? unMappedCount : 0} unmapped column found out of ${mapping?.length} columns.`}</span></h5> : <div className="placeholder-glow hstack"><span className="placeholder"></span>{" "}<span className="placeholder"></span>{" "}<span className="placeholder"></span></div>}
                </div>
                <div>
                    {!loading ?
                        <TableComponent
                            tableClass="align-middle table-nowrap"
                            theadClass="table-light text-muted"
                            thClass="border-bottom-1 table-soft-primary"
                            columns={columns} data={mapping} onUpdate={onUpdate} updateRow={updateRow} importJson={importJson} />
                        :
                        <DtsTablePlaceholder
                            columns={columns}
                            tableClass="align-middle table-nowrap"
                            theadClass="table-light text-muted"
                            thClass="border-bottom-1 table-soft-primary"
                        />
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Mapping);
