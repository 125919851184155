/**
 * TreeFlow.tsx
 *
 * This component builds a tree layout using data fetched from an API.
 * It uses dagre to compute node positions and ReactFlow for rendering.
 * The tree nodes are sorted by the createdAt timestamp. After computing
 * the layout, we calculate a custom viewport transform so that the entire
 * tree fits within the window with a consistent margin from all sides.
 * An extra zoom-out factor is applied so that the tree appears a bit smaller.
 */

import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
	ReactFlow,
	Background,
	Controls,
	Node,
	Edge,
	Position,
	ReactFlowInstance,
	ReactFlowProps,
} from '@xyflow/react';
import dagre from 'dagre';
import CustomNode from './CustomNode';
import { buildTree, TreeNode } from '../../../helpers/buildTree';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { ApplicationState } from 'store';
import { teamsListGetRequest } from 'store/teams/action';
import AddDrawer from '../Actions/AddDrawer';
import EditDrawer from '../Actions/EditDrawer';
import DeleteModal from '../Actions/DeleteModal';
import { ToastContainer } from 'react-toastify';

// Define fixed node dimensions.
const nodeWidth = 300;
const nodeHeight = 36;

const placeholderNodeStyle: React.CSSProperties = {
	width: `${50}px`,
	height: `${nodeHeight}px`,
};

/**
 * getLayoutedElements
 *
 * Computes positions of nodes and edges using dagre.
 * Sets larger vertical gap (ranksep) and horizontal gap (nodesep).
 *
 * @param nodes Array of ReactFlow nodes.
 * @param edges Array of ReactFlow edges.
 * @param direction Layout direction ('TB' or 'LR').
 * @returns Object containing layouted nodes and edges.
 */
const getLayoutedElements = (
	nodes: Node[],
	edges: Edge[],
	direction: 'TB' | 'LR' = 'TB'
): { nodes: Node[]; edges: Edge[] } => {
	// Create a new dagre graph instance.
	const dagreGraph = new dagre.graphlib.Graph();
	dagreGraph.setDefaultEdgeLabel(() => ({}));
	// Set graph options with increased gaps.
	dagreGraph.setGraph({ rankdir: direction, ranksep: 100, nodesep: 50 });

	// Set nodes with their dimensions.
	nodes.forEach((node) => {
		dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
	});

	// Set edges.
	edges.forEach((edge) => {
		dagreGraph.setEdge(edge.source, edge.target);
	});

	// Compute layout with dagre.
	dagre.layout(dagreGraph);

	// Update node positions based on dagre calculations.
	const layoutedNodes: Node[] = nodes.map((node) => {
		const nodeWithPosition = dagreGraph.node(node.id);
		const isHorizontal = direction === 'LR';

		node.targetPosition = (isHorizontal ? 'left' : 'top') as Position;
		node.sourcePosition = (isHorizontal ? 'right' : 'bottom') as Position;
		node.position = {
			x: nodeWithPosition.x - nodeWidth / 2,
			y: nodeWithPosition.y - nodeHeight / 2,
		};
		return node;
	});

	return { nodes: layoutedNodes, edges };
};

const TreeFlow: React.FC = () => {
	const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
	const teamList = useSelector((state: ApplicationState) => state.teams.teams);
	const loading = useSelector((state: ApplicationState) => state.teams.loading);
	const [addRecord, setAddRecord] = useState<any>(null);
	const [editRecord, setEditRecord] = useState<any>(null);
	const [deleteRecord, setDeleteRecord] = useState<any>(null);
	// Store the ReactFlow instance.
	const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);

	// Fetch team list from API.
	useEffect(() => {
		const queryParams: URLSearchParams = new URLSearchParams();
		queryParams.set('page', '0');
		queryParams.set('pageSize', '500');
		dispatch(teamsListGetRequest(queryParams.toString()));
	}, [dispatch]);

	// Build and sort the tree using the helper function.
	const tree = useMemo<TreeNode[]>(() => {
		return teamList?.length ? buildTree(teamList) : [];
	}, [teamList]);

	// Convert the tree into ReactFlow nodes and edges.
	const { nodes, edges } = useMemo(() => {
		const n: Node[] = [];
		const e: Edge[] = [];

		/**
		 * Recursively traverses the tree to build nodes and edges.
		 * @param node Current tree node.
		 */
		const traverse = (node: TreeNode): void => {
			n.push({
				id: node.id,
				type: 'custom', // Use custom node type.
				data: {
					name: node.name,
					label: node.name,
					id: node.id,
					isDeleted: !!node.deletedAt,
					onAdd: setAddRecord,
					onEdit: setEditRecord,
					onDelete: setDeleteRecord
				},
				position: { x: 0, y: 0 }, // Temporary; will be updated by dagre.
			});
			if (node.children && node.children.length > 0) {
				node.children.forEach((child: TreeNode) => {
					e.push({
						id: `e-${node.id}-${child.id}`,
						source: node.id,
						target: child.id,
					});
					traverse(child);
				});
			}
		};

		tree.forEach((root: TreeNode) => traverse(root));
		return getLayoutedElements(n, e, 'TB');
	}, [tree]);

	// Use onInit to capture the ReactFlow instance.
	const onInit: ReactFlowProps['onInit'] = useCallback((instance: ReactFlowInstance) => {
		setReactFlowInstance(instance);
	}, []);

	// Custom viewport calculation: compute a zoom factor and translation such that
	// the entire tree fits within the window with fixed margins.
	useEffect(() => {
		if (reactFlowInstance && nodes.length > 0) {
			// Calculate the bounding box for the nodes.
			const xs = nodes.map((n) => n.position.x);
			const ys = nodes.map((n) => n.position.y);
			const minX = Math.min(...xs);
			const maxX = Math.max(...xs) + nodeWidth; // Include node width.
			const minY = Math.min(...ys);
			const maxY = Math.max(...ys) + nodeHeight; // Include node height.
			const treeWidth = maxX - minX;
			const treeHeight = maxY - minY;

			// Define desired margins.
			const marginLeft = 50;
			const marginRight = 50;
			const marginTop = 50;
			const marginBottom = 50;

			// Available space inside the window.
			const availableWidth = window.innerWidth - marginLeft - marginRight;
			const availableHeight = window.innerHeight - marginTop - marginBottom;

			// Calculate the zoom factor that fits the entire tree.
			const zoomX = availableWidth / treeWidth;
			const zoomY = availableHeight / treeHeight;
			const computedZoom = Math.min(zoomX, zoomY);

			// Apply an extra zoom out (for example, 0.9) to give some breathing room.
			const extraZoomOutFactor = 0.9;
			// Set a maximum zoom (e.g., 1) to prevent excessive zoom-in for small trees
			const MAX_ZOOM = 1.0;
			const MIN_ZOOM = 0.1; // Optional: prevent tree from becoming too small
			const finalZoom = Math.min(Math.max(computedZoom * extraZoomOutFactor, MIN_ZOOM), MAX_ZOOM);


			// Compute new viewport translation to position the tree with desired margins.
			const newX = marginLeft - (minX * finalZoom);
			const newY = marginTop - (minY * finalZoom);

			// Set the computed viewport.
			reactFlowInstance.setViewport({ x: newX, y: newY, zoom: finalZoom });
		}
	}, [nodes, reactFlowInstance]);

	// Register the custom node type.
	const nodeTypes = useMemo(() => ({ custom: CustomNode }), []);

	return (
		<>
			<ToastContainer />
			<DeleteModal
				show={!!deleteRecord}
				onCloseClick={() => setDeleteRecord(null)}
				record={deleteRecord}
			/>
			<AddDrawer
				parent={addRecord}
				show={!!addRecord}
				onClose={() => setAddRecord(null)}
			/>
			<EditDrawer
				id={editRecord?.id}
				show={!!editRecord}
				onClose={() => setEditRecord(null)}
			/>
			<div style={{ height: '90vh' }}>
				{loading ? (
					// 🌳 Tree-like shimmer structure while loading
					<div className="placeholder-glow d-flex flex-column align-items-center gap-4 h-100 p-4 overflow-auto">
						{/* Root Node - Level 0 */}
						<span className="placeholder rounded" style={placeholderNodeStyle} />

						{/* Level 1 */}
						<div className="d-flex justify-content-between w-100 px-5">
							<span className="placeholder rounded" style={placeholderNodeStyle} />
							<span className="placeholder rounded" style={placeholderNodeStyle} />
						</div>

						{/* Level 2 */}
						<div className="d-flex justify-content-between w-100 px-5 flex-wrap gap-4">
							{Array.from({ length: 4 }).map((_, idx) => (
								<div key={`l3-${idx}`} className="d-flex flex-column gap-3">
									<span className="placeholder rounded" style={placeholderNodeStyle} />
									<span className="placeholder rounded" style={placeholderNodeStyle} />
								</div>
							))}
						</div>

						{/* Level 3 */}
						<div className="d-flex justify-content-between w-100 px-5 flex-wrap gap-4">
							{Array.from({ length: 6 }).map((_, idx) => (
								<div key={`l3-${idx}`} className="d-flex flex-column gap-3">
									<span className="placeholder rounded" style={placeholderNodeStyle} />
									<span className="placeholder rounded" style={placeholderNodeStyle} />
								</div>
							))}
						</div>
					</div>
				) : nodes.length === 0 ? (
					// 🌟 Empty state - show "Create First Team" button
					<div className="d-flex flex-column justify-content-start align-items-center h-100 text-center px-4 mt-4">
						<p className="text-muted mb-3">No teams found</p>
						<button className="btn btn-primary" onClick={() => setAddRecord(true)}>
							Create First Team
						</button>
					</div>
				) : (
					// 🌳 Show tree
					<ReactFlow
						nodes={nodes}
						edges={edges}
						nodeTypes={nodeTypes}
						onInit={onInit}
					>
						<Background />
						<Controls />
					</ReactFlow>
				)}
			</div>
		</>
	);
};

export default TreeFlow;
