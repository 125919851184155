import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row, Label, Table, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import axios from 'axios'
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
import html2pdf from 'html2pdf.js';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { ApplicationState } from 'store';
import moment from 'moment';
import config from "../../../config";
import { agencychangeformGetRequest } from 'store/agencychangeforms/action';
import { isMobile } from 'react-device-detect';
import { tenantGetOpenRequest } from 'store/user/action';
import { isJson } from 'utils';
import InPersonVerify from '../InPersonVerify';
import AgentChangeFormSuccess from './AgentChangeFormSuccess';
import SuccessModal from './SuccessModal';
import FormBuilder from 'Components/Common/FormBuilder/FormBuilder';
import CompanyletterHeadHeader from 'Components/Common/LetterHead/CompanyLetterHeadHeader';

interface ResetPasswordProps {
    t: (key: string) => string;
}

const { DTS_API_STUDENT } = config.api;

const StudentAgentChange: React.FC<ResetPasswordProps> = ({ t }) => {
    const { tid, appid } = useParams();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const recommendDetails: any = useSelector((state: ApplicationState) => state.agencychangeforms.details);
    const tenatDetails = useSelector((state: ApplicationState) => state.user.tenatDetails);
    const [isUploading, setIsUploading] = useState(false);
    const [isConfirm, setIsConfirm] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selfieData, setSelfieData] = useState<string | null>(null);
    const [verificationCode, setVerificationCode] = useState<string | null>(null);
    const [showButtons, setShowButtons] = useState(true);
    const getTargetElement: any = () => document.getElementById('form-content');
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [tenant, setTenant] = useState<any>({})
    const [reason, setReason] = useState('');
    
    const options: any = {
        method: 'build',
        resolution: Resolution.NORMAL,
        page: {
            margin: Margin.MEDIUM,
            format: 'A4',
            orientation: 'portrait',
        },
        canvas: {
            mimeType: 'image/png',
            qualityRatio: 1
        },
        overrides: {
            // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
            pdf: {
                compress: true
            },
            // see https://html2canvas.hertzen.com/configuration for more options
            canvas: {
                useCORS: true
            }
        },
    };    

    useEffect(() => {
        setReason(recommendDetails?.valuesJson?.reason)
    }, [recommendDetails])

    useEffect(() => {
        const handleSuccess = () => { }
        const handlefailure = () => { }
        dispatch(agencychangeformGetRequest(tid, appid, handleSuccess, handlefailure))
        dispatch(tenantGetOpenRequest(tid))
    }, [appid, tid])

    useEffect(() => {
        let tenant = tenatDetails?.settings && isJson(tenatDetails?.settings) ? JSON.parse(tenatDetails?.settings) : tenatDetails?.settings;
        setTenant(tenant)
    }, [tenatDetails])

    const sendBlobAsFormData = async () => {
        let pdf = await generatePDF(getTargetElement, options);
        let Url = pdf?.output("bloburl");
        const response = await fetch(Url);
        const blob = await response.blob();
        if (blob) {
            const formData = new FormData();
            formData.append('file', blob, 'agent_form.pdf');
            formData.append('documentType', 'agent_form');
            formData.append('studentId', recommendDetails?.valuesJson?.studentId);
            formData.append('applicationId', recommendDetails?.valuesJson?.applicationId);
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }
            await axios.post(`${DTS_API_STUDENT}/documents/forms/${tid}`, formData, config)
                .then((res: any) => {
                    if (res.includes('http')) {
                        setSuccessModal(true)
                        setIsUploading(false);
                    }
                })
        }
    }

    const pdfPreview = () => {
        const element = document.getElementById("form-content");
        console.log("element", element)
        if (element) {
            setShowButtons(false);
            const opt = {
                margin: [10, 10, 10, 10], // Top, Left, Bottom, Right margins in mm
                filename: 'authorization_form.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };
            html2pdf()
                .from(element)
                .set(opt)
                .toPdf()
                .get('pdf')
                .then(async (pdf: any) => {
                    const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
                    const selfieSection = document.getElementById("selfie-section");
                    const selfieHeight = selfieSection?.clientHeight || 0;
            if (selfieHeight >= (pageHeight - 20)) {
                pdf.addPage(); // Add a new page if the selfie is too large
            }
            if (selfieSection) {
                pdf.html(selfieSection, {
                    x: 20,
                    y: pageHeight + 300, // Place it on the next page
                    callback: function (doc: any) {
                        doc.save('agent_form.pdf');
                    }
                });
            }
            else {
                pdf.save('agent_form.pdf');
                setShowButtons(true);
            }
            setSuccessModal(false)
            setIsConfirm(true)
        });
        }
    };

    const handleSelfieCapture = (selfie: string, code: string) => {
        setSelfieData(selfie);
        setVerificationCode(code);
        toast.success("Selfie captured successfully!");
        toggleModal(); // Close the modal after capturing the selfie
    };

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleRetakeSelfie = () => {
        setSelfieData(null); // Reset the selfie data to allow for retaking the selfie
        toggleModal(); // Open the modal to retake the selfie
    };

    const style = {
        width: "40%",
        whiteSpace: "normal"
    }

    const stylevalue = {
        width: "60%",
        whiteSpace: "normal"
    }

    let successComponent = <div className="mt-4 text-center">
        <h4 className="mb-3">Thank you..!</h4>
        <p className="text-muted mb-4">Your Form submited successfully.</p>
    </div>
    
    return (
        <>
            <SuccessModal successModal={successModal} setSuccessModal={setSuccessModal} pdfPreview={pdfPreview} setIsConfirm={setIsConfirm} />
            {modalOpen && <InPersonVerify isOpen={modalOpen} toggle={toggleModal} onSelfieCapture={handleSelfieCapture} />}
            <ToastContainer />
            {
                isConfirm ?
                    <AgentChangeFormSuccess tenant={tenant} children={successComponent} />
                    :
                    <Row className="px-3 justify-content-center">
                        <Col xs={12} sm={12} lg={8} md={8} xl={8} className="justify-content-center">
                            <Row id="form-content" className="justify-content-center">
                                <Col  xs={12} sm={12} lg={12} md={12} xl={12}>
                                    <Card>
                                        <CardBody className="card-body p-2">
                                            <CompanyletterHeadHeader />
                                            <div className="text-center">
                                                <h3>AGENT REQUEST FORM</h3>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <div>
                                                    <Label>Date: {moment().format("MM/DD/YYYY")}</Label>
                                                </div>
                                            </div>
                                            <Table responsive bordered className="table align-middle position-relative table-nowrap border border-1">
                                                <tbody>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%", whiteSpace: "nowrap" }}>First Name</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%" }}>{recommendDetails?.valuesJson?.first_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%", whiteSpace: "nowrap" }}>Last Name</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%" }}>{recommendDetails?.valuesJson?.last_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%", whiteSpace: "nowrap" }}>Date of Birth:</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%" }}>{recommendDetails?.valuesJson?.date_of_birth ? moment(recommendDetails?.valuesJson?.date_of_birth).format("LL") : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%", whiteSpace: "nowrap" }}>Country of Nationality:</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%" }}>{recommendDetails?.valuesJson?.country_of_nationality}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%", whiteSpace: "nowrap" }}>Your Current Agent or ‘Applied
                                                            Direct’</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%" }}>
                                                            {recommendDetails?.valuesJson?.channel_name?.label ? recommendDetails?.valuesJson?.channel_name?.label : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%", whiteSpace: "nowrap" }}>Address of Your Current Agent:</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%", whiteSpace: "wrap" }}>{recommendDetails?.valuesJson?.channel_address}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%", whiteSpace: "nowrap" }}>Your Proposed Agent:</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%" }}>
                                                            {recommendDetails?.valuesJson?.proposed_agent ? recommendDetails?.valuesJson?.proposed_agent?.label : ""}
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%", whiteSpace: "nowrap" }}>Address of Your Proposed Agent:</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%", whiteSpace: "wrap" }}>{
                                                            recommendDetails?.valuesJson?.proposed_agent_address ? recommendDetails?.valuesJson?.proposed_agent_address : ""
                                                        }</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={isMobile ? style : { width: "40%", whiteSpace: "nowrap" }}>Reason(s) for taking assistance:</td>
                                                        <td style={isMobile ? stylevalue : { width: "60%" }}>
                                                           <FormBuilder
                                                                type={"textarea"}
                                                                defaultValue={reason}
                                                                value={reason}
                                                                name={"reason"}
                                                                placeholder={"Enter Reason(s) here..."}
                                                                className="w-100 h-100"
                                                                onChange={(value: any) => setReason(value)}
                                                                isEditState={true}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>                                            
                                            <br />
                                            <br />
                                            <Row>
                                                <div className='d-flex justify-content-start'>
                                                    <div className="">
                                                        <h5>Authorization Instructions</h5>
                                                        <p>To complete your verification process, we just need you to confirm your identity with a quick selfie.</p>

                                                        <h5>How to proceed:</h5>
                                                        <ol>
                                                            <li>Click on the <strong>"Capture Selfie"</strong> button below.</li>
                                                            <li>Ensure you are in a well-lit environment for clear visibility.</li>
                                                            <li>Write the <strong>verification code</strong> provided to you on a piece of paper.</li>
                                                            <li>Hold the paper with the verification code <strong>clearly visible below your face</strong> while taking the selfie.</li>
                                                            <li>Align your face within the frame and click the button to capture your selfie.</li>
                                                            <li>Once the selfie is taken, it will be automatically uploaded for verification.</li>
                                                        </ol>
                                                        <br></br>
                                                        <p>Your privacy and security are important to us. The selfie and verification code will only be used for verification purposes.</p>

                                                        <p>Thank you for your cooperation!</p>
                                                    </div>

                                                </div>
                                            </Row>
                                            <br />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col  xs={12} sm={12} lg={12} md={12} xl={12}>
                                    {selfieData && (
                                        <div id="selfie-section" className="mt-4 text-center">
                                            <div>
                                                <Label>Verification Code: {verificationCode}</Label>
                                            </div>
                                            <img
                                                src={selfieData}
                                                alt="Captured Selfie"
                                                style={{ maxWidth: "100%", maxHeight: "300px", borderRadius: "10px" }}
                                            />
                                        </div>
                                    )}
                                    <div className="text-center pb-3">
                                        {showButtons ? (
                                            <>
                                                {selfieData ? (
                                                    <div className='hstack p-2 gap-2 justify-content-center'>
                                                        <Button onClick={handleRetakeSelfie}>Retake Selfie</Button>
                                                        <Button color="primary" onClick={() => {
                                                            sendBlobAsFormData()
                                                            setIsUploading(true)
                                                        }}>                                                
                                                            {isUploading ? <><Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading...</Spinner>{" "}</>: null }
                                                            Submit
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <Button onClick={toggleModal}>Capture Selfie</Button>
                                                )}
                                            </>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
            }

        </>
    );
};

export default withTranslation()(StudentAgentChange);