import { useEffect, useRef, useState } from "react";
import {
    Label,
    Input,
    Form,
    Button,
    FormFeedback,
    ButtonGroup,
    Spinner,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";

interface DtsTextInputProps {
    label?: string;
    type?: string;
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
    isEditable?: boolean;
    name?: string;
    errorText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    actionButtons?: boolean;
    defaultValue?: string;
    rest?: any;
    invalid?: any;
    formValues?: any;
    dataFields?: any;
    onSaveClick?: any;
    setdisabled?: any;
    setError?: any;
    formerror?: any;
    resetvalue?: any;
    rows?: any;
    loading?: boolean;
}

const DtsTextInput: React.FC<DtsTextInputProps> = ({loading, rows, resetvalue, formerror, setError, setdisabled, onSaveClick, rest, defaultValue, label, type, value, placeholder, onChange, name, icon, iconPosition, actionButtons, readOnly, disabled }: any) => {
    const [hover, setHover] = useState<any>(false)
    const [checkdisabled, setCheckdisabled] = useState<any>(false)
    const [errormsg, setErrormsg] = useState();
    const inputRef = useRef<HTMLInputElement>(null);
    const [resetValue, setResetValue] = useState<any>(null)
    const [mandatory, setMandatory] = useState<any>(false)
    const onhandleChange = (value: any) => {
        onChange(value)
        validateInput(value);
    }
    useEffect(() => {
        onChange && onChange(resetvalue)
        setResetValue(resetvalue)
    }, [resetvalue])

    const validateInput = (value: any) => {
        if (rest && rest.type == 'email') {
            if (value === '' || !/^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                let error: any = `Please enter valid ${label}`;
                setErrormsg(error)
                if (typeof setError === 'function') {
                    setError({ ...formerror, [name]: error })
                }
                setCheckdisabled(true)
                return false
            }
        }
        if (rest && rest.validate && rest.validate.required) {
            if (value === "") {
                let error: any = `Please enter ${label}`;
                setErrormsg(error)
                if (typeof setError === 'function') {
                    setError({ ...formerror, [name]: error })
                }
                setCheckdisabled(true)
                return false
            }
        }
        if (rest && rest.validate && rest.validate.minLength) {
            if (value.length < rest.validate.minLength) {
                let error: any = `Length of ${label} must be atleast ${rest.validate.minLength}`;
                setErrormsg(error)
                if (typeof setError === 'function') {
                    setError({ ...formerror, [name]: error })
                }
                setCheckdisabled(true)
                return false
            }
        }
        if (rest && rest.validate && rest.validate.maxLength) {
            if (value.length > rest.validate.maxLength) {
                let error: any = `Length of ${label} must be not more than ${rest.validate.maxLength}`;
                setErrormsg(error)
                if (typeof setError === 'function') {
                    setError({ ...formerror, [name]: error })
                }
                setCheckdisabled(true)
                return false
            }
        }
        setErrormsg(undefined)
        if (typeof setError === 'function') {
            const { [name]: _, ...newObject } = formerror;
            setError(newObject)
        }
        setCheckdisabled(false)
        return true
    }

    useEffect(() => {
        if (rest && rest.validate) {
            if (errormsg) {
                if (typeof setdisabled === 'function') {
                    setdisabled(true)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if (inputdiv) inputdiv.classList.add('d-block');
            }
            else {
                if (typeof setdisabled === 'function') {
                    setdisabled(false)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if (inputdiv) inputdiv.classList.remove('d-block');
            }
        }
    }, [value])

    const onCheckClick = (e: any) => {
        e.preventDefault();
        setHover(false)
        onSaveClick()
    }

    useEffect(() => {
        if (rest && rest.validate && rest.validate.required) {
            const feedback: any = document.getElementById(`starred${name}`);
            if (feedback) feedback.classList.remove('d-none');
            setMandatory(true)
        }
    }, [rest])

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (inputRef && inputRef.current && !inputRef.current.contains(event.target)) {
                setHover(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [inputRef]);

    return (
        <div className="pb-3">
            {
                actionButtons ?
                    <div>
                        {hover ?
                            <div>
                                {label ? <Label htmlFor={name} className="form-label fw-bold fs-14">{label}{mandatory ? <span id={`starred${name}`} className="fw-bold text-danger">{" "}*</span> : null}</Label> : null}
                                <div className="d-flex border rounded-3 align-items-center">
                                    <Input type={type} rows={type === 'textarea' ? 8 : 1} className="form-control border-0 ps-2" id={`dts${name}`} placeholder={placeholder} value={value} onChange={(e: any) => onhandleChange(e.target.value)} readOnly={readOnly} disabled={true} 
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    <ButtonGroup>
                                        <Button onClick={(e) => { e.preventDefault(); setHover(false); onChange(resetValue) }} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-close-fill" /> </Button>
                                        <Button onClick={(e) => { onCheckClick(e) }} disabled={checkdisabled} color="primary" className="btn-icon"> <i className="ri-check-fill" /> </Button>
                                    </ButtonGroup>
                                </div>
                                {<FormFeedback id={`${name}feedback`}>{errormsg}</FormFeedback>}
                            </div>
                            : <div>
                                {label ? <Label htmlFor={name} className="form-label fw-bold fs-14 mb-0">{label}<span id={`starred${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label> : null}
                                <div className="hstack justify-content-between topbar-user">
                                    <div className="fs-14 fw-medium form-control border-0 topbar-user text-nowrap text-truncate text-nowrap-ellipsis ps-2">
                                        <span style={{ opacity: 1 }}>{value}</span>
                                    </div>


                                    {
                                        readOnly ? null
                                            :<Button disabled={loading} onClick={() => setHover(true)} color="primary" className="btn-icon btn-soft-primary" id={`btn${name}`}> 
                                            {loading ? <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner> : <i className="ri-edit-2-fill" /> } 
                                        </Button>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div>
                        {label ? <Label htmlFor={name} className="form-label fw-bold fs-14 text-nowrap text-truncate text-nowrap-ellipsis">{label}{mandatory ? <span id={`starred${name}`} className="fw-bold text-danger">{" "}*</span> : null}</Label> : null}
                        <Input  rows={rows ? rows : type === 'textarea' ? 8 : 1} type={type} className="form-control ps-2" id={`dts${name}`} placeholder={placeholder} value={value} onChange={(e: any) => onhandleChange(e.target.value)} readOnly={readOnly} disabled={true}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                            }
                          }}
                        />
                        {<FormFeedback id={`${name}feedback`}>{errormsg}</FormFeedback>}
                    </div>
            }
        </div>
    );
};

export default DtsTextInput;
