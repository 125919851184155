import React from 'react';
import { Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PreviousImport from './PreviousImport';
import BreadCrumb from 'Components/Common/BreadCrumb';

const Universities = (props: any) => {      
    
    document.title="DTS | Zilter";
    return (
        <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title={props.t("import.listing.breadcrumb.recentimports")} pageTitle={props.t("import.listing.breadcrumb.recentimports")} />
                <Row>
                    <PreviousImport />
                </Row>
            </Container>
        </div>
        </React.Fragment>
        
    );
};

export default withTranslation()(Universities);

