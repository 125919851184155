import { useGoogleLogin } from '@react-oauth/google';
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getGoogleCalendarData, postGoogleCalendarSync } from "store/calendarSync/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';

const GmailSync = ({ t, isOpen, setIsOpen, setModal }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const login = useGoogleLogin({
        onSuccess: codeResponse => {
          handleGoogleCalendarSync(codeResponse.code, codeResponse.scope);
        },
        flow: 'auth-code',
        scope: "https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.readonly",
      });
    const handleGoogleCalendarSync = (code: any, scope: any) => {
        const data =
        {
            code: code,      
            scope: scope,
            provider: 'google',
            isSignup: "false"
        }
        const handleSuccess = (body: any): void => {
            toast.success("Account connected successfully!");
            setIsOpen(false);
            setModal(false);
            dispatch(getGoogleCalendarData())
        };
        const handleError = (body: any): void => {
        };
        dispatch(postGoogleCalendarSync(data, handleSuccess, handleError));
    };

    return (
        <>
            <Modal id="flipModal" isOpen={isOpen} modalClassName="zoomIn" centered >
                <ModalHeader className="pb-3 bg-primary" toggle={() => { setIsOpen(false) }}>
                    <h5 className="text-white modal-title" id="exampleModalLabel">{t("userpreferences.connect_your_google_account")}</h5>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            {t("userpreferences.confirm_modal_para1")}
                        </p>
                        <p>
                            <li><strong>{t("userpreferences.google_calendar")}</strong>
                                <p className="mx-3">{t("userpreferences.confirm_modal_para2")}</p>
                            </li>
                        </p>
                        <p>
                            {t("userpreferences.confirm_modal_para3")}
                        </p>
                        <p>
                            {t("userpreferences.confirm_modal_para4")}<span><strong><Link to="#">{t("userpreferences.here")}</Link></strong></span>
                        </p>
                        <p>
                            {t("userpreferences.confirm_modal_para5")}{t("userpreferences.confirm_modal_para4")}<span><strong><Link to="#">{t("userpreferences.privacy_policy")}</Link></strong></span>
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-start">
                    <Button
                        color="success"
                        className="btn btn-success"
                        onClick={() => {
                            login();
                        }}
                    >
                        {t("userpreferences.accept_connect_with_google")}
                    </Button>
                    <Button
                        color="danger"
                        className="btn btn-danger"
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        {t("userpreferences.cancel")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default withTranslation()(GmailSync);