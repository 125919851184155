import { Reducer } from "redux";
import { CGRActionTypes, CGRState } from "./types";


export const initialState: CGRState =
{
    loading: false,
}
const reducer: Reducer<CGRState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case CGRActionTypes.POST_CALL_REQUEST: {
            return { ...state, loading: true };
        }
        case CGRActionTypes.POST_CALL_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case CGRActionTypes.POST_CALL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }   
        default: {
            return state;
        }
    }
};

export { reducer as CGRReducer };
